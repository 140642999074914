import React from 'react';
import { Menu, Divider, Button, Row, Col, Tooltip, Affix } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  ContactsOutlined,
  SolutionOutlined,
  TeamOutlined,
  LogoutOutlined,
  MailOutlined,
  AppstoreOutlined,
  FundOutlined,
} from '@ant-design/icons';
import Swal from 'sweetalert2';
import { path } from '../../../../routes';

const { SubMenu } = Menu;
// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

const MenuAdministrador = (props) => {
  let history = useHistory();
  const { collapsed } = props;
  const LogOut = () => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      focusConfirm: false,
      title: `Cerrar sesión`,
      text: `¿Desea cerrar la sesión?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `Cerrar sesión`,
      confirmButtonColor: '#81973a',
      icon: 'question',
    }).then(function (result) {
      if (result.value) {
        sessionStorage.removeItem('TokenUsuario');
        window.location.href = path.default;
      } else if (result.dismiss) {
      }
    });
  };
  const [openKeys, setOpenKeys] = React.useState(['sub1']);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Affix offsetTop={collapsed ? 120 : 185}>
        <Menu
          mode='inline'
          openKeys={openKeys}
          defaultSelectedKeys={[`${document.location.pathname}`]}
          theme='dark'
          style={{ backgroundColor: '#F0F2F5' }}
          onOpenChange={onOpenChange}
        >
          <SubMenu key='sub1' icon={<MailOutlined />} title='Gestión'>
            <Menu.Item
              onClick={() => history.push(path.consultarUsuarios)}
              key={path.consultarUsuarios}
              icon={<TeamOutlined />}
            >
              Usuarios
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push(path.consultarProveedores)}
              key={path.consultarProveedores}
              icon={<ContactsOutlined />}
            >
              Proveedores
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push(path.consultarEmpresas)}
              key={path.consultarEmpresas}
              icon={<SolutionOutlined />}
            >
              Empresas
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push(path.consultarConsumo)}
              key={path.consultarConsumo}
              icon={<FundOutlined />}
            >
              Consumo
            </Menu.Item>
          </SubMenu>
          {/* <SubMenu key='sub2' icon={<AppstoreOutlined />} title='Módulos'>
            <Menu.Item key='4'>Control de Envases</Menu.Item>
            <Menu.Item key='5'>Inventario</Menu.Item>
            <Menu.Item key='6'>Consumo</Menu.Item>
            <Menu.Item key='7'>Facturación</Menu.Item>
            <Menu.Item key='8'>Estado de Cuenta</Menu.Item>
          </SubMenu> */}
        </Menu>

        <Affix offsetTop={530}>
          <Divider
            style={{
              width: '100%',
            }}
          />
        </Affix>

        <Affix offsetTop={565}>
          <Row
            style={{
              width: '100%',
            }}
          >
            <Col span={4} />
            <Col span={16}>
              {collapsed ? (
                <Tooltip title='Salir' placement='right'>
                  <Button
                    onClick={LogOut}
                    style={{
                      backgroundColor: '#848889',
                      color: 'white',
                      borderRadius: '5px',
                      borderColor: '#848889',
                      width: '100%',
                    }}
                  >
                    {collapsed ? <LogoutOutlined /> : 'SALIR'}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={LogOut}
                  style={{
                    backgroundColor: '#848889',
                    color: 'white',
                    borderRadius: '5px',
                    borderColor: '#848889',
                    width: '100%',
                  }}
                >
                  {collapsed ? <LogoutOutlined /> : 'SALIR'}
                </Button>
              )}
            </Col>
            <Col span={4} />
          </Row>
        </Affix>
      </Affix>
    </>
  );
};
export default MenuAdministrador;
