import React, { Component } from 'react';
import ConsultarUsuarios from '../../../components/gestionDeUsuarios/consultarUsuarios/consultarUsuarios';
import Loading from '../../../components/loading/loading';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';

class consultarUsuarios extends Component {
  componentDidMount() {
    //Usuario logueado en el sistema
    var Token = sessionStorage.getItem('TokenUsuario');
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };

    this.props.onGetLoading();
    this.props.onGetAllUsuarios(usuario.usuario.authmethod);
    this.props.onGetAllEmpresas(usuario.usuario.authmethod);
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <ConsultarUsuarios {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    empresas: state.empresas.empresas,
    persona: state.usuarios.persona,
    usuarios: state.usuarios.usuarios,
    usuariosError: state.usuarios.usuariosError,
    Agregar_Usuario_Status: state.usuarios.Agregar_Usuario_Status,
    Actualizar_Usuario_Status: state.usuarios.Actualizar_Usuario_Status,
    ActivarDesactivar_Usuario_Status:
      state.usuarios.ActivarDesactivar_Usuario_Status,
    loading: state.usuarios.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUsuario: (id) => dispatch(actions.usuarios.calls.getUsuariosById(id)),
    onGetAllUsuarios: (id) =>
      dispatch(actions.usuarios.calls.getAllUsuarios(id)),
    onPostUsuarios: (nombre, data) =>
      dispatch(actions.usuarios.calls.postUsuarios(nombre, data)),
    onPutUsuarios: (usuario) =>
      dispatch(actions.usuarios.calls.putUsuarios(usuario)),
    onDeleteUsuarios: (id, nombre) =>
      dispatch(actions.usuarios.calls.deleteUsuarios(id, nombre)),
    onGetAllEmpresas: (id) =>
      dispatch(actions.empresas.calls.getAllEmpresas(id)),
    cerrarAlertaActivarInactivar: () =>
      dispatch(actions.usuarios.calls.cerrarAlertaActivarInactivar()),
    cerrarAlertaAgregar: () =>
      dispatch(actions.usuarios.calls.cerrarAlertaAgregar()),
    cerrarAlertaActualizar: () =>
      dispatch(actions.usuarios.calls.cerrarAlertaActualizar()),
    onGetLoading: () => dispatch(actions.usuarios.calls.getLoading()),
    onUnGetLoading: () => dispatch(actions.usuarios.calls.ungetLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(consultarUsuarios);
