import actions from '../actions';

const controlDeEnvases = actions.controlDeEnvases;

const initialState = {
  insumos: [],
  controlDeEnvasesError: '',
  loading: false,
};

function controlDeEnvasesApp(state = initialState, action) {
  switch (action.type) {
    case controlDeEnvases.actionsType.GET_INSUMOS_SUCCESS:
      return controlDeEnvases.action.getInsumosAction(state, action.data);
    case controlDeEnvases.actionsType.GET_INSUMOS_FAILURE:
      return {
        ...state,
        insumos: [],
        controlDeEnvasesError: 'No se pudo recuperar la información',
        loading: false,
      };
    case controlDeEnvases.actionsType.GET_INSUMOS_LOADING:
      return controlDeEnvases.action.getInsumosLoading(state);
    default:
      return { ...state };
  }
}

export default controlDeEnvasesApp;
