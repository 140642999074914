import signs from './signs';
import api from '../../../api/api';

//CALLS PARA OBTENER LOS DATOS DE UNA FACTURA ESPECIFICA
function getDatosDeFactura(parametros) {
  return (dispatch) => {
    api
      .post(`api/Invoice/detalleFactura`, { ...parametros })
      .then(function (response) {
        dispatch(signs.getDatosDeFacturaSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getDatosDeFacturaFailure(err));
      });
  };
}

function getDatosDeFacturaLoading() {
  return (dispatch) => {
    dispatch(signs.getDatosDeFacturaLoading());
  };
}

function cerrarAlertaObtenerDatosDeFactura() {
  return (dispatch) => {
    dispatch(signs.cerrarAlertaObtenerDatosFactura());
  };
}

export default {
  getDatosDeFactura: getDatosDeFactura,
  getDatosDeFacturaLoading: getDatosDeFacturaLoading,
  cerrarAlertaObtenerDatosDeFactura: cerrarAlertaObtenerDatosDeFactura,
};
