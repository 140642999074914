import React from 'react';
import { Row, Col } from 'antd';

const Seccion = (props) => {
  return (
    <>
      <Row
        style={{
          background: '#81973a',
          borderRadius: 5,
          marginTop: props.marginTop,
          fontWeight: "bold",
        }}
      >
        <Col style={{ color: 'white', marginLeft: props.marginLeft || "1%" }}>{props.titulo}</Col>
      </Row>
    </>
  );
};
export default Seccion;
