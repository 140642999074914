import signs from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

function getInventarioAction(state, response) {
  return {
    ...state,
    inventario: response.data,
    loading: false,
  };
}

function getInventarioLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

export default {
  actionsType: actionsType,
  sign: signs,
  action: {
    getInventarioAction: getInventarioAction,
    getInventarioLoading: getInventarioLoading,
  },
  calls: calls,
};
