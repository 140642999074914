import actionsType from './actionsTypes';

function getConsumoSuccess(data) {
  return { type: actionsType.GET_CONSUMO_SUCCESS, data: data };
}
function getConsumoFailure(error) {
  return { type: actionsType.GET_CONSUMO_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getAllConsumoSuccess(data) {
  return { type: actionsType.GETALL_CONSUMO_SUCCESS, data: data };
}
function getAllConsumoFailure(error) {
  return { type: actionsType.GETALL_CONSUMO_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function postConsumoSuccess(data) {
  return { type: actionsType.POST_CONSUMO_SUCCESS, data: data };
}
function postConsumoFailure(error) {
  return { type: actionsType.POST_CONSUMO_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function putConsumoSuccess(data) {
  return { type: actionsType.PUT_CONSUMO_SUCCESS, data: data };
}
function putConsumoFailure(error) {
  return { type: actionsType.PUT_CONSUMO_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function deleteConsumoSuccess(data) {
  return { type: actionsType.DELETE_CONSUMO_SUCCESS, data: data };
}
function deleteConsumoFailure(error) {
  return { type: actionsType.DELETE_CONSUMO_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaEliminar() {
  return { type: actionsType.CERRAR_ALERTA_ELIMINAR };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaAgregar() {
  return { type: actionsType.CERRAR_ALERTA_AGREGAR };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaActualizar() {
  return { type: actionsType.CERRAR_ALERTA_ACTUALIZAR };
}

function getConsumoLoading() {
  return { type: actionsType.GET_CONSUMO_LOADING };
}
//--------------------------------------------------------------------------------------
function getAllTiposConsumoSuccess(data) {
  return { type: actionsType.GET_TIPOSCONSUMO_SUCCESS, data: data };
}
function getAllTiposConsumoFailure(error) {
  return { type: actionsType.GET_TIPOSCONSUMO_FAILURE, errorMessage: error };
}

export default {
  getConsumoSuccess: getConsumoSuccess,
  getConsumoFailure: getConsumoFailure,
  getAllConsumoSuccess: getAllConsumoSuccess,
  getAllConsumoFailure: getAllConsumoFailure,
  postConsumoSuccess: postConsumoSuccess,
  postConsumoFailure: postConsumoFailure,
  putConsumoSuccess: putConsumoSuccess,
  putConsumoFailure: putConsumoFailure,
  deleteConsumoSuccess: deleteConsumoSuccess,
  deleteConsumoFailure: deleteConsumoFailure,
  cerrarAlertaEliminar: cerrarAlertaEliminar,
  cerrarAlertaAgregar: cerrarAlertaAgregar,
  cerrarAlertaActualizar: cerrarAlertaActualizar,
  getConsumoLoading: getConsumoLoading,
  getAllTiposConsumoSuccess: getAllTiposConsumoSuccess,
  getAllTiposConsumoFailure: getAllTiposConsumoFailure,
};
