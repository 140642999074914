import React, { useState, useEffect } from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import IconoUsuario from '../../assets/icono-usuario.png';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import Cookies from "js-cookie";
import {
  Row,
  Col,
  Divider,
  Menu,
  Dropdown,
  Badge,
  Drawer,
  Button,
  Empty,
} from 'antd';
import './userPanel.css';
import { LogoutOutlined, CaretRightOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { path } from '../../routes';
import Actions from '../../data/actions';
import CardsNotificaciones from './CardsNotificaciones';

const UserPanel = () => {
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let history = useHistory();
  let dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [Notificaciones, setNotificaciones] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const notificaciones = useSelector(
    (state) => state.notificaciones.notificaciones,
  );
  const notificacionesNuevas = useSelector(
    (state) => state.notificaciones.notificacionesNuevas,
  );
  const notificacionesNuevasConsumo = useSelector(
    (state) => state.notificaciones.notificacionesNuevasConsumo,
  );

  const ContarNoLeidas = useSelector(
    (state) => state.notificaciones.ContarNoLeidas,
  );


  useEffect(() => {
    dispatch(
      Actions.notificaciones.calls.getAllNotificacionesNuevas(
        usuario.usuario.Compania,
      ),
    );
    dispatch(
      Actions.notificaciones.calls.getAllNotificacionesNuevasConsumo(
        usuario.usuario.Compania,
      ),
    );
  }, []);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      dispatch(
        Actions.notificaciones.calls.getAllNotificacionesNuevasConsumo(
          usuario.usuario.Compania,
        ),

      );
      setRefresh(!refresh)
    }, 100000);
    return () => clearTimeout(timer1);
  }, [refresh]);

  useEffect(() => {
    if (ContarNoLeidas) {
      let notificacionesActuales = [];
      for (let index in notificaciones) {
        let NotificacionActual = notificaciones[index];
        if (NotificacionActual.Notificacion_Leida === false) {
          let Fecha = NotificacionActual.Fecha_Notificacion.split('T');
          Fecha = Fecha[0].split('-');
          Fecha = `${Fecha[2]}/${Fecha[1]}/${Fecha[0]}`;
          NotificacionActual = {
            key: NotificacionActual.ID_Notificacion,
            Modulo:
              NotificacionActual.ID_Proveedor === 1
                ? 'Control de Envases'
                : NotificacionActual.ID_Proveedor === 3
                ? 'Facturación'
                : NotificacionActual.ID_Proveedor === 2
                ? 'Inventario'
                : NotificacionActual.ID_Proveedor === 4
                ? 'Consumo'
                : 'No Definido',
            Fecha: Fecha,
            Categoría: NotificacionActual.TipoNotificacion.Tipo_Notificacion,
            Mensaje: NotificacionActual.Mensaje_Notificacion,
          };
          notificacionesActuales.push(NotificacionActual);
        }
      }
      return setNotificaciones(notificacionesActuales);
    } else if (!ContarNoLeidas) {
      let todasNotificaciones = notificacionesNuevas.concat(notificacionesNuevasConsumo);
      let notificacionesActuales = [];
      for (let index in todasNotificaciones) {
        let NotificacionActual = todasNotificaciones[index];
        console.log("NotificacionActual",NotificacionActual)
        let Fecha = NotificacionActual.Fecha_Notificacion.split('T');
        Fecha = Fecha[0].split('-');
        Fecha = `${Fecha[2]}/${Fecha[1]}/${Fecha[0]}`;
        NotificacionActual = {
          key: NotificacionActual.ID_Notificacion,
          Modulo:
            NotificacionActual.ID_Proveedor === 1
              ? 'Control de Envases'
              : NotificacionActual.ID_Proveedor === 3
              ? 'Facturación'
              : NotificacionActual.ID_Proveedor === 2
              ? 'Inventario'
              : NotificacionActual.ID_Proveedor === 4
              ? 'Consumo'
              : 'No Definido',
          Fecha: Fecha,
          Categoría: NotificacionActual.TipoNotificacion.Tipo_Notificacion,
          Mensaje: NotificacionActual.Mensaje_Notificacion,
        };
        notificacionesActuales.push(NotificacionActual);
      }
      return setNotificaciones(notificacionesActuales);
    }
  }, [notificacionesNuevas, notificacionesNuevasConsumo, notificaciones, ContarNoLeidas]);

  var Letra = usuario.usuario.unique_name.split(' ');
  Letra = Letra[0].split('');

  let nombreResponsive = '';
  let apellidoResponsive = '';
  let nombre = usuario.usuario.unique_name.split(' ');
  let apellido = usuario.usuario.unique_name.split(' ');
  if (nombre[0].length > 10) {
    nombre = nombre[0].split('');
    for (let i = 0; i < 10; i++) {
      nombreResponsive += nombre[i];
    }
  } else {
    nombreResponsive = nombre[0];
  }

  if (apellido[1].length > 10) {
    apellido = apellido[1].split('');
    for (let i = 0; i < 10; i++) {
      apellidoResponsive += apellido[i];
    }
  } else {
    apellidoResponsive = apellido[1];
  }

  const LogOut = () => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      focusConfirm: false,
      title: `Cerrar sesión`,
      text: `¿Desea cerrar la sesión?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `Cerrar sesión`,
      confirmButtonColor: '#81973a',
      icon: 'question',
    }).then(function (result) {
      if (result.value) {
        sessionStorage.removeItem('TokenUsuario');
        window.location.href = path.default;
      } else if (result.dismiss) {
      }
    });
  };
  const menu = (
    <Menu>
      <Menu.Item key='0' icon={<LogoutOutlined />} className='MenuSalir'>
        <span onClick={LogOut}>Salir</span>
      </Menu.Item>
    </Menu>
  );

  const openDrawerFunction = () => {
    setOpenDrawer(!openDrawer);
  };

  const closeDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <>
      {/* DRAWER */}
      <Drawer
        width={'45%'}
        title={
          usuario.usuario.role === '2' ? (
            <>
              <span>Notificaciones</span>

              <Button
                className='BotonPanelNotificaciones'
                onClick={() => {
                  history.push({ pathname: path.moduloNotificaciones });
                  dispatch(
                    Actions.sideBar.calls.postOpcionActiva(
                      path.moduloNotificaciones,
                    ),
                  );
                  setOpenDrawer(!openDrawer);
                }}
              >
                Ir al Panel
              </Button>
            </>
          ) : (
            <span>Notificaciones</span>
          )
        }
        visible={openDrawer}
        onClose={closeDrawer}
        closable={false}
      >
        {usuario.usuario.role === '2' ? (
          <>
            {Notificaciones.length === 0 ? (
              <Empty description='No hay nuevas notificaciones' />
            ) : (
              Notificaciones.map((Notificacion) => (
                <CardsNotificaciones
                  key={Notificacion.key}
                  Notificacion={Notificacion}
                  setOpenDrawer={setOpenDrawer}
                />
              ))
            )}
          </>
        ) : (
          'Contenido de notificaciones'
        )}
      </Drawer>

      <Row justify='end'>
        <Col
          lg={{ span: 1 }}
          md={{ span: 2 }}
          sm={{ span: 2 }}
          xs={{ span: 2 }}
        >
          {usuario.usuario.role === '1' ||
          usuario.usuario.role === '3' ||
          usuario.usuario.role === '4' ? (
            // <Badge
            //   count={1}
            //   style={{
            //     backgroundColor: 'white',
            //     color: '#81973a',
            //     marginTop: -5,
            //     marginRight: 35,
            //   }}
            // >
              <Avatar
                //onClick={openDrawerFunction}
                size={35}
                src={IconoUsuario}
                style={{ backgroundColor: '#687D33', marginTop: -10 }}
              />
            // </Badge>
          ) : (
            <Badge
              count={Notificaciones.length}
              style={{
                backgroundColor: 'white',
                color: '#81973a',
                marginTop: -5,
                marginRight: 35,
              }}
            >
              <Avatar
                onClick={openDrawerFunction}
                size={35}
                style={{ backgroundColor: '#687D33', marginTop: -10 }}
              >
                {Letra[0]}
              </Avatar>
            </Badge>
          )}
        </Col>
        <Divider type='vertical' style={{ visibility: 'hidden' }} />
        <Col
          lg={{ span: 5 }}
          md={{ span: 10 }}
          sm={{ span: 10 }}
          xs={{ span: 11 }}
          style={{
            lineHeight: '1em',
          }}
        >
          <Row style={{ marginTop: 15 }}>
            <Col
              lg={{ offset: 0, span: 24 }}
              md={{ offset: 0, span: 24 }}
              sm={{ offset: 0, span: 24 }}
              xs={{ offset: 0, span: 24 }}
            >
              <Dropdown overlay={menu} trigger={['click']}>
                <a href='/'>
                  {nombreResponsive + ' ' + apellidoResponsive + ' '}
                  <CaretRightOutlined rotate={90} />
                </a>
              </Dropdown>
            </Col>
            <Col
              lg={{ offset: 0, span: 24 }}
              md={{ offset: 0, span: 10 }}
              sm={{ offset: 0, span: 10 }}
              xs={{ offset: 0, span: 24 }}
            >
              <h5 style={{ color: 'white' }}>
                {usuario.usuario.role === '1'
                  ? 'Administrador'
                  : usuario.usuario.role === '2'
                  ? 'Cliente'
                  : usuario.usuario.role === '3'
                  ? 'Consult Manager'
                  : 'Cliente Administrador'}
              </h5>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default UserPanel;
