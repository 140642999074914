import React from 'react';
import PantallaDeNoAcceso from '../../pantallaDeNoAcceso';
import EstadoDeCuenta from '../../../assets/Imagen6-Carousel.png';

const ModuloEstadoDeCuentaNoAcceso = () => {
  return (
    <>
      <PantallaDeNoAcceso src={EstadoDeCuenta} alt={'Estado de Cuenta'} />
    </>
  );
};
export default ModuloEstadoDeCuentaNoAcceso;
