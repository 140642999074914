import signs from './sings';
import api from '../../../api/api';

function login(data) {
  return (dispatch) => {
    api
      .post(`api/Login`, { ...data })
      .then(function (response) {
        dispatch(signs.loginSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.loginFailure(err));
      });
  };
}

function logout() {
  return (dispatch) => {
    dispatch(signs.logoutSuccess());
  };
}

function startLoading() {
  return (dispatch) => {
    dispatch(signs.startLoading());
  };
}

function limpiarState() {
  return (dispatch) => {
    dispatch(signs.LimpiarStateLogin());
  };
}

export default {
  login: login,
  logout: logout,
  startLoading: startLoading,
  limpiarState: limpiarState,
};
