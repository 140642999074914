const actionsType = {
  GET_COMPROBANTE_SUCCESS: 'GET_COMPROBANTE_SUCCESS',
  GET_COMPROBANTE_FAILURE: 'GET_COMPROBANTE_FAILURE',

  SET_CODIGO_CLIENTE: 'SET_CODIGO_CLIENTE',
  CLEAR_CODIGO_CLIENTE: 'CLEAR_CODIGO_CLIENTE',

  GET_COMPROBANTE_LOADING: 'GET_COMPROBANTE_LOADING',

  LIMPIAR_STATUS: 'LIMPIAR_STATUS',
};

export default actionsType;
