import actions from '../actions';

const login = actions.login;

const initialState = {
  login: false,
  loginError: '',
  loading: false,
  Login_Status: null,
};

function loginApp(state = initialState, action) {
  switch (action.type) {
    case login.actionsType.LOGIN_SUCCESS:
      return login.action.loginAction(state, action);
    case login.actionsType.LOGIN_FAILURE:
      return {
        ...state,
        loginError:
          "No se pudo recuperar la información de 'Login' del servidor",
        loading: false,
        Login_Status: action.errorMessage.response.status,
      };
    case login.actionsType.LOGOUT_SUCCESS:
      return login.action.logoutAction(state);
    case login.actionsType.START_LOADING:
      return login.action.startLoading(state);
    case login.actionsType.LIMPIAR_STATE_LOGIN:
      return login.action.limpiarStateLogin();
    default:
      return { ...state };
  }
}

export default loginApp;
