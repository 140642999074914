import React, { useState, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { path } from '../../../routes';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import {
  EditOutlined,
  BankOutlined,
  InfoCircleOutlined,
  KeyOutlined,
  PhoneOutlined,
  LinkOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import {
  Table,
  Input,
  Button,
  Space,
  Divider,
  Tooltip,
  Row,
  Col,
  Modal,
  Form,
  notification,
  Select,
  Switch,
} from 'antd';
import './consultarEmpresas.css';
import { useEffect } from 'react';
const { TextArea } = Input;
const { Option } = Select;

const ConsultarEmpresas = (props) => {
  //Usuario logueado en el sistema
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalActualizarEmpresa, setModalActualizarEmpresa] = useState(false);
  let searchInput = useRef();

  //-------------------------Values del modal actualizar-----------------------------
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [sitioWeb, setSitioWeb] = useState('');
  const [contactoEmergencia, setContactoEmergencia] = useState('');
  const [pais, setPais] = useState('');

  const [codigoControlEnvases, setCodigoControlEnvases] = useState('');
  const [codigoInventario, setCodigoInventario] = useState('');
  const [codigoConsumo, setcodigoConsumo] = useState('');
  const [codigoFacturación, setCodigoFacturación] = useState('');

  const menuPaises = (
    <Select suffixIcon={<CaretRightOutlined rotate={90} />} placeholder='País'>
      {props.paises.map((elemento) => (
        <Option key={elemento.ID_Pais} value={elemento.ID_Pais}>
          {elemento.Nombre}
        </Option>
      ))}
    </Select>
  );

  useEffect(() => {
    if (props.empresa.length !== 0) {
      setNombre(props.empresa.Nombre);
      setDescripcion(props.empresa.Descripcion);
      setDireccion(props.empresa.Direccion);
      setTelefono(props.empresa.Telefono);
      setSitioWeb(props.empresa.Web_site);
      setContactoEmergencia(props.empresa.Contacto_Emergencia);
      setPais(props.empresa.Pais);
      props.empresa.providers.map(function (elemento) {
        if (elemento.providerId === 1) {
          setCodigoControlEnvases(elemento.uniqueCode);
        } else if (elemento.providerId === 2) {
          setCodigoInventario(elemento.uniqueCode);
        } else if (elemento.providerId === 3) {
          setCodigoFacturación(elemento.uniqueCode);
        } else if (elemento.providerId === 4) {
          setcodigoConsumo(elemento.uniqueCode);
        }
      });
    }
  }, [props.empresa]);

  //-------------USEEFFECTS PARA AGREGAR EMPRESA--------------------------------

  useEffect(() => {
    if (props.Agregar_Empresa_Status === 200) {
      notification.open({
        type: 'success',
        message: '¡Empresa agregada correctamente!',
        description: 'La empresa se agregó correctamente.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    } else if (
      props.Agregar_Empresa_Status === 400 ||
      props.Agregar_Empresa_Status === 404 ||
      props.Agregar_Empresa_Status === 500
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    }
  }, [props.Agregar_Empresa_Status]);

  //-------------USEEFFECTS PARA ACTUALIZAR EMPRESA--------------------------------
  useEffect(() => {
    if (props.Actualizar_Empresa_Status === 200) {
      props.onGetAllEmpresas(usuario.usuario.authmethod);
      notification.open({
        type: 'success',
        message: '¡Empresa actualizada correctamente!',
        description: 'La empresa se actualizó correctamente.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActualizar();
        },
      });
    } else if (
      props.Actualizar_Empresa_Status === 400 ||
      props.Actualizar_Empresa_Status === 404 ||
      props.Actualizar_Empresa_Status === 500
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActualizar();
        },
      });
    }
  }, [props.Actualizar_Empresa_Status]);

  //-------------USEEFFECTS PARA ELIMINAR EMPRESAS--------------------------------
  useEffect(() => {
    if (props.Eliminar_Empresa_Status === 200) {
      props.onGetAllEmpresas(usuario.usuario.authmethod);
      notification.open({
        type: 'success',
        message: '¡Acción realizada con éxito!',
        description: 'La acción se realizó con éxito.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaEliminar();
        },
      });
    } else if (
      props.Eliminar_Empresa_Status === 400 ||
      props.Eliminar_Empresa_Status === 404 ||
      props.Eliminar_Empresa_Status === 500
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaEliminar();
        },
      });
    }
  }, [props.Eliminar_Empresa_Status]);

  const datos = props.empresas.map(function (elemento) {
    let CodigoEmpresa = '';
    elemento.providers.forEach((proveedor) => {
      if (proveedor.providerId === 3) {
        CodigoEmpresa = proveedor.uniqueCode;
      }
    });
    return {
      key: elemento.ID_CompaniaCliente,
      código: CodigoEmpresa,
      nombre: elemento.Nombre,
      descripción: elemento.Descripcion,
      dirección: elemento.Direccion,
      teléfono: elemento.Telefono,
      web: elemento.Web_site,
      contacto: elemento.Contacto_Emergencia,
      país: elemento.NombrePais,
      estado: elemento.Activo === true ? 'Activa' : 'Inactiva',
      estadoSwitch: elemento.Activo,
    };
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
            className='BotonRestablecer'
          >
            Reiniciar
          </Button>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
            className='BotonBuscar'
          >
            Aceptar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <>
        <Tooltip title={`Buscar ${dataIndex}`}>
          <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : '#81973a',
              float: 'left',
            }}
            className='iconoBuscar'
          />
          {/* <Divider
            type='vertical'
            style={{
              height: '50%',
              marginTop: '50%',
              float: 'right',
              borderWidth: '2px',
              borderColor: 'white',
              marginRight: '1px',
            }}
          /> */}
        </Tooltip>
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  //--------------------Funciones de Modal actualizar empresa-----------------
  const ActualizarEmpresa = (id) => {
    props.onGetEmpresa(id);
    setTimeout(() => {
      setModalActualizarEmpresa(!modalActualizarEmpresa);
    }, 1000);
  };

  //---------------------------------Función actualizar empresa----------------------------
  //No se permite actualizar una empresa si no tiene al menos un módulo asociado a ella
  //Por cuestiones de tiempo y seguir con los sprints, se soluciona la validación de los
  //campos de los módulos que no permitan vacíos ni espacios en blanco para actualizar la empresa, pero
  //esta validación puede mejorar sustancialmente
  const handleSubmitActualizar = (values) => {
    if (
      values.codigoUnicoControlEnvases === undefined &&
      values.codigoUnicoInventario === undefined &&
      values.codigoUnicoConsumo === undefined &&
      values.codigoUnicoFacturacion === undefined
    ) {
      Swal.fire(
        'Atención',
        '¡La empresa debe tener al menos un módulo asociado!',
        'info',
      );
    } else {
      if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoControlEnvases.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoInventario.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoConsumo.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (values.codigoUnicoFacturacion.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoControlEnvases.trim() === '' &&
          values.codigoUnicoInventario.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoControlEnvases.trim() === '' &&
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (
          values.codigoUnicoConsumo.trim() === '' &&
          values.codigoUnicoFacturacion.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === '' &&
          values.codigoUnicoFacturacion.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases.trim() === '' &&
        values.codigoUnicoInventario.trim() === '' &&
        values.codigoUnicoConsumo.trim() === '' &&
        values.codigoUnicoFacturacion.trim() === ''
      ) {
        Swal.fire(
          'Atención',
          '¡La empresa debe tener al menos un módulo asociado!',
          'info',
        );
      } else {
        const Empresa = {
          ID_CompaniaCliente: props.empresa.ID_CompaniaCliente,
          Nombre: values.nombre,
          Descripcion: values.descripcion,
          Activo: props.empresa.Activo,
          Pais: values.pais,
          Direccion: values.direccion,
          Telefono: values.telefono,
          Web_site: values.website,
          Contacto_Emergencia: values.contactoEmergencia,
          providers: [
            {
              providerId: 1,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoControlEnvases,
            },
            {
              providerId: 2,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoInventario,
            },
            {
              providerId: 3,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoFacturacion,
            },
            {
              providerId: 4,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoConsumo,
            },
          ],
        };
        props.onGetEmpresasLoading();
        props.onPutEmpresas(Empresa);
      }
    }
  };

  const handleCancelActualizar = () => {
    setModalActualizarEmpresa(!modalActualizarEmpresa);
  };

  //--------------------Funciones de eliminar empresa---------------------
  const handleSwitchChange = (record) => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      focusConfirm: false,
      title: `${
        record.estadoSwitch === false ? 'Activar' : 'Inactivar'
      } Empresa`,
      text: `¿Desea ${
        record.estadoSwitch === false ? 'activar' : 'inactivar'
      } el estado de la empresa? ${
        record.estadoSwitch === true
          ? 'Si la empresa tiene usuarios activos asociados a ella, entonces estos usuarios serán inactivados también.'
          : 'Los usuarios que están asociados a esta empresa y anteriormente estaban activos, serán activados nuevamente.'
      }`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `${
        record.estadoSwitch === false ? 'Activar' : 'Inactivar'
      }`,
      confirmButtonColor: '#81973a',
      icon: 'question',
    }).then(function (result) {
      if (result.value) {
        if (
          record.key === parseInt(usuario.usuario.Compania) &&
          record.estadoSwitch === true
        ) {
          Swal.fire(
            '¡Acción no permitida!',
            'No puedes inactivar la empresa a la que está asociado tu usuario.',
            'info',
          );
        } else {
          props.onGetEmpresasLoading();
          props.onDeleteEmpresas(record.key, usuario.usuario.unique_name);
        }
      } else if (result.dismiss) {
      }
    });
  };

  //--------------------Funciones de busqueda en columnas---------------------
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Código',
      dataIndex: 'código',
      key: 'código',
      width: '10%',
      ...getColumnSearchProps('código'),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: '40%',
      ...getColumnSearchProps('nombre'),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripción',
      key: 'descripción',
      width: '30%',
      ...getColumnSearchProps('descripción'),
    },
    {
      title: 'País',
      dataIndex: 'país',
      key: 'país',
      width: '14%',
      ...getColumnSearchProps('país'),
    },
    {
      title: 'Teléfono',
      dataIndex: 'teléfono',
      key: 'teléfono',
      width: '16%',
      ...getColumnSearchProps('teléfono'),
    },
    {
      title: 'Contacto Emergencia',
      dataIndex: 'contacto',
      key: 'contacto',
      width: '25%',
      ...getColumnSearchProps('contacto'),
    },
    {
      title: 'Dirección',
      dataIndex: 'dirección',
      key: 'dirección',
      width: '25%',
      ...getColumnSearchProps('dirección'),
    },
    {
      title: 'Sitio Web',
      dataIndex: 'web',
      key: 'web',
      width: '20%',
      ...getColumnSearchProps('web'),
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: '10%',
      filters: [
        { text: 'Activa', value: 'Activa' },
        { text: 'Inactiva', value: 'Inactiva' },
      ],
      onFilter: (value, record) => record.estado.indexOf(value) === 0,
    },
    {
      fixed: 'right',
      title: 'Acciones',
      width: '10%',
      align: 'center',
      render: (record) => (
        <>
          <Tooltip title='Editar'>
            <EditOutlined onClick={() => ActualizarEmpresa(record.key)} />
          </Tooltip>
          <Divider type='vertical' style={{ visibility: 'hidden' }} />
          <Switch
            checkedChildren='Inactivar'
            unCheckedChildren='Activar'
            checked={record.estadoSwitch}
            onChange={() => handleSwitchChange(record)}
          />
        </>
      ),
    },
  ];

  //------------------------Col Form Item Responsive----------------------------
  const FormItemResponsive = (props) => {
    return (
      <Col
        lg={{ offset: 1, span: 21 }}
        md={{ offset: 1, span: 21 }}
        sm={{ offset: 1, span: 21 }}
        xs={{ offset: 1, span: 21 }}
      >
        {props.FormItem}
      </Col>
    );
  };

  return (
    <>
      {/* Modal para actualizar una empresa*/}
      <Modal
        title='Actualizar Empresa'
        visible={modalActualizarEmpresa}
        onCancel={handleCancelActualizar}
        footer={null}
        destroyOnClose
        centered
      >
        <Form
          name='Actualizar_Empresa_Form'
          initialValues={{
            nombre: nombre,
            descripcion: descripcion,
            direccion: direccion,
            telefono: telefono,
            website: sitioWeb,
            contactoEmergencia: contactoEmergencia,
            pais: pais,
            codigoUnicoControlEnvases: codigoControlEnvases,
            codigoUnicoInventario: codigoInventario,
            codigoUnicoConsumo: codigoConsumo,
            codigoUnicoFacturacion: codigoFacturación,
          }}
          onFinish={handleSubmitActualizar}
        >
          <Row>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='nombre'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 100,
                        message: 'Mínimo 1 carácter, máximo 100 carácteres',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Nombre'
                      prefix={<BankOutlined />}
                      suffix={
                        <Tooltip
                          title='Nombre de la Empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='descripcion'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 200,
                        message: 'Mínimo 1 carácter, máximo 200 carácteres',
                      },
                    ]}
                  >
                    <TextArea rows={3} placeholder='Descripción' />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='direccion'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 200,
                        message: 'Mínimo 1 carácter, máximo 200 carácteres',
                      },
                    ]}
                  >
                    <TextArea rows={3} placeholder='Dirección de la empresa' />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    validateTrigger='onSubmit'
                    name='telefono'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 20,
                        message: 'Mínimo 1 carácter, máximo 20 carácteres',
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: 'Solo se admiten números',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Teléfono'
                      prefix={<PhoneOutlined />}
                      suffix={
                        <Tooltip
                          title='Teléfono de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='website'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 100,
                        message: 'Mínimo 1 carácter, máximo 100 carácteres',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Sitio Web'
                      prefix={<LinkOutlined />}
                      suffix={
                        <Tooltip
                          title='Sitio Web de la Empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    validateTrigger='onSubmit'
                    name='contactoEmergencia'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      // {
                      //   min: 1,
                      //   max: 20,
                      //   message: 'Mínimo 1 carácter, máximo 20 carácteres',
                      // },
                      // {
                      //   pattern: /^([0-9])*$/,
                      //   message: 'Solo se admiten números',
                      // },
                    ]}
                  >
                    <Input
                      placeholder='Contacto Emergencia'
                      prefix={<PhoneOutlined />}
                      suffix={
                        <Tooltip
                          title='Contacto en caso de emergencia'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
            </Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='pais'
                    rules={[
                      { required: true, message: '¡Este campo es requerido!' },
                    ]}
                  >
                    {menuPaises}
                  </Form.Item>
                }
              />

              <Divider orientation='left' style={{ marginTop: '-2%' }}>
                Control de Envases
              </Divider>
              <FormItemResponsive
                FormItem={
                  <Form.Item name='codigoUnicoControlEnvases'>
                    <Input
                      type='text'
                      placeholder='Código único'
                      prefix={<KeyOutlined />}
                      suffix={
                        <Tooltip
                          title='Código de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />

              <Divider orientation='left' style={{ marginTop: '-2%' }}>
                Inventario
              </Divider>
              <FormItemResponsive
                FormItem={
                  <Form.Item name='codigoUnicoInventario'>
                    <Input
                      type='text'
                      placeholder='Código único'
                      prefix={<KeyOutlined />}
                      suffix={
                        <Tooltip
                          title='Código de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <Divider orientation='left' style={{ marginTop: '-2%' }}>
                Consumo
              </Divider>
              <FormItemResponsive
                FormItem={
                  <Form.Item name='codigoUnicoConsumo'>
                    <Input
                      type='text'
                      placeholder='Código único'
                      prefix={<KeyOutlined />}
                      suffix={
                        <Tooltip
                          title='Código de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />

              <Divider orientation='left' style={{ marginTop: '-2%' }}>
                Facturación / Estado de Cuenta
              </Divider>
              <FormItemResponsive
                FormItem={
                  <Form.Item name='codigoUnicoFacturacion'>
                    <Input
                      type='text'
                      placeholder='Código único'
                      prefix={<KeyOutlined />}
                      suffix={
                        <Tooltip
                          title='Código de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
            </Col>
          </Row>

          <Row justify='center'>
            <Col
              lg={{ offset: 0, span: 8 }}
              md={{ offset: 0, span: 8 }}
              sm={{ offset: 0, span: 8 }}
              xs={{ offset: 0, span: 8 }}
            >
              <Button htmlType='submit' className='botonActualizarEmpresa'>
                Actualizar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Pantalla de consulta de empresas */}
      <Row justify='end' style={{ marginTop: '2%' }}>
        <Col
          lg={{ offset: 0, span: 4 }}
          md={{ offset: 0, span: 5 }}
          sm={{ offset: 0, span: 5 }}
          xs={{ offset: 0, span: 7 }}
        >
          <Button
            onClick={() => history.push({ pathname: path.agregarEmpresas })}
            className='botonAgregarUsuario'
          >
            Agregar
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: '1%' }}>
        <Col
          lg={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          xs={{ offset: 0, span: 24 }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={datos}
            scroll={{ x: 2500, y: 420 }}
            pagination={{
              defaultCurrent: 1,
              total: datos.length,
              responsive: true,
              showSizeChanger: true,
              size: 'small',
              defaultPageSize: 20,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ConsultarEmpresas;
