import React, { useState, useEffect } from 'react';
import {
  Table,
  Modal,
  Row,
  Col,
  Typography,
  Tooltip,
  notification,
} from 'antd';
import { CheckSquareOutlined  } from '@ant-design/icons';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../data/actions';
const { Column } = Table;

const NotificacionesConsumo = (props) => {
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let { Notificaciones } = props;
  let dispatch = useDispatch();

  const [AbrirModal, setAbrirModal] = useState(false);
  const [Notificacion, setNotificacion] = useState({});
  const [TablaNotificaciones, setTablaNotificaciones] = useState([]);

  const Marcar_Notificacion_Leida_Status = useSelector(
    (state) => state.notificaciones.Marcar_Notificacion_Leida_Status,
  );
  const Marcar_Notificacion_Leida_Panel_Status = useSelector(
    (state) => state.notificaciones.Marcar_Notificacion_Leida_Panel_Status,
  );
  const Notificacion_Seleccionada = useSelector(
    (state) => state.notificaciones.Notificacion_Seleccionada,
  );
  const Tab_Activo = useSelector((state) => state.notificaciones.Tab_Activo);
  const ContarNoLeidas = useSelector(
    (state) => state.notificaciones.ContarNoLeidas,
  );
  const FechaInicio = useSelector((state) => state.notificaciones.FechaInicio);
  const FechaFin = useSelector((state) => state.notificaciones.FechaFin);

  useEffect(() => {
    if (
      TablaNotificaciones !== 0 &&
      Marcar_Notificacion_Leida_Status === 200 &&
      Notificacion_Seleccionada !== null &&
      Tab_Activo === 'Consumo'
    ) {
      TablaNotificaciones.map(function (NotificacionActual) {
        if (NotificacionActual.key === Notificacion_Seleccionada) {
          setNotificacion(NotificacionActual);
          setAbrirModal(true);
        }
      });
    }
  }, [
    Notificacion_Seleccionada,
    TablaNotificaciones,
    Marcar_Notificacion_Leida_Status,
    Tab_Activo,
  ]);

  useEffect(() => {
    if (
      Marcar_Notificacion_Leida_Panel_Status === 200 &&
      Tab_Activo === 'Consumo' &&
      !ContarNoLeidas
    ) {
      setAbrirModal(true);
      dispatch(
        Actions.notificaciones.calls.getAllNotificacionesNuevasConsumo(
          usuario.usuario.Compania,
        ),
      );
      dispatch(
        Actions.notificaciones.calls.getAllNotificaciones(
          usuario.usuario.Compania,
        ),
      );
      dispatch(
        Actions.notificaciones.calls.cerrarAlertaObtenerNotificaciones(),
      );
    } else if (
      Marcar_Notificacion_Leida_Panel_Status === 200 &&
      Tab_Activo === 'Consumo' &&
      ContarNoLeidas
    ) {
      setAbrirModal(true);
      dispatch(
        Actions.notificaciones.calls.getAllNotificacionesConFecha({
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: FechaInicio,
          FechaFin: FechaFin,
        }),
      );
      dispatch(
        Actions.notificaciones.calls.cerrarAlertaObtenerNotificaciones(),
      );
    } else if (
      (Marcar_Notificacion_Leida_Panel_Status === 400 &&
        Tab_Activo === 'Consumo') ||
      (Marcar_Notificacion_Leida_Panel_Status === 404 &&
        Tab_Activo === 'Consumo')
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes en la petición.',
        duration: 3,
        onClose: () => {
          dispatch(
            Actions.notificaciones.calls.cerrarAlertaObtenerNotificaciones(),
          );
        },
      });
    } else if (
      (Marcar_Notificacion_Leida_Panel_Status === 500 &&
        Tab_Activo === 'Consumo') ||
      (Marcar_Notificacion_Leida_Panel_Status === 503 &&
        Tab_Activo === 'Consumo')
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que el servidor está fuera de servicio.',
        duration: 3,
        onClose: () => {
          dispatch(
            Actions.notificaciones.calls.cerrarAlertaObtenerNotificaciones(),
          );
        },
      });
    }
  }, [Marcar_Notificacion_Leida_Panel_Status, Tab_Activo]);

  useEffect(() => {
    let NotificacionesAjustadas = [];
    for (let i in Notificaciones) {
      let NotificacionActual = Notificaciones[i];
      NotificacionActual = {
        key: NotificacionActual.key,
        Fecha: NotificacionActual.Fecha,
        Estado:
          NotificacionActual.Estado === 'Leída' ? (
            <Tooltip title='Leída'>
              <CheckSquareOutlined style={{ fontSize: '18px', color: '#81973a' }} />
            </Tooltip>
          ) : (
            ''
          ),
        Categoría: NotificacionActual.Categoría,
        Mensaje: NotificacionActual.Mensaje,
      };
      NotificacionesAjustadas.push(NotificacionActual);
    }
    return setTablaNotificaciones(NotificacionesAjustadas);
  }, [Notificaciones]);

  const CerrarNotificacion = () => {
    dispatch(
      Actions.notificaciones.calls.limpiarIdDeNotificacionSeleccionada(),
    );
    dispatch(Actions.notificaciones.calls.cerrarAlertaObtenerNotificaciones());
    setAbrirModal(false);
  };

  return (
    <>
      <Modal
        title='Consumo'
        visible={AbrirModal}
        closable
        onCancel={() => CerrarNotificacion()}
        footer={false}
        centered
        destroyOnClose
      >
        <Row justify='center'>
          <Col
            lg={{ span: 11 }}
            md={{ span: 11 }}
            sm={{ span: 11 }}
            xs={{ span: 11 }}
          >
            <Typography.Text strong style={{ float: 'right' }}>
              Fecha: {Notificacion.Fecha}
            </Typography.Text>
          </Col>
          <Col
            lg={{ offset: 2, span: 11 }}
            md={{ offset: 2, span: 11 }}
            sm={{ offset: 2, span: 11 }}
            xs={{ offset: 2, span: 11 }}
          >
            <Typography.Text strong >
              Categoría: {Notificacion.Categoría}
            </Typography.Text>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: '2%' }}>
          <Col
            style={{ textAlign: 'center' }}
            lg={{ span: 20 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 20 }}
          >
            {Notificacion.Mensaje}
          </Col>
        </Row>
      </Modal>
      <Table
        bordered
        className='TablaNotificaciones'
        size='small'
        scroll={{ x: 1000, y: 800 }}
        dataSource={TablaNotificaciones}
        onRow={(registro) => {
          return {
            onClick: () => {
              setNotificacion(registro);
              if (registro.Estado === '') {
                dispatch(
                  Actions.notificaciones.calls.marcarNotificacionLeidaPanel({
                    IdNotificacion: registro.key,
                  }),
                );
              } else {
                setAbrirModal(!AbrirModal);
              }
            },
          };
        }}
      >
        <Column title='Fecha' dataIndex='Fecha' width='10%' align='left' />
        <Column title='Leído' dataIndex='Estado' width='10%' align='center' />
        <Column title='Mensaje' dataIndex='Mensaje' width='70%' />
        <Column
          title='Categoría'
          dataIndex='Categoría'
          width='10%'
          align='center'
        />
      </Table>
    </>
  );
};
export default NotificacionesConsumo;
