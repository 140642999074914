import actions from '../actions';

const empresas = actions.empresas;

const initialState = {
  empresa: [],
  empresas: [],
  paises: [],
  empresasError: '',
  Agregar_Empresa_Status: null,
  Actualizar_Empresa_Status: null,
  Eliminar_Empresa_Status: null,
  loading: false,
};

function empresasApp(state = initialState, action) {
  switch (action.type) {
    case empresas.actionsType.GET_EMPRESAS_SUCCESS:
      return empresas.action.getEmpresasAction(state, action.data);
    case empresas.actionsType.GET_EMPRESAS_FAILURE:
      return {
        ...state,
        empresa: [],
        empresas: [],
        empresasError: 'No se pudo recuperar la información',
      };
    case empresas.actionsType.GETALL_EMPRESAS_SUCCESS:
      return empresas.action.getAllEmpresasAction(state, action.data);
    case empresas.actionsType.GETALL_EMPRESAS_FAILURE:
      return {
        ...state,
        empresa: [],
        empresas: [],
        empresasError: 'No se pudo recuperar la información',
        loading: false,
      };
    case empresas.actionsType.POST_EMPRESAS_SUCCESS:
      return empresas.action.postEmpresasAction(state, action);
    case empresas.actionsType.POST_EMPRESAS_FAILURE:
      return {
        ...state,
        Agregar_Empresa_Status: action.errorMessage.response.status,
        loading: false,
      };
    case empresas.actionsType.PUT_EMPRESAS_SUCCESS:
      return empresas.action.putEmpresasAction(state, action);
    case empresas.actionsType.PUT_EMPRESAS_FAILURE:
      return {
        ...state,
        Actualizar_Empresa_Status: action.errorMessage.response.status,
        loading: false,
      };
    case empresas.actionsType.DELETE_EMPRESAS_SUCCESS:
      return empresas.action.deleteEmpresasAction(state, action);
    case empresas.actionsType.DELETE_EMPRESAS_FAILURE:
      return {
        ...state,
        Eliminar_Empresa_Status: action.errorMessage.response.status,
        loading: false,
      };
    case empresas.actionsType.GET_PAISES_SUCCESS:
      return empresas.action.getAllPaisesAction(state, action.data);
    case empresas.actionsType.GET_PAISES_FAILURE:
      return {
        ...state,
      };
    case empresas.actionsType.CERRAR_ALERTA_AGREGAR:
      return empresas.action.cerrarAlertaAgregar(state);
    case empresas.actionsType.CERRAR_ALERTA_ELIMINAR:
      return empresas.action.cerrarAlertaEliminar(state);
    case empresas.actionsType.CERRAR_ALERTA_ACTUALIZAR:
      return empresas.action.cerrarAlertaActualizar(state);
    case empresas.actionsType.GET_EMPRESAS_LOADING:
      return empresas.action.getEmpresasLoading(state);
    default:
      return { ...state };
  }
}

export default empresasApp;
