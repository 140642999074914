import { createStore, applyMiddleware, compose } from 'redux';
import indexReducer from '../reducers';
import thunk from 'redux-thunk';

// const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

// const store = createStoreWithMiddleware(indexReducer);

const store = createStore(
  indexReducer,
  compose(
    applyMiddleware(thunk),

    typeof window === 'object' &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export default store;
