import signs from './signs';
import api from '../../../api/api';

function getAllInsumos(parametros) {
  return (dispatch) => {
    api
      .post(`api/TrackAbout`, { ...parametros })
      .then(function (response) {
        dispatch(signs.getInsumosSuccess(response.data));
      })
      .catch((err) => {
        dispatch(signs.getInsumosFailure(err.message));
      });
  };
}

function getInsumosLoading() {
  return (dispatch) => {
    dispatch(signs.getInsumosLoading());
  };
}

export default {
  getAllInsumos: getAllInsumos,
  getInsumosLoading: getInsumosLoading,
};
