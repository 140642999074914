import sing from './sings';
import calls from './calls';
import actionsType from './actionsTypes';

function getConsumoAction(state, response) {
  return {
    ...state,
    consumo: response,
    consumosError: '',
  };
}
function getAllConsumoAction(state, response) {
  return {
    ...state,
    consumos: response,
    consumosError: '',
    loading: false,
    Agregar_Consumo_Status: null,
  };
}
function getAllTiposConsumoAction(state, response) {
  return {
    ...state,
    tiposConsumo: response,
  };
}
function postConsumoAction(state, response) {
  // let newUsuario = [
  //   ...state.usuario,
  //   { id: state.usuario.length, text: response },
  // ];
  return {
    ...state,
    Agregar_Consumo_Status: response.data.status,
    loading: false,
  };
}

function putConsumoAction(state, response) {
  return {
    ...state,
    Actualizar_Consumo_Status: response.data.status,
    loading: false,
  };
}

function deleteConsumoAction(state, response) {
  return {
    ...state,
    Eliminar_Consumo_Status: response.data.status,
    loading: false,
  };
}

function cerrarAlertaAgregar(state) {
  return {
    ...state,
    Agregar_Consumo_Status: null,
  };
}

function cerrarAlertaEliminar(state) {
  return {
    ...state,
    Eliminar_Consumo_Status: null,
  };
}

function cerrarAlertaActualizar(state) {
  return {
    ...state,
    Actualizar_Consumo_Status: null,
  };
}

function getConsumoLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

export default {
  actionsType: actionsType,
  sign: sing,
  action: {
    getConsumoAction: getConsumoAction,
    getAllConsumoAction: getAllConsumoAction,
    postConsumoAction: postConsumoAction,
    putConsumoAction: putConsumoAction,
    deleteConsumoAction: deleteConsumoAction,
    cerrarAlertaAgregar: cerrarAlertaAgregar,
    cerrarAlertaEliminar: cerrarAlertaEliminar,
    cerrarAlertaActualizar: cerrarAlertaActualizar,
    getConsumoLoading: getConsumoLoading,
    getAllTiposConsumoAction: getAllTiposConsumoAction,
  },
  calls: calls,
};
