import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, Tooltip, Col, Row } from 'antd';
import { path } from '../../routes';
import {
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import './login.css';
import Swal from 'sweetalert2';
import api from '../../api/api';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      cargando: false,
    };
  }
  render() {
    if (this.props.login && this.props.Login_Status === 200) {
      this.setState({ cargando: false });
      window.location.href = path.default;
    } else if (!this.props.login && this.props.Login_Status === 200) {
      this.setState({ cargando: false });
      Swal.fire('Error', '¡Los datos son incorrectos!', 'info');
      this.props.onLimpiarStateLogin();
    }
    // this.componentWillReceiveProps = (nextProps) => {
    //   if (
    //     nextProps.Login_Status !== this.props.Login_Status &&
    //     nextProps.login === ''
    //   ) {
    //     this.setState({ cargando: false });
    //     Swal.fire('Error', '¡Los datos son incorrectos!', 'info');
    //     this.props.onLimpiarStateLogin();
    //   } else if (
    //     nextProps.Login_Status !== this.props.Login_Status &&
    //     nextProps.login !== ''
    //   ) {
    //     this.setState({ cargando: false });
    //     window.location.href = path.default;
    //   } else {
    //     this.setState({ cargando: false });
    //     Swal.fire('Error', '¡Los datos son incorrectos!', 'info');
    //     this.props.onLimpiarStateLogin();
    //   }
    // };

    const olvidoContrasenna = () => {
      Swal.fire({
        title: 'Digite su Correo Electrónico',
        width: '30%',
        input: 'email',
        validationMessage: 'Dirección de correo electrónico inválida',
        inputPlaceholder: 'Correo Electrónico',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: (email) => {
          return api.post('api/Login/OlvidoContrasena', {email})
            .then(response => {
              if (response.status == 200) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Contraseña enviada correctamente',
                  showConfirmButton: false,
                  timer: 2000
                })
              }
              
            })
            .catch(error => {
              if (error.status == 404 ) {
                Swal.showValidationMessage(
                  "Correo Electrónico Inválido"
                )
              }
              else if (error.status == 400 ) {
                Swal.showValidationMessage(
                  "Error inesperado"
                )
              }else{
                Swal.showValidationMessage(
                  "Error al intentar recuperar contraseña"
                )
              }
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
    }

    const onFinish = (values) => {
      const formulario = {
        email: values.username,
        contrasenna: values.password,
      };
      if (values.remember === true) {
        localStorage.setItem('UserName', values.username);
      }
      // this.props.startLoading();
      this.setState({ cargando: true });
      this.props.onLogin(formulario);
    };
    return (
      <>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: false,
            username: localStorage.getItem('UserName'),
          }}
          onFinish={onFinish}
        >
          <Row align='center'>
            <Col xs={24} sm={16} md={20} lg={20} xl={20}>
              <Form.Item
                name='username'
                rules={[
                  {
                    required: true,
                    message: '¡Por favor ingrese su usuario!',
                  },
                ]}
              >
                <Input
                  size='large'
                  type='email'
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  suffix={
                    <Tooltip
                      title='Usuario dado por Gaspro'
                      placement='bottomLeft'
                    >
                      <InfoCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                  placeholder='Usuario'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} md={20} lg={20} xl={20}>
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: '¡Por favor ingrese su contraseña!',
                  },
                ]}
              >
                <Input.Password
                  size='large'
                  prefix={<LockOutlined className='site-form-item-icon' />}
                  type='password'
                  placeholder='Contraseña'
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={16}
              md={20}
              lg={20}
              xl={20}
              style={{ marginTop: '-5%' }}
            >
              <Form.Item name='remember' valuePropName='checked'>
                <Checkbox style={{ fontSize: '80%', fontWeight: 'bold' }}>
                  Recordar usuario
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} md={20} lg={20} xl={20}>
              <Form.Item>
                <Button
                  loading={this.state.cargando}
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  style={{
                    backgroundColor: '#81973a',
                    borderColor: '#81973a',
                    width: '100%',
                  }}
                >
                  Ingresar
                </Button>
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={16}
              md={20}
              lg={20}
              xl={20}
              style={{ marginTop: '-7%' }}
            >
              <Form.Item>
                <a href='/#' className='LinkOC' onClick={olvidoContrasenna}>
                  ¿Olvidó su contraseña?
                </a>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
export default Login;
