import { Col, Row } from 'antd';
import React from 'react';

class godaddy extends React.Component {
  render() {
    return (
      <>
        <Row style={{ marginTop: '2%' }}>
          <Col
            lg={{ offset: 0, span: 24 }}
            md={{ offset: 0, span: 24 }}
            sm={{ offset: 0, span: 24 }}
            xs={{ offset: 0, span: 24 }}
          >
           e31uo6u1qffagppe7lg8turk2h
          </Col>
        </Row>
      </>
    );
  }
}

export default godaddy;
