import sings from './sings';
import api from '../../../api/api';

function getRolesById(id) {
  return (dispatch) => {
    api
      .get(`api/Rols/${id}`)
      .then(function (response) {
        dispatch(sings.getRolesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getRolesFailure(err.message));
      });
  };
}

function getAllRoles() {
  return (dispatch) => {
    api
      .get('api/Rols')
      .then(function (response) {
        dispatch(sings.getAllRolesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getAllRolesFailure(err.message));
      });
  };
}

function postRoles(usuario) {
  return (dispatch) => {
    api
      .post('api/Rols', { ...usuario })
      .then(function (response) {
        dispatch(sings.postRolesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.postRolesFailure(err.message));
      });
  };
}

function putRoles(id, usuario) {
  return (dispatch) => {
    api
      .put(`api/Rols/${id}`, { ...usuario })
      .then(function (response) {
        dispatch(sings.putRolesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.putRolesFailure(err.message));
      });
  };
}

function deleteRoles(id) {
  return (dispatch) => {
    api
      .delete(`api/Rols/${id}`)
      .then(function (response) {
        dispatch(sings.deleteRolesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.deleteRolesFailure(err.message));
      });
  };
}

export default {
  getRolesById: getRolesById,
  getAllRoles: getAllRoles,
  postRoles: postRoles,
  putRoles: putRoles,
  deleteRoles: deleteRoles,
};
