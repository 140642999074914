import { Col, Row } from 'antd';
import React from 'react';
import Imagen1 from '../assets/Imagen1-Carousel.png';
import Imagen2 from '../assets/Imagen2-Carousel.png';
import Imagen3 from '../assets/Imagen3-Carousel.png';
import Imagen4 from '../assets/Imagen4-Carousel.png';
import Imagen5 from '../assets/Imagen5-Carousel.png';
import Imagen6 from '../assets/Imagen6-Carousel.png';
import Imagen7 from '../assets/Imagen7-Carousel.png';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

class HomeCarousel extends React.Component {
  render() {
    const images = [
      {
        original: Imagen1,
        thumbnail: Imagen1,
      },
      {
        original: Imagen2,
        thumbnail: Imagen2,
      },
      {
        original: Imagen3,
        thumbnail: Imagen3,
      },
      {
        original: Imagen4,
        thumbnail: Imagen4,
      },
      {
        original: Imagen5,
        thumbnail: Imagen5,
      },
      {
        original: Imagen6,
        thumbnail: Imagen6,
      },
      {
        original: Imagen7,
        thumbnail: Imagen7,
      },
    ];

    return (
      <>
        <Row style={{ marginTop: '2%' }}>
          <Col
            lg={{ offset: 0, span: 24 }}
            md={{ offset: 0, span: 24 }}
            sm={{ offset: 0, span: 24 }}
            xs={{ offset: 0, span: 24 }}
          >
            <ImageGallery
              items={images}
              autoPlay
              slideDuration={1000}
              slideInterval={10000}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default HomeCarousel;
