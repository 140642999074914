import actions from '../actions';

const consumo = actions.gestionConsumo;

const initialState = {
  consumo: [],
  consumos: [],
  tiposConsumo: [],
  consumosError: '',
  Agregar_Consumo_Status: null,
  Actualizar_Consumo_Status: null,
  Eliminar_Consumo_Status: null,
  loading: false,
};

function consumoApp(state = initialState, action) {
  switch (action.type) {
    case consumo.actionsType.GET_CONSUMO_SUCCESS:
      return consumo.action.getConsumoAction(state, action.data);
    case consumo.actionsType.GET_CONSUMO_FAILURE:
      return {
        ...state,
        consumo: [],
        consumos: [],
        consumosError: 'No se pudo recuperar la información',
      };
    case consumo.actionsType.GETALL_CONSUMO_SUCCESS:
      return consumo.action.getAllConsumoAction(state, action.data);
    case consumo.actionsType.GETALL_CONSUMO_FAILURE:
      return {
        ...state,
        consumo: [],
        consumos: [],
        consumosError: 'No se pudo recuperar la información',
        loading: false,
      };
    case consumo.actionsType.POST_CONSUMO_SUCCESS:
      return consumo.action.postConsumoAction(state, action);
    case consumo.actionsType.POST_CONSUMO_FAILURE:
      return {
        ...state,
        Agregar_Consumo_Status: action.errorMessage.response.status,
        loading: false,
      };
    case consumo.actionsType.PUT_CONSUMO_SUCCESS:
      return consumo.action.putConsumoAction(state, action);
    case consumo.actionsType.PUT_CONSUMO_FAILURE:
      return {
        ...state,
        Actualizar_Consumo_Status: action.errorMessage.response.status,
        loading: false,
      };
    case consumo.actionsType.DELETE_CONSUMO_SUCCESS:
      return consumo.action.deleteConsumoAction(state, action);
    case consumo.actionsType.DELETE_CONSUMO_FAILURE:
      return {
        ...state,
        Eliminar_Consumo_Status: action.errorMessage.response.status,
        loading: false,
      };
    case consumo.actionsType.GET_TIPOSCONSUMO_SUCCESS:
      return consumo.action.getAllTiposConsumoAction(state, action.data);
    case consumo.actionsType.GET_TIPOSCONSUMO_FAILURE:
      return {
        ...state,
      };
    case consumo.actionsType.CERRAR_ALERTA_AGREGAR:
      return consumo.action.cerrarAlertaAgregar(state);
    case consumo.actionsType.CERRAR_ALERTA_ELIMINAR:
      return consumo.action.cerrarAlertaEliminar(state);
    case consumo.actionsType.CERRAR_ALERTA_ACTUALIZAR:
      return consumo.action.cerrarAlertaActualizar(state);
    case consumo.actionsType.GET_CONSUMO_LOADING:
      return consumo.action.getConsumoLoading(state);
    default:
      return { ...state };
  }
}

export default consumoApp;
