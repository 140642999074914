import signs from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

function getNotificacionesAction(state, response) {
  return {
    ...state,
    notificaciones: response.data,
    loading: false,
    Obtener_Notificaciones_Status: response.status,
  };
}

function getNotificacionesConFechaAction(state, response) {
  return {
    ...state,
    notificaciones: response.data,
    loading: false,
    Obtener_Notificaciones_Status: response.status,
  };
}

function getNotificacionesNuevasAction(state, response) {
  return {
    ...state,
    notificacionesNuevas: response.data,
    loading: false,
    Obtener_Notificaciones_Nuevas_Status: response.status,
  };
}

function getNotificacionesNuevasConsumoAction(state, response) {
  return {
    ...state,
    notificacionesNuevasConsumo: response.data,
    loading: false,
    Obtener_Notificaciones_Nuevas_Consumo_Status: response.status,
  };
}

function MarcarNotificacionLeidaAction(state, response) {
  return {
    ...state,
    loading: false,
    Marcar_Notificacion_Leida_Status: response.status,
  };
}

function MarcarNotificacionLeidaPanelAction(state, response) {
  return {
    ...state,
    loading: false,
    Marcar_Notificacion_Leida_Panel_Status: response.status,
  };
}

function getNotificacionesLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function cerrarAlertaObtenerNotificaciones(state) {
  return {
    ...state,
    Obtener_Notificaciones_Status: null,
    Obtener_Notificaciones_Nuevas_Status: null,
    Marcar_Notificacion_Leida_Status: null,
    Marcar_Notificacion_Leida_Panel_Status: null,
  };
}

function establecerTabActivo(state, response) {
  return {
    ...state,
    Tab_Activo: response.data,
  };
}

function establecerIdDeNotificacionSeleccionada(state, response) {
  return {
    ...state,
    Notificacion_Seleccionada: response.data,
  };
}

function limpiarIdDeNotificacionSeleccionada(state) {
  return {
    ...state,
    Notificacion_Seleccionada: null,
  };
}

function contarNotificacionesNoLeidasAction(state, response) {
  return {
    ...state,
    ContarNoLeidas: response,
  };
}

function filtrandoFechasAction(state, response) {
  return {
    ...state,
    FechaInicio: response.FechaInicio,
    FechaFin: response.FechaFin,
  };
}

export default {
  actionsType: actionsType,
  sign: signs,
  action: {
    getNotificacionesAction: getNotificacionesAction,
    getNotificacionesConFechaAction: getNotificacionesConFechaAction,
    getNotificacionesNuevasAction: getNotificacionesNuevasAction,
    getNotificacionesNuevasConsumoAction: getNotificacionesNuevasConsumoAction,
    MarcarNotificacionLeidaAction: MarcarNotificacionLeidaAction,
    MarcarNotificacionLeidaPanelAction: MarcarNotificacionLeidaPanelAction,
    getNotificacionesLoading: getNotificacionesLoading,
    cerrarAlertaObtenerNotificaciones: cerrarAlertaObtenerNotificaciones,
    establecerTabActivo: establecerTabActivo,
    establecerIdDeNotificacionSeleccionada: establecerIdDeNotificacionSeleccionada,
    limpiarIdDeNotificacionSeleccionada: limpiarIdDeNotificacionSeleccionada,
    contarNotificacionesNoLeidasAction: contarNotificacionesNoLeidasAction,
    filtrandoFechasAction: filtrandoFechasAction,
  },
  calls: calls,
};
