import React, { useRef } from 'react';
import { Menu, Divider, Row, Col, Tooltip, Button, Affix } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  SnippetsOutlined,
  SolutionOutlined,
  BarChartOutlined,
  LogoutOutlined,
  FundOutlined,
  BellOutlined,
  MailOutlined,
} from '@ant-design/icons';
import Swal from 'sweetalert2';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import Actions from '../../../../data/actions';
import { path } from '../../../../routes';
import './menuCliente.css';

const MenuCliente = (props) => {
  //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
  // pantalla
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let MenuRef = useRef();
  let history = useHistory();
  let dispatch = useDispatch();
  const { collapsed } = props;
  const Opcion_Activa = useSelector((state) => state.sideBar.Opcion_Activa);

  const LogOut = () => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      focusConfirm: false,
      title: `Cerrar sesión`,
      text: `¿Desea cerrar la sesión?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `Cerrar sesión`,
      confirmButtonColor: '#81973a',
      icon: 'question',
    }).then(function (result) {
      if (result.value) {
        sessionStorage.removeItem('TokenUsuario');
        window.location.href = path.default;
      } else if (result.dismiss) {
      }
    });
  };

  return (
    <>
      <Affix offsetTop={collapsed ? 120 : 185}>
        <Menu
          ref={MenuRef}
          selectedKeys={[`${Opcion_Activa}`]}
          theme='dark'
          mode='inline'
          style={{ backgroundColor: '#F0F2F5' }}
        >
          <Menu.Item
            onClick={() => {
              history.push({ pathname: path.moduloControlDeEnvases });
              dispatch(
                Actions.sideBar.calls.postOpcionActiva(
                  path.moduloControlDeEnvases,
                ),
              );
            }}
            key={path.moduloControlDeEnvases}
            icon={
              <Tooltip
                title='Registro y control de cilindros.'
                placement='bottomRight'
              >
                <BarChartOutlined />
              </Tooltip>
            }
          >
            <Tooltip
              title='Registro y control de cilindros.'
              placement='bottomRight'
            >
              Control de Envases
            </Tooltip>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              history.push({ pathname: path.moduloInventario });
              dispatch(
                Actions.sideBar.calls.postOpcionActiva(path.moduloInventario),
              );
            }}
            key={path.moduloInventario}
            icon={
              <Tooltip
                title='Información en tiempo real sobre niveles de gases en tanques y patrones de consumo.'
                placement='bottomRight'
              >
                <SnippetsOutlined />
              </Tooltip>
            }
          >
            <Tooltip
              title='Información en tiempo real sobre niveles de gases en tanques y patrones de consumo.'
              placement='bottomRight'
            >
              Inventario
            </Tooltip>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              history.push({ pathname: path.moduloConsumo });
              dispatch(
                Actions.sideBar.calls.postOpcionActiva(path.moduloConsumo),
              );
            }}
            key={path.moduloConsumo}
            icon={
              <Tooltip
                title='Acceso en línea al consumo real de gas en tanques e históricos de consumo.'
                placement='bottomRight'
              >
                <FundOutlined />
              </Tooltip>
            }
          >
            <Tooltip
              title='Acceso en línea al consumo real de gas en tanques e históricos de consumo.'
              placement='bottomRight'
            >
              Consumo
            </Tooltip>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              history.push({ pathname: path.moduloFacturacion });
              dispatch(
                Actions.sideBar.calls.postOpcionActiva(path.moduloFacturacion),
              );
            }}
            key={path.moduloFacturacion}
            icon={
              <Tooltip
                title='Acceso a información, histórico, saldos y descarga de facturas.'
                placement='bottomRight'
              >
                <SolutionOutlined />
              </Tooltip>
            }
          >
            <Tooltip
              title='Acceso a información, histórico, saldos y descarga de facturas.'
              placement='bottomRight'
            >
              Facturación
            </Tooltip>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              if (usuario.usuario.Facturacion === 'true') {
                dispatch(
                  Actions.estadoDeCuenta.calls.abrirModalEstadoDeCuenta(),
                );
              } else {
                history.push({ pathname: path.moduloEstadoDeCuentaNoAcceso });
                dispatch(
                  Actions.sideBar.calls.postOpcionActiva(
                    path.moduloEstadoDeCuentaNoAcceso,
                  ),
                );
              }
            }}
            key='5'
            icon={
              <Tooltip
                title='Acceso sobre movimientos, actividades, consumos y montos a pagar.'
                placement='bottomRight'
              >
                <SolutionOutlined />
              </Tooltip>
            }
          >
            <Tooltip
              title='Acceso sobre movimientos, actividades, consumos y montos a pagar.'
              placement='bottomRight'
            >
              Estado de Cuenta
            </Tooltip>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              history.push({ pathname: path.moduloNotificaciones });
              dispatch(
                Actions.sideBar.calls.postOpcionActiva(
                  path.moduloNotificaciones,
                ),
              );
            }}
            key={path.moduloNotificaciones}
            icon={
              <Tooltip title='Alertas y mensajes de todos los sistemas.' placement='bottomRight'>
                <BellOutlined />
              </Tooltip>
            }
          >
            <Tooltip title='Alertas y mensajes de todos los sistemas.' placement='bottomRight'>
              Notificaciones
            </Tooltip>
          </Menu.Item>

          <Affix offsetTop={480}>
            <Divider
              style={{
                width: '100%',
              }}
            />
          </Affix>

          <Affix offsetTop={520}>
            <Row
              style={{
                width: '100%',
              }}
            >
              <Col span={4} />
              <Col span={16} style={{ textAlign: 'center' }}>
                {collapsed ? (
                  <Tooltip title='Contáctenos' placement='right'>
                    <Button
                      className='ContactenosButton'
                      href={
                        usuario.usuario.Pais === 'Guatemala'
                          ? 'https://gaspro.com/contacto-guatemala/'
                          : usuario.usuario.Pais === 'El Salvador'
                          ? 'https://gaspro.com/contacto-el-salvador/'
                          : usuario.usuario.Pais === 'Nicaragua'
                          ? 'https://gaspro.com/contacto-nicaragua/'
                          : usuario.usuario.Pais === 'Honduras'
                          ? 'https://gaspro.com/contacto-honduras/'
                          : 'https://gaspro.com/contacto-panama/'
                      }
                      target='blank'
                    >
                      {collapsed ? <MailOutlined /> : 'Contáctenos'}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className='ContactenosButton'
                    href={
                      usuario.usuario.Pais === 'Guatemala'
                        ? 'https://gaspro.com/contacto-guatemala/'
                        : usuario.usuario.Pais === 'El Salvador'
                        ? 'https://gaspro.com/contacto-el-salvador/'
                        : usuario.usuario.Pais === 'Nicaragua'
                        ? 'https://gaspro.com/contacto-nicaragua/'
                        : usuario.usuario.Pais === 'Honduras'
                        ? 'https://gaspro.com/contacto-honduras/'
                        : 'https://gaspro.com/contacto-panama/'
                    }
                    target='blank'
                  >
                    {collapsed ? <MailOutlined /> : 'Contáctenos'}
                  </Button>
                )}
              </Col>
              <Col span={4} />
            </Row>
          </Affix>

          <Affix offsetTop={560}>
            <Row
              style={{
                width: '100%',
              }}
            >
              <Col span={4} />
              <Col span={16}>
                {collapsed ? (
                  <Tooltip title='Salir' placement='right'>
                    <Button
                      onClick={LogOut}
                      style={{
                        backgroundColor: '#848889',
                        color: 'white',
                        borderRadius: '5px',
                        borderColor: '#848889',
                        width: '100%',
                      }}
                    >
                      {collapsed ? <LogoutOutlined /> : 'SALIR'}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={LogOut}
                    style={{
                      backgroundColor: '#848889',
                      color: 'white',
                      borderRadius: '5px',
                      borderColor: '#848889',
                      width: '100%',
                    }}
                  >
                    {collapsed ? <LogoutOutlined /> : 'SALIR'}
                  </Button>
                )}
              </Col>
              <Col span={4} />
            </Row>
          </Affix>
        </Menu>
      </Affix>
    </>
  );
};
export default MenuCliente;
