const actionsType = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  LIMPIAR_STATE_LOGIN: 'LIMPIAR_STATE_LOGIN',

  START_LOADING: 'START_LOADING',
};

export default actionsType;
