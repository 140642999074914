import actions from '../actions';

const notificaciones = actions.notificaciones;

const initialState = {
  notificaciones: [],
  notificacionesNuevas: [],
  notificacionesNuevasConsumo: [],
  loading: false,
  Tab_Activo: 'Control de Envases',
  Notificacion_Seleccionada: null,
  Obtener_Notificaciones_Status: null,
  Obtener_Notificaciones_Nuevas_Status: null,
  Obtener_Notificaciones_Nuevas_Consumo_Status: null,
  Marcar_Notificacion_Leida_Status: null,
  Marcar_Notificacion_Leida_Panel_Status: null,
  ContarNoLeidas: false,
  FechaInicio: null,
  FechaFin: null,
};

function notificacionesApp(state = initialState, action) {
  switch (action.type) {
    case notificaciones.actionsType.GET_NOTIFICACIONES_SUCCESS:
      return notificaciones.action.getNotificacionesAction(state, action.data);
    case notificaciones.actionsType.GET_NOTIFICACIONES_FAILURE:
      return {
        ...state,
        loading: false,
        Obtener_Notificaciones_Status: action.errorMessage.response.status,
      };
    case notificaciones.actionsType.GET_NOTIFICACIONES_CON_FECHA_SUCCESS:
      return notificaciones.action.getNotificacionesConFechaAction(
        state,
        action.data,
      );
    case notificaciones.actionsType.GET_NOTIFICACIONES_CON_FECHA_FAILURE:
      return {
        ...state,
        loading: false,
        Obtener_Notificaciones_Status: action.errorMessage.response.status,
      };
    case notificaciones.actionsType.GET_NOTIFICACIONES_NUEVAS_SUCCESS:
      return notificaciones.action.getNotificacionesNuevasAction(
        state,
        action.data,
      );
    case notificaciones.actionsType.GET_NOTIFICACIONES_NUEVAS_FAILURE:
    return {
      ...state,
      loading: false,
      Obtener_Notificaciones_Nuevas_Status:
        action.errorMessage.response.status,
    };
    case notificaciones.actionsType.GET_NOTIFICACIONES_NUEVAS_CONSUMO_SUCCESS:
    return notificaciones.action.getNotificacionesNuevasConsumoAction(
      state,
      action.data,
    );
    case notificaciones.actionsType.GET_NOTIFICACIONES_NUEVAS_CONSUMO_FAILURE:
    return {
      ...state,
      loading: false,
      Obtener_Notificaciones_Nuevas_Consumo_Status:
        action.errorMessage.response.status,
    };
    case notificaciones.actionsType.MARCAR_NOTIFICACION_LEIDA_SUCCESS:
      return notificaciones.action.MarcarNotificacionLeidaAction(
        state,
        action.data,
      );
    case notificaciones.actionsType.MARCAR_NOTIFICACION_LEIDA_FAILURE:
      return {
        ...state,
        loading: false,
        Marcar_Notificacion_Leida_Status: action.errorMessage.response.status,
      };
    case notificaciones.actionsType.MARCAR_NOTIFICACION_LEIDA_PANEL_SUCCESS:
      return notificaciones.action.MarcarNotificacionLeidaPanelAction(
        state,
        action.data,
      );
    case notificaciones.actionsType.MARCAR_NOTIFICACION_LEIDA_PANEL_FAILURE:
      return {
        ...state,
        loading: false,
        Marcar_Notificacion_Leida_Panel_Status:
          action.errorMessage.response.status,
      };
    case notificaciones.actionsType.GET_NOTIFICACIONES_LOADING:
      return notificaciones.action.getNotificacionesLoading(state);
    case notificaciones.actionsType.CERRAR_ALERTA_OBTENER_NOTIFICACIONES:
      return notificaciones.action.cerrarAlertaObtenerNotificaciones(state);
    case notificaciones.actionsType.ESTABLECER_TAB_ACTIVO:
      return notificaciones.action.establecerTabActivo(state, action);
    case notificaciones.actionsType.ESTABLECER_ID_DE_NOTIFICACION_SELECCIONADA:
      return notificaciones.action.establecerIdDeNotificacionSeleccionada(
        state,
        action,
      );
    case notificaciones.actionsType.LIMPIAR_ID_DE_NOTIFICACION_SELECCIONADA:
      return notificaciones.action.limpiarIdDeNotificacionSeleccionada(state);
    case notificaciones.actionsType.CONTAR_NOTIFICACIONES_NO_LEIDAS:
      return notificaciones.action.contarNotificacionesNoLeidasAction(
        state,
        action.data,
      );
    case notificaciones.actionsType.FILTRANDO_FECHAS:
      return notificaciones.action.filtrandoFechasAction(state, action.data);
    default:
      return { ...state };
  }
}

export default notificacionesApp;
