const actionsType = {
  GET_PROVEEDORES_SUCCESS: 'GET_PROVEEDORES_SUCCESS',
  GET_PROVEEDORES_FAILURE: 'GET_PROVEEDORES_FAILURE',

  GETALL_PROVEEDORES_SUCCESS: 'GETALL_PROVEEDORES_SUCCESS',
  GETALL_PROVEEDORES_FAILURE: 'GETALL_PROVEEDORES_FAILURE',

  GET_PROVEEDOR_SUCCESS: 'GET_PROVEEDOR_SUCCESS',
  GET_PROVEEDOR_FAILURE: 'GET_PROVEEDOR_FAILURE',

  POST_PROVEEDORES_SUCCESS: 'POST_PROVEEDORES_SUCCESS',
  POST_PROVEEDORES_FAILURE: 'POST_PROVEEDORES_FAILURE',

  PUT_PROVEEDORES_SUCCESS: 'PUT_PROVEEDORES_SUCCESS',
  PUT_PROVEEDORES_FAILURE: 'PUT_PROVEEDORES_FAILURE',

  DELETE_PROVEEDORES_SUCCESS: 'DELETE_PROVEEDORES_SUCCESS',
  DELETE_PROVEEDORES_FAILURE: 'DELETE_PROVEEDORES_FAILURE',

  //Cerrar alerta de actualizar proveedor
  CERRAR_ALERTA_ACTUALIZAR: 'CERRAR_ALERTA_ACTUALIZAR',

  GET_PROVEEDORES_LOADING: 'GET_PROVEEDORES_LOADING',
};

export default actionsType;
