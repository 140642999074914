import React, { Component } from 'react';
import ConsultarProveedores from '../../../components/gestionDeProveedor/consultarProveedor/consultarProveedor';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import Loading from '../../../components/loading/loading';

class consultarProveedores extends Component {
  componentDidMount() {
    this.props.onGetProveedoresLoading();
    this.props.onGetAllProveedores();
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <ConsultarProveedores {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    proveedor: state.proveedores.proveedor,
    proveedores: state.proveedores.proveedores,
    proveedoresError: state.proveedores.proveedoresError,
    Actualizar_Proveedor_Status: state.proveedores.Actualizar_Proveedor_Status,
    loading: state.proveedores.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProveedor: (id) =>
      dispatch(actions.proveedores.calls.getProveedoresById(id)),
    onGetAllProveedores: () =>
      dispatch(actions.proveedores.calls.getAllProveedores()),
    onPutProveedor: (proveedor) =>
      dispatch(actions.proveedores.calls.putProveedores(proveedor)),
    cerrarAlertaActualizar: () =>
      dispatch(actions.proveedores.calls.cerrarAlertaActualizar()),
    onGetProveedoresLoading: () =>
      dispatch(actions.proveedores.calls.getProveedoresLoading()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(consultarProveedores);
