import actionsType from './actionsTypes';

function loginSuccess(data) {
  return { type: actionsType.LOGIN_SUCCESS, data: data };
}
function loginFailure(error) {
  return { type: actionsType.LOGIN_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function logoutSuccess(data) {
  return { type: actionsType.LOGOUT_SUCCESS, data: data };
}
function logoutFailure(error) {
  return { type: actionsType.LOGOUT_FAILURE, errorMessage: error };
}

function startLoading() {
  return { type: actionsType.START_LOADING };
}

function LimpiarStateLogin() {
  return { type: actionsType.LIMPIAR_STATE_LOGIN };
}

export default {
  loginSuccess: loginSuccess,
  loginFailure: loginFailure,
  logoutSuccess: logoutSuccess,
  logoutFailure: logoutFailure,
  startLoading: startLoading,
  LimpiarStateLogin: LimpiarStateLogin,
};
