import React, { useState, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { path } from '../../../routes';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import {
  EditOutlined,
  CaretRightOutlined,
  UserOutlined,
  InfoCircleOutlined,
  LockOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Table,
  Input,
  Button,
  Space,
  Divider,
  Switch,
  Tooltip,
  Row,
  Col,
  Modal,
  Select,
  Form,
  notification,
} from 'antd';
import './consultarUsuarios.css';
import { useEffect } from 'react';

const { Option } = Select;

const ConsultarUsuarios = (props) => {
  //Usuario logueado en el sistema
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [deshabilitarAgregar, setDeshabilitarAgregar] = useState(false);
  let searchInput = useRef();
  // const [datos, setdatos] = useState([]);

  //-------------------------Values del modal actualizar-----------------------------
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [email, setEmail] = useState('');
  const [contrasenna, setContrasenna] = useState('');
  const [rol, setRol] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [cargo, setCargo] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [teloficina, setTeloficina] = useState('');
  const [telmovil, setTelmovil] = useState('');

  const menuEmpresa = (
    <Select
      suffixIcon={<CaretRightOutlined rotate={90} />}
      placeholder='Empresa'
      showSearch
      optionFilterProp='label'
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {props.empresas.map((elemento) => (
        <Option
          key={elemento.ID_CompaniaCliente}
          value={elemento.ID_CompaniaCliente}
          label={elemento.Nombre}
        >
          {elemento.Nombre}
        </Option>
      ))}
    </Select>
  );
  useEffect(() => {
    setNombre(props.persona.Nombre);
    setApellido(props.persona.Apellido);
    setEmail(props.persona.Email);
    setContrasenna(props.persona.Contrasenna);
    setRol(props.persona.RoleID);
    setEmpresa(props.persona.CompaniaClienteID);
    setCargo(props.persona.Cargo);
    setDepartamento(props.persona.Departamento);
    setTeloficina(props.persona.Telefono_Oficina);
    setTelmovil(props.persona.Telefono_Movil);
  }, [props.persona]);

  //-------------USEEFFECTS PARA AGREGAR USUARIO--------------------------------
  useEffect(() => {
    setDeshabilitarAgregar(false);
  }, [props.usuarios]);

  useEffect(() => {
    if (props.Agregar_Usuario_Status === 200) {
      setDeshabilitarAgregar(true);
      notification.open({
        type: 'success',
        message: '¡Usuario agregado correctamente!',
        description: 'El usuario se agregó correctamente.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    } else if (
      props.Agregar_Usuario_Status === 400 ||
      props.Agregar_Usuario_Status === 404 ||
      props.Agregar_Usuario_Status === 500
    ) {
      setDeshabilitarAgregar(true);
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    }
  }, [props.Agregar_Usuario_Status]);

  //-------------USEEFFECTS PARA ACTUALIZAR USUARIO--------------------------------
  useEffect(() => {
    if (props.Actualizar_Usuario_Status === 200) {
      props.onGetAllUsuarios(usuario.usuario.authmethod);
      notification.open({
        type: 'success',
        message: '¡Usuario actualizado correctamente!',
        description: 'El usuario se actualizó correctamente.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActualizar();
        },
      });
    } else if (
      props.Actualizar_Usuario_Status === 400 ||
      props.Actualizar_Usuario_Status === 404 ||
      props.Actualizar_Usuario_Status === 500
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActualizar();
        },
      });
    }
  }, [props.Actualizar_Usuario_Status]);

  //-------------USEEFFECTS PARA ACTIVAR / INACTIVAR USUARIO--------------------------------

  useEffect(() => {
    if (props.ActivarDesactivar_Usuario_Status === 200) {
      props.onGetAllUsuarios(usuario.usuario.authmethod);
      notification.open({
        type: 'success',
        message: '¡Acción realizada con éxito!',
        description: 'La acción se realizó con éxito.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActivarInactivar();
        },
      });
    } else if (
      props.ActivarDesactivar_Usuario_Status === 400 ||
      props.ActivarDesactivar_Usuario_Status === 404 ||
      props.ActivarDesactivar_Usuario_Status === 500
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActivarInactivar();
        },
      });
    }
  }, [props.ActivarDesactivar_Usuario_Status]);

  const datos = props.usuarios.map(function (elemento) {
    return {
      key: elemento.ID_Usuario,
      nombre: elemento.Nombre + ' ' + elemento.Apellido,
      empresa: elemento.CompaniaCliente.Nombre,
      estado: elemento.Activo === true ? 'Activo' : 'Inactivo',
      estadoSwitch: elemento.Activo,
      rol: elemento.Rol.Nombre,
      cargo: elemento.Cargo,
      departamento: elemento.Departamento,
      móvil: elemento.Telefono_Movil,
      oficina: elemento.Telefono_Oficina,
      correo: elemento.Email,
    };
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
            className='BotonRestablecer'
          >
            Reiniciar
          </Button>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
            className='BotonBuscar'
          >
            Aceptar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <>
        <Tooltip title={`Buscar ${dataIndex}`}>
          <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : '#81973a',
              float: 'left',
            }}
            className='iconoBuscar'
          />
          {/* <Divider
            type='vertical'
            style={{
              height: '50%',
              marginTop: '50%',
              float: 'right',
              borderWidth: '2px',
              borderColor: 'white',
              marginRight: '1px',
            }}
          /> */}
        </Tooltip>
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSwitchChange = (record) => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      focusConfirm: false,
      title: `${
        record.estadoSwitch === false ? 'Activar' : 'Inactivar'
      } Usuario`,
      text: `¿Desea ${
        record.estadoSwitch === false ? 'activar' : 'inactivar'
      } el estado del usuario?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `${
        record.estadoSwitch === false ? 'Activar' : 'Inactivar'
      }`,
      confirmButtonColor: '#81973a',
      icon: 'question',
    }).then(async function (result) {
      if (result.value) {
        if (
          record.correo === usuario.usuario.email &&
          record.estadoSwitch === true
        ) {
          Swal.fire(
            '¡Acción no permitida!',
            'No puedes inactivar tu propio usuario.',
            'info',
          );
        } else {
          const usuarioABuscar = await props.usuarios.find(
            (usuario) => usuario.ID_Usuario === record.key,
          );
          const empresaABuscar = await props.empresas.find(
            (empresa) =>
              empresa.ID_CompaniaCliente === usuarioABuscar.CompaniaClienteID,
          );
          if (
            empresaABuscar.Activo === false &&
            record.estadoSwitch === false
          ) {
            Swal.fire(
              '¡Acción no permitida!',
              'No puedes activar este usuario porque la empresa está inactiva.',
              'info',
            );
          } else {
            props.onGetLoading();
            props.onDeleteUsuarios(record.key, usuario.usuario.unique_name);
          }
        }
      } else if (result.dismiss) {
      }
    });
  };

  //--------------------Función de actualizar usuario-----------------
  const ActualizarUsuario = (id) => {
    props.onGetUsuario(id);
    setTimeout(() => {
      setVisible(!visible);
    }, 1000);
  };
  //--------------------Funciones de Modal actualizar usuario-----------------
  const handleSubmitActualizar = (values) => {
    const Usuario = {
      ID_Usuario: props.persona.ID_Usuario,
      Nombre: values.nombre,
      Apellido: values.apellido,
      Email: values.email,
      RoleID: values.rol,
      CompaniaClienteID: values.empresa,
      Contrasenna: values.password,
      Created_At: props.persona.Created_At,
      Update_At: props.persona.Update_At,
      Activo: props.persona.Activo,
      Cargo: values.cargo,
      Departamento: values.departamento,
      Telefono_Oficina: values.teloficina,
      Telefono_Movil: values.telmovil,
      Estado_Anterior: props.persona.Estado_Anterior,
    };
    props.onGetLoading();
    props.onPutUsuarios(Usuario);
  };

  const handleCancel = () => {
    setVisible(!visible);
  };
  //--------------------Funciones de busqueda en columnas---------------------
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: '9%',
      ...getColumnSearchProps('nombre'),
    },
    {
      title: 'Empresa',
      dataIndex: 'empresa',
      key: 'empresa',
      width: '25%',
      ...getColumnSearchProps('empresa'),
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      width: '10%',
      ...getColumnSearchProps('cargo'),
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
      width: '10%',
      ...getColumnSearchProps('departamento'),
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
      width: '12%',
      ...getColumnSearchProps('correo'),
    },
    {
      title: 'Tel. Oficina',
      dataIndex: 'oficina',
      key: 'oficina',
      width: '6%',
      ...getColumnSearchProps('oficina'),
    },
    {
      title: 'Tel. Móvil',
      dataIndex: 'móvil',
      key: 'móvil',
      width: '6%',
      ...getColumnSearchProps('móvil'),
    },
    {
      title: 'Rol',
      dataIndex: 'rol',
      key: 'rol',
      width: '8%',
      ...getColumnSearchProps('rol'),
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: '4%',
      filters: [
        { text: 'Activo', value: 'Activo' },
        { text: 'Inactivo', value: 'Inactivo' },
      ],
      onFilter: (value, record) => record.estado.indexOf(value) === 0,
    },
    {
      title: 'Acciones',
      align: 'center',
      fixed: 'right',
      width: '6%',
      render: (record) => (
        <>
          <Tooltip title='Editar'>
            <EditOutlined onClick={() => ActualizarUsuario(record.key)} />
          </Tooltip>
          {usuario.usuario.role === '1' ? (
            <>
              <Divider type='vertical' style={{ visibility: 'hidden' }} />
              <Switch
                checkedChildren='Inactivar'
                unCheckedChildren='Activar'
                checked={record.estadoSwitch}
                onChange={() => handleSwitchChange(record)}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  //------------------------Col Form Item Responsive----------------------------
  const FormItemResponsive = (props) => {
    return (
      <Col
        lg={{ offset: 1, span: 21 }}
        md={{ offset: 1, span: 21 }}
        sm={{ offset: 1, span: 21 }}
        xs={{ offset: 1, span: 21 }}
      >
        {props.FormItem}
      </Col>
    );
  };

  return (
    <>
      {/* Modal para actualizar un usuario*/}
      <Modal
        title='Actualizar Usuario'
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        centered
      >
        <Form
          name='Actualizar_Usuario_Form'
          initialValues={{
            nombre: nombre,
            apellido: apellido,
            email: email,
            password: contrasenna,
            rol: rol,
            empresa: empresa,
            cargo: cargo,
            departamento: departamento,
            telmovil: telmovil,
            teloficina: teloficina,
          }}
          onFinish={handleSubmitActualizar}
        >
          <Row>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='nombre'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 100,
                        message: 'Mínimo 1 carácter, máximo 100 carácteres',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Nombre'
                      prefix={<UserOutlined />}
                      suffix={
                        <Tooltip
                          title='Nombre del usuario'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='apellido'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 100,
                        message: 'Mínimo 1 carácter, máximo 100 carácteres',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Apellido'
                      value={apellido}
                      style={{ marginTop: '-2%' }}
                      prefix={<UserOutlined />}
                      suffix={
                        <Tooltip
                          title='Apellido del usuario'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    validateTrigger='onSubmit'
                    name='email'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 150,
                        message: 'Mínimo 1 carácter, máximo 150 carácteres',
                      },
                      {
                        pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                        message: 'Formato de correo inválido',
                      },
                    ]}
                  >
                    <Input
                      type='email'
                      value={email}
                      placeholder='Usuario (correo)'
                      style={{ marginTop: '-2%' }}
                      prefix={<UserOutlined />}
                      suffix={
                        <Tooltip
                          title='Correo del usuario'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='password'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 8,
                        max: 150,
                        message: 'Mínimo 8 carácteres, máximo 150 carácteres',
                      },
                      {
                        pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,150}$/,
                        message:
                          'La contraseña debe contener (Mayúsculas, minúsculas y números)',
                      },
                    ]}
                  >
                    <Input.Password
                      type='password'
                      placeholder='Contraseña'
                      value={contrasenna}
                      style={{ marginTop: '-2%' }}
                      prefix={<LockOutlined />}
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='rol'
                    rules={[
                      {
                        required: true,
                        message: '¡Este campo es requerido!',
                      },
                    ]}
                  >
                    <Select
                      placeholder='Rol'
                      suffixIcon={<CaretRightOutlined rotate={90} />}
                    >
                      {usuario.usuario.role === '1' ? (
                        <>
                          <Option value={1}>Administrador</Option>
                          <Option value={2}>Cliente</Option>
                          <Option value={3}>Consult Manager</Option>
                          <Option value={4}>Cliente Administrador</Option>
                        </>
                      ) : (
                        <>
                          <Option value={2}>Cliente</Option>
                        </>
                      )}
                    </Select>
                  </Form.Item>
                }
              />
            </Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='empresa'
                    rules={[
                      { required: true, message: '¡Este campo es requerido!' },
                    ]}
                  >
                    {menuEmpresa}
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='cargo'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 100,
                        message: 'Mínimo 1 carácter, máximo 100 carácteres',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Cargo'
                      prefix={<UserOutlined />}
                      suffix={
                        <Tooltip
                          title='Cargo del usuario'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='departamento'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 100,
                        message: 'Mínimo 1 carácter, máximo 100 carácteres',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Departamento'
                      prefix={<UserOutlined />}
                      suffix={
                        <Tooltip
                          title='Departamento del usuario'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    validateTrigger='onSubmit'
                    name='teloficina'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 20,
                        message: 'Mínimo 1 carácter, máximo 20 carácteres',
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: 'Solo se admiten números',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Teléfono oficina'
                      prefix={<UserOutlined />}
                      suffix={
                        <Tooltip
                          title='Teléfono de oficina del usuario'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    validateTrigger='onSubmit'
                    name='telmovil'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 20,
                        message: 'Mínimo 1 carácter, máximo 20 carácteres',
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: 'Solo se admiten números',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Teléfono móvil'
                      prefix={<UserOutlined />}
                      suffix={
                        <Tooltip
                          title='Teléfono móvil del usuario'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
            </Col>
          </Row>
          <Row justify='center'>
            <Col
              lg={{ offset: 0, span: 8 }}
              md={{ offset: 0, span: 8 }}
              sm={{ offset: 0, span: 8 }}
              xs={{ offset: 0, span: 8 }}
            >
              <Button htmlType='submit' className='botonActualizarUsuario'>
                Actualizar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Pantalla de consulta de usuarios */}
      <Row justify='end' style={{ marginTop: '2%' }}>
        <Col
          lg={{ offset: 0, span: 4 }}
          md={{ offset: 0, span: 6 }}
          sm={{ offset: 0, span: 10 }}
          xs={{ offset: 0, span: 13 }}
        >
          <Button
            disabled={deshabilitarAgregar}
            onClick={() => history.push({ pathname: path.agregarUsuarios })}
            className='botonAgregarUsuario'
          >
            Agregar
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: '1%' }}>
        <Col
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={datos}
            scroll={{ x: 2700, y: 420 }}
            pagination={{
              defaultCurrent: 1,
              total: datos.length,
              responsive: true,
              showSizeChanger: true,
              size: 'small',
              defaultPageSize: 20,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ConsultarUsuarios;
