import React, { Component } from 'react';
import ModuloNotificaciones from '../../../components/modulosCliente/moduloNotificaciones/moduloNotificaciones';
import Loading from '../../../components/loading/loading';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';

class moduloNotificaciones extends Component {
  componentDidMount() {
    //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
    // pantalla
    var Token = sessionStorage.getItem('TokenUsuario');
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };

    let Cliente = usuario.usuario.Compania;

    this.props.onGetLoading();
    this.props.onGetNotificaciones(Cliente);
    this.props.onGetNotificacionesNuevas(Cliente);
    this.props.onGetNotificacionesNuevasConsumo(Cliente);
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <ModuloNotificaciones {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificaciones: state.notificaciones.notificaciones,
    notificacionesNuevas: state.notificaciones.notificacionesNuevas,
    notificacionesNuevasConsumo: state.notificaciones.notificacionesNuevasConsumo,
    loading: state.notificaciones.loading,
    Tab_Activo: state.notificaciones.Tab_Activo,
    Obtener_Notificaciones_Status:
      state.notificaciones.Obtener_Notificaciones_Status,
    ContarNoLeidas: state.notificaciones.ContarNoLeidas,
    FechaInicio: state.notificaciones.FechaInicio,
    FechaFin: state.notificaciones.FechaFin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetNotificaciones: (cliente) =>
      dispatch(actions.notificaciones.calls.getAllNotificaciones(cliente)),
    onGetNotificacionesConFecha: (parametros) =>
      dispatch(
        actions.notificaciones.calls.getAllNotificacionesConFecha(parametros),
      ),
    onGetNotificacionesNuevas: (cliente) =>
      dispatch(
        actions.notificaciones.calls.getAllNotificacionesNuevas(cliente),
      ),
    onGetNotificacionesNuevasConsumo: (cliente) =>
    dispatch(
      actions.notificaciones.calls.getAllNotificacionesNuevasConsumo(cliente),
    ),
    onGetLoading: () =>
      dispatch(actions.notificaciones.calls.getNotificacionesLoading()),
    cerrarAlertaObtenerNotificaciones: () =>
      dispatch(
        actions.notificaciones.calls.cerrarAlertaObtenerNotificaciones(),
      ),
    establecerTabActivo: (Tab) =>
      dispatch(actions.notificaciones.calls.establecerTabActivo(Tab)),
    contarNotificacionesNoLeidas: (valor) =>
      dispatch(
        actions.notificaciones.calls.contarNotificacionesNoLeidas(valor),
      ),
    filtrandoFechas: (Fechas) =>
      dispatch(actions.notificaciones.calls.filtrandoFechas(Fechas)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(moduloNotificaciones);
