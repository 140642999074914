import sing from './sings';
import calls from './calls';
import actionsType from './actionsTypes';

function getEmpresasAction(state, response) {
  return {
    ...state,
    empresa: response,
    empresasError: '',
  };
}
function getAllEmpresasAction(state, response) {
  return {
    ...state,
    empresas: response,
    empresasError: '',
    loading: false,
    Agregar_Empresa_Status: null,
  };
}
function getAllPaisesAction(state, response) {
  return {
    ...state,
    paises: response,
  };
}
function postEmpresasAction(state, response) {
  // let newUsuario = [
  //   ...state.usuario,
  //   { id: state.usuario.length, text: response },
  // ];
  return {
    ...state,
    Agregar_Empresa_Status: response.data.status,
    loading: false,
  };
}

function putEmpresasAction(state, response) {
  return {
    ...state,
    Actualizar_Empresa_Status: response.data.status,
    loading: false,
  };
}

function deleteEmpresasAction(state, response) {
  return {
    ...state,
    Eliminar_Empresa_Status: response.data.status,
    loading: false,
  };
}

function cerrarAlertaAgregar(state) {
  return {
    ...state,
    Agregar_Empresa_Status: null,
  };
}

function cerrarAlertaEliminar(state) {
  return {
    ...state,
    Eliminar_Empresa_Status: null,
  };
}

function cerrarAlertaActualizar(state) {
  return {
    ...state,
    Actualizar_Empresa_Status: null,
  };
}

function getEmpresasLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

export default {
  actionsType: actionsType,
  sign: sing,
  action: {
    getEmpresasAction: getEmpresasAction,
    getAllEmpresasAction: getAllEmpresasAction,
    postEmpresasAction: postEmpresasAction,
    putEmpresasAction: putEmpresasAction,
    deleteEmpresasAction: deleteEmpresasAction,
    cerrarAlertaAgregar: cerrarAlertaAgregar,
    cerrarAlertaEliminar: cerrarAlertaEliminar,
    cerrarAlertaActualizar: cerrarAlertaActualizar,
    getEmpresasLoading: getEmpresasLoading,
    getAllPaisesAction: getAllPaisesAction,
  },
  calls: calls,
};
