import React from 'react';
import logo from '../../assets/logo-gpc-blanco.png';
import { useHistory } from 'react-router-dom';
import { path } from '../../routes';

const Logo = () => {
  let history = useHistory();
  return (
    <img
      src={logo}
      alt="logo"
      width={150}
      height={50}
      onClick={() => history.push(path.default)}
    />
  );
};
export default Logo;
