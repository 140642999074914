import sings from './sings';
import api from '../../../api/api';

function getProveedoresById(id) {
  return (dispatch) => {
    api
      .get(`api/Provider/${id}`)
      .then(function (response) {
        dispatch(sings.getProveedoresSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getProveedoresFailure(err.message));
      });
  };
}

function getAllProveedores() {
  return (dispatch) => {
    api
      .get('api/Provider')
      .then(function (response) {
        dispatch(sings.getAllProveedoresSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getAllProveedoresFailure(err.message));
      });
  };
}

function postProveedores(proveedor) {
  return (dispatch) => {
    api
      .post('api/Provider', { ...proveedor })
      .then(function (response) {
        dispatch(sings.postProveedoresSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.postProveedoresFailure(err.message));
      });
  };
}

function putProveedores(proveedor) {
  return (dispatch) => {
    api
      .post(`api/Provider/Update`, { ...proveedor })
      .then(function (response) {
        dispatch(sings.putProveedoresSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.putProveedoresFailure(err));
      });
  };
}

function deleteProveedores(id) {
  return (dispatch) => {
    api
      .delete(`api/Provider/${id}`)
      .then(function (response) {
        dispatch(sings.deleteProveedoresSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.deleteProveedoresFailure(err.message));
      });
  };
}

function cerrarAlertaActualizar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaActualizarProveedor());
  };
}

function getProveedoresLoading() {
  return (dispatch) => {
    dispatch(sings.getProveedoresLoading());
  };
}

export default {
  getProveedoresById: getProveedoresById,
  getAllProveedores: getAllProveedores,
  postProveedores: postProveedores,
  putProveedores: putProveedores,
  deleteProveedores: deleteProveedores,
  cerrarAlertaActualizar: cerrarAlertaActualizar,
  getProveedoresLoading: getProveedoresLoading,
};
