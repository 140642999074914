import React from 'react';
import { Menu, Divider, Button, Row, Col, Tooltip, Affix } from 'antd';
import { useHistory } from 'react-router-dom';
import { TeamOutlined, LogoutOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { path } from '../../../../routes';

const MenuClienteAdministrador = (props) => {
  let history = useHistory();
  const { collapsed } = props;
  const LogOut = () => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      focusConfirm: false,
      title: `Cerrar sesión`,
      text: `¿Desea cerrar la sesión?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `Cerrar sesión`,
      confirmButtonColor: '#81973a',
      icon: 'question',
    }).then(function (result) {
      if (result.value) {
        sessionStorage.removeItem('TokenUsuario');
        window.location.href = path.default;
      } else if (result.dismiss) {
      }
    });
  };
  return (
    <>
      <Affix offsetTop={collapsed ? 120 : 170}>
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          style={{ backgroundColor: '#F0F2F5' }}
        >
          <Menu.Item
            onClick={() => history.push(path.consultarUsuarios)}
            key="1"
            icon={<TeamOutlined />}
          >
            Usuarios
          </Menu.Item>

          <Affix offsetTop={520}>
            <Divider
              style={{
                width: '100%',
              }}
            />
          </Affix>

          <Affix offsetTop={560}>
            <Row
              style={{
                width: '100%',
              }}
            >
              <Col span={4} />
              <Col span={16}>
                {collapsed ? (
                  <Tooltip title="Salir" placement="right">
                    <Button
                      onClick={LogOut}
                      style={{
                        backgroundColor: '#848889',
                        color: 'white',
                        borderRadius: '5px',
                        borderColor: '#848889',
                        width: '100%',
                      }}
                    >
                      {collapsed ? <LogoutOutlined /> : 'SALIR'}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={LogOut}
                    style={{
                      backgroundColor: '#848889',
                      color: 'white',
                      borderRadius: '5px',
                      borderColor: '#848889',
                      width: '100%',
                    }}
                  >
                    {collapsed ? <LogoutOutlined /> : 'SALIR'}
                  </Button>
                )}
              </Col>
              <Col span={4} />
            </Row>
          </Affix>
        </Menu>
      </Affix>
    </>
  );
};
export default MenuClienteAdministrador;
