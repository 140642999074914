import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';

export const isAdmin = () => {
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };
  if (usuario.usuario.role === '1' || usuario.usuario.role === '4') {
    return true;
  } else {
    return false;
  }
};
export const isClient = () => {
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };
  if (usuario.usuario.role === '2') {
    return true;
  } else {
    return false;
  }
};

export const isConsultManager = () => {
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };
  if (usuario.usuario.role === '3') {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticated = () => true;
export const isNormalUser = () => false;
export const isVendorUser = () => true;
export const validationNotNeeded = () => true;
