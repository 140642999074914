import React, { Component } from "react";
import ModuloInventario from "../../../components/modulosCliente/moduloInventario/moduloInventario";
import Loading from "../../../components/loading/loading";
import { connect } from "react-redux";
import actions from "../../../data/actions";
import { decrypt } from "react-crypt-gsm";
import jwtDecode from "jwt-decode";
import moment from "moment";

class moduloInventario extends Component {
  componentDidMount() {
    //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
    // pantalla
    var Token = sessionStorage.getItem("TokenUsuario");
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };
    this.props.onGetLoading();
    if (usuario.usuario.Facturacion === "true") {
      let parametro = {
        CodigoEmpresa: usuario.usuario.FacturacionCodigo,
        Pagina: 1,
      };
      this.props.onGetLoading();
      this.props.onGetFacturas(parametro);
    }
    let FechaInicio = moment().subtract(3, "days").utcOffset(0, true).format();
    let FechaFin = moment().add(4, "days").utcOffset(0, true).format();

    let parametros = {
      IdEmpresa: usuario.usuario.Compania,
      FechaInicio: FechaInicio,
      FechaFin: FechaFin,
    };
    this.props.onGetInventario(parametros);
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <ModuloInventario {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inventario: state.inventario.inventario,
    loading: state.inventario.loading,
    facturas: state.facturacion.facturas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetInventario: (parametros) =>
      dispatch(actions.inventario.calls.getInventario(parametros)),
    onGetLoading: () =>
      dispatch(actions.inventario.calls.getInventarioLoading()),
    onGetFacturas: (parametros) =>
      dispatch(actions.facturacion.calls.getAllFacturas(parametros)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(moduloInventario);
