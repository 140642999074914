import actions from '../actions';

const estadoDeCuenta = actions.estadoDeCuenta;

const initialState = {
  estadoDeCuenta: [],
  estadoDeCuentaError: '',
  abrirModalEstadoDeCuenta: false,
  loading: false,
  EstadoDeCuenta_Status: null,
};

function estadoDeCuentaApp(state = initialState, action) {
  switch (action.type) {
    case estadoDeCuenta.actionsType.GET_ESTADODECUENTA_SUCCESS:
      return estadoDeCuenta.action.getEstadoDeCuentaAction(state, action.data);
    case estadoDeCuenta.actionsType.GET_ESTADODECUENTA_FAILURE:
      return {
        loading: false,
        EstadoDeCuenta_Status: action.errorMessage.response.status,
      };
    case estadoDeCuenta.actionsType.GET_ESTADODECUENTA_LOADING:
      return estadoDeCuenta.action.getEstadoDeCuentaLoading(state);
    case estadoDeCuenta.actionsType.CERRAR_ALERTA_ESTADODECUENTA:
      return estadoDeCuenta.action.cerrarAlertaEstadoDeCuenta(state);
    case estadoDeCuenta.actionsType.ABRIR_MODAL_ESTADODECUENTA:
      return estadoDeCuenta.action.abrirModalEstadoDeCuenta(state);
    case estadoDeCuenta.actionsType.CERRAR_MODAL_ESTADODECUENTA:
      return estadoDeCuenta.action.cerrarModalEstadoDeCuenta(state);
    default:
      return { ...state };
  }
}

export default estadoDeCuentaApp;
