import signs from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

function getConsumoAction(state, response) {
  return {
    ...state,
    consumo: response,
    consumoError: '',
    loading: false,
  };
}

function getConsumoLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

export default {
  actionsType: actionsType,
  sign: signs,
  action: {
    getConsumoAction: getConsumoAction,
    getConsumoLoading: getConsumoLoading,
  },
  calls: calls,
};
