import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { path } from "../../../routes";
import Swal from "sweetalert2";
import { decrypt } from "react-crypt-gsm";
import jwtDecode from "jwt-decode";
import {
  Row,
  Col,
  Input,
  Tooltip,
  Form,
  Button,
  Select,
  notification,
  InputNumber,
  Space,
} from "antd";
import {
  CaretRightOutlined,
  InfoCircleOutlined,
  DotChartOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  GroupOutlined,
  EnvironmentOutlined,
  FileSyncOutlined,
  ProjectOutlined,
  FormOutlined,
  ClockCircleOutlined ,
} from "@ant-design/icons";
import "./agregarConsumo.css";
const { TextArea } = Input;
const { Option } = Select;

const AgregarConsumo = (props) => {
  //Usuario logueado en el sistema
  var Token = sessionStorage.getItem("TokenUsuario");
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  const history = useHistory();
  const [deshabilitarGuardar, setDeshabilitarGuardar] = useState(false);
  let formAgregarConsumo = useRef();

  const menuTiposConsumo = (
    <Select
      suffixIcon={<CaretRightOutlined rotate={90} />}
      placeholder="Tipo Consumo"
    >
      {props.tiposConsumo.map((elemento) => (
        <Option key={elemento.ID_TipoMedicion} value={elemento.ID_TipoMedicion}>
          {elemento.Descripcion}
        </Option>
      ))}
    </Select>
  );

  const menuEmpresa = (
    <Select
      suffixIcon={<CaretRightOutlined rotate={90} />}
      placeholder="Empresa"
      showSearch
      optionFilterProp="label"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {props.empresas.map((elemento) => (
        <Option
          key={elemento.ID_CompaniaCliente}
          value={elemento.ID_CompaniaCliente}
          label={elemento.Nombre}
        >
          {elemento.Nombre}
        </Option>
      ))}
    </Select>
  );

  //-------------USEEFFECTS PARA AGREGAR comsuno--------------------------------
  useEffect(() => {
    setDeshabilitarGuardar(false);
  }, [props.Agregar_EmpAgregar_Consumo_Statusresa_Status]);

  useEffect(() => {
    if (props.Agregar_Consumo_Status === 200) {
      setDeshabilitarGuardar(true);
      history.push({ pathname: path.consultarConsumo });
    } else if (
      props.Agregar_Consumo_Status === 400 ||
      props.Agregar_Consumo_Status === 404 ||
      props.Agregar_Consumo_Status === 500
    ) {
      setDeshabilitarGuardar(true);
      notification.open({
        type: "error",
        message: "¡Ha ocurrido un error!",
        description:
          "La acción no se realizó debido a que hay problemas presentes.",
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    }
  }, [props.Agregar_Consumo_Status]);

  //--------------------Funciones de agregar comsuno-----------------

  const handleSubmitAgregarConsumo = (values) => {
    if (values.Mediciones === undefined || values.Mediciones === null) {
      values.Mediciones = []
    }
    props.onGetConsumoLoading();
    props.onPostEmpresas(values);
  };

  //------------------------Col Form Item Responsive----------------------------
  const FormItemResponsive = (props) => {
    return (
      <Col
        lg={{ offset: 0, span: 21 }}
        md={{ offset: 0, span: 21 }}
        sm={{ offset: 0, span: 21 }}
        xs={{ offset: 0, span: 21 }}
      >
        {props.FormItem}
      </Col>
    );
  };

  return (
    <>
      {/* Pantalla de agregar empresa */}
      <Form
        name="Agregar_Empresa_Form"
        onFinish={handleSubmitAgregarConsumo}
        ref={formAgregarConsumo}
      >
        <Row justify="end" style={{ marginTop: "2%" }}>
          <Col
            lg={{ offset: 0, span: 4 }}
            md={{ offset: 0, span: 6 }}
            sm={{ offset: 0, span: 10 }}
            xs={{ offset: 0, span: 13 }}
          >
            <Button
              disabled={deshabilitarGuardar}
              htmlType="submit"
              className="botonGuardarUsuario"
            >
              Guardar
            </Button>
          </Col>

          <Col
            style={{ marginLeft: 20 }}
            lg={{ offset: 0, span: 4 }}
            md={{ offset: 0, span: 6 }}
            sm={{ offset: 0, span: 10 }}
            xs={{ offset: 0, span: 13 }}
          >
            <Button
              className="botonCancelarEmpresas"
              onClick={() => history.push({ pathname: path.consultarConsumo })}
            >
              Cancelar
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            background: "#81973a",
            borderRadius: 5,
            marginBottom: "2%",
            marginTop: "2%",
          }}
        >
          <Col style={{ color: "white", marginLeft: "1%" }}>
            Información de Consumo
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="ID_Tanque"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "¡Este campo es requerido!",
                    },
                    {
                      min: 1,
                      max: 100,
                      message: "Mínimo 1 carácter, máximo 100 carácteres",
                    },
                  ]}
                >
                  <Input
                    placeholder="ID Tanque"
                    prefix={<GroupOutlined />}
                    suffix={
                      <Tooltip
                        title="Identificador del Tanque"
                        placement="bottomLeft"
                      >
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="ID_CompaniaCliente"
                  rules={[
                    {
                      required: true,
                      message: "¡Este campo es requerido!",
                    },
                  ]}
                >
                  {menuEmpresa}
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="Equipo"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "¡Este campo es requerido!",
                    },
                    {
                      min: 1,
                      max: 200,
                      message: "Mínimo 1 carácter, máximo 200 carácteres",
                    },
                  ]}
                >
                  <Input
                    placeholder="Equipo"
                    prefix={<GroupOutlined />}
                    suffix={
                      <Tooltip title="Tipo de Equipo" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="Gas"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "¡Este campo es requerido!",
                    },
                    {
                      min: 1,
                      max: 100,
                      message: "Mínimo 1 carácter, máximo 100 carácteres",
                    },
                  ]}
                >
                  <Input
                    placeholder="Gas"
                    prefix={<DotChartOutlined />}
                    suffix={
                      <Tooltip title="Tipo de Gas" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="Molecula"
                  rules={[
                    {
                      required: true,
                      message: "¡Este campo es requerido!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={<CaretRightOutlined rotate={90} />}
                    placeholder="Molécula"
                  >
                    <Option value={"O2"}>O2</Option>
                    <Option value={"N2"}>N2</Option>
                    <Option value={"Ar"}>Ar</Option>
                  </Select>
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="Presion_Alta"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "¡Este campo es requerido!",
                    },
                    {
                      min: 1,
                      max: 20,
                      message: "Mínimo 1 carácter, máximo 20 carácteres",
                    },
                    {
                      pattern: /^([0-9])*$/,
                      message: "Solo se admiten números",
                    },
                  ]}
                >
                  <Input
                    placeholder="Presión Alta"
                    prefix={<VerticalAlignTopOutlined />}
                    suffix={
                      <Tooltip title="Presión Alta" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />

          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="Presion_Baja"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "¡Este campo es requerido!",
                    },
                    {
                      min: 1,
                      max: 20,
                      message: "Mínimo 1 carácter, máximo 20 carácteres",
                    },
                    {
                      pattern: /^([0-9])*$/,
                      message: "Solo se admiten números",
                    },
                  ]}
                >
                  <Input
                    placeholder="Presión Baja"
                    prefix={<VerticalAlignBottomOutlined />}
                    suffix={
                      <Tooltip title="Presión Baja" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="HorasTrabajo"
                  rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        min: 1,
                        max: 2,
                        message: "Mínimo 1 carácter, máximo 2 carácteres",
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: "Solo se admiten números",
                      },
                  ]}
                >
                   <Input
                    placeholder="Horas Trabajo"
                    prefix={<ClockCircleOutlined />}
                    suffix={
                      <Tooltip title="Horas de Trabajo" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="PromedioConsumo"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "¡Este campo es requerido!",
                    },
                    {
                      pattern: /^([0-9])*$/,
                      message: "Solo se admiten números",
                    },
                  ]}
                >
                  <Input
                    placeholder="Promedio Consumo"
                    prefix={<ProjectOutlined rotate="180" />}
                    suffix={
                      <Tooltip title="Promedio Consumo" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="Dias_Facturacion"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "¡Este campo es requerido!",
                    },
                    {
                      pattern: /^([0-9])*$/,
                      message: "Solo se admiten números",
                    },
                  ]}
                >
                  <Input
                    placeholder="Días Facturación"
                    prefix={<FileSyncOutlined />}
                    suffix={
                      <Tooltip title="Días Facturación" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name="Ubicacion"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "¡Este campo es requerido!",
                    },
                    {
                      min: 1,
                      max: 50,
                      message: "Mínimo 1 carácter, máximo 50 carácteres",
                    },
                  ]}
                >
                  <Input
                    placeholder="Ubicación"
                    prefix={<EnvironmentOutlined />}
                    suffix={
                      <Tooltip title="Ubicación" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            {/* <Form.List name="Mediciones">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <FormItemResponsive
                        FormItem={
                          <Form.Item
                            {...field}
                            name={[field.name, "ID_Medicion"]}
                            fieldKey={[field.fieldKey, "ID_Medicion"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "¡Este campo es requerido!",
                              },
                              {
                                min: 1,
                                max: 50,
                                message:
                                  "Mínimo 1 carácter, máximo 50 carácteres",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Id Medición"
                              prefix={<FormOutlined />}
                              suffix={
                                <Tooltip
                                  title="Ubicación"
                                  placement="bottomLeft"
                                >
                                  <InfoCircleOutlined
                                    style={{ color: "rgba(0,0,0,.45)" }}
                                  />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                        }
                      />
                      <Form.Item
                        {...field}
                        name={[field.name, "Tipo_Medicion"]}
                        fieldKey={[field.fieldKey, "Tipo_Medicion"]}
                        rules={[
                          {
                            required: true,
                            message: "¡Este campo es requerido!",
                          },
                        ]}
                      >
                        {menuTiposConsumo}
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                      style={{ width: "87%" }}
                    >
                      Agregar Mediciones
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List> */}
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default AgregarConsumo;
