import actionsType from './actionsTypes';

function getEmpresasSuccess(data) {
  return { type: actionsType.GET_EMPRESAS_SUCCESS, data: data };
}
function getEmpresasFailure(error) {
  return { type: actionsType.GET_EMPRESAS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getAllEmpresasSuccess(data) {
  return { type: actionsType.GETALL_EMPRESAS_SUCCESS, data: data };
}
function getAllEmpresasFailure(error) {
  return { type: actionsType.GETALL_EMPRESAS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function postEmpresasSuccess(data) {
  return { type: actionsType.POST_EMPRESAS_SUCCESS, data: data };
}
function postEmpresasFailure(error) {
  return { type: actionsType.POST_EMPRESAS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function putEmpresasSuccess(data) {
  return { type: actionsType.PUT_EMPRESAS_SUCCESS, data: data };
}
function putEmpresasFailure(error) {
  return { type: actionsType.PUT_EMPRESAS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function deleteEmpresasSuccess(data) {
  return { type: actionsType.DELETE_EMPRESAS_SUCCESS, data: data };
}
function deleteEmpresasFailure(error) {
  return { type: actionsType.DELETE_EMPRESAS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaEliminar() {
  return { type: actionsType.CERRAR_ALERTA_ELIMINAR };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaAgregar() {
  return { type: actionsType.CERRAR_ALERTA_AGREGAR };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaActualizar() {
  return { type: actionsType.CERRAR_ALERTA_ACTUALIZAR };
}

function getEmpresasLoading() {
  return { type: actionsType.GET_EMPRESAS_LOADING };
}
//--------------------------------------------------------------------------------------
function getAllPaisesSuccess(data) {
  return { type: actionsType.GET_PAISES_SUCCESS, data: data };
}
function getAllPaisesFailure(error) {
  return { type: actionsType.GET_PAISES_FAILURE, errorMessage: error };
}

export default {
  getEmpresasSuccess: getEmpresasSuccess,
  getEmpresasFailure: getEmpresasFailure,
  getAllEmpresasSuccess: getAllEmpresasSuccess,
  getAllEmpresasFailure: getAllEmpresasFailure,
  postEmpresasSuccess: postEmpresasSuccess,
  postEmpresasFailure: postEmpresasFailure,
  putEmpresasSuccess: putEmpresasSuccess,
  putEmpresasFailure: putEmpresasFailure,
  deleteEmpresasSuccess: deleteEmpresasSuccess,
  deleteEmpresasFailure: deleteEmpresasFailure,
  cerrarAlertaEliminar: cerrarAlertaEliminar,
  cerrarAlertaAgregar: cerrarAlertaAgregar,
  cerrarAlertaActualizar: cerrarAlertaActualizar,
  getEmpresasLoading: getEmpresasLoading,
  getAllPaisesSuccess: getAllPaisesSuccess,
  getAllPaisesFailure: getAllPaisesFailure,
};
