import React, { useState } from 'react';
import './sidebar.css';
import IconoUsuario from '../../../assets/icono-usuario.png';
import { Layout, Card, Affix } from 'antd';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import MenuAdministrador from './menuAdministrador/menuAdministrador';
import MenuCliente from './menuCliente/menuCliente';
import MenuConsultManager from './menuConsultManager/menuConsultManager';
import MenuClienteAdministrador from './menuClienteAdministrador/menuClienteAdministrador';
import Avatar from 'antd/lib/avatar/avatar';
const { Sider } = Layout;

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);

  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let Letra = usuario.usuario.unique_name.split(' ');
  Letra = Letra[0].split('');

  let nombreResponsive = '';
  let apellidoResponsive = '';
  let nombre = usuario.usuario.unique_name.split(' ');
  let apellido = usuario.usuario.unique_name.split(' ');
  if (nombre[0].length > 25) {
    nombre = nombre[0].split('');
    for (let i = 0; i < 25; i++) {
      nombreResponsive += nombre[i];
    }
  } else {
    nombreResponsive = nombre[0];
  }

  if (apellido[1].length > 25) {
    apellido = apellido[1].split('');
    for (let i = 0; i < 25; i++) {
      apellidoResponsive += apellido[i];
    }
  } else {
    apellidoResponsive = apellido[1];
  }

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ backgroundColor: '#FFF', marginTop: 62 }}
      >
        <Affix offsetTop={63}>
          <Card
            style={{
              backgroundColor: '#D1D3D4',
              textAlign: 'center',
              height: collapsed ? 53 : 120,
            }}
          >
            {usuario.usuario.role === '1' ||
            usuario.usuario.role === '3' ||
            usuario.usuario.role === '4' ? (
              <Avatar
                size={collapsed ? 30 : 55}
                src={IconoUsuario}
                style={{ backgroundColor: '#BCBEC0', marginTop: -12 }}
              />
            ) : (
              <Avatar
                size={collapsed ? 30 : 55}
                style={{ backgroundColor: '#BCBEC0', marginTop: -12 }}
              >
                {Letra[0]}
              </Avatar>
            )}

            <h5
              hidden={collapsed ? true : false}
              style={{
                fontWeight: 'bold',
                fontSize: 19,
              }}
            >
              {nombreResponsive + ' ' + apellidoResponsive}
            </h5>

            <h5
              hidden={collapsed ? true : false}
              style={{
                fontSize: 11,
                marginTop: -11,
              }}
            >
              {usuario.usuario.role === '1'
                ? 'Administrador'
                : usuario.usuario.role === '2'
                ? 'Cliente'
                : usuario.usuario.role === '3'
                ? 'Consult Manager'
                : 'Cliente Administrador'}
            </h5>
          </Card>
        </Affix>

        {usuario.usuario.role === '1' ? (
          <MenuAdministrador collapsed={collapsed} />
        ) : usuario.usuario.role === '3' ? (
          <MenuConsultManager collapsed={collapsed} />
        ) : usuario.usuario.role === '4' ? (
          <MenuClienteAdministrador collapsed={collapsed} />
        ) : (
          <MenuCliente collapsed={collapsed} />
        )}
      </Sider>
    </>
  );
};
export default SideBar;
