import React, { Component } from 'react';
import { Col, Row } from 'antd';
import Logo from '../logo/logo';
import SocialMedia from '../socialMedia/socialMedia';
import Login from '../login/login';
import Fondo from '../../assets/FOTO-GASPRO-CONNECT-blur.png';

class Home extends Component {
  render() {
    return (
      <>
        <Row style={{ height: '100vh' }}>
          <Row>
            <Col
              style={{
                position: 'fixed',
                zIndex: 1,
                width: '100%',
                height: '8%',
                paddingLeft: 20,
                paddingRight: 20,
                backgroundColor: '#81973a',
              }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row>
                <Col
                  style={{ marginTop: '0.3%' }}
                  lg={{ span: 11 }}
                  md={{ span: 11 }}
                  sm={{ span: 11 }}
                  xs={{ span: 11 }}
                >
                  <Logo />
                </Col>
                <Col
                  lg={{ span: 13 }}
                  md={{ span: 13 }}
                  sm={{ span: 13 }}
                  xs={{ span: 13 }}
                >
                  <SocialMedia />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              backgroundImage: `url(${Fondo})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: 'auto',
              width: '100%',
            }}
          >
            <Col
              style={{ marginTop: '10%' }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row justify='end'>
                <Col
                  lg={{ offset: 2, span: 6 }}
                  md={{ offset: 0, span: 24 }}
                  sm={{ offset: 0, span: 24 }}
                  xs={{ offset: 0, span: 24 }}
                >
                  <Row justify='center' style={{ height: '46vh' }}>
                    <Col
                      style={{
                        background: 'rgba(250, 250, 250, 0.5)',
                        height: '100%',
                      }}
                      lg={{ span: 20 }}
                      md={{ span: 20 }}
                      sm={{ span: 20 }}
                      xs={{ span: 20 }}
                    >
                      <Row justify='center'>
                        <Col
                          style={{
                            marginTop: '10%',
                          }}
                          lg={{ span: 22 }}
                          md={{ span: 22 }}
                          sm={{ span: 22 }}
                          xs={{ span: 22 }}
                        >
                          <Login {...this.props} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </>
    );
  }
}
export default Home;
