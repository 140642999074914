import actions from '../actions';

const facturacionVerFactura = actions.facturacionVerFactura;

const initialState = {
  DatosFactura: [],
  Ver_Factura_Status: null,
  Ver_Factura_Loading: false,
};

function facturacionVerFacturaApp(state = initialState, action) {
  switch (action.type) {
    case facturacionVerFactura.actionsType.OBTENER_DATOS_FACTURA_SUCCESS:
      return facturacionVerFactura.action.getDatosDeFacturaAction(
        state,
        action.data,
      );
    case facturacionVerFactura.actionsType.OBTENER_DATOS_FACTURA_FAILURE:
      return {
        ...state,
        Ver_Factura_Loading: false,
        Ver_Factura_Status: action.errorMessage.response.status,
      };
    case facturacionVerFactura.actionsType.OBTENER_DATOS_FACTURA_LOADING:
      return facturacionVerFactura.action.getDatosDeFacturaLoading(state);
    case facturacionVerFactura.actionsType.CERRAR_ALERTA_OBTENER_DATOS_FACTURA:
      return facturacionVerFactura.action.cerrarAlertaObtenerDatosDeFactura(
        state,
      );
    default:
      return { ...state };
  }
}

export default facturacionVerFacturaApp;
