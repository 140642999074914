import actionsType from './actionsTypes';

function getEstadoDeCuentaSuccess(data) {
  return { type: actionsType.GET_ESTADODECUENTA_SUCCESS, data: data };
}
function getEstadoDeCuentaFailure(error) {
  return { type: actionsType.GET_ESTADODECUENTA_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getEstadoDeCuentaLoading() {
  return { type: actionsType.GET_ESTADODECUENTA_LOADING };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaEstadoDeCuenta() {
  return { type: actionsType.CERRAR_ALERTA_ESTADODECUENTA };
}
//--------------------------------------------------------------------------------------
function abrirModalEstadoDeCuenta() {
  return { type: actionsType.ABRIR_MODAL_ESTADODECUENTA };
}
function cerrarModalEstadoDeCuenta() {
  return { type: actionsType.CERRAR_MODAL_ESTADODECUENTA };
}

export default {
  getEstadoDeCuentaSuccess: getEstadoDeCuentaSuccess,
  getEstadoDeCuentaFailure: getEstadoDeCuentaFailure,
  getEstadoDeCuentaLoading: getEstadoDeCuentaLoading,
  abrirModalEstadoDeCuenta: abrirModalEstadoDeCuenta,
  cerrarModalEstadoDeCuenta: cerrarModalEstadoDeCuenta,
  cerrarAlertaEstadoDeCuenta: cerrarAlertaEstadoDeCuenta,
};
