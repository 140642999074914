const actionsType = {
  GET_CONSUMO_SUCCESS: 'GET_CONSUMO_SUCCESS',
  GET_CONSUMO_FAILURE: 'GET_CONSUMO_FAILURE',

  GETALL_CONSUMO_SUCCESS: 'GETALL_CONSUMO_SUCCESS',
  GETALL_CONSUMO_FAILURE: 'GETALL_CONSUMO_FAILURE',

  POST_CONSUMO_SUCCESS: 'POST_CONSUMO_SUCCESS',
  POST_CONSUMO_FAILURE: 'POST_CONSUMO_FAILURE',

  PUT_CONSUMO_SUCCESS: 'PUT_CONSUMO_SUCCESS',
  PUT_CONSUMO_FAILURE: 'PUT_CONSUMO_FAILURE',

  DELETE_CONSUMO_SUCCESS: 'DELETE_CONSUMO_SUCCESS',
  DELETE_CONSUMO_FAILURE: 'DELETE_CONSUMO_FAILURE',

  //Cerrar alerta de eliminar empresa
  CERRAR_ALERTA_ELIMINAR: 'CERRAR_ALERTA_ELIMINAR',
  //Cerrar alerta de agregar empresa
  CERRAR_ALERTA_AGREGAR: 'CERRAR_ALERTA_AGREGAR',
  //Cerrar alerta de actualizar empresa
  CERRAR_ALERTA_ACTUALIZAR: 'CERRAR_ALERTA_ACTUALIZAR',

  GET_CONSUMO_LOADING: 'GET_CONSUMO_LOADING',

  GET_TIPOSCONSUMO_SUCCESS: 'GET_TIPOSCONSUMO_SUCCESS',
  GET_TIPOSCONSUMO_FAILURE: 'GET_TIPOSCONSUMO_FAILURE',
};

export default actionsType;
