import React from "react";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Logo from "../../../assets/logo-gaspro.png";
import WhatsApp from "../../../assets/icono-tel.png";
import Correo from "../../../assets/icono-mail.png";
import FloatFormatter from "../../../hoc/helpers";

const Factura = (props) => {
  //Se toma la fecha del response, y se separa en arreglos para poder reacomodar el orden de la fecha en DD/MM/YYYY
  let FechaFactura = props.datos.FechaFactura.split("T");
  FechaFactura = FechaFactura[0].split("-");
  FechaFactura = `${FechaFactura[2]}/${FechaFactura[1]}/${FechaFactura[0]}`;

  //Declarión de arreglos que van a tener los datos correspondientes a los detalles
  let Cantidades,
    Codigos,
    Unidades,
    Descripciones,
    Precios,
    Importes = [];

  let moneda =
    props.datos.Moneda === "EUR"
      ? "€"
      : props.datos.Moneda === "USD"
      ? "$"
      : props.datos.Moneda === "QTZ"
      ? "Q"
      : props.datos.Moneda === "LPS"
      ? "L"
      : props.datos.Moneda === "NIO"
      ? "C$"
      : props.datos.Moneda === "CRC"
      ? "₡"
      : "";
      
  let tipoNit =
    props.pais === "Guatemala" ||
    props.pais === "Costa Rica" ||
    props.pais === "El Salvador"
      ? "NIT"
      : props.pais === "Panama" || props.pais === "Nicaragua"
      ? "RUC"
      : props.pais === "Honduras"
      ? "RTN"
      : "Na";

  //Se sacan unicamente las cantidades de los detalles para poder retornar TEXTS con las cantidades correspondientes
  Cantidades = props.datos.Detalle.map(function (registro) {
    return registro.Cantidad;
  });
  //Se sacan unicamente los códigos de los detalles para poder retornar TEXTS con las códigos correspondientes
  Codigos = props.datos.Detalle.map(function (registro) {
    return registro.Codigo;
  });
  //Se sacan unicamente las unidades de los detalles para poder retornar TEXTS con las unidades correspondientes
  Unidades = props.datos.Detalle.map(function (registro) {
    return registro.Unidad;
  });
  //Se sacan unicamente las descripciones de los detalles para poder retornar TEXTS con las descripciones correspondientes
  Descripciones = props.datos.Detalle.map(function (registro) {
    return registro.Descripcion;
  });
  //Se sacan unicamente los precios de los detalles para poder retornar TEXTS con los precios correspondientes
  Precios = props.datos.Detalle.map(function (registro) {
    return registro.PrecioUnitario;
  });
  //Se sacan unicamente los importes de los detalles para poder retornar TEXTS con los importes correspondientes
  Importes = props.datos.Detalle.map(function (registro) {
    return registro.Importe;
  });

  return (
    <>
      <Document title={"Factura No Fiscal"}>
        <Page size="A4">
          {/* ENCABEZADO DE FACTURA */}
          <View style={styles.viewHeaderContainer} fixed>
            <View style={styles.viewHeaderContainerBox}>
              <View style={styles.viewComponentsInLineTop}>
                <View style={styles.viewLogoContainer}>
                  <View style={styles.viewLogo}>
                    <Image src={Logo} style={styles.imageLogo} />
                  </View>
                  <View style={styles.viewNameLogo}>
                    <Text style={styles.textNameLogo}>
                      {props.datos.Compania.toUpperCase()}
                    </Text>
                  </View>
                </View>
                <View style={styles.viewDataContainer}>
                  <View style={styles.viewAddressData}>
                    <Text style={styles.textAddressData}>
                      {props.datos.DireccionCompania}
                    </Text>
                    <Text style={styles.textName}>
                      {props.datos.RUCCompania}
                    </Text>
                  </View>
                  <View style={styles.viewInfoContactData}>
                    <View style={styles.viewImageWhatsApp}>
                      <Image src={WhatsApp} style={styles.imageWhatsApp} />
                    </View>
                    <View style={styles.viewWhatsAppNumber}>
                      <Text>{props.datos.TelefonoCompania}</Text>
                    </View>
                    <View style={styles.viewImageCorreo}>
                      <Image src={Correo} style={styles.imageCorreo} />
                    </View>
                    <View style={styles.viewCorreo}>
                      <Text>{props.datos.CorreoCompania}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.viewOrderNumber}>
                  <View style={styles.viewOrderNumberContainer}>
                    <Text style={styles.textOrderNumberTitle}>
                      Comprobante de Compra
                    </Text>
                    <Text style={styles.textOrderNumberTitleNumber}>
                      Nº {props.datos.NumeroFactura}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.viewComponentsInLineCenter}>
                <View style={styles.viewLeftSide}>
                  <Text>
                    Cliente:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {props.datos.NombreCliente}
                  </Text>
                  <Text>
                    Dirección:&nbsp;&nbsp;{props.datos.DireccionCliente}
                  </Text>
                  <Text>
                    Grupo:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {props.datos.GrupoCliente}
                  </Text>
                </View>
                <View style={styles.viewRightSide}>
                  <Text>
                    {tipoNit}: {props.datos.RUCCliente}
                  </Text>
                  <Text>TEL: {props.datos.TelefonoCliente}</Text>
                </View>
              </View>
              <View style={styles.viewComponentsInLineBottom}>
                <View style={styles.viewComponentsInLineBottomTop}>
                  <View style={styles.view1InLineBottomTop}>
                    <Text style={styles.textInLineBottomTop}>FECHA</Text>
                  </View>
                  <View style={styles.view2InLineBottomTop}>
                    <Text style={styles.textInLineBottomTop}>LUGAR</Text>
                  </View>
                  <View style={styles.view3InLineBottomTop}>
                    <Text style={styles.textInLineBottomTop}>VENDEDOR</Text>
                  </View>
                  <View style={styles.view4InLineBottomTop}>
                    <Text style={styles.textInLineBottomTop}>
                      ORDEN DE COMPRA
                    </Text>
                  </View>
                  <View style={styles.view5InLineBottomTop}>
                    <Text style={styles.textInLineBottomTop}>
                      CÓDIGO DEL CLIENTE
                    </Text>
                  </View>
                  <View style={styles.view6InLineBottomTop}>
                    <Text style={styles.textInLineBottomTop}>
                      CONDICIONES DE PAGO
                    </Text>
                  </View>
                </View>
                <View style={styles.viewComponentsInLineBottomDown}>
                  <View style={styles.view1InLineBottomDown}>
                    <Text>{FechaFactura}</Text>
                  </View>
                  <View style={styles.view2InLineBottomDown}>
                    <Text>{props.datos.Lugar}</Text>
                  </View>
                  <View style={styles.view3InLineBottomDown}>
                    <Text>{props.datos.Vendedor}</Text>
                  </View>
                  <View style={styles.view4InLineBottomDown}>
                    <Text>{props.datos.NumeroOrden}</Text>
                  </View>
                  <View style={styles.view5InLineBottomDown}>
                    <Text>{props.datos.CodigoCliente}</Text>
                  </View>
                  <View style={styles.view6InLineBottomDown}>
                    <Text>{props.datos.Condiciones}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* DETALLE DE FACTURA */}
          <View style={styles.viewDetailContainer} fixed>
            <View style={styles.viewDetailContainerBox}>
              <View style={styles.viewDetailInlineTop}>
                <View style={styles.view1InLineCenterTop}>
                  <Text style={styles.textInLineCenterTop}>CANTIDAD</Text>
                </View>
                <View style={styles.view2InLineCenterTop}>
                  <Text style={styles.textInLineCenterTop}>CÓDIGO</Text>
                </View>
                <View style={styles.view3InLineCenterTop}>
                  <Text style={styles.textInLineCenterTop}>UNIDAD</Text>
                </View>
                <View style={styles.view4InLineCenterTop}>
                  <Text style={styles.textInLineCenterTop}>DESCRIPCIÓN</Text>
                </View>
                <View style={styles.view5InLineCenterTop}>
                  <Text style={styles.textInLineCenterTop}>PRECIO</Text>
                  <Text style={styles.textInLineCenterTop}>UNITARIO</Text>
                </View>
                <View style={styles.view6InLineCenterTop}>
                  <Text style={styles.textInLineCenterTop}>IMPORTE</Text>
                </View>
              </View>

              <View style={styles.viewDetailInlineCenter}>
                <View style={styles.viewDisclaimer}>
                  <Text style={styles.textDisclaimer}>DOCUMENTO</Text>
                  <Text style={styles.textDisclaimer}>NO FISCAL</Text>
                </View>
                <View style={styles.viewDetailInlineCenterContent}>
                  <View style={styles.view1InLineCenterCenter}>
                    {Cantidades.map(function (Cantidad, index) {
                      return (
                        <Text key={index} style={styles.textCenterContent}>
                          {Cantidad}
                        </Text>
                      );
                    })}
                  </View>
                  <View style={styles.view2InLineCenterCenter}>
                    {Codigos.map(function (Codigo, index) {
                      return (
                        <Text key={index} style={styles.textCenterContent}>
                          {Codigo}
                        </Text>
                      );
                    })}
                  </View>
                  <View style={styles.view3InLineCenterCenter}>
                    {Unidades.map(function (Unidad, index) {
                      return (
                        <Text key={index} style={styles.textCenterContent}>
                          {Unidad}
                        </Text>
                      );
                    })}
                  </View>
                  <View style={styles.view4InLineCenterCenter}>
                    {Descripciones.map(function (Descripcion, index) {
                      return (
                        <Text key={index} style={styles.textCenterContent}>
                          {Descripcion}
                        </Text>
                      );
                    })}
                  </View>
                  <View style={styles.view5InLineCenterCenter}>
                    {Precios.map(function (Precio, index) {
                      return (
                        <Text key={index} style={styles.textCenterContent}>
                          {moneda}&nbsp;
                          {FloatFormatter(
                            (Math.round(Precio * 100) / 100).toFixed(2)
                          )}
                        </Text>
                      );
                    })}
                  </View>
                  <View style={styles.view6InLineCenterCenter}>
                    {Importes.map(function (Importe, index) {
                      return (
                        <Text key={index} style={styles.textCenterContent}>
                          {moneda}&nbsp;
                          {FloatFormatter(
                            (Math.round(Importe * 100) / 100).toFixed(2)
                          )}
                        </Text>
                      );
                    })}
                  </View>
                </View>
              </View>
              <View style={styles.viewDetailInlineBottom}>
                <View style={styles.view1DetailInlineBottom}></View>
                <View style={styles.view2DetailInlineBottom}>
                  <Text style={styles.textItemsBottom}>SUB-TOTAL</Text>
                  <Text style={styles.textItemsBottom}>L.T.B.M.S.</Text>
                  <Text style={styles.textItemsBottom}>TOTAL</Text>
                </View>
                <View style={styles.view3DetailInlineBottom}></View>
                <View style={styles.view4DetailInlineBottom}>
                  <Text style={styles.textItemsBottom}>
                    {moneda}&nbsp;
                    {FloatFormatter(
                      (Math.round(props.datos.SubTotal * 100) / 100).toFixed(2)
                    )}
                  </Text>
                  <Text style={styles.textItemsBottom}>
                    {moneda}&nbsp;
                    {FloatFormatter(
                      (Math.round(props.datos.LTBMS * 100) / 100).toFixed(2)
                    )}
                  </Text>
                  <Text style={styles.textItemsBottom}>
                    {moneda}&nbsp;
                    {FloatFormatter(
                      (Math.round(props.datos.Total * 100) / 100).toFixed(2)
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default Factura;

const styles = StyleSheet.create({
  // ESTILOS DE ENCABEZADO DE FACTURA
  viewHeaderContainer: {
    height: "22%",
    justifyContent: "center",
    alignItems: "center",
  },
  viewHeaderContainerBox: {
    height: "85%",
    width: "95%",
  },
  viewComponentsInLineTop: {
    flexDirection: "row",
    height: "50%",
  },
  viewLogoContainer: {
    width: "25%",
  },
  viewLogo: {
    height: "70%",
  },
  imageLogo: {
    width: "97%",
    height: "auto",
  },
  viewNameLogo: {
    height: "30%",
    textAlign: "center",
    justifyContent: "center",
  },
  textNameLogo: {
    color: "black",
    fontSize: 9,
    fontWeight: "bold",
    marginBottom: 20,
  },
  textName: {
    color: "black",
    fontSize: 9,
    fontWeight: "bold",
    marginBottom: 0,
    marginTop: 5,
  },
  viewDataContainer: {
    width: "50%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  viewAddressData: {
    height: "60%",
    textAlign: "justify",
    justifyContent: "center",
  },
  textAddressData: {
    color: "black",
    fontWeight: "bold",
    fontSize: 10,
  },
  viewInfoContactData: {
    flexDirection: "row",
    height: "20%",
    fontSize: 8,
  },
  viewImageWhatsApp: {
    width: "7%",
    marginRight: 5,
  },
  imageWhatsApp: {
    height: "auto",
    width: 15,
  },
  viewWhatsAppNumber: {
    width: "35%",
    color: "black",
    fontWeight: "bold",
    fontSize: 10,
    justifyContent: "center",
  },
  viewImageCorreo: {
    width: "7%",
    marginRight: 5,
  },
  imageCorreo: {
    height: "auto",
    width: 15,
  },
  viewCorreo: {
    width: "45%",
    color: "black",
    fontWeight: "bold",
    fontSize: 10,
    justifyContent: "center",
  },
  viewRUCData: {
    height: "20%",
  },
  textRUCData: {
    color: "black",
    fontWeight: "bold",
    fontSize: 10,
  },
  viewOrderNumber: {
    width: "25%",
    justifyContent: "center",
  },
  viewOrderNumberContainer: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    border: 2,
    borderRadius: 8,
    borderColor: "#525659",
  },
  textOrderNumberTitle: {
    marginRight: 12,
    color: "#81973a",
    fontSize: 10,
    fontWeight: "bold",
  },
  textOrderNumberTitleNumber: {
    marginRight: 12,
    color: "red",
    fontSize: 10,
    fontWeight: "bold",
  },
  viewComponentsInLineCenter: {
    flexDirection: "row",
    fontSize: 10,
    height: "30%",
    marginTop: "1%",
  },
  viewLeftSide: {
    width: "65%",
    paddingRight: "2%",
  },
  viewRightSide: {
    width: "25%",
  },
  viewComponentsInLineBottom: {
    height: "25%",
  },
  viewComponentsInLineBottomTop: {
    flexDirection: "row",
    backgroundColor: "#81973a",
    height: "40%",
    width: "100%",
  },
  view1InLineBottomTop: {
    width: "14%",
    textAlign: "center",
    justifyContent: "center",
  },
  view2InLineBottomTop: {
    width: "14%",
    textAlign: "center",
    justifyContent: "center",
  },
  view3InLineBottomTop: {
    width: "14%",
    textAlign: "center",
    justifyContent: "center",
  },
  view4InLineBottomTop: {
    width: "18%",
    textAlign: "center",
    justifyContent: "center",
  },
  view5InLineBottomTop: {
    width: "20%",
    textAlign: "center",
    justifyContent: "center",
  },
  view6InLineBottomTop: {
    width: "20%",
    textAlign: "center",
    justifyContent: "center",
  },
  textInLineBottomTop: {
    color: "white",
    fontSize: 8,
    fontWeight: "bold",
  },
  viewComponentsInLineBottomDown: {
    flexDirection: "row",
    height: "60%",
    width: "100%",
  },
  view1InLineBottomDown: {
    borderRight: 1,
    borderBottom: 1,
    borderLeft: 1,
    borderColor: "#525659",
    width: "14%",
    textAlign: "center",
    fontSize: 7,
    justifyContent: "center",
  },
  view2InLineBottomDown: {
    borderRight: 1,
    borderBottom: 1,
    borderColor: "#525659",
    width: "14%",
    textAlign: "center",
    fontSize: 7,
    justifyContent: "center",
  },
  view3InLineBottomDown: {
    borderRight: 1,
    borderBottom: 1,
    borderColor: "#525659",
    width: "14%",
    textAlign: "center",
    fontSize: 7,
    justifyContent: "center",
  },
  view4InLineBottomDown: {
    borderRight: 1,
    borderBottom: 1,
    borderColor: "#525659",
    width: "18%",
    textAlign: "center",
    fontSize: 7,
    justifyContent: "center",
  },
  view5InLineBottomDown: {
    borderRight: 1,
    borderBottom: 1,
    borderColor: "#525659",
    width: "20%",
    textAlign: "center",
    fontSize: 7,
    justifyContent: "center",
  },
  view6InLineBottomDown: {
    borderRight: 1,
    borderBottom: 1,
    borderColor: "#525659",
    width: "20%",
    textAlign: "center",
    fontSize: 7,
    justifyContent: "center",
  },
  // ESTILOS DE DETALLE DE FACTURA
  viewDetailContainer: {
    height: "78%",
    justifyContent: "center",
    alignItems: "center",
  },
  viewDetailContainerBox: {
    height: "95%",
    width: "95%",
  },
  viewDetailInlineTop: {
    height: "3%",
    backgroundColor: "#81973a",
    flexDirection: "row",
  },
  viewDetailInlineCenter: {
    height: "85%",
    // flexDirection: 'row',
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderColor: "#525659",
  },
  viewDetailInlineCenterContent: {
    height: "100%",
    marginTop: -529,
    flexDirection: "row",
  },
  textInLineCenterTop: {
    color: "white",
    fontSize: 8,
    fontWeight: "bold",
  },
  view1InLineCenterTop: {
    width: "9%",
    textAlign: "center",
    justifyContent: "center",
  },
  view2InLineCenterTop: {
    width: "9%",
    textAlign: "center",
    justifyContent: "center",
  },
  view3InLineCenterTop: {
    width: "9%",
    textAlign: "center",
    justifyContent: "center",
  },
  view4InLineCenterTop: {
    width: "45%",
    textAlign: "center",
    justifyContent: "center",
  },
  view5InLineCenterTop: {
    width: "14%",
    textAlign: "center",
    justifyContent: "center",
  },
  view6InLineCenterTop: {
    width: "15%",
    textAlign: "center",
    justifyContent: "center",
  },
  view1InLineCenterCenter: {
    height: "100%",
    width: "8%",
    textAlign: "center",
    fontSize: 10,
    borderRight: 1,
    borderColor: "#525659",
  },
  view2InLineCenterCenter: {
    height: "100%",
    width: "10%",
    textAlign: "center",
    fontSize: 10,
    borderRight: 1,
    borderColor: "#525659",
  },
  view3InLineCenterCenter: {
    height: "100%",
    width: "10%",
    textAlign: "center",
    fontSize: 10,
    borderRight: 1,
    borderColor: "#525659",
  },
  textCenterContent: {
    marginTop: 2,
  },
  view4InLineCenterCenter: {
    height: "100%",
    width: "45%",
    textAlign: "left",
    marginLeft: "1px",
    fontSize: 10,
    borderRight: 1,
    borderColor: "#525659",
  },
  view5InLineCenterCenter: {
    height: "100%",
    width: "13%",
    textAlign: "right",
    fontSize: 10,
    borderRight: 1,
    borderColor: "#525659",
    paddingRight: "4em",
  },
  view6InLineCenterCenter: {
    height: "100%",
    width: "13%",
    textAlign: "right",
    fontSize: 10,
  },
  viewDetailInlineBottom: {
    flexDirection: "row",
    textAlign: "right",
    height: "12%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderColor: "#525659",
  },
  view1DetailInlineBottom: {
    height: "100%",
    width: "49%",
    borderRight: 1,
    borderColor: "#525659",
  },
  view2DetailInlineBottom: {
    height: "100%",
    width: "24%",
    borderRight: 1,
    borderColor: "#525659",
  },
  textItemsBottom: {
    fontSize: 10,
    fontWeight: "bold",
    color: "black",
    marginTop: 10,
    marginRight: 5,
    textAlign: "right",
  },
  view3DetailInlineBottom: {
    height: "100%",
    width: "13%",
    borderRight: 1,
    borderColor: "#525659",
  },
  view4DetailInlineBottom: {
    height: "100%",
    width: "14%",
  },
  viewDisclaimer: {
    textAlign: "center",
    justifyContent: "center",
    height: "100%",
  },
  textDisclaimer: {
    fontSize: 55,
    color: "lightgray",
  },
});
