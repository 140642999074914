import signs from './signs';
import api from '../../../api/api';

function getConsumo(parametros) {
  return (dispatch) => {
    api
      .post(`api/Consumo`, { ...parametros })
      .then(function (response) {
        dispatch(signs.getConsumoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(signs.getConsumoFailure(err.message));
      });
  };
}

function getConsumoLoading() {
  return (dispatch) => {
    dispatch(signs.getConsumoLoading());
  };
}

export default {
  getConsumo: getConsumo,
  getConsumoLoading: getConsumoLoading,
};
