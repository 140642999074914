import signs from './signs';

function postOpcionActiva(opcion) {
  return (dispatch) => {
    dispatch(signs.postOpcionActiva(opcion));
  };
}

export default {
  postOpcionActiva: postOpcionActiva,
};
