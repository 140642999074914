import signs from './signs';
import api from '../../../api/api';

function getInventario(parametros) {
  return (dispatch) => {
    api
      .post(`api/Inventario`, { ...parametros })
      .then(function (response) {
        dispatch(signs.getInventarioSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getInventarioFailure(err));
      });
  };
}

function getInventarioLoading() {
  return (dispatch) => {
    dispatch(signs.getInventarioLoading());
  };
}

export default {
  getInventario: getInventario,
  getInventarioLoading: getInventarioLoading,
};
