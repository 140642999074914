import sing from './sings';
import calls from './calls';
import actionsType from './actionsTypes';

function getProveedoresAction(state, response) {
  return {
    ...state,
    proveedor: response,
    proveedoresError: '',
  };
}

function getAllProveedoresAction(state, response) {
  return {
    ...state,
    proveedores: response,
    proveedoresError: '',
    loading: false,
  };
}

function getProveedorAction(state, response) {
  return {
    ...state,
  };
}

function postProveedoresAction(state, response) {
  return {
    ...state,
    loading: false,
  };
}

function putProveedoresAction(state, response) {
  return {
    ...state,
    Actualizar_Proveedor_Status: response.data.status,
    loading: false,
  };
}

function deleteProveedoresAction(state, response) {
  return {
    ...state,
    loading: false,
  };
}

function cerrarAlertaActualizar(state) {
  return {
    ...state,
    Actualizar_Proveedor_Status: null,
  };
}

function getProveedoresLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

export default {
  actionsType: actionsType,
  sign: sing,
  action: {
    getProveedoresAction: getProveedoresAction,
    getAllProveedoresAction: getAllProveedoresAction,
    postProveedoresAction: postProveedoresAction,
    putProveedoresAction: putProveedoresAction,
    deleteProveedoresAction: deleteProveedoresAction,
    cerrarAlertaActualizar: cerrarAlertaActualizar,
    getProveedoresLoading: getProveedoresLoading,
  },
  calls: calls,
};
