import React from 'react';
import { Row, Col } from 'antd';

const PantallaDeNoAcceso = (props) => {
  return (
    <>
      <Row
        style={{
          background: '#81973a',
          borderRadius: 5,
          marginTop: '2%',
          width: '80%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Col span={24} style={{ color: 'white', textAlign: 'center' }}>
          Actualmente no tienes acceso a este módulo.
        </Col>
      </Row>
      <Row
        style={{
          marginTop: '1%',
          height: '80%',
          width: '100%',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Col style={{ width: '80%' }}>
          <img width={'100%'} height={'auto'} src={props.src} alt={props.alt} />
        </Col>
      </Row>
      <Row
        style={{
          background: '#81973a',
          borderRadius: 5,
          marginTop: '2%',
          width: '80%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Col span={24} style={{ color: 'white', textAlign: 'center' }}>
          Contáctanos para adquirir este módulo con todos sus beneficios.
        </Col>
      </Row>
    </>
  );
};
export default PantallaDeNoAcceso;
