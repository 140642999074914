import actions from '../actions';

const consumo = actions.consumo;

const initialState = {
  consumo: [],
  consumoError: '',
  loading: false,
};

function consumoApp(state = initialState, action) {
  switch (action.type) {
    case consumo.actionsType.GET_CONSUMO_SUCCESS:
      return consumo.action.getConsumoAction(state, action.data);
    case consumo.actionsType.GET_CONSUMO_FAILURE:
      return {
        ...state,
        consumo: [],
        consumoError: '',
        loading: false,
      };
    case consumo.actionsType.GET_CONSUMO_LOADING:
      return consumo.action.getConsumoLoading(state);
    default:
      return { ...state };
  }
}

export default consumoApp;
