import React, { useState, useRef } from "react";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { path } from "../../../routes";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { decrypt } from "react-crypt-gsm";
import jwtDecode from "jwt-decode";
import {
  EditOutlined,
  GroupOutlined,
  InfoCircleOutlined,
  ProjectOutlined,
  CaretRightOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignBottomOutlined,
  DotChartOutlined,
  FileSyncOutlined,
  EnvironmentOutlined,
  FormOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  Table,
  Input,
  Button,
  Space,
  Divider,
  Tooltip,
  Row,
  Col,
  Modal,
  Form,
  notification,
  Select,
  TimePicker,
} from "antd";
import moment from "moment";
import "./consultarConsumo.css";
import { useEffect } from "react";
const { TextArea } = Input;
const { Option } = Select;

const ConsultarConsumo = (props) => {
  //Usuario logueado en el sistema
  var Token = sessionStorage.getItem("TokenUsuario");
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalActualizarConsumo, setModalActualizarConsumo] = useState(false);
  const [dataUpdate, setDataUpdate] = useState([]);
  let searchInput = useRef();

  useEffect(() => {
    if (props.consumo.length !== 0) {
      let datos = {
        ID_Tanque: props.consumo.ID_Tanque,
        ID_CompaniaCliente: props.consumo.ID_CompaniaCliente,
        Equipo: props.consumo.Equipo,
        Gas: props.consumo.Gas,
        Molecula: props.consumo.Molecula,
        Presion_Alta: props.consumo.Presion_Alta.toString(),
        Presion_Baja: props.consumo.Presion_Baja.toString(),
        HorasTrabajo: props.consumo.HorasTrabajo.toString(),
        PromedioConsumo: props.consumo.Gate.toString(),
        Dias_Facturacion: props.consumo.Dias_Facturacion.toString(),
        Ubicacion: props.consumo.Ubicacion,
        Mediciones: props.consumo.Mediciones,
      };
      setDataUpdate(datos);
    }
  }, [props.consumo]);

  const menuEmpresa = (
    <Select
      suffixIcon={<CaretRightOutlined rotate={90} />}
      placeholder="Empresa"
      showSearch
      optionFilterProp="label"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {props.empresas.map((elemento) => (
        <Option
          key={elemento.ID_CompaniaCliente}
          value={elemento.ID_CompaniaCliente}
          label={elemento.Nombre}
        >
          {elemento.Nombre}
        </Option>
      ))}
    </Select>
  );

  const menuTiposConsumo = (
    <Select
      disabled={false}
      suffixIcon={<CaretRightOutlined rotate={90} />}
      placeholder="Tipo Consumo"
    >
      {props.tiposConsumo.map((elemento) => (
        <Option key={elemento.ID_TipoMedicion} value={elemento.ID_TipoMedicion}>
          {elemento.Descripcion}
        </Option>
      ))}
    </Select>
  );

  //-------------USEEFFECTS PARA AGREGAR CONSUMO--------------------------------
  useEffect(() => {
    if (props.Agregar_Consumo_Status === 200) {
      notification.open({
        type: "success",
        message: "¡Consumo agregado correctamente!",
        description: "El consumo se agregó correctamente.",
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    } else if (
      props.Agregar_Consumo_Status === 400 ||
      props.Agregar_Consumo_Status === 404 ||
      props.Agregar_Consumo_Status === 500
    ) {
      notification.open({
        type: "error",
        message: "¡Ha ocurrido un error!",
        description:
          "La acción no se realizó debido a que hay problemas presentes.",
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    }
  }, [props.Agregar_Consumo_Status]);

  //-------------USEEFFECTS PARA ACTUALIZAR CONSUMO--------------------------------
  useEffect(() => {
    if (props.Actualizar_Consumo_Status === 200) {
      notification.open({
        type: "success",
        message: "¡Consumo actualizada correctamente!",
        description: "El consumo se actualizó correctamente.",
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActualizar();
        },
      });
      props.onGetAllConsumo(usuario.usuario.authmethod);
    } else if (
      props.Actualizar_Consumo_Status === 400 ||
      props.Actualizar_Consumo_Status === 404 ||
      props.Actualizar_Consumo_Status === 500
    ) {
      notification.open({
        type: "error",
        message: "¡Ha ocurrido un error!",
        description:
          "La acción no se realizó debido a que hay problemas presentes.",
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActualizar();
        },
      });
    }
  }, [props.Actualizar_Consumo_Status]);

  const datos = props.consumos.map(function (elemento) {
    return {
      key: elemento.ID_Tanque,
      tanque: elemento.ID_Tanque,
      equipo: elemento.Equipo,
      molecula: elemento.Molecula,
      gas: elemento.Gas,
      presionAlta: elemento.Presion_Alta,
      presionBaja: elemento.Presion_Baja,
      horasTrabajo: elemento.HorasTrabajo,
      gate: elemento.Gate,
      diasFacturacion: elemento.Dias_Facturacion,
      ubicacion: elemento.Ubicacion,
      cliente: elemento.Cliente.Nombre,
    };
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="BotonRestablecer"
          >
            Reiniciar
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="BotonBuscar"
          >
            Aceptar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <>
        <Tooltip title={`Buscar ${dataIndex}`}>
          <SearchOutlined
            style={{
              color: filtered ? "#1890ff" : "#81973a",
              float: "left",
            }}
            className="iconoBuscar"
          />
          {/* <Divider
            type='vertical'
            style={{
              height: '50%',
              marginTop: '50%',
              float: 'right',
              borderWidth: '2px',
              borderColor: 'white',
              marginRight: '1px',
            }}
          /> */}
        </Tooltip>
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //--------------------Funciones de Modal actualizar consumo-----------------
  const ActualizarConsumo = (id) => {
    props.onGetConsumo(id);
    setTimeout(() => {
      setModalActualizarConsumo(!modalActualizarConsumo);
    }, 1000);
  };

  //---------------------------------Función actualizar consumo----------------------------
  const handleSubmitActualizar = (values) => {
    props.onGetConsumoLoading();
    props.onPutConsumo(values);
  };

  const handleCancelActualizar = () => {
    setModalActualizarConsumo(!modalActualizarConsumo);
  };

  //--------------------Funciones de busqueda en columnas---------------------
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Tanque",
      dataIndex: "tanque",
      key: "tanque",
      width: "10%",
      ...getColumnSearchProps("tanque"),
    },
    {
      title: "Empresa",
      dataIndex: "cliente",
      key: "cliente",
      width: "30%",
      ...getColumnSearchProps("cliente"),
    },
    {
      title: "Equipo",
      dataIndex: "equipo",
      key: "equipo",
      width: "15%",
      ...getColumnSearchProps("equipo"),
    },
    {
      title: "Gas",
      dataIndex: "gas",
      key: "gas",
      width: "8%",
      ...getColumnSearchProps("gas"),
    },
    {
      title: "Molécula",
      dataIndex: "molecula",
      key: "molecula",
      width: "8%",
      ...getColumnSearchProps("molecula"),
    },
    {
      title: "Presión Alta",
      dataIndex: "presionAlta",
      key: "presionAlta",
      width: "10%",
      ...getColumnSearchProps("presionAlta"),
    },
    {
      title: "Presión Baja",
      dataIndex: "presionBaja",
      key: "presionBaja",
      width: "10%",
      ...getColumnSearchProps("presionBaja"),
    },
    {
      title: "Horas de Trabajo",
      dataIndex: "horasTrabajo",
      key: "horasTrabajo",
      width: "12%",
      ...getColumnSearchProps("horaInicio"),
    },
    {
      title: "Gate",
      dataIndex: "gate",
      key: "gate",
      width: "8%",
      ...getColumnSearchProps("gate"),
    },
    {
      title: "Días Facturación",
      dataIndex: "diasFacturacion",
      key: "diasFacturacion",
      width: "13%",
      ...getColumnSearchProps("diasFacturacion"),
    },
    {
      title: "Ubicación",
      dataIndex: "ubicacion",
      key: "ubicacion",
      width: "10%",
      ...getColumnSearchProps("ubicacion"),
    },
    {
      fixed: "right",
      title: "Acción",
      width: "05%",
      align: "center",
      render: (record) => (
        <>
          <Tooltip title="Editar">
            <EditOutlined onClick={() => ActualizarConsumo(record.key)} />
          </Tooltip>
          <Divider type="vertical" style={{ visibility: "hidden" }} />
        </>
      ),
    },
  ];

  //------------------------Col Form Item Responsive----------------------------
  const FormItemResponsive = (props) => {
    return (
      <Col
        lg={{ offset: 1, span: 21 }}
        md={{ offset: 1, span: 21 }}
        sm={{ offset: 1, span: 21 }}
        xs={{ offset: 1, span: 21 }}
      >
        {props.FormItem}
      </Col>
    );
  };

  return (
    <>
      {/* Modal para actualizar una consumo*/}
      <Modal
        title="Actualizar Consumo"
        visible={modalActualizarConsumo}
        onCancel={handleCancelActualizar}
        footer={null}
        destroyOnClose
        centered
        width="80%"
      >
        <Form
          name="Actualizar_Consumo_Form"
          initialValues={dataUpdate}
          onFinish={handleSubmitActualizar}
        >
          <Row>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="ID_Tanque"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        min: 1,
                        max: 100,
                        message: "Mínimo 1 carácter, máximo 100 carácteres",
                      },
                    ]}
                  >
                    <Input
                      disabled
                      placeholder="ID Tanque"
                      prefix={<GroupOutlined />}
                      suffix={
                        <Tooltip
                          title="Identificador del Tanque"
                          placement="bottomLeft"
                        >
                          <InfoCircleOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="ID_CompaniaCliente"
                    rules={[
                      {
                        required: true,
                        message: "¡Este campo es requerido!",
                      },
                    ]}
                  >
                    {menuEmpresa}
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="Equipo"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        min: 1,
                        max: 200,
                        message: "Mínimo 1 carácter, máximo 200 carácteres",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Equipo"
                      prefix={<GroupOutlined />}
                      suffix={
                        <Tooltip title="Tipo de Equipo" placement="bottomLeft">
                          <InfoCircleOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="Gas"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        min: 1,
                        max: 100,
                        message: "Mínimo 1 carácter, máximo 100 carácteres",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Gas"
                      prefix={<DotChartOutlined />}
                      suffix={
                        <Tooltip title="Tipo de Gas" placement="bottomLeft">
                          <InfoCircleOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="Molecula"
                    rules={[
                      {
                        required: true,
                        message: "¡Este campo es requerido!",
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={<CaretRightOutlined rotate={90} />}
                      placeholder="Molécula"
                    >
                      <Option value={"O2"}>O2</Option>
                      <Option value={"N2"}>N2</Option>
                      <Option value={"Ar"}>Ar</Option>
                    </Select>
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="Presion_Alta"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        min: 1,
                        max: 20,
                        message: "Mínimo 1 carácter, máximo 20 carácteres",
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: "Solo se admiten números",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Presión Alta"
                      prefix={<VerticalAlignTopOutlined />}
                      suffix={
                        <Tooltip title="Presión Alta" placement="bottomLeft">
                          <InfoCircleOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="Presion_Baja"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        min: 1,
                        max: 20,
                        message: "Mínimo 1 carácter, máximo 20 carácteres",
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: "Solo se admiten números",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Presión Baja"
                      prefix={<VerticalAlignBottomOutlined />}
                      suffix={
                        <Tooltip title="Presión Baja" placement="bottomLeft">
                          <InfoCircleOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
            </Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="HorasTrabajo"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        min: 1,
                        max: 2,
                        message: "Mínimo 1 carácter, máximo 2 carácteres",
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: "Solo se admiten números",
                      },
                    ]}
                  >
                   <Input
                    placeholder="Horas Trabajo"
                    prefix={<ClockCircleOutlined />}
                    suffix={
                      <Tooltip title="Horas de Trabajo" placement="bottomLeft">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="PromedioConsumo"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: "Solo se admiten números",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Promedio Consumo"
                      prefix={<ProjectOutlined rotate="180" />}
                      suffix={
                        <Tooltip
                          title="Promedio Consumo"
                          placement="bottomLeft"
                        >
                          <InfoCircleOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="Dias_Facturacion"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        pattern: /^([0-9])*$/,
                        message: "Solo se admiten números",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Días Facturación"
                      prefix={<FileSyncOutlined />}
                      suffix={
                        <Tooltip
                          title="Días Facturación"
                          placement="bottomLeft"
                        >
                          <InfoCircleOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name="Ubicacion"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "¡Este campo es requerido!",
                      },
                      {
                        min: 1,
                        max: 50,
                        message: "Mínimo 1 carácter, máximo 50 carácteres",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Ubicación"
                      prefix={<EnvironmentOutlined />}
                      suffix={
                        <Tooltip title="Ubicación" placement="bottomLeft">
                          <InfoCircleOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              {/* <Form.List name="Mediciones" >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Col
                      lg={{ offset: 0, span: 24 }}
                      md={{ offset: 0, span: 24 }}
                      sm={{ offset: 0, span: 24 }}
                      xs={{ offset: 0, span: 24 }}
                    >
                      <Space key={field.key} align="baseline" style={{marginLeft:'4%'}} >
                        <Col
                          lg={{ offset: 0, span: 24 }}
                          md={{ offset: 0, span: 24 }}
                          sm={{ offset: 0, span: 24 }}
                          xs={{ offset: 0, span: 24 }}
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "ID_Medicion"]}
                            fieldKey={[field.fieldKey, "ID_Medicion"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "¡Este campo es requerido!",
                              },
                              {
                                min: 1,
                                max: 50,
                                message:
                                  "Mínimo 1 carácter, máximo 50 carácteres",
                              },
                            ]}
                          >
                            <Input
                              disabled={false}
                              placeholder="Id Medición"
                              prefix={<FormOutlined />}
                              suffix={
                                <Tooltip
                                  title="Ubicación"
                                  placement="bottomLeft"
                                >
                                  <InfoCircleOutlined
                                    style={{ color: "rgba(0,0,0,.45)" }}
                                  />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          lg={{ offset: 0, span: 24 }}
                          md={{ offset: 0, span: 24 }}
                          sm={{ offset: 0, span: 24 }}
                          xs={{ offset: 0, span: 24 }}
                        >
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }
                          >
                            {() => (
                              <Form.Item
                                {...field}
                                name={[field.name, "Tipo_Medicion"]}
                                fieldKey={[field.fieldKey, "Tipo_Medicion"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "¡Este campo es requerido!",
                                  },
                                ]}
                              >
                                {menuTiposConsumo}
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Col>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                      </Col>
                    ))}

                    <Form.Item style={{marginLeft:'4%'}}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        style={{ width: "90%" }}
                      >
                        Agregar Mediciones
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List> */}
            </Col>
          </Row>

          <Row justify="center">
            <Col
              lg={{ offset: 0, span: 8 }}
              md={{ offset: 0, span: 8 }}
              sm={{ offset: 0, span: 8 }}
              xs={{ offset: 0, span: 8 }}
            >
              <Button htmlType="submit" className="botonActualizarConsumo">
                Actualizar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Pantalla de consulta de Consumo */}
      <Row justify="end" style={{ marginTop: "2%" }}>
        <Col
          lg={{ offset: 0, span: 4 }}
          md={{ offset: 0, span: 5 }}
          sm={{ offset: 0, span: 5 }}
          xs={{ offset: 0, span: 7 }}
        >
          <Button
            onClick={() => history.push({ pathname: path.agregarConsumo })}
            className="botonAgregarUsuario"
          >
            Agregar
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: "1%" }}>
        <Col
          lg={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          xs={{ offset: 0, span: 24 }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={datos}
            scroll={{ x: 2500, y: 420 }}
            pagination={{
              defaultCurrent: 1,
              total: datos.length,
              responsive: true,
              showSizeChanger: true,
              size: "small",
              defaultPageSize: 20,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ConsultarConsumo;
