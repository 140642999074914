import sings from './sings';
import api from '../../../api/api';

function getUsuariosById(id) {
  return (dispatch) => {
    api
      .get(`api/User/${id}`)
      .then(function (response) {
        dispatch(sings.getUsuariosSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getUsuariosFailure(err.message));
      });
  };
}

function getAllUsuarios(id) {
  return (dispatch) => {
    api
      .get(`api/User?id=${id}`)
      .then(function (response) {
        dispatch(sings.getAllUsuariosSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getAllUsuariosFailure(err.message));
      });
  };
}

function postUsuarios(nombre, usuario) {
  return (dispatch) => {
    api
      .post(`api/User?usuarioActivo=${nombre}`, { ...usuario })
      .then(function (response) {
        dispatch(sings.postUsuariosSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.postUsuariosFailure(err));
      });
  };
}

function putUsuarios(usuario) {
  return (dispatch) => {
    api
      .post(`api/User/Update`, { ...usuario })
      .then(function (response) {
        dispatch(sings.putUsuariosSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.putUsuariosFailure(err));
      });
  };
}

function deleteUsuarios(id, nombre) {
  return (dispatch) => {
    api
      .delete(`api/User/${id}?usuarioActivo=${nombre}`)
      .then(function (response) {
        dispatch(sings.deleteUsuariosSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.deleteUsuariosFailure(err));
      });
  };
}

function cerrarAlertaActivarInactivar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaActivarInactivar());
  };
}
function cerrarAlertaAgregar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaAgregarUsuario());
  };
}
function cerrarAlertaActualizar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaActualizarUsuario());
  };
}

function getLoading() {
  return (dispatch) => {
    dispatch(sings.getUsuariosLoading());
  };
}
function ungetLoading() {
  return (dispatch) => {
    dispatch(sings.ungetUsuariosLoading());
  };
}

export default {
  getUsuariosById: getUsuariosById,
  getAllUsuarios: getAllUsuarios,
  postUsuarios: postUsuarios,
  putUsuarios: putUsuarios,
  deleteUsuarios: deleteUsuarios,
  cerrarAlertaActivarInactivar: cerrarAlertaActivarInactivar,
  cerrarAlertaAgregar: cerrarAlertaAgregar,
  cerrarAlertaActualizar: cerrarAlertaActualizar,
  getLoading: getLoading,
  ungetLoading: ungetLoading,
};
