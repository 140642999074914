import React from "react";
import { Layout, Row, Col } from "antd";

import "antd/dist/antd.css";
import "./layout.css";
import SideBar from "../../components/navigation/sidebar/sidebar";
import logo from "../../assets/logo-gpc-blanco.png";
import UserPanel from "../../components/userPanel/userPanel";
import { useHistory } from "react-router-dom";
import { path } from "../../routes";
const { Header, Content, Footer } = Layout;

const HomeLayout = (props) => {
  let history = useHistory();
  let complete_layout = (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          paddingLeft: 10,
          paddingRight: 5,
          backgroundColor: "#81973a",
          height: 60,
        }}
      >
        <Row>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 5 }}
          >
            <img
              style={{ marginTop: -10 }}
              src={logo}
              alt="logo"
              width={140}
              height={50}
              onClick={() => history.push(path.default)}
            />
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 18 }}
          >
            <UserPanel />
          </Col>
        </Row>
      </Header>
      <Layout className="site-layout">
        <SideBar {...props} />
        <Content className="Content">{props.children}</Content>
      </Layout>
      <Footer
        style={{
          textAlign: "center",
          backgroundColor: "white",
          color: "black",
          fontWeight:'600'
        }}
      >
        © 2021 · Gaspro
      </Footer>
    </Layout>
  );

  return complete_layout;
};

export default HomeLayout;
