import React, { Component } from 'react';
import AgregarUsuarios from '../../../components/gestionDeUsuarios/agregarUsuarios/agregarUsuarios';
import actions from '../../../data/actions';
import { connect } from 'react-redux';
import Loading from '../../../components/loading/loading';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';

class agregarUsuarios extends Component {
  componentDidMount() {
    //Usuario logueado en el sistema
    var Token = sessionStorage.getItem('TokenUsuario');
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };

    this.props.onGetAllEmpresas(usuario.usuario.authmethod);
    this.props.onGetAllPaises();
  }
  render() {
    return (
      <>
        {this.props.loading ? <Loading /> : <AgregarUsuarios {...this.props} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    empresas: state.empresas.empresas,
    paises: state.empresas.paises,
    Agregar_Usuario_Status: state.usuarios.Agregar_Usuario_Status,
    Agregar_Empresa_Status: state.empresas.Agregar_Empresa_Status,
    loading: state.usuarios.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPostUsuarios: (nombre, data) =>
      dispatch(actions.usuarios.calls.postUsuarios(nombre, data)),
    onPostEmpresa: (nombre, data) =>
      dispatch(actions.empresas.calls.postEmpresas(nombre, data)),
    onGetAllEmpresas: (id) =>
      dispatch(actions.empresas.calls.getAllEmpresas(id)),
    onGetAllUsuarios: () => dispatch(actions.usuarios.calls.getAllUsuarios()),
    cerrarAlertaAgregar: () =>
      dispatch(actions.usuarios.calls.cerrarAlertaAgregar()),
    onGetLoading: () => dispatch(actions.usuarios.calls.getLoading()),
    onGetAllPaises: () => dispatch(actions.empresas.calls.getAllPaises()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(agregarUsuarios);
