import actions from '../actions';

const inventario = actions.inventario;

const initialState = {
  inventario: [],
  loading: false,
};

function inventarioApp(state = initialState, action) {
  switch (action.type) {
    case inventario.actionsType.GET_INVENTARIO_SUCCESS:
      return inventario.action.getInventarioAction(state, action.data);
    case inventario.actionsType.GET_INVENTARIO_FAILURE:
      return {
        ...state,
        inventario: [],
        loading: false,
      };
    case inventario.actionsType.GET_INVENTARIO_LOADING:
      return inventario.action.getInventarioLoading(state);
    default:
      return { ...state };
  }
}

export default inventarioApp;
