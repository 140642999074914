import signs from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

//Actions para obtener
function getDatosDeFacturaAction(state, response) {
  return {
    ...state,
    DatosFactura: response.data,
    Ver_Factura_Loading: false,
    Ver_Factura_Status: response.status,
  };
}

function getDatosDeFacturaLoading(state) {
  return {
    ...state,
    Ver_Factura_Loading: true,
  };
}

function cerrarAlertaObtenerDatosDeFactura(state) {
  return {
    ...state,
    Ver_Factura_Status: null,
  };
}

export default {
  actionsType: actionsType,
  sign: signs,
  action: {
    getDatosDeFacturaAction: getDatosDeFacturaAction,
    getDatosDeFacturaLoading: getDatosDeFacturaLoading,
    cerrarAlertaObtenerDatosDeFactura: cerrarAlertaObtenerDatosDeFactura,
  },
  calls: calls,
};
