import sings from './sings';
import api from '../../../api/api';

function getConsumoById(id) {
  return (dispatch) => {
    api
      .get(`api/Consumo/${id}`)
      .then(function (response) {
        dispatch(sings.getConsumoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getConsumoFailure(err.message));
      });
  };
}

function getAllConsumo(id) {
  return (dispatch) => {
    api
      .get(`api/Consumo/TodosTanques/${id}`)
      .then(function (response) {
        dispatch(sings.getAllConsumoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getAllConsumoFailure(err.message));
      });
  };
}

function postConsumo(consumo) {
  return (dispatch) => {
    api
      .post(`api/Consumo/AgregarTanque`, { ...consumo })
      .then(function (response) {
        dispatch(sings.postConsumoSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.postConsumoFailure(err));
      });
  };
}

function putConsumo(consumo) {
  return (dispatch) => {
    api
      .post(`api/Consumo/ActualizarTanque`, { ...consumo })
      .then(function (response) {
        dispatch(sings.putConsumoSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.putConsumoFailure(err));
      });
  };
}

function deleteConsumo(id, nombre) {
  return (dispatch) => {
    api
      .delete(`api/ClientCompany/${id}?usuarioActivo=${nombre}`)
      .then(function (response) {
        dispatch(sings.deleteConsumoSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.deleteConsumoFailure(err));
      });
  };
}

function cerrarAlertaEliminar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaEliminar());
  };
}
function cerrarAlertaAgregar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaAgregar());
  };
}
function cerrarAlertaActualizar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaActualizar());
  };
}

function getConsumoLoading() {
  return (dispatch) => {
    dispatch(sings.getConsumoLoading());
  };
}

function getAllTiposConsumo() {
  return (dispatch) => {
    api
      .get(`api/Consumo/TiposMediciones`)
      .then(function (response) {
        dispatch(sings.getAllTiposConsumoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getAllTiposConsumoFailure(err.message));
      });
  };
}

export default {
  getConsumoById: getConsumoById,
  getAllConsumo: getAllConsumo,
  postConsumo: postConsumo,
  putConsumo: putConsumo,
  deleteConsumo: deleteConsumo,
  cerrarAlertaEliminar: cerrarAlertaEliminar,
  cerrarAlertaAgregar: cerrarAlertaAgregar,
  cerrarAlertaActualizar: cerrarAlertaActualizar,
  getConsumoLoading: getConsumoLoading,
  getAllTiposConsumo: getAllTiposConsumo,
};
