const actionsType = {
  OBTENER_DATOS_FACTURA_SUCCESS: 'OBTENER_DATOS_FACTURA_SUCCESS',
  OBTENER_DATOS_FACTURA_FAILURE: 'OBTENER_DATOS_FACTURA_FAILURE',

  OBTENER_DATOS_FACTURA_LOADING: 'OBTENER_DATOS_FACTURA_LOADING',

  CERRAR_ALERTA_OBTENER_DATOS_FACTURA: 'CERRAR_ALERTA_OBTENER_DATOS_FACTURA',
};

export default actionsType;
