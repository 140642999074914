import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Typography,
  DatePicker,
  Card,
  Tabs,
  Select,
  Form,
  Input,
  Empty,
  Tooltip,
  Button,
  Collapse,
} from "antd";
import { ScheduleOutlined, AlertFilled } from "@ant-design/icons";
import { Line as LineChart } from "@ant-design/charts";
import { decrypt } from "react-crypt-gsm";
import jwtDecode from "jwt-decode";
import Consumo from "../../../assets/Imagen4-Carousel.png";
import PantallaDeNoAcceso from "../../pantallaDeNoAcceso";
import moment from "moment";
import Seccion from "../../Seccion";
import "./moduloConsumo.css";
import FloatFormatter from "../../../hoc/helpers";
const { TabPane } = Tabs;
const { Option } = Select;

const ModuloConsumo = (props) => {
  //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva pantalla
  var Token = sessionStorage.getItem("TokenUsuario");
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let { consumo, onGetConsumo } = props;
  const [ConsumoItemCheck, setConsumoItemCheck] = useState(0);
  const [ConsumoItem, setConsumoItem] = useState([]);
  const [consumoDiarioHora, setconsumoDiarioHora] = useState([]);
  const [consumoMensual, setconsumoMensual] = useState([]);
  let datosMedicion = useRef();
  let refFecha = useRef();

  useEffect(() => {
    setConsumoItem(consumo[ConsumoItemCheck]);
    if (
      ConsumoItem !== [] &&
      ConsumoItem !== undefined &&
      ConsumoItem !== null &&
      datosMedicion.current !== undefined
    ) {
      datosMedicion.current.setFieldsValue({
        flujoActual: FloatFormatter(
          (Math.round(ConsumoItem.FlujoActual * 100) / 100).toFixed(1)
        ),
        presion: FloatFormatter(
          (Math.round(ConsumoItem.PresionActual * 100) / 100).toFixed(1)
        ),
        temperatura: ConsumoItem.Temperatura,
        flujoPromedio: FloatFormatter(
          (Math.round(ConsumoItem.FlujoPromedioDiario * 100) / 100).toFixed(1)
        ),
        consumoDia: FloatFormatter(
          (Math.round(ConsumoItem.ConsumoDia * 100) / 100).toFixed(1)
        ),
        consumoMes: FloatFormatter(
          (Math.round(ConsumoItem.ConsumoMes * 100) / 100).toFixed(1)
        ),
        // UnidadMedidaFA: ConsumoItem.UnidadMedidaGas,
        UnidadMedidaP: ConsumoItem.UnidadMedidaPresion,
        UnidadMedidaT: ConsumoItem.UnidadMedidaTemperatura,
        // UnidadMedidaC: ConsumoItem.UnidadMedidaLiquido,
      });
    }
  }, [ConsumoItem, ConsumoItemCheck, consumo]);

  //Crear los arreglos que consumierá el grafica de Consumo Diario por Hora & Consumo Mensual
  useEffect(() => {
    let vConsumoDiario = [];
    let vConsumoMensual = [];
    for (const key in consumo) {
      consumo[ConsumoItemCheck].ConsumoDiario.reverse().forEach(function (
        consumoDiario
      ) {
        let Fecha = consumoDiario.Fecha.split("T");
        let Hora = Fecha[1].split(":");
        Hora = `${Hora[0]}`;
        let lecturasDia = {
          Fecha: `${Fecha[0]}T${Hora}:00`,
          Valor: consumoDiario.Valor,
        };
        vConsumoDiario.push(lecturasDia);
      });
      consumo[ConsumoItemCheck].ConsumoMensual.reverse().forEach(function (
        consumoMensual
      ) {
        let Fecha = consumoMensual.Fecha.split("T");
        let lecturasMes = {
          Fecha: `${Fecha[0]}`,
          Valor: consumoMensual.Valor,
        };
        vConsumoMensual.push(lecturasMes);
      });
    }
    return (
      setconsumoDiarioHora(vConsumoDiario), setconsumoMensual(vConsumoMensual)
    );
  }, [ConsumoItem, ConsumoItemCheck, consumo]);

  //Metodo que actualiza la informacion de los datos cuando cambia la fecha
  const ObtenerDatosFormularioPorFecha = (fecha) => {
    let UnidadMedidaFA = datosMedicion.current.getFieldValue("UnidadMedidaFA");
    let UnidadMedidaC = datosMedicion.current.getFieldValue("UnidadMedidaC");
    let parametros = {
      IdEmpresa: usuario.usuario.Compania,
      Fecha: fecha.utcOffset(0, true).format(),
      UnidadMedidaGas: UnidadMedidaFA,
      UnidadMedidaLiquido: UnidadMedidaC,
    };
    onGetConsumo(parametros);
  };
  //Metodo que actualiza la informacion de los datos cuando cambia la fecha
  const ObtenerDatosFormularioPorUnidades = () => {
    let UnidadMedidaFA = datosMedicion.current.getFieldValue("UnidadMedidaFA");
    let UnidadMedidaC = datosMedicion.current.getFieldValue("UnidadMedidaC");
    let Fecha = refFecha.current
      .getFieldValue("fechagraficos")
      .utcOffset(0, true)
      .format();
    let parametros = {
      IdEmpresa: usuario.usuario.Compania,
      Fecha: Fecha,
      UnidadMedidaGas: UnidadMedidaFA,
      UnidadMedidaLiquido: UnidadMedidaC,
    };
    onGetConsumo(parametros);
  };

  const convertirPSIaBARViceversa = (unidad) => {
    let covPresion = datosMedicion.current.getFieldValue("presion");
    if (unidad === 16) {
      datosMedicion.current.setFieldsValue({
        presion: FloatFormatter(
          (Math.round(covPresion * 0.0689476 * 100) / 100).toFixed(1)
        ),
      });
    } else {
      if (datosMedicion.current !== undefined) {
        for (let index in consumo) {
          let medidas = consumo[ConsumoItemCheck];
          datosMedicion.current.setFieldsValue({
            presion: FloatFormatter(
              (Math.round(medidas.PresionActual * 100) / 100).toFixed(1)
            ),
          });
        }
      }
    }
  };

  const convertirCaFViceversa = (unidad) => {
    let covTemperatura = datosMedicion.current.getFieldValue("temperatura");
    if (unidad === 20) {
      datosMedicion.current.setFieldsValue({
        temperatura: FloatFormatter(
          (Math.round(((covTemperatura * 9) / 5 + 32) * 100) / 100).toFixed(1)
        ),
      });
    } else {
      if (datosMedicion.current !== undefined) {
        for (let index in consumo) {
          let medidas = consumo[ConsumoItemCheck];
          datosMedicion.current.setFieldsValue({
            temperatura: medidas.Temperatura,
          });
        }
      }
    }
  };

  //Configuración de los graficos
  var config = {
    xField: "Fecha",
    yField: "Valor",
    smooth: "true",
    yAxis: {
      min: 0,
      max: 6000,
      nice: true,
    },
  };

  const CambiarDatosDeCliente = (i) => {
    setConsumoItem(consumo[i]);
    setConsumoItemCheck(i);
  };

  const columns = [
    {
      title: 'Señal',
      width: '50%',
    },
    {
      title: 'Estado',
      width: '50%',
    },
  ];

  return (
    <>
      {usuario.usuario.Consumo === "true" ? (
        <>
          {/* SECCIÓN PARA LA INFORMACIÓN DEL CLIENTE */}
          <Row>
            <Col
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Seccion titulo={"Información del Cliente"} marginTop={"1.9%"} />

              <Row style={{ marginTop: "1%" }}>
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {consumo.length !== 0 ? (
                    <>
                      <Tabs
                        className="TabTanque"
                        defaultActiveKey="0"
                        type="card"
                        onChange={CambiarDatosDeCliente}
                      >
                        {consumo.map((consumo, index) => {
                          return (
                            <>
                              <TabPane
                                tab={
                                  <span>
                                    <ScheduleOutlined />
                                    {consumo.Cliente} ({consumo.Equipo})
                                  </span>
                                }
                                key={`${index}`}
                              ></TabPane>
                            </>
                          );
                        })}
                      </Tabs>
                    </>
                  ) : (
                    <Tabs
                      className="TabTanque"
                      defaultActiveKey="0"
                      type="card"
                    >
                      <TabPane
                        tab={
                          <span>
                            <ScheduleOutlined />
                            No hay datos
                          </span>
                        }
                      ></TabPane>
                    </Tabs>
                  )}
                  {consumo.length !== 0 ? (
                    <>
                      <Card className="CardInfoCliente">
                        <Row>
                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>Cliente:</Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text>
                              {ConsumoItem.Cliente}
                            </Typography.Text>
                          </Col>

                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>Ubicación:</Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text>
                              {ConsumoItem.Ubicacion}
                            </Typography.Text>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>Equipo:</Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text>
                              {ConsumoItem.Equipo}
                            </Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>Gas:</Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text>{ConsumoItem.Gas}</Typography.Text>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>
                              Horas Trabajo:
                            </Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text>
                              {ConsumoItem.HorasTrabajo} HORAS
                            </Typography.Text>
                          </Col>

                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>
                              Presión de Servicio:
                            </Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text style={{ marginRight: 10 }}>
                              Baja
                            </Typography.Text>
                            <Typography.Text
                              style={{
                                padding: "0% 2% 0% 2%",
                                marginRight: 20,
                              }}
                            >
                              {ConsumoItem.PresionBaja}
                            </Typography.Text>
                            <Typography.Text style={{ marginRight: 10 }}>
                              Alta
                            </Typography.Text>
                            <Typography.Text
                              style={{
                                padding: "0% 2% 0% 2%",
                              }}
                            >
                              {ConsumoItem.PresionAlta}
                            </Typography.Text>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>
                              Promedio de Consumo:
                            </Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text>
                              {ConsumoItem.Gate} NM3H
                            </Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>
                              Intervalo de Facturación:
                            </Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text>
                              {ConsumoItem.Facturacion} Días
                            </Typography.Text>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            lg={{ span: 4 }}
                            md={{ span: 4 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text strong>
                              Presión Normal:
                            </Typography.Text>
                          </Col>
                          <Col
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <Typography.Text>
                              {ConsumoItem.AlertaFuga} NM3H
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  ) : (
                    <Card className="CardInfoCliente">
                      <Empty />
                    </Card>
                  )}
                </Col>
              </Row>
            </Col>

            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Seccion titulo={"Mediciones"} marginTop={"4%"} />

              {consumo.length !== 0 ? (
                <>
                  <Card className="CardMediciones">
                    <Form ref={datosMedicion}>
                      <Row>
                        <Col
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                            Flujo Actual:
                          </Typography.Text>
                        </Col>
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="flujoActual">
                            <Input readOnly size="small" />
                          </Form.Item>
                        </Col>
                        <Col
                          lg={{ offset: 1, span: 6 }}
                          md={{ offset: 1, span: 6 }}
                          sm={{ offset: 1, span: 24 }}
                          xs={{ offset: 1, span: 24 }}
                        >
                          <Form.Item name="UnidadMedidaFA">
                            <Select
                              size="small"
                              onChange={() =>
                                ObtenerDatosFormularioPorUnidades()
                              }
                              defaultValue={23}
                            >
                              <Option value={23}>
                                <Tooltip title="Metro Cúbico Normalizado por Hora">
                                  NM3H
                                </Tooltip>
                              </Option>
                              <Option value={22}>
                                <Tooltip title="Pies Cúbico Estándar por Hora">
                                  SCFH
                                </Tooltip>
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "-1.6em" }}>
                        <Col
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>Presión:</Typography.Text>
                        </Col>
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="presion">
                            <Input readOnly size="small" />
                          </Form.Item>
                        </Col>
                        <Col
                          lg={{ offset: 1, span: 6 }}
                          md={{ offset: 1, span: 6 }}
                          sm={{ offset: 1, span: 24 }}
                          xs={{ offset: 1, span: 24 }}
                        >
                          <Form.Item name="UnidadMedidaP">
                            <Select
                              size="small"
                              onChange={convertirPSIaBARViceversa}
                              defaultValue={7}
                            >
                              <Option value={7}>
                                <Tooltip title="Libras por Pulgada Cuadrada">
                                  PSI
                                </Tooltip>
                              </Option>
                              <Option value={16}>Bar</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "-1.6em" }}>
                        <Col
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>Temperatura:</Typography.Text>
                        </Col>
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="temperatura">
                            <Input readOnly size="small" />
                          </Form.Item>
                        </Col>
                        <Col
                          lg={{ offset: 1, span: 6 }}
                          md={{ offset: 1, span: 6 }}
                          sm={{ offset: 1, span: 24 }}
                          xs={{ offset: 1, span: 24 }}
                        >
                          <Form.Item name="UnidadMedidaT">
                            <Select
                              size="small"
                              onChange={convertirCaFViceversa}
                              defaultValue={19}
                            >
                              <Option value={19}>Celsius</Option>
                              <Option value={20}>Fahrenheit</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "-1.6em" }}>
                        <Col
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                            Flujo Promedio Diario:
                          </Typography.Text>
                        </Col>
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="flujoPromedio">
                            <Input readOnly size="small" />
                          </Form.Item>
                        </Col>
                        <Col
                          lg={{ offset: 1, span: 6 }}
                          md={{ offset: 1, span: 6 }}
                          sm={{ offset: 1, span: 24 }}
                          xs={{ offset: 1, span: 24 }}
                        >
                          <Form.Item key={2} name="UnidadMedidaFA">
                            <Select
                              size="small"
                              onChange={() =>
                                ObtenerDatosFormularioPorUnidades()
                              }
                              defaultValue={23}
                            >
                              <Option value={23}>
                                <Tooltip title="Metro Cúbico Normalizado por Hora">
                                  NM3H
                                </Tooltip>
                              </Option>
                              <Option value={22}>
                                <Tooltip title="Pies Cúbico Estándar por Hora">
                                  SCFH
                                </Tooltip>
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "-1.6em" }}>
                        <Col
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                            Unidad Medida:
                          </Typography.Text>
                        </Col>
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="UnidadMedidaC">
                            <Select
                              size="small"
                              onChange={() =>
                                ObtenerDatosFormularioPorUnidades()
                              }
                              defaultValue={4}
                            >
                              <Option value={4}>Galones</Option>
                              <Option value={17}>Litros</Option>
                              <Option value={5}>Pies Cúbicos</Option>
                              <Option value={18}>Metros Cúbicos</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "-1.6em" }}>
                        <Col
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>Consumo Día:</Typography.Text>
                        </Col>
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="consumoDia">
                            <Input readOnly size="small" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "-1.6em" }}>
                        <Col
                          lg={{ span: 8 }}
                          md={{ span: 8 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                            Consumo Mensual:
                          </Typography.Text>
                        </Col>
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="consumoMes">
                            <Input readOnly size="small" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </>
              ) : (
                <Card className="CardMediciones">
                  <Empty />
                </Card>
              )}
            </Col>

            <Col
              lg={{ offset: 1, span: 11 }}
              md={{ offset: 1, span: 11 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Seccion titulo={"Monitoreo de Alertas"} marginTop={"4%"} />

              {consumo.length !== 0 ? (
                <>
                  <Card className="CardMonitoreo">

                    <Form>
                    <Row>
                        <Col
                          lg={{ span: 10 }}
                          md={{ span: 10}}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                          </Typography.Text>
                        </Col>
                        <Col
                          lg={{offset:2, span: 5 }}
                          md={{offset:2, span: 5 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                         <Typography.Text strong>
                            Estatus
                          </Typography.Text>
                        </Col>
                        <Col
                          lg={{ span: 11 }}
                          md={{ span: 11}}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                          </Typography.Text>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col
                          lg={{ span: 10 }}
                          md={{ span: 10}}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                            Operación Normal:
                          </Typography.Text>
                        </Col>
                       <Col
                          lg={{offset: 2,span: 6 }}
                          md={{offset: 2,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          >
                          <Typography.Text strong>
                             {ConsumoItem.AlertaFlujo === true || ConsumoItem.AlertaPresion === true || ConsumoItem.AlertaAlimentacion === true ? "Crítico" : "Normal"}
                          </Typography.Text>
                        </Col>
                        <Col
                          lg={{offset: 1,span: 5 }}
                          md={{offset: 1,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="operacionNormal">
                          {ConsumoItem.AlertaFlujo === true || ConsumoItem.AlertaPresion === true || ConsumoItem.AlertaAlimentacion === true ?
                              <p className='botonAlerta' style={{background: "#E12121", color: "#E12121"}}>.</p>
                            : <p className='botonAlerta' style={{ background: "#81973a", color: "#81973a"}}>.</p>}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "-0.9em" }}>
                        <Col
                          lg={{ span: 10 }}
                          md={{ span: 10 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                            Flujo Dentro de Rango:
                          </Typography.Text>
                        </Col>
                         <Col
                          lg={{offset: 2,span: 5 }}
                          md={{offset: 2,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          >
                          <Typography.Text strong>
                            {ConsumoItem.AlertaFlujo === false ? "Normal" : "Crítico"}
                          </Typography.Text>
                        </Col>
                        <Col
                         lg={{offset: 2,span: 5 }}
                          md={{offset: 2,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="flujoRango">
                            {ConsumoItem.AlertaFlujo === false ? <p className='botonAlerta' style={{background: "#81973a", color: "#81973a"}}>.</p>
                                                               : <p className='botonAlerta' style={{ background: "#E12121", color: "#E12121"}}>.</p>}

                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "-0.9em" }}>
                        <Col
                          lg={{ span: 10 }}
                          md={{ span: 10 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                            Presión Dentro de Rango:
                          </Typography.Text>
                        </Col>
                         <Col
                          lg={{offset: 2,span: 6 }}
                          md={{offset: 2,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          >
                          <Typography.Text strong>
                             {ConsumoItem.AlertaPresion === false ? "Normal" : "Crítico"}
                          </Typography.Text>
                        </Col>
                        <Col
                         lg={{offset: 1,span: 5 }}
                          md={{offset: 1,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="presionRango">
                            {ConsumoItem.AlertaPresion === false ? <p className='botonAlerta' style={{background: "#81973a", color: "#81973a"}}>.</p>
                                                               : <p className='botonAlerta' style={{ background: "#E12121", color: "#E12121"}}>.</p>}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "-0.9em" }}>
                        <Col
                          lg={{ span: 10 }}
                          md={{ span: 10 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                            Conexión Energía Eléctrica:
                          </Typography.Text>
                        </Col>
                         <Col
                          lg={{offset: 2,span: 6 }}
                          md={{offset: 2,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          >
                          <Typography.Text strong>
                            {ConsumoItem.AlertaAlimentacion === false ? "Normal" : "Crítico"}
                          </Typography.Text>
                        </Col>
                        <Col
                         lg={{offset: 1,span: 5 }}
                          md={{offset: 1,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="bateria respaldo">
                           {ConsumoItem.AlertaAlimentacion === false ? <p className='botonAlerta' style={{background: "#81973a", color: "#81973a"}}>.</p>
                                                               : <p className='botonAlerta' style={{ background: "#E12121", color: "#E12121"}}>.</p>}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "-0.9em", marginBottom: "-1.7em"}}>
                        <Col
                          lg={{ span: 10 }}
                          md={{ span: 10 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Typography.Text strong>
                          Batería de Respaldo:
                          </Typography.Text>
                        </Col>
                         <Col
                          lg={{offset: 2,span: 6 }}
                          md={{offset: 2,span: 7 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          >
                          <Typography.Text strong>
                            {ConsumoItem.AlertaAlimentacionRespaldo === false ? "Normal" : "Crítico"}
                          </Typography.Text>
                        </Col>
                        <Col
                          lg={{offset:1, span: 5 }}
                          md={{offset:1,span: 3 }}
                          sm={{ span: 7 }}
                          xs={{ span: 7 }}
                        >
                          <Form.Item name="bateriarRespaldo">
                             {ConsumoItem.AlertaAlimentacionRespaldo === false ? <p className='botonAlerta' style={{background: "#81973a", color: "#81973a"}}>.</p>
                                                               : <p className='botonAlerta' style={{ background: "#E12121", color: "#E12121"}}>.</p>}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </>
              ) : (
                <Card className="CardMediciones">
                  <Empty />
                </Card>
              )}
            </Col>
          </Row>

          <Row
            style={{
              marginTop: "1.9%",
            }}
          >
            <Col
              lg={{ span: 19 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Seccion titulo={"Históricos"} />
            </Col>
            <Col
              lg={{ span: 2 }}
              md={{ span: 5 }}
              sm={{ span: 3 }}
              xs={{ span: 6 }}
            >
              <Row
                style={{
                  background: "#81973a",
                  borderRadius: "5px 0px 0px 5px",
                  marginLeft: "5%",
                  height: "86%",
                }}
              >
                <Col
                  style={{
                    color: "white",
                    marginLeft: "10%",
                    fontSize: "13px",
                    marginTop: "1%",
                    fontWeight: "bold",
                  }}
                >
                  Fecha:
                </Col>
              </Row>
            </Col>
            <Col
              lg={{ span: 3 }}
              md={{ span: 7 }}
              sm={{ span: 12 }}
              xs={{ span: 18 }}
            >
              <Form
                ref={refFecha}
                initialValues={{ fechagraficos: moment() }}
                style={{ marginTop: -5 }}
              >
                <Form.Item name="fechagraficos" style={{ height: "1vh" }}>
                  <DatePicker
                    size="small"
                    format="DD/MM/YYYY"
                    allowClear={false}
                    onChange={ObtenerDatosFormularioPorFecha}
                    style={{ width: "100%", border: "2px solid #81973a" }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1" type="card" style={{ marginTop: "1%" }}>
            <TabPane key="1" tab="Consumo Diario por Hora">
              <Row
                justify="center"
                style={{
                  height: "50vh",
                  border: "2px solid #d1d3d4",
                  backgroundColor: "#fff",
                  marginBottom: "2%",
                  alignItems: "center",
                }}
              >
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{
                    height: "50vh",
                    padding: "1%",
                  }}
                >
                  {consumoDiarioHora.length !== 0 ? (
                    <>
                      <LineChart data={consumoDiarioHora} {...config} />
                    </>
                  ) : (
                    <>
                      <Empty style={{ marginTop: "10em" }} />
                    </>
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane key="2" tab="Consumo Mensual">
              <Row
                justify="center"
                style={{
                  height: "50vh",
                  border: "2px solid #d1d3d4",
                  backgroundColor: "#fff",
                  marginBottom: "2%",
                  alignItems: "center",
                }}
              >
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{
                    height: "50vh",
                    padding: "1%",
                  }}
                >
                  {consumoMensual.length !== 0 ? (
                    <>
                      <LineChart data={consumoMensual} {...config} />
                    </>
                  ) : (
                    <>
                      <Empty style={{ marginTop: "10em" }} />
                    </>
                  )}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </>
      ) : (
        <>
          <PantallaDeNoAcceso src={Consumo} alt={"Consumo"} />
        </>
      )}
    </>
  );
};
export default ModuloConsumo;
