import signs from './signs';
import api from '../../../api/api';

function getEstadoDeCuenta(parametros) {
  return (dispatch) => {
    api
      .post(`api/Invoice/estadoCuenta`, { ...parametros })
      .then(function (response) {
        dispatch(signs.getEstadoDeCuentaSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getEstadoDeCuentaFailure(err));
      });
  };
}

function getEstadoDeCuentaLoading() {
  return (dispatch) => {
    dispatch(signs.getEstadoDeCuentaLoading());
  };
}

function cerrarAlertaEstadoDeCuenta() {
  return (dispatch) => {
    dispatch(signs.cerrarAlertaEstadoDeCuenta());
  };
}

function abrirModalEstadoDeCuenta() {
  return (dispatch) => {
    dispatch(signs.abrirModalEstadoDeCuenta());
  };
}

function cerrarModalEstadoDeCuenta() {
  return (dispatch) => {
    dispatch(signs.cerrarModalEstadoDeCuenta());
  };
}

export default {
  getEstadoDeCuenta: getEstadoDeCuenta,
  getEstadoDeCuentaLoading: getEstadoDeCuentaLoading,
  abrirModalEstadoDeCuenta: abrirModalEstadoDeCuenta,
  cerrarModalEstadoDeCuenta: cerrarModalEstadoDeCuenta,
  cerrarAlertaEstadoDeCuenta: cerrarAlertaEstadoDeCuenta,
};
