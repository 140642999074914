import React, { useState, useRef, useEffect } from "react";
import { Column as ColumnChart, Pie, Line } from "@ant-design/charts";
import Highlighter from "react-highlight-words";
import Column from "antd/lib/table/Column";
import "moment/locale/es";
import moment from "moment";
import esEs from "antd/es/date-picker/locale/es_ES";
import { SearchOutlined } from "@ant-design/icons";
import iconloading from "../../../assets/icono_cargando2.gif";
import { decrypt } from "react-crypt-gsm";
import jwtDecode from "jwt-decode";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
  Typography,
  Modal,
  Spin,
  Tabs,
  Empty,
} from "antd";
import {
  EyeOutlined,
  DownloadOutlined,
  PieChartOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { BlobProvider } from "@react-pdf/renderer";
import Comprobante from "./moduloControlDeEnvasesVerComprobante";
import ControlDeEnvases from "../../../assets/Imagen2-Carousel.png";
import PantallaDeNoAcceso from "../../pantallaDeNoAcceso";
import Seccion from "../../Seccion";
import "./moduloControlDeEnvases.css";
const { TabPane } = Tabs;

const { Text } = Typography;

const ModuloControlDeEnvases = (props) => {
  var Token = sessionStorage.getItem("TokenUsuario");
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let datosCliente = {
    CodigoCliente: usuario.usuario.ControlEnvaseCodigo,
    NombreCompania: usuario.usuario.NombreCompania,
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [TablaResumen, setTablaResumen] = useState([]);
  const [DataPie, setDataPie] = useState([]);
  const [DataBar, setDataBar] = useState([]);
  const [TablaInsumos, setTablaInsumos] = useState([]);
  const [TablaDescripcion, setTablaDescripcion] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [CargandoComprobante, setCargandoComprobante] = useState(false);
  const [FilaSeleccionada, setFilaSeleccionada] = useState(null);
  let searchInput = useRef();

  useEffect(() => {
    let resumen = [];
    let DataPie = [];
    for (let i in props.insumos.envases) {
      let tupla = props.insumos.envases[i];
      tupla = {
        key: i,
        envase: tupla.envase,
        cantidad: tupla.cantidad,
        pds: tupla.pds,
        porcentaje:
          tupla.porcentaje === 100 || tupla.porcentaje === 0
            ? tupla.porcentaje
            : (Math.round(tupla.porcentaje * 100) / 100).toFixed(1),
      };
      if (tupla.envase !== "Total") {
        if (tupla.cantidad !== 0) {
          let data = {
            envase: tupla.envase,
            cantidad: tupla.cantidad,
          };
          DataPie.push(data);
        }
      }
      resumen.push(tupla);
    }
    return setTablaResumen(resumen), setDataPie(DataPie), setLoadingData(false);
  }, [props.insumos.envases]);

  useEffect(() => {
    let descripciones = [];
    for (let i in props.insumos.descripcion) {
      let tupla = props.insumos.descripcion[i];
      tupla = {
        key: i,
        descripción: tupla.descripcion,
        cantidad: tupla.cantidad,
        pds: tupla.pds,
        porcentaje:
          tupla.porcentaje !== 100
            ? (Math.round(tupla.porcentaje * 100) / 100).toFixed(1)
            : tupla.porcentaje,
      };
      descripciones.push(tupla);
    }
    return setTablaDescripcion(descripciones), setLoadingData(false);
  }, [props.insumos.descripcion]);

  useEffect(() => {
    let insumos = [];
    for (let i in props.insumos.insumos) {
      let tupla = props.insumos.insumos[i];
      tupla = {
        key: i,
        envase: tupla.envase,
        código: tupla.codigo,
        descripción: tupla.descripcion,
        serie: tupla.numSerie,
        barcode: tupla.codigoBarras,
        entregado: tupla.entregado,
        ds: tupla.ds,
      };
      insumos.push(tupla);
    }
    return setTablaInsumos(insumos), setLoadingData(false);
  }, [props.insumos.insumos]);

  useEffect(() => {
    let DataBar = [];
    for (let i in props.insumos.datosPorMes) {
      let tupla = props.insumos.datosPorMes[i];
      tupla = {
        key: i,
        Mes: tupla.Mes,
        Cantidad: tupla.Cantidad,
      };
      DataBar.push(tupla);
    }
    return setDataBar(DataBar.reverse()), setLoadingData(false);
  }, [props.insumos.datosPorMes]);

  // USEEFFECT PARA CONSUMIR EL API DE COMPROBANTE Y SI ES OK EL STATUS ENTONCES ABRIR EL RECIBO
  useEffect(() => {
    if (props.verComprobanteStatus === 200) {
      setCargandoComprobante(!CargandoComprobante);
    } else if (
      props.verComprobanteStatus === 400 ||
      props.verComprobanteStatus === 404 ||
      props.verComprobanteStatus === 500
    ) {
      setCargandoComprobante(!CargandoComprobante);
      notification.open({
        type: "error",
        message: "¡Ha ocurrido un error!",
        description:
          "La acción no se realizó debido a que hay problemas presentes.",
        duration: 3,
        onClose: () => {
          props.onClearCodigoCliente();
        },
      });
    } else if (props.verComprobanteStatus === 409) {
      setCargandoComprobante(!CargandoComprobante);
      notification.open({
        type: "warning",
        message: "¡NO HAY RECIBO PARA MOSTRAR!",
        description:
          "Lo sentimos, no hemos encontrado su recibo de entrega. Póngase en contacto con el Administrador del sitio.",
        duration: 4,
        onClose: () => {
          props.onClearCodigoCliente();
        },
      });
    }
  }, [props.verComprobanteStatus]);

  var configuracionPie = {
    appendPadding: 10,
    angleField: "cantidad",
    colorField: "envase",
    radius: 0.8,
    legend: false,
    label: {
      type: "spider",
      labelHeight: 28,
      content: function content(_ref) {
        var percent = _ref.percent;
        var envase = _ref.envase;
        return envase.concat('\n', percent !== 1 ? (Math.round((percent * 100) * 100) / 100).toFixed(1) : (Math.round((percent * 100) * 100) / 100).toFixed(0) , '%');
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
  };

  var paletteSemanticRed = "#81973a";
  var brandColor = "#81973a";
  var configuracionDataBar = {
    xField: "Mes",
    yField: "Cantidad",
    label: {
      style: {
        fill: "#000",
        opacity: 0.7,
      },
      position: 'middle',
    },
    meta: {
      type: { alias: "Total" },
      sales: { alias: "Total" },
    },
    //color: '#81973a',
    color: function color(_ref) {
      var type = _ref.Mes;
      if (type === "Feb.") {
        return paletteSemanticRed;
      }
      return brandColor;
    },
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="BotonRestablecer"
          >
            Reiniciar
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="BotonBuscar"
          >
            Aceptar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <>
        <Tooltip title={`Buscar ${dataIndex}`}>
          <SearchOutlined
            style={{
              color: filtered ? "#1890ff" : "#81973a",
              float: "left",
            }}
            className="iconoBuscar"
          />
        </Tooltip>
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //--------------------Funciones de busqueda en columnas---------------------
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const FiltrarResumen = (value) => {
    if (value === null) {
    } else {
      let fecha = value._d + "";
      fecha = fecha.split(" ");
      let mes = fecha[1];
      let año = fecha[3];

      if (mes === "Jan") {
        mes = "01";
      } else if (mes === "Feb") {
        mes = "02";
      } else if (mes === "Mar") {
        mes = "03";
      } else if (mes === "Apr") {
        mes = "04";
      } else if (mes === "May") {
        mes = "05";
      } else if (mes === "Jun") {
        mes = "06";
      } else if (mes === "Jul") {
        mes = "07";
      } else if (mes === "Aug") {
        mes = "08";
      } else if (mes === "Sep") {
        mes = "09";
      } else if (mes === "Oct") {
        mes = "10";
      } else if (mes === "Nov") {
        mes = "11";
      } else {
        mes = "12";
      }
      fecha = `${mes}/${año}`;

      let parametros = {
        Empresa: usuario.usuario.ControlEnvaseCodigo,
        Fecha: fecha,
      };
      setLoadingData(true);
      props.onGetInsumos(parametros);
    }
  };

  const verComprobante = (registro) => {
    let parametros = {
      CodigoCliente: usuario.usuario.ControlEnvaseCodigo,
      CodigoActivo: registro.barcode,
    };
    setCargandoComprobante(!CargandoComprobante);
    props.onGetComprobante(parametros);
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('month');
  }

  return (
    <>
      <Modal
        visible={CargandoComprobante}
        footer={null}
        centered
        closable={false}
        bodyStyle={{ backgroundColor: "rgba(255,255,255,0.60)" }}
      >
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Spin
            size="large"
            indicator={<img src={iconloading} />}
            style={{ color: "#81973a" }}
          />
          <p style={{ color: "#81973a" }}>Cargando...</p>
        </div>
      </Modal>
      {usuario.usuario.ControlEnvase === "true" ? (
        <>
          {/* <Seccion titulo={'Resumen'} marginTop={'2%'} /> */}

          <Row
            style={{
              marginTop: "1.9%",
            }}
          >
            <Col
              lg={{ span: 19 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Seccion titulo={"Resumen"} />
            </Col>
            <Col
              lg={{ span: 2 }}
              md={{ span: 5 }}
              sm={{ span: 3 }}
              xs={{ span: 6 }}
            >
              <Row
                style={{
                  background: "#81973a",
                  borderRadius: '5px 0px 0px 5px',
                  marginLeft: '5%',
                  height: "85%"
                }}
              >
                <Col style={{ color: "white", marginLeft: "10%", fontSize: "13px", marginTop: '1%', fontWeight: "bold" }}>Fecha:</Col>
              </Row>
            </Col>
            <Col
              lg={{ span: 3 }}
              md={{ span: 7 }}
              sm={{ span: 12 }}
              xs={{ span: 18 }}
            >
              <DatePicker
                size="small"
                picker="month"
                format="MMMM YYYY"
                defaultValue={moment()}
                onChange={FiltrarResumen}
                locale={esEs}
                style={{ width: "100%", height: "85%", border: "2px solid #81973a" }}
                disabledDate={disabledDate}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "1%" }}>
            <Col
              style={{ marginRight: "0.5%" }}
              lg={{ span: 9 }}
              md={{ span: 9 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Table
                bordered
                className="TablaCE"
                loading={loadingData}
                size="small"
                dataSource={TablaDescripcion}
                pagination={{
                  hideOnSinglePage: true,
                  size: "small",
                  pageSize: 50,
                }}
                scroll={{ x: 400, y: 250 }}
              >
                <Column
                  title="Descripción"
                  dataIndex="descripción"
                  key="descripción"
                  width="50%"
                />
                <Column
                  title="Cantidad"
                  dataIndex="cantidad"
                  key="cantidad"
                  width="16%"
                  align="left"
                />
                <Column
                  title={
                    <Tooltip title="Promedio de Días en Sitio">DS</Tooltip>
                  }
                  dataIndex="pds"
                  key="pds"
                  width="9%"
                  align="left"
                />
                <Column
                  title={<Tooltip title="Porcentaje">%</Tooltip>}
                  dataIndex="porcentaje"
                  key="porcentaje"
                  width="9%"
                  align="left"
                />
              </Table>
            </Col>

            <Col
              style={{ marginRight: "3%" }}
              lg={{ span: 4 }}
              md={{ span: 4 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Table
                bordered
                className="TablaCE2"
                loading={loadingData}
                size="small"
                dataSource={TablaResumen}
                pagination={{ hideOnSinglePage: true }}
              >
                <Column
                  title="Tipo"
                  dataIndex="envase"
                  key="envase"
                  align="left"
                />
                <Column
                  title="Cantidad"
                  dataIndex="cantidad"
                  key="cantidad"
                  align="left"
                />
                <Column
                  title={
                    <Tooltip title="Ponderación de Días en Sitio">PDS</Tooltip>
                  }
                  dataIndex="pds"
                  key="pds"
                  align="left"
                />
                <Column
                  title={<Tooltip title="Porcentaje">%</Tooltip>}
                  dataIndex="porcentaje"
                  key="porcentaje"
                  align="left"
                />
              </Table>
            </Col>

            <Col
              lg={{ span: 10 }}
              md={{ span: 10 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Tabs className="TabCE" defaultActiveKey="1" type="card">
                <TabPane
                  style={{ height: "32vh" }}
                  tab={
                    <Tooltip title="Cantidad de envases en sitio al cierre de cada mes.">
                      <span>
                        <LineChartOutlined />
                        Envases en Sitio por Mes
                      </span>
                    </Tooltip>
                  }
                  key="1"
                >
                  {DataBar.length !== 0 ? (
                    <ColumnChart
                      loading={loadingData}
                      {...configuracionDataBar}
                      data={DataBar}
                    />
                  ) : (
                    <Empty />
                  )}
                </TabPane>
                <TabPane
                  style={{ height: "30vh" }}
                  tab={
                    <Tooltip title="Porcentaje de envases en sitio por mes.">
                      <span>
                        <PieChartOutlined />
                        Porcentaje de Envases por Mes
                      </span>
                    </Tooltip>
                  }
                  key="2"
                >
                  {DataPie.length !== 0 ? (
                    <Pie
                      loading={loadingData}
                      {...configuracionPie}
                      data={DataPie}
                    />
                  ) : (
                    <Empty />
                  )}
                </TabPane>
              </Tabs>
            </Col>
          </Row>

          <Seccion titulo={"Detalle de Envases"} marginTop={"1%"} />

          <Row style={{ marginTop: "1%" }}>
            <Col
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Table
                bordered
                style={{ marginBottom: "4%" }}
                loading={loadingData}
                size="small"
                dataSource={TablaInsumos}
                pagination={{
                  size: "small",
                  total: TablaInsumos.length,
                  showSizeChanger: true,
                }}
                scroll={{ x: 1300, y: 350 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      props.onLimpiarStatusVerComprobante();
                      setFilaSeleccionada(record.key);
                    },
                  };
                }}
              >
                <Column
                  title="Envase"
                  dataIndex="envase"
                  key="envase"
                  width="15%"
                  {...getColumnSearchProps("envase")}
                />
                <Column
                  title="Descripción"
                  dataIndex="descripción"
                  key="descripción"
                  width="18%"
                  {...getColumnSearchProps("descripción")}
                />
                <Column
                  title="# de Serie"
                  dataIndex="serie"
                  key="serie"
                  width="6%"
                  {...getColumnSearchProps("serie")}
                />
                <Column
                  title="Barcode"
                  dataIndex="barcode"
                  key="barcode"
                  width="6%"
                  {...getColumnSearchProps("barcode")}
                />
                <Column
                  title="Entregado"
                  dataIndex="entregado"
                  key="entregado"
                  width="6%"
                  {...getColumnSearchProps("entregado")}
                />
                <Column
                  title={<Tooltip title="Días en Sitio">DS</Tooltip>}
                  dataIndex="ds"
                  key="ds"
                  width="4%"
                  {...getColumnSearchProps("ds")}
                />
                <Column
                  title="Código"
                  dataIndex="código"
                  key="código"
                  width="5%"
                  {...getColumnSearchProps("código")}
                />
                <Column
                  width="7%"
                  // fixed='right'
                  title="Comprobante"
                  dataIndex="comprobante"
                  key="comprobante"
                  align="center"
                  render={(text, row) => {
                    if (props.verComprobanteStatus === 200) {
                      if (FilaSeleccionada !== null) {
                        if (parseInt(FilaSeleccionada) === parseInt(row.key)) {
                          return (
                            <BlobProvider
                              document={
                                <Comprobante
                                  datos={props.datos}
                                  datosCliente={datosCliente}
                                />
                              }
                            >
                              {({ url }) =>
                                url !== null ? (
                                  (window.open(url, "_blank"),
                                  (
                                    <Button
                                      onClick={props.onLimpiarStatusVerComprobante()}
                                      className="BotonVerMas"
                                      type="link"
                                    >
                                      <EyeOutlined />
                                      <DownloadOutlined />
                                    </Button>
                                  ))
                                ) : (
                                  <Button
                                    onClick={() => verComprobante(row)}
                                    className="BotonVerMas"
                                    type="link"
                                  >
                                    <EyeOutlined />
                                    <DownloadOutlined />
                                  </Button>
                                )
                              }
                            </BlobProvider>
                          );
                        } else {
                          return (
                            <Button
                              onClick={() => verComprobante(row)}
                              className="BotonVerMas"
                              type="link"
                            >
                              <EyeOutlined />
                              <DownloadOutlined />
                            </Button>
                          );
                        }
                      }
                    } else {
                      return (
                        <Button
                          onClick={() => verComprobante(row)}
                          className="BotonVerMas"
                          type="link"
                        >
                          <EyeOutlined />
                          <DownloadOutlined />
                        </Button>
                      );
                    }
                  }}
                />
              </Table>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <PantallaDeNoAcceso src={ControlDeEnvases} alt={"ControlDeEnvases"} />
        </>
      )}
    </>
  );
};
export default ModuloControlDeEnvases;
