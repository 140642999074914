import React, { Component } from 'react';
import AgregarEmpresas from '../../../components/gestionDeEmpresas/agregarEmpresas/agregarEmpresas';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import Loading from '../../../components/loading/loading';

class agregarEmpresas extends Component {
  componentDidMount() {
    this.props.onGetAllPaises();
  }
  render() {
    return (
      <>
        {this.props.loading ? <Loading /> : <AgregarEmpresas {...this.props} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paises: state.empresas.paises,
    empresasError: state.empresas.empresasError,
    Agregar_Empresa_Status: state.empresas.Agregar_Empresa_Status,
    loading: state.empresas.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllPaises: () => dispatch(actions.empresas.calls.getAllPaises()),
    onPostEmpresas: (nombre, data) =>
      dispatch(actions.empresas.calls.postEmpresas(nombre, data)),
    cerrarAlertaAgregar: () =>
      dispatch(actions.empresas.calls.cerrarAlertaAgregar()),
    onGetEmpresasLoading: () =>
      dispatch(actions.empresas.calls.getEmpresasLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(agregarEmpresas);
