import actionsType from './actionsTypes';

function getInventarioSuccess(data) {
  return { type: actionsType.GET_INVENTARIO_SUCCESS, data: data };
}
function getInventarioFailure(error) {
  return { type: actionsType.GET_INVENTARIO_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getInventarioLoading() {
  return { type: actionsType.GET_INVENTARIO_LOADING };
}

export default {
  getInventarioSuccess: getInventarioSuccess,
  getInventarioFailure: getInventarioFailure,
  getInventarioLoading: getInventarioLoading,
};
