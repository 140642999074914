import actions from '../actions';

const controlDeEnvasesVerComprobante = actions.controlDeEnvasesVerComprobante;

const initialState = {
  datos: [],
  codigoCliente: '',
  loading: false,
  verComprobanteStatus: null,
};

function controlDeEnvasesVerComprobanteApp(state = initialState, action) {
  switch (action.type) {
    case controlDeEnvasesVerComprobante.actionsType.GET_COMPROBANTE_SUCCESS:
      return controlDeEnvasesVerComprobante.action.getComprobanteAction(
        state,
        action,
      );
    case controlDeEnvasesVerComprobante.actionsType.SET_CODIGO_CLIENTE:
      return controlDeEnvasesVerComprobante.action.setCodigoClienteAction(
        state,
        action.data,
      );
    case controlDeEnvasesVerComprobante.actionsType.CLEAR_CODIGO_CLIENTE:
      return {
        datos: [],
        codigoCliente: '',
        loading: false,
        verComprobanteStatus: null,
      };
    case controlDeEnvasesVerComprobante.actionsType.GET_COMPROBANTE_FAILURE:
      return {
        ...state,
        datos: [],
        codigoCliente: '',
        verComprobanteStatus: action.errorMessage.response.status,
        loading: false,
      };
    case controlDeEnvasesVerComprobante.actionsType.GET_COMPROBANTE_LOADING:
      return controlDeEnvasesVerComprobante.action.getComprobanteLoading(state);
    case controlDeEnvasesVerComprobante.actionsType.LIMPIAR_STATUS:
      return {
        ...state,
        verComprobanteStatus: null,
      };
    default:
      return { ...state };
  }
}

export default controlDeEnvasesVerComprobanteApp;
