import actionsType from './actionsTypes';

function getUsuariosSuccess(data) {
  return { type: actionsType.GET_USUARIOS_SUCCESS, data: data };
}
function getUsuariosFailure(error) {
  return { type: actionsType.GET_USUARIOS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getAllUsuariosSuccess(data) {
  return { type: actionsType.GETALL_USUARIOS_SUCCESS, data: data };
}
function getAllUsuariosFailure(error) {
  return { type: actionsType.GETALL_USUARIOS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function postUsuariosSuccess(data) {
  return { type: actionsType.POST_USUARIOS_SUCCESS, data: data };
}
function postUsuariosFailure(error) {
  return { type: actionsType.POST_USUARIOS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function putUsuariosSuccess(data) {
  return { type: actionsType.PUT_USUARIOS_SUCCESS, data: data };
}
function putUsuariosFailure(error) {
  return { type: actionsType.PUT_USUARIOS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function deleteUsuariosSuccess(data) {
  return { type: actionsType.DELETE_USUARIOS_SUCCESS, data: data };
}
function deleteUsuariosFailure(error) {
  return { type: actionsType.DELETE_USUARIOS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaActivarInactivar() {
  return { type: actionsType.CERRAR_ALERTA_ACTIVARINACTIVAR };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaAgregarUsuario() {
  return { type: actionsType.CERRAR_ALERTA_AGREGAR };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaActualizarUsuario() {
  return { type: actionsType.CERRAR_ALERTA_ACTUALIZAR };
}

function getUsuariosLoading() {
  return { type: actionsType.GET_USUARIOS_LOADING };
}

function ungetUsuariosLoading() {
  return { type: actionsType.UNGET_USUARIOS_LOADING };
}

export default {
  getUsuariosSuccess: getUsuariosSuccess,
  getUsuariosFailure: getUsuariosFailure,
  getAllUsuariosSuccess: getAllUsuariosSuccess,
  getAllUsuariosFailure: getAllUsuariosFailure,
  postUsuariosSuccess: postUsuariosSuccess,
  postUsuariosFailure: postUsuariosFailure,
  putUsuariosSuccess: putUsuariosSuccess,
  putUsuariosFailure: putUsuariosFailure,
  deleteUsuariosSuccess: deleteUsuariosSuccess,
  deleteUsuariosFailure: deleteUsuariosFailure,
  cerrarAlertaActivarInactivar: cerrarAlertaActivarInactivar,
  cerrarAlertaAgregarUsuario: cerrarAlertaAgregarUsuario,
  cerrarAlertaActualizarUsuario: cerrarAlertaActualizarUsuario,
  getUsuariosLoading: getUsuariosLoading,
  ungetUsuariosLoading: ungetUsuariosLoading,
};
