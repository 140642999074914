import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Table,
  Select,
  Tabs,
  Typography,
  DatePicker,
  Form,
  Button,
  Checkbox,
  Input,
  Card,
  Skeleton,
  Empty,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import {
  AreaChartOutlined,
  FundViewOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import iconloading from "../../../assets/icono_cargando2.gif";
import { decrypt } from "react-crypt-gsm";
import jwtDecode from "jwt-decode";
import { Line } from "@ant-design/charts";
import Inventario from "../../../assets/Imagen3-Carousel.png";
import PantallaDeNoAcceso from "../../pantallaDeNoAcceso";
import Seccion from "../../Seccion";
import "../../../antDesignClases.css";
import "./moduloInventario.css";
import FloatFormatter from "../../../hoc/helpers";
const { Column } = Table;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ModuloInventario = (props) => {
  //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
  // pantalla
  var Token = sessionStorage.getItem("TokenUsuario");
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let { inventario, onGetInventario } = props;

  let ItemDatePicker = useRef();
  let FormRangoDeFechas = useRef();
  let DatosTanque = useRef();

  const [FechaFiltrada, setFechaFiltrada] = useState("1 Semana");
  const [OnChangeActivo, setOnChangeActivo] = useState(false);

  var [NivelTanque, setNivelTanque] = useState(0);
  const [VerPronostico, setVerPronostico] = useState(false);
  // var color = ['#D71414', '#FAAD14', '#81973a'];
  const [Tanques, setTanques] = useState([]);
  const [Resumen, setResumen] = useState([]);
  const [TablaResumen, setTablaResumen] = useState([]);
  var [TanqueSeleccionado, setTanqueSeleccionado] = useState(0);
  const prevTanqueSeleccionado = usePrevious(TanqueSeleccionado);
  const [Lecturas, setLecturas] = useState([]);
  const [Pronostico, setPronostico] = useState([]);
  const [LecturasConPronostico, setLecturasConPronostico] = useState([]);
  const [DiaActualGrafico, setDiaActualGrafico] = useState(null);
  const [MaximoDelTanque, setMaximoDelTanque] = useState(20000);
  const [LineaLleno, setLineaLleno] = useState(0);
  const [LineaReOrder, setLineaReOrder] = useState(0);
  const [LineaCritica, setLineaCritica] = useState(0);
  const [MostrarElNivel, setMostrarElNivel] = useState(false);
  const [NivelReal, setNivelReal] = useState(0);
  const [CopiaConPSI, setCopiaConPSI] = useState([]);
  const [Colorcito, setColorcito] = useState("#81973a");
  const [CargandoPantalla, setCargandoPantalla] = useState(false);
  const [TipoMedicion, setTipoMedicion] = useState("");

  //Hook para cambiar el nivel del tanque para demo
  useEffect(() => {
    let Tanque = Tanques.find(
      (elemento) => parseInt(elemento.index) === parseInt(TanqueSeleccionado)
    );
    if (
      Tanque !== undefined ||
      (Tanque !== undefined && TanqueSeleccionado !== prevTanqueSeleccionado)
    ) {
      if (
        parseInt(Tanque.Actual2) <= parseInt(Tanque.Neto2) &&
        parseInt(Tanque.Actual2) >= parseInt(Tanque.Neto2) / 2
      ) {
        setColorcito("#81973a");
      } else if (
        parseInt(Tanque.Actual2) <= parseInt(Tanque.Neto2) / 2 &&
        parseInt(Tanque.Actual2) >= parseInt(Tanque.Neto2) / 4
      ) {
        setColorcito("#FAAD14");
      } else if (parseInt(Tanque.Actual2) <= parseInt(Tanque.Neto2) / 4) {
        setColorcito("#D71414");
      }
      // var interval = setInterval(function () {
      //   if (NivelTanque >= Tanque.NivelTanque) {
      //     setMostrarElNivel(true);
      //     setNivelReal(Tanque.NivelTanque);
      //     clearInterval(interval);
      //   } else {
      //     NivelTanque += 1;
      //     setNivelTanque(NivelTanque);
      //   }
      // }, 100);
      setNivelTanque(Tanque.NivelTanque);
    }
  }, [Tanques, TanqueSeleccionado]);

  //Armar los arreglos con la información respectiva
  useEffect(() => {
    let TANQUES = [];
    for (let index in inventario) {
      let tanqueActual = inventario[index];
      let FechaTanque = tanqueActual.Fecha.split("T");
      FechaTanque = FechaTanque[0].split("-");
      tanqueActual = {
        index: parseInt(index),
        Fecha: `${FechaTanque[2]}/${FechaTanque[1]}/${FechaTanque[0]}`,
        Producto: tanqueActual.Producto,
        Descripcion: tanqueActual.Descripcion,
        Estado:
          Colorcito === "#81973a"
            ? "Normal"
            : Colorcito === "#FAAD14"
            ? "Reorden"
            : Colorcito === "#D71414"
            ? "Crítico"
            : "Na",
        UnidadMedida: tanqueActual.UnidadMedidaId,
        Nominal: FloatFormatter(
          (Math.round(tanqueActual.VolumenNominal * 100) / 100).toFixed(1)
        ),
        Neto: FloatFormatter(
          (Math.round(tanqueActual.VolumenNeto * 100) / 100).toFixed(1)
        ),
        Neto2: (Math.round(tanqueActual.VolumenNeto * 100) / 100).toFixed(1),
        Actual: FloatFormatter(
          (Math.round(tanqueActual.VolumenActual * 100) / 100).toFixed(1)
        ),
        Actual2: 
          (Math.round(tanqueActual.VolumenActual * 100) / 100).toFixed(1)
        ,
        Porcentaje: `${(
          Math.round(tanqueActual.Porcentaje * 100) / 100
        ).toFixed(1)}%`,
        UnidadMedidaP: tanqueActual.UnidadMedidaPresionId,
        Presion: (Math.round(tanqueActual.Presion * 100) / 100).toFixed(1),
        NivelTanque: (Math.round(tanqueActual.Porcentaje * 100) / 100).toFixed(
          1
        ),
        Normal: FloatFormatter(
          (Math.round(tanqueActual.VolumenNeto * 100) / 100).toFixed(1)
        ),
        Reorden: FloatFormatter(
          (Math.round(tanqueActual.ValoreReorden * 100) / 100).toFixed(1)
        ),
        Critico: FloatFormatter(
          (Math.round(tanqueActual.ValorCritico * 100) / 100).toFixed(1)
        ),
      };

      TANQUES.push(tanqueActual);
    }
    return setTanques(TANQUES);
  }, [inventario, Colorcito]);

  //Hook para extraer el resumen de cada tanque
  useEffect(() => {
    let RESUMEN = [];
    let PRONOSTICO = [];
    let LECTURAS = [];
    inventario.forEach(function (tanque, index) {
      let ResumenActual = {
        key: index,
        ConsumoMes: tanque.Resumen.ConsumoMes,
        Inicio: tanque.Resumen.Inicio,
        PromedioDia: tanque.Resumen.PromedioDia,
        PromedioHora: tanque.Resumen.PromedioHora,
        Medida: tanque.UnidadMedidaId,
      };
      let PronosticoActual = {
        key: index,
        Pronostico: tanque.Forecast,
      };
      let LecturaActual = {
        key: index,
        Lectura: tanque.Lecturas.reverse(),
      };
      RESUMEN.push(ResumenActual);
      PRONOSTICO.push(PronosticoActual);
      LECTURAS.push(LecturaActual);
    });
    return (
      setResumen(RESUMEN), setPronostico(PRONOSTICO), setLecturas(LECTURAS)
    );
  }, [inventario]);

  useEffect(() => {
    if (VerPronostico) {
      let PronosticoTanque = Pronostico.find(
        (elemento) => parseInt(elemento.key) === parseInt(TanqueSeleccionado)
      );
      let LecturasTanque = Lecturas.find(
        (elemento) => parseInt(elemento.key) === parseInt(TanqueSeleccionado)
      );

      if (Lecturas.length !== 0 && LecturasTanque !== undefined) {
        if (LecturasTanque.Lectura.length !== 0) {
          let LecturaArray = LecturasTanque.Lectura;
          let UltimaFechaLectura = LecturaArray[LecturaArray.length - 1];
          let FechaPronostico = UltimaFechaLectura.Fecha.split("T");
          let HoraPronostico = FechaPronostico[1].split(":");
          HoraPronostico = `${HoraPronostico[0]}`;
          let Dia = `${FechaPronostico[0]}T${HoraPronostico}:00`;
          setDiaActualGrafico(Dia);
        } else if (Pronostico.length !== 0 && PronosticoTanque !== undefined) {
          if (PronosticoTanque.Pronostico.length !== 0) {
            let PronosticoArray = PronosticoTanque.Pronostico;
            let UltimaFechaLectura = PronosticoArray[0];
            let FechaPronostico = UltimaFechaLectura.Fecha.split("T");
            let HoraPronostico = FechaPronostico[1].split(":");
            HoraPronostico = `${HoraPronostico[0]}`;
            let Dia = `${FechaPronostico[0]}T${HoraPronostico}:00`;
            setDiaActualGrafico(Dia);
          }
        }
      }

      if (PronosticoTanque !== undefined && LecturasTanque !== undefined) {
        let Lectura = LecturasTanque.Lectura;
        let Pronostico = PronosticoTanque.Pronostico;
        let PronosticoAjustado = [];
        let LecturasGrafico = [];
        Pronostico.forEach(function (PronosticoActual) {
          PronosticoActual = {
            Fecha: PronosticoActual.Fecha,
            Lectura: PronosticoActual.Valor,
          };
          PronosticoAjustado.push(PronosticoActual);
        });

        let LecturaConPronostico = Lectura.concat(PronosticoAjustado);
        LecturaConPronostico.forEach(function (Lectura, index) {
          let Fecha = Lectura.Fecha.split("T");
          let Hora = Fecha[1].split(":");
          Hora = `${Hora[0]}`;
          Lectura = {
            key: index,
            Fecha: `${Fecha[0]}T${Hora}:00`,
            Nivel: Lectura.Lectura,
          };
          LecturasGrafico.push(Lectura);
        });
        setLecturasConPronostico(LecturasGrafico);
      }
    } else {
      let PronosticoTanque = Pronostico.find(
        (elemento) => parseInt(elemento.key) === parseInt(TanqueSeleccionado)
      );
      let LecturasTanque = Lecturas.find(
        (elemento) => parseInt(elemento.key) === parseInt(TanqueSeleccionado)
      );
      if (PronosticoTanque !== undefined && LecturasTanque !== undefined) {
        let Lectura = LecturasTanque.Lectura;
        let LecturasGrafico = [];

        Lectura.forEach(function (Lectura, index) {
          let Fecha = Lectura.Fecha.split("T");
          let Hora = Fecha[1].split(":");
          Hora = `${Hora[0]}`;
          Lectura = {
            key: index,
            Fecha: `${Fecha[0]}T${Hora}:00`,
            Nivel: Lectura.Lectura,
          };
          LecturasGrafico.push(Lectura);
        });
        setLecturasConPronostico(LecturasGrafico);
      }
    }
  }, [Pronostico, Lecturas, VerPronostico, TanqueSeleccionado]);

  //Hook para cambiar la tabla de resumen con el resumen del tanque seleccionado
  useEffect(() => {
    let Registros = [];
    let Tanque = Resumen.find(
      (elemento) => parseInt(elemento.key) === parseInt(TanqueSeleccionado)
    );
    if (
      Tanque !== undefined ||
      (Tanque !== undefined && TanqueSeleccionado !== prevTanqueSeleccionado)
    ) {
      let TanqueConDecimal = {
        ConsumoMes:
          Tanque.ConsumoMes === 0
            ? Tanque.ConsumoMes
            : FloatFormatter(
                (Math.round(Tanque.ConsumoMes * 100) / 100).toFixed(1)
              ),
        Inicio:
          Tanque.Inicio === 0
            ? Tanque.Inicio
            : FloatFormatter(
                (Math.round(Tanque.Inicio * 100) / 100).toFixed(1)
              ),
        PromedioDia:
          Tanque.PromedioDia === 0
            ? Tanque.PromedioDia
            : FloatFormatter(
                (Math.round(Tanque.PromedioDia * 100) / 100).toFixed(1)
              ),
        PromedioHora:
          Tanque.PromedioHora === 0
            ? Tanque.PromedioHora
            : FloatFormatter(
                (Math.round(Tanque.PromedioHora * 100) / 100).toFixed(1)
              ),
        key: Tanque.key,
      };

      let TanqueAjustado = {
        ConsumoMes:
          Tanque.Medida === 3
            ? `${TanqueConDecimal.ConsumoMes} Kg`
            : Tanque.Medida === 2
            ? `${TanqueConDecimal.ConsumoMes} Lb`
            : Tanque.Medida === 4
            ? `${TanqueConDecimal.ConsumoMes} Gl`
            : Tanque.Medida === 17
            ? `${TanqueConDecimal.ConsumoMes} Lt`
            : Tanque.Medida === 5
            ? `${TanqueConDecimal.ConsumoMes} Pc`
            : Tanque.Medida === 18
            ? `${TanqueConDecimal.ConsumoMes} Mc`
            : TanqueConDecimal.ConsumoMes,
        Inicio:
          Tanque.Medida === 3
            ? `${TanqueConDecimal.Inicio} Kg`
            : Tanque.Medida === 2
            ? `${TanqueConDecimal.Inicio} Lb`
            : Tanque.Medida === 4
            ? `${TanqueConDecimal.Inicio} Gl`
            : Tanque.Medida === 17
            ? `${TanqueConDecimal.Inicio} Lt`
            : Tanque.Medida === 5
            ? `${TanqueConDecimal.Inicio} Pc`
            : Tanque.Medida === 18
            ? `${TanqueConDecimal.Inicio} Mc`
            : TanqueConDecimal.Inicio,
        PromedioDia:
          Tanque.Medida === 3
            ? `${TanqueConDecimal.PromedioDia} Kg`
            : Tanque.Medida === 2
            ? `${TanqueConDecimal.PromedioDia} Lb`
            : Tanque.Medida === 4
            ? `${TanqueConDecimal.PromedioDia} Gl`
            : Tanque.Medida === 17
            ? `${TanqueConDecimal.PromedioDia} Lt`
            : Tanque.Medida === 5
            ? `${TanqueConDecimal.PromedioDia} Pc`
            : Tanque.Medida === 18
            ? `${TanqueConDecimal.PromedioDia} Mc`
            : TanqueConDecimal.PromedioDia,
        PromedioHora:
          Tanque.Medida === 3
            ? `${TanqueConDecimal.PromedioHora} Kg`
            : Tanque.Medida === 2
            ? `${TanqueConDecimal.PromedioHora} Lb`
            : Tanque.Medida === 4
            ? `${TanqueConDecimal.PromedioHora} Gl`
            : Tanque.Medida === 17
            ? `${TanqueConDecimal.PromedioHora} Lt`
            : Tanque.Medida === 5
            ? `${TanqueConDecimal.PromedioHora} Pc`
            : Tanque.Medida === 18
            ? `${TanqueConDecimal.PromedioHora} Mc`
            : TanqueConDecimal.PromedioHora,
        key: Tanque.key,
      };
      Registros.push(TanqueAjustado);
    }
    return setTablaResumen(Registros);
  }, [Resumen, TanqueSeleccionado]);

  useEffect(() => {
    let Tanque = Resumen.find(
      (elemento) => parseInt(elemento.key) === parseInt(TanqueSeleccionado)
    );
    let Medicion = "";
    if (
      Tanque !== undefined ||
      (Tanque !== undefined && TanqueSeleccionado !== prevTanqueSeleccionado)
    ) {
      Medicion =
        Tanque.Medida === 3
          ? "Kg"
          : Tanque.Medida === 2
          ? "Lb"
          : Tanque.Medida === 4
          ? "Gl"
          : Tanque.Medida === 17
          ? "Lt"
          : Tanque.Medida === 5
          ? "Pc"
          : Tanque.Medida === 18
          ? "Mc"
          : "Na";
    }
    return setTipoMedicion(Medicion);
  }, [Resumen, TanqueSeleccionado]);

  useEffect(() => {
    if (DatosTanque.current !== undefined) {
      for (let index in Tanques) {
        let tanque = Tanques[index];
        if (tanque.index === TanqueSeleccionado) {
          setCargandoPantalla(false);
          setMaximoDelTanque(parseInt(tanque.Neto2));
          setLineaLleno(parseInt(tanque.Neto2));
          setLineaReOrder(parseInt(tanque.Neto2) / 2);
          setLineaCritica(parseInt(tanque.Neto2) / 4);
          DatosTanque.current.setFieldsValue({
            Fecha: tanque.Fecha,
            Producto: tanque.Producto,
            Descripcion: tanque.Descripcion,
            Estado:
              Colorcito === "#81973a"
                ? "Normal"
                : Colorcito === "#FAAD14"
                ? "Reorden"
                : Colorcito === "#D71414"
                ? "Crítico"
                : "Na",
            Volumen: tanque.UnidadMedida,
            Nominal: tanque.Nominal,
            Neto: tanque.Neto,
            Actual: tanque.Actual,
            Porcentaje: tanque.Porcentaje,
            UnidadMedidaP: tanque.UnidadMedidaP,
            Presion: tanque.Presion,
            Normal: tanque.Normal,
            Reorden: tanque.Reorden,
            Critico: tanque.Critico,
          });
        }
      }
    }
  }, [Tanques, Colorcito]);

  const ConvertirVolumen = (unidad) => {
    setCargandoPantalla(true);
    let Fechas = FormRangoDeFechas.current.getFieldsValue("ItemDatePicker");
    let parametros = {
      IdEmpresa: usuario.usuario.Compania,
      FechaInicio: Fechas.ItemDatePicker[0].utcOffset(0, true).format(),
      FechaFin: Fechas.ItemDatePicker[1].utcOffset(0, true).format(),
      UnidadMedida: unidad,
    };
    onGetInventario(parametros);
  };

  //Función para filtrar la fecha haciendo zoom en el tiempo
  const FiltrarFechaZoomIn = () => {
    setCargandoPantalla(true);
    if (OnChangeActivo) {
      if (FechaFiltrada === "3 Horas") {
        setFechaFiltrada("6 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(3, "hours"),
            moment().add(3, "hours"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(3, "hours")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(3, "hours").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "6 Horas") {
        setFechaFiltrada("12 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(6, "hours"),
            moment().add(6, "hours"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(6, "hours")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(6, "hours").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "12 Horas") {
        setFechaFiltrada("1 Día");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(12, "hours"),
            moment().add(12, "hours"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(12, "hours")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(12, "hours").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "1 Día") {
        setFechaFiltrada("2 Días");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [moment().subtract(1, "day"), moment().add(1, "day")],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(1, "day").utcOffset(0, true).format(),
          FechaFin: moment().add(1, "day").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "2 Días") {
        setFechaFiltrada("4 Días");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(2, "days"),
            moment().add(2, "days"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(2, "days").utcOffset(0, true).format(),
          FechaFin: moment().add(2, "days").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "4 Días") {
        setFechaFiltrada("1 Semana");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(3, "days"),
            moment().add(4, "days"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(3, "days").utcOffset(0, true).format(),
          FechaFin: moment().add(4, "days").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "1 Semana") {
        setFechaFiltrada("2 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(1, "week"),
            moment().add(1, "week"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(1, "week").utcOffset(0, true).format(),
          FechaFin: moment().add(1, "week").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "2 Semanas") {
        setFechaFiltrada("4 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(2, "weeks"),
            moment().add(2, "weeks"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(2, "weeks")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(2, "weeks").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "4 Semanas") {
        setFechaFiltrada("12 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(6, "weeks"),
            moment().add(6, "weeks"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(6, "weeks")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(6, "weeks").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "12 Semanas") {
        setFechaFiltrada("1 Año");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(6, "months"),
            moment().add(6, "months"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(6, "months")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(6, "months").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "1 Año") {
        setFechaFiltrada("2 Años");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(1, "year"),
            moment().add(1, "year"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(1, "year").utcOffset(0, true).format(),
          FechaFin: moment().add(1, "year").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
    } else {
      let fechaInicio, fechaFinal;
      let FechaDatePicker = FormRangoDeFechas.current.getFieldValue(
        "ItemDatePicker"
      );

      //Validar si la fecha del datepicker comprende el rango de 3 Horas entonces se hace zoom a 6 horas
      if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(1.5, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[0].format("hh") ===
          moment().subtract(1.5, "hours").format("hh") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(1.5, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("hh") ===
          moment().add(1.5, "hours").format("hh")
      ) {
        fechaInicio = moment().subtract(3, "hours");
        fechaFinal = moment().add(3, "hours");
        setFechaFiltrada("6 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 6 Horas entonces se hace zoom a 12 horas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(3, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[0].format("hh") ===
          moment().subtract(3, "hours").format("hh") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(3, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("hh") ===
          moment().add(3, "hours").format("hh")
      ) {
        fechaInicio = moment().subtract(6, "hours");
        fechaFinal = moment().add(6, "hours");
        setFechaFiltrada("12 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 12 Horas entonces se hace zoom a 1 día
      if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(6, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[0].format("hh") ===
          moment().subtract(6, "hours").format("hh") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(6, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("hh") ===
          moment().add(6, "hours").format("hh")
      ) {
        fechaInicio = moment().subtract(12, "hours");
        fechaFinal = moment().add(12, "hours");
        setFechaFiltrada("1 Día");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 1 día entonces se hace zoom a 2 días
      if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(12, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[0].format("hh") ===
          moment().subtract(12, "hours").format("hh") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(12, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("hh") ===
          moment().add(12, "hours").format("hh")
      ) {
        fechaInicio = moment().subtract(1, "day");
        fechaFinal = moment().add(1, "day");
        setFechaFiltrada("2 Días");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 2 días entonces se hace zoom a 4 días
      if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(1, "day").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(1, "day").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(2, "days");
        fechaFinal = moment().add(2, "days");
        setFechaFiltrada("4 Días");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 4 días entonces se hace zoom a 1 semana
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(2, "days").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(2, "days").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(3, "days");
        fechaFinal = moment().add(4, "days");
        setFechaFiltrada("1 Semana");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de la semana actual entonces se hace zoom a 2 semanas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(3, "days").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(4, "days").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(1, "week");
        fechaFinal = moment().add(1, "week");
        setFechaFiltrada("2 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 2 semanas entonces se hace zoom a 4 semanas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(1, "week").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(1, "week").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(2, "weeks");
        fechaFinal = moment().add(2, "weeks");
        setFechaFiltrada("4 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 4 semanas entonces se hace zoom a 12 semanas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(2, "weeks").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(2, "weeks").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(6, "weeks");
        fechaFinal = moment().add(6, "weeks");
        setFechaFiltrada("12 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 12 semanas entonces se hace zoom a 1 año
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(6, "weeks").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(6, "weeks").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(6, "months");
        fechaFinal = moment().add(6, "months");
        setFechaFiltrada("1 Año");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 1 año entonces se hace zoom a 2 años
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(6, "months").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(6, "months").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(1, "year");
        fechaFinal = moment().add(1, "year");
        setFechaFiltrada("2 Años");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
    }
  };

  const FiltrarFechaZoomOut = () => {
    setCargandoPantalla(true);
    if (OnChangeActivo) {
      if (FechaFiltrada === "2 Años") {
        setFechaFiltrada("1 Año");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(6, "months"),
            moment().add(6, "months"),
          ],
        });
        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(6, "months")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(6, "months").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "1 Año") {
        setFechaFiltrada("12 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(6, "weeks"),
            moment().add(6, "weeks"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(6, "weeks")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(6, "weeks").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "12 Semanas") {
        setFechaFiltrada("4 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(2, "weeks"),
            moment().add(2, "weeks"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(2, "weeks")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(2, "weeks").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "4 Semanas") {
        setFechaFiltrada("2 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(1, "week"),
            moment().add(1, "week"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(1, "week").utcOffset(0, true).format(),
          FechaFin: moment().add(1, "week").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "2 Semanas") {
        setFechaFiltrada("1 Semana");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(3, "days"),
            moment().add(4, "days"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(3, "days").utcOffset(0, true).format(),
          FechaFin: moment().add(4, "days").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "1 Semana") {
        setFechaFiltrada("4 Días");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(2, "days"),
            moment().add(2, "days"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(2, "days").utcOffset(0, true).format(),
          FechaFin: moment().add(2, "days").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "4 Días") {
        setFechaFiltrada("2 Días");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [moment().subtract(1, "day"), moment().add(1, "day")],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment().subtract(1, "day").utcOffset(0, true).format(),
          FechaFin: moment().add(1, "day").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "2 Días") {
        setFechaFiltrada("1 Día");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(12, "hours"),
            moment().add(12, "hours"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(12, "hours")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(12, "hours").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "1 Día") {
        setFechaFiltrada("12 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(6, "hours"),
            moment().add(6, "hours"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(6, "hours")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(6, "hours").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "12 Horas") {
        setFechaFiltrada("6 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(3, "hours"),
            moment().add(3, "hours"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(3, "hours")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(3, "hours").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      } else if (FechaFiltrada === "6 Horas") {
        setFechaFiltrada("3 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [
            moment().subtract(1.5, "hours"),
            moment().add(1.5, "hours"),
          ],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: moment()
            .subtract(1.5, "hours")
            .utcOffset(0, true)
            .format(),
          FechaFin: moment().add(1.5, "hours").utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
    } else {
      let fechaInicio, fechaFinal;
      let FechaDatePicker = FormRangoDeFechas.current.getFieldValue(
        "ItemDatePicker"
      );

      //Validar si la fecha del datepicker comprende el rango de 2 años entonces se hace zoom a 1 año
      if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(1, "year").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(1, "year").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(6, "months");
        fechaFinal = moment().add(6, "months");
        setFechaFiltrada("1 Año");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 1 año entonces se hace zoom a 12 semanas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(6, "months").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(6, "months").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(6, "weeks");
        fechaFinal = moment().add(6, "weeks");
        setFechaFiltrada("12 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 12 semanas entonces se hace zoom a 4 semanas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(6, "weeks").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(6, "weeks").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(2, "weeks");
        fechaFinal = moment().add(2, "weeks");
        setFechaFiltrada("4 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 4 semanas entonces se hace zoom a 2 semanas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(2, "weeks").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(2, "weeks").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(1, "week");
        fechaFinal = moment().add(1, "week");
        setFechaFiltrada("2 Semanas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 2 semanas entonces se hace zoom a 1 semana
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(7, "days").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(7, "days").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(3, "days");
        fechaFinal = moment().add(4, "days");
        setFechaFiltrada("1 Semana");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de la semana actual entonces se hace zoom a 4 días
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(3, "days").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(4, "days").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(2, "days");
        fechaFinal = moment().add(2, "days");
        setFechaFiltrada("4 Días");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 4 días entonces se hace zoom a 2 días
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(2, "days").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(2, "days").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(1, "day");
        fechaFinal = moment().add(1, "day");
        setFechaFiltrada("2 Días");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 2 días entonces se hace zoom a 1 día
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(1, "day").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(1, "day").format("DD/MM/YYYY")
      ) {
        fechaInicio = moment().subtract(12, "hours");
        fechaFinal = moment().add(12, "hours");
        setFechaFiltrada("1 Día");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 1 día entonces se hace zoom a 12 horas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(12, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[0].format("hh") ===
          moment().subtract(12, "hours").format("hh") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(12, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("hh") ===
          moment().add(12, "hours").format("hh")
      ) {
        fechaInicio = moment().subtract(6, "hours");
        fechaFinal = moment().add(6, "hours");
        setFechaFiltrada("12 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 12 Horas entonces se hace zoom a 6 horas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(6, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[0].format("hh") ===
          moment().subtract(6, "hours").format("hh") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(6, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("hh") ===
          moment().add(6, "hours").format("hh")
      ) {
        fechaInicio = moment().subtract(3, "hours");
        fechaFinal = moment().add(3, "hours");
        setFechaFiltrada("6 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
      //Validar si la fecha del datepicker comprende el rango de 6 Horas entonces se hace zoom a 3 horas
      else if (
        FechaDatePicker[0].format("DD/MM/YYYY") ===
          moment().subtract(3, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[0].format("hh") ===
          moment().subtract(3, "hours").format("hh") &&
        FechaDatePicker[1].format("DD/MM/YYYY") ===
          moment().add(3, "hours").format("DD/MM/YYYY") &&
        FechaDatePicker[1].format("hh") ===
          moment().add(3, "hours").format("hh")
      ) {
        fechaInicio = moment().subtract(1.5, "hours");
        fechaFinal = moment().add(1.5, "hours");
        setFechaFiltrada("3 Horas");
        FormRangoDeFechas.current.setFieldsValue({
          ItemDatePicker: [fechaInicio, fechaFinal],
        });

        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: fechaInicio.utcOffset(0, true).format(),
          FechaFin: fechaFinal.utcOffset(0, true).format(),
        };
        onGetInventario(parametros);
      }
    }
  };

  const CambiarDatosDeFormulario = (id) => {
    setTanqueSeleccionado(parseInt(id));
    setLineaLleno(0);
    setLineaReOrder(0);
    setLineaCritica(0);
    if (DatosTanque.current !== undefined) {
      for (let index in Tanques) {
        let tanque = Tanques[index];
        if (tanque.index === parseInt(id)) {
          setMaximoDelTanque(parseInt(tanque.Neto2));
          setLineaLleno(parseInt(tanque.Neto2));
          setLineaReOrder(parseInt(tanque.Neto2) / 2);
          setLineaCritica(parseInt(tanque.Neto2) / 4);
          DatosTanque.current.setFieldsValue({
            Fecha: tanque.Fecha,
            Producto: tanque.Producto,
            Descripcion: tanque.Descripcion,
            Estado:
              Colorcito === "#81973a"
                ? "Normal"
                : Colorcito === "#FAAD14"
                ? "Reorden"
                : Colorcito === "#D71414"
                ? "Crítico"
                : "Na",
            Volumen: tanque.UnidadMedida,
            Nominal: tanque.Nominal,
            Neto: tanque.Neto,
            Actual: tanque.Actual,
            Porcentaje: tanque.Porcentaje,
            UnidadMedidaP: tanque.UnidadMedidaP,
            Presion: tanque.Presion,
            Normal: tanque.Normal,
            Reorden: tanque.Reorden,
            Critico: tanque.Critico,
          });
        }
      }
    }
  };

  const convertirPSIaBARViceversa = (unidad) => {
    let covPresion = DatosTanque.current.getFieldValue("Presion");
    if (unidad === 16) {
      DatosTanque.current.setFieldsValue({
        Presion: FloatFormatter(
          (Math.round(covPresion * 0.0689476 * 100) / 100).toFixed(1)
        ),
      });
    } else {
      if (DatosTanque.current !== undefined) {
        for (let index in Tanques) {
          let medidas = Tanques[TanqueSeleccionado];
          DatosTanque.current.setFieldsValue({
            Presion: FloatFormatter(
              (Math.round(medidas.Presion * 100) / 100).toFixed(1)
            ),
          });
        }
      }
    }
  };

  var config = {
    xField: "Fecha",
    yField: "Nivel",
    smooth: "true",
    yAxis: {
      min: 0,
      max: MaximoDelTanque,
      nice: true,
    },
    meta: {
      Fecha: { alias: "Nivel" },
      Nivel: {
        formatter: function formatter(v) {
          return ""
            .concat(v.toFixed(1), " ", TipoMedicion)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",", function (s) {
              return "".concat(s, ",");
            });
        },
      },
    },
  };
  
  return (
    <>
      <Modal
        visible={CargandoPantalla}
        footer={null}
        centered
        closable={false}
        bodyStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      >
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Spin
            size="large"
            indicator={<img src={iconloading} />}
            style={{ color: "#81973a" }}
          />
          <p style={{ color: "#81973a" }}>Cargando...</p>
        </div>
      </Modal>

      {usuario.usuario.Inventario === "true" ? (
        <>
          <Seccion titulo={"Información del Cliente"} marginTop={"2%"} />

          {props.facturas.length !== 0 ? (
            <>
              <Card className="CardFacturacion">
                <Row>
                  <Col
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text strong>
                          Cliente:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.NombreCliente}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text strong>
                          Código de Cliente:&nbsp;&nbsp;
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.CodigoCliente}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text strong>
                          País:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.PaisCliente}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text strong>
                          Dirección:&nbsp;&nbsp;
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.DireccionCliente}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </>
          ) : (
            <Card className="CardFacturacion">
              <Skeleton />
            </Card>
          )}
          {Tanques.length !== 0 ? (
            <>
              <Row style={{ marginTop: "1%" }}>
                <Col
                  lg={{ span: 14 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Tabs
                    className="TabTanque"
                    defaultActiveKey="0"
                    type="card"
                    onChange={CambiarDatosDeFormulario}
                  >
                    {Tanques.map((tanque, index) => {
                      return (
                        <>
                          <TabPane
                            tab={
                              <span>
                                <ScheduleOutlined />
                                {tanque.Descripcion}
                              </span>
                            }
                            key={`${index}`}
                          ></TabPane>
                        </>
                      );
                    })}
                  </Tabs>

                  <Card className="CardFormularioTanque">
                    <Form ref={DatosTanque}>
                      <Row>
                        <Col
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Row>
                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "3%" }}>
                                <Col
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Fecha:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              lg={{ offset: 1, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Fecha">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "-1.5em" }}>
                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "3%" }}>
                                <Col
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Producto:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              lg={{ offset: 1, span: 8 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Producto">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "-1.5em" }}>
                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "3%" }}>
                                <Col
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Descripción:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              lg={{ offset: 1, span: 14 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Descripcion">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "-1.5em" }}>
                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "3%" }}>
                                <Col
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Estado:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              lg={{ offset: 1, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Estado">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>

                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "4%" }}>
                                <Col
                                  xl={{ offset: 11, span: 24 }}
                                  lg={{ offset: 20, span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Presión:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xl={{ offset: 2, span: 3 }}
                              lg={{ offset: 4, span: 3 }}
                              md={{ offset: 1, span: 5 }}
                              sm={{ offset: 1, span: 5 }}
                              xs={{ offset: 1, span: 5 }}
                            >
                              <Form.Item name="Presion">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>
                            <Col
                              xl={{ offset: 1, span: 3 }}
                              lg={{ offset: 0, span: 2 }}
                              md={{ span: 4 }}
                              sm={{ span: 4 }}
                              xs={{ span: 4 }}
                            >
                              <Form.Item name="UnidadMedidaP">
                                <Select
                                  size="small"
                                  onChange={(unidad) =>
                                    convertirPSIaBARViceversa(unidad)
                                  }
                                >
                                  <Option value={7}>
                                    <Tooltip title="Libras por Pulgada Cuadrada">
                                      PSI
                                    </Tooltip>
                                  </Option>

                                  <Option value={16}>
                                    <Tooltip title="Bar">Bar</Tooltip>
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "-1.5em" }}>
                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "3%" }}>
                                <Col
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Unidad de Medida:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              lg={{ offset: 1, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Volumen">
                                <Select
                                  size="small"
                                  style={{ width: "auto" }}
                                  onChange={ConvertirVolumen}
                                >
                                  <Option value={3}>Kilogramos</Option>
                                  <Option value={2}>Libras</Option>
                                  <Option value={4}>Galones</Option>
                                  <Option value={17}>Litros</Option>
                                  <Option value={5}>Pies Cúbicos</Option>
                                  <Option value={18}>Metros Cúbicos</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "4%" }}>
                                <Col
                                  xl={{ offset: 11, span: 24 }}
                                  lg={{ offset: 20, span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Porcentaje:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xl={{ offset: 2, span: 4 }}
                              lg={{ offset: 4, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Porcentaje">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "-1.5em" }}>
                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "3%" }}>
                                <Col
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Volumen Nominal:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              lg={{ offset: 1, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Nominal">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>

                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "4%" }}>
                                <Col
                                  xl={{ offset: 11, span: 24 }}
                                  lg={{ offset: 20, span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Estado Normal:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xl={{ offset: 2, span: 4 }}
                              lg={{ offset: 4, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Normal">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "-1.5em" }}>
                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "3%" }}>
                                <Col
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Volumen Neto:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              lg={{ offset: 1, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Neto">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>

                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "4%" }}>
                                <Col
                                  xl={{ offset: 11, span: 24 }}
                                  lg={{ offset: 20, span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Estado Reorden:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xl={{ offset: 2, span: 4 }}
                              lg={{ offset: 4, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Reorden">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "-1.5em" }}>
                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "3%" }}>
                                <Col
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Volumen Actual:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              lg={{ offset: 1, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Actual">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>

                            <Col
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Row style={{ marginTop: "4%" }}>
                                <Col
                                  xl={{ offset: 11, span: 24 }}
                                  lg={{ offset: 20, span: 24 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  xs={{ span: 24 }}
                                >
                                  <Typography.Text strong>
                                    Estado Crítico:
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xl={{ offset: 2, span: 4 }}
                              lg={{ offset: 4, span: 4 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                            >
                              <Form.Item name="Critico">
                                <Input readOnly size="small" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </Col>
                <Col
                  style={{ height: "57vh" }}
                  lg={{ offset: 1, span: 9 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row justify="center" style={{ height: "100%" }}>
                    <Col
                      className="ImagenTanque"
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Row
                        justify="center"
                        style={{
                          // backgroundColor: 'turquoise',
                          height: "38vh",
                          marginTop: "7vh",
                          // marginLeft: '8vh',
                        }}
                      >
                        <Col
                          lg={{ span: 5 }}
                          md={{ span: 4 }}
                          sm={{ span: 6 }}
                          xs={{ span: 9 }}
                        >
                          {/* BARRA CONTENEDORA */}
                          <div
                            style={{
                              height: "100%",
                              width: "inherit",
                              backgroundColor: "#BEBFBC",
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            {/* BARRA DE LLENADO */}
                            <div
                              style={{
                                height: `${NivelTanque}%`,
                                width: "100%",
                                textAlign: "center",
                                backgroundColor: Colorcito,
                              }}
                            >
                              <span
                                style={{
                                  padding: 5,
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {/* {MostrarElNivel
                                  ? `${NivelReal}%`
                                  : `${NivelTanque}%`} */}
                                {`${NivelTanque}%`}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Seccion titulo={"Resumen del Mes Actual"} marginTop={"1%"} />
              <Row style={{ marginTop: "1%", height: "auto" }}>
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Table
                    bordered
                    size="small"
                    dataSource={TablaResumen}
                    pagination={{
                      size: "small",
                      pageSize: 20,
                    }}
                    scroll={{ x: 400 }}
                  >
                    <Column
                      title="Inicio"
                      dataIndex="Inicio"
                      key="Inicio"
                      align="center"
                    />
                    <Column
                      title="Consumo Mes"
                      dataIndex="ConsumoMes"
                      key="ConsumoMes"
                      align="center"
                    />
                    <Column
                      title="Promedio Hora"
                      dataIndex="PromedioHora"
                      key="PromedioHora"
                      align="center"
                    />
                    <Column
                      title="Promedio Día"
                      dataIndex="PromedioDia"
                      key="PromedioDia"
                      align="center"
                    />
                  </Table>
                </Col>
              </Row>

              <Seccion titulo={"Gráfico de Volumenes"} marginTop={"1%"} />
              <Row>
                <Col
                  lg={{ span: 5 }}
                  md={{ span: 8 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Form
                    ref={FormRangoDeFechas}
                    initialValues={{
                      ItemDatePicker: [
                        moment().subtract(3, "days"),
                        moment().add(4, "days"),
                      ],
                    }}
                  >
                    <Form.Item name="ItemDatePicker">
                      <RangePicker
                        allowClear={false}
                        size="small"
                        format={("DD/MM/YYYY", "DD/MM/YYYY")}
                        onChange={(fecha) => {
                          setCargandoPantalla(true);
                          setOnChangeActivo(true);
                          let parametros = {
                            IdEmpresa: usuario.usuario.Compania,
                            FechaInicio: fecha[0].format(),
                            FechaFin: fecha[1].format(),
                          };
                          onGetInventario(parametros);
                        }}
                        ref={ItemDatePicker}
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col
                  style={{ marginTop: 4, textAlign: "center" }}
                  lg={{ span: 1 }}
                  md={{ span: 2 }}
                  sm={{ span: 3 }}
                  xs={{ span: 3 }}
                >
                  <ZoomOutOutlined
                    style={{ fontSize: 17 }}
                    onClick={() => FiltrarFechaZoomOut()}
                  />
                </Col>
                <Col
                  style={{ marginTop: 4, textAlign: "center" }}
                  lg={{ span: 3 }}
                  md={{ span: 4 }}
                  sm={{ span: 7 }}
                  xs={{ span: 14 }}
                >
                  <Button
                    className="BotonAhora"
                    size="small"
                    onClick={() => {
                      setCargandoPantalla(true);
                      setFechaFiltrada("1 Semana");
                      FormRangoDeFechas.current.setFieldsValue({
                        ItemDatePicker: [
                          moment().subtract(3, "days"),
                          moment().add(4, "days"),
                        ],
                      });

                      let parametros = {
                        IdEmpresa: usuario.usuario.Compania,
                        FechaInicio: moment().subtract(3, "days").format(),
                        FechaFin: moment().add(4, "days").format(),
                      };
                      onGetInventario(parametros);
                    }}
                  >
                    Volumen Actual
                  </Button>
                </Col>
                <Col
                  style={{ marginTop: 4, textAlign: "center" }}
                  lg={{ span: 1 }}
                  md={{ span: 2 }}
                  sm={{ span: 3 }}
                  xs={{ span: 3 }}
                >
                  <ZoomInOutlined
                    style={{ fontSize: 17 }}
                    onClick={() => FiltrarFechaZoomIn()}
                  />
                </Col>
                <Col
                  style={{ marginTop: 4, textAlign: "center" }}
                  lg={{ span: 2 }}
                  md={{ span: 5 }}
                  sm={{ span: 10 }}
                  xs={{ span: 12 }}
                >
                  <Typography.Text>{FechaFiltrada}</Typography.Text>
                </Col>
                <Col
                  style={{ marginTop: 5, textAlign: "center" }}
                  lg={{ span: 3 }}
                  md={{ span: 6 }}
                  sm={{ span: 12 }}
                  xs={{ span: 18 }}
                >
                  <Checkbox
                    checked={VerPronostico}
                    onChange={() => setVerPronostico(!VerPronostico)}
                  >
                    <Typography.Text>Ver Pronóstico</Typography.Text>
                  </Checkbox>
                </Col>
              </Row>
              {/* Panel de tabs para el Forecast y Resumen  */}
              <Row style={{ marginTop: "-2%" }}>
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {/* Gráfico */}
                  <Row
                    justify="center"
                    style={{
                      height: "80vh",
                      border: "2px solid #d1d3d4",
                      backgroundColor: "#fff",
                      marginBottom: "2%",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      style={{ height: "70vh" }}
                      lg={{ span: 23 }}
                      md={{ span: 23 }}
                      sm={{ span: 23 }}
                      xs={{ span: 23 }}
                    >
                      {LecturasConPronostico.length !== 0 ? (
                        <Line
                          data={LecturasConPronostico}
                          {...config}
                          animation={{
                            appear: {
                              animation: "path-in",
                              duration: 5000,
                            },
                          }}
                          annotations={
                            VerPronostico
                              ? [
                                  // [VALORES X Y DE FORMA HORIZONTAL, VALORES X Y DE FORMA VERTICAL]
                                  {
                                    //LINEA DE TIEMPO PARA EL PRONOSTICO
                                    type: "line",
                                    // start: ['2014-09', 'min'],
                                    // end: ['2014-09', 'max'],
                                    start: [`${DiaActualGrafico}`, "min"],
                                    end: [`${DiaActualGrafico}`, "max"],
                                    style: {
                                      stroke: "#34606A",
                                    },
                                  },
                                  {
                                    //LINEA DE TIEMPO PARA EL PRONOSTICO
                                    type: "region",
                                    // start: ['2014-09', 'min'],
                                    start: [`${DiaActualGrafico}`, "min"],
                                    end: ["max", "max"],
                                    style: {
                                      fill: "#BDDBBA",
                                      fillOpacity: 1,
                                    },
                                  },
                                  {
                                    //Linea de tanque lleno
                                    type: "line",
                                    // start: ['min', 'end'],
                                    // end: ['max', 'end'],
                                    start: ["min", `${LineaLleno}`],
                                    end: ["max", `${LineaLleno}`],
                                    style: {
                                      stroke: "#81973A",
                                      lineDash: [10, 10],
                                    },
                                  },
                                  {
                                    //Label de tanque lleno
                                    type: "regionFilter",
                                    start: ["min", `${LineaLleno}`],
                                    end: ["max", "max"],
                                    color: "#81973A",
                                  },
                                  {
                                    //Linea de re orden de tanque
                                    type: "line",
                                    // start: ['min', 'median'],
                                    // end: ['max', 'median'],
                                    start: ["min", `${LineaReOrder}`],
                                    end: ["max", `${LineaReOrder}`],
                                    style: {
                                      stroke: "#FAAD14",
                                      lineDash: [10, 10],
                                    },
                                  },
                                  {
                                    //Label de re orden de tanque
                                    type: "regionFilter",
                                    // start: ['min', 'median'],
                                    // end: ['max', '600'],
                                    start: ["min", `${LineaReOrder}`],
                                    end: ["max", `${LineaCritica}`],
                                    color: "#FAAD14",
                                  },
                                  {
                                    //Linea crítica de tanque
                                    type: "line",
                                    // start: ['min', '600'],
                                    // end: ['max', '600'],
                                    start: ["min", `${LineaCritica}`],
                                    end: ["max", `${LineaCritica}`],
                                    style: {
                                      stroke: "#D71414",
                                      lineDash: [10, 10],
                                    },
                                  },
                                  {
                                    //Label Crítico de tanque
                                    type: "regionFilter",
                                    start: ["min", "0"],
                                    end: ["max", `${LineaCritica}`],
                                    color: "#D71414",
                                  },
                                ]
                              : [
                                  // [VALORES X Y DE FORMA HORIZONTAL, VALORES X Y DE FORMA VERTICAL]
                                  {
                                    //Linea de tanque lleno
                                    type: "line",
                                    start: ["min", `${LineaLleno}`],
                                    end: ["max", `${LineaLleno}`],
                                    style: {
                                      stroke: "#81973A",
                                      lineDash: [10, 10],
                                    },
                                  },
                                  {
                                    //Label de tanque lleno
                                    type: "regionFilter",
                                    start: ["min", `${LineaLleno}`],
                                    end: ["max", "max"],
                                    color: "#81973A",
                                  },
                                  {
                                    //Linea de re orden de tanque
                                    type: "line",
                                    start: ["min", `${LineaReOrder}`],
                                    end: ["max", `${LineaReOrder}`],
                                    style: {
                                      stroke: "#FAAD14",
                                      lineDash: [10, 10],
                                    },
                                  },
                                  {
                                    //Label de re orden de tanque
                                    type: "regionFilter",
                                    start: ["min", `${LineaReOrder}`],
                                    end: ["max", `${LineaCritica}`],
                                    color: "#FAAD14",
                                  },
                                  {
                                    //Linea crítica de tanque
                                    type: "line",
                                    start: ["min", `${LineaCritica}`],
                                    end: ["max", `${LineaCritica}`],
                                    style: {
                                      stroke: "#D71414",
                                      lineDash: [10, 10],
                                    },
                                  },
                                  {
                                    //Label Crítico de tanque
                                    type: "regionFilter",
                                    start: ["min", "0"],
                                    end: ["max", `${LineaCritica}`],
                                    color: "#D71414",
                                  },
                                ]
                          }
                        />
                      ) : (
                        <>
                          <Empty />
                          <Skeleton />
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row justify="center" style={{ marginTop: "2%" }}>
                <Col>
                  <Card className="CardInventario">
                    Actualmente no hay registros de tanques sobre este cliente.
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: "2%" }}>
                <Col
                  lg={{ span: 14 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Skeleton active />
                  <Skeleton active />
                </Col>
                <Col
                  style={{ height: "60vh" }}
                  lg={{ offset: 1, span: 9 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row justify="center" style={{ height: "100%" }}>
                    <Col
                      className="ImagenTanque"
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Row
                        justify="center"
                        style={{
                          height: "39vh",
                          marginTop: "8vh",
                        }}
                      >
                        <Col
                          lg={{ span: 5 }}
                          md={{ span: 4 }}
                          sm={{ span: 6 }}
                          xs={{ span: 9 }}
                        >
                          {/* BARRA CONTENEDORA */}
                          <div
                            style={{
                              height: "100%",
                              width: "inherit",
                              backgroundColor: " rgb(174, 214, 174)",
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            {/* BARRA DE LLENADO */}
                            <div
                              style={{
                                height: `${NivelTanque}%`,
                                width: "100%",
                                textAlign: "center",
                                backgroundColor: Colorcito,
                              }}
                            >
                              <span
                                style={{
                                  padding: 5,
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >{`${NivelTanque}%`}</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Skeleton active />
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <PantallaDeNoAcceso src={Inventario} alt={"Inventario"} />
        </>
      )}
    </>
  );
};
export default ModuloInventario;
