import sings from './sings';
import api from '../../../api/api';

function getEmpresasById(id) {
  return (dispatch) => {
    api
      .get(`api/ClientCompany/${id}`)
      .then(function (response) {
        dispatch(sings.getEmpresasSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getEmpresasFailure(err.message));
      });
  };
}

function getAllEmpresas(id) {
  return (dispatch) => {
    api
      .get(`api/ClientCompany/AllCompanies?id=${id}`)
      .then(function (response) {
        dispatch(sings.getAllEmpresasSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getAllEmpresasFailure(err.message));
      });
  };
}

function postEmpresas(nombre, empresa) {
  return (dispatch) => {
    api
      .post(`api/ClientCompany?usuarioActivo=${nombre}`, { ...empresa })
      .then(function (response) {
        dispatch(sings.postEmpresasSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.postEmpresasFailure(err));
      });
  };
}

function putEmpresas(empresa) {
  return (dispatch) => {
    api
      .post(`api/ClientCompany/Update`, { ...empresa })
      .then(function (response) {
        dispatch(sings.putEmpresasSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.putEmpresasFailure(err));
      });
  };
}

function deleteEmpresas(id, nombre) {
  return (dispatch) => {
    api
      .delete(`api/ClientCompany/${id}?usuarioActivo=${nombre}`)
      .then(function (response) {
        dispatch(sings.deleteEmpresasSuccess(response));
      })
      .catch((err) => {
        dispatch(sings.deleteEmpresasFailure(err));
      });
  };
}

function cerrarAlertaEliminar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaEliminar());
  };
}
function cerrarAlertaAgregar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaAgregar());
  };
}
function cerrarAlertaActualizar() {
  return (dispatch) => {
    dispatch(sings.cerrarAlertaActualizar());
  };
}

function getEmpresasLoading() {
  return (dispatch) => {
    dispatch(sings.getEmpresasLoading());
  };
}

function getAllPaises() {
  return (dispatch) => {
    api
      .get(`api/Country`)
      .then(function (response) {
        dispatch(sings.getAllPaisesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sings.getAllPaisesFailure(err.message));
      });
  };
}

export default {
  getEmpresasById: getEmpresasById,
  getAllEmpresas: getAllEmpresas,
  postEmpresas: postEmpresas,
  putEmpresas: putEmpresas,
  deleteEmpresas: deleteEmpresas,
  cerrarAlertaEliminar: cerrarAlertaEliminar,
  cerrarAlertaAgregar: cerrarAlertaAgregar,
  cerrarAlertaActualizar: cerrarAlertaActualizar,
  getEmpresasLoading: getEmpresasLoading,
  getAllPaises: getAllPaises,
};
