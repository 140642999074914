import React, { Component } from 'react';
import ModuloConsumo from '../../../components/modulosCliente/moduloConsumo/moduloConsumo';
import Loading from '../../../components/loading/loading';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

class moduloConsumo extends Component {
  componentDidMount() {
    //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
    // pantalla
    var Token = sessionStorage.getItem('TokenUsuario');
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };

    let Fecha = moment().utcOffset(0, true).format();
    
    let parametros = {
      IdEmpresa: usuario.usuario.Compania,
      Fecha: Fecha,
    };
    this.props.onGetLoading();
    this.props.onGetConsumo(parametros);
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <ModuloConsumo {...this.props} />
        )} 

        {/* <ModuloConsumo {...this.props} /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    consumo: state.consumo.consumo,
    loading: state.consumo.loading,};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetConsumo: (parametros) =>
      dispatch(actions.consumo.calls.getConsumo(parametros)),
    onGetLoading: () =>
      dispatch(actions.consumo.calls.getConsumoLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(moduloConsumo);
