import signs from './signs';
import api from '../../../api/api';

function getComprobante(parametros) {
  return (dispatch) => {
    api
      .post(`api/TrackAbout/reciboEntrega`, { ...parametros })
      .then(function (response) {
        dispatch(signs.getComprobanteSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getComprobanteFailure(err));
      });
  };
}

function setCodigoCliente(data) {
  return (dispatch) => {
    dispatch(signs.setCodigoCliente(data));
  };
}
function clearCodigoCliente() {
  return (dispatch) => {
    dispatch(signs.clearCodigoCliente());
  };
}

function getComprobanteLoading() {
  return (dispatch) => {
    dispatch(signs.getComprobanteLoading());
  };
}

function limpiarStatusVerComprobante() {
  return (dispatch) => {
    dispatch(signs.limpiarStatusVerComprobane());
  };
}

export default {
  getComprobante: getComprobante,
  getComprobanteLoading: getComprobanteLoading,
  setCodigoCliente: setCodigoCliente,
  clearCodigoCliente: clearCodigoCliente,
  getComprobanteLoading: getComprobanteLoading,
  limpiarStatusVerComprobante: limpiarStatusVerComprobante,
};
