const actionsType = {
  GET_NOTIFICACIONES_SUCCESS: 'GET_NOTIFICACIONES_SUCCESS',
  GET_NOTIFICACIONES_FAILURE: 'GET_NOTIFICACIONES_FAILURE',

  GET_NOTIFICACIONES_CON_FECHA_SUCCESS: 'GET_NOTIFICACIONES_CON_FECHA_SUCCESS',
  GET_NOTIFICACIONES_CON_FECHA_FAILURE: 'GET_NOTIFICACIONES_CON_FECHA_FAILURE',

  GET_NOTIFICACIONES_NUEVAS_SUCCESS: 'GET_NOTIFICACIONES_NUEVAS_SUCCESS',
  GET_NOTIFICACIONES_NUEVAS_FAILURE: 'GET_NOTIFICACIONES_NUEVAS_FAILURE',

  GET_NOTIFICACIONES_NUEVAS_CONSUMO_SUCCESS: 'GET_NOTIFICACIONES_NUEVAS_CONSUMO_SUCCESS',
  GET_NOTIFICACIONES_NUEVAS_CONSUMO_FAILURE: 'GET_NOTIFICACIONES_NUEVAS_CONSUMO_FAILURE',

  MARCAR_NOTIFICACION_LEIDA_SUCCESS: 'MARCAR_NOTIFICACION_LEIDA_SUCCESS',
  MARCAR_NOTIFICACION_LEIDA_FAILURE: 'MARCAR_NOTIFICACION_LEIDA_FAILURE',

  MARCAR_NOTIFICACION_LEIDA_PANEL_SUCCESS:
    'MARCAR_NOTIFICACION_LEIDA_PANEL_SUCCESS',
  MARCAR_NOTIFICACION_LEIDA_PANEL_FAILURE:
    'MARCAR_NOTIFICACION_LEIDA_PANEL_FAILURE',

  GET_NOTIFICACIONES_LOADING: 'GET_NOTIFICACIONES_LOADING',

  CERRAR_ALERTA_OBTENER_NOTIFICACIONES: 'CERRAR_ALERTA_OBTENER_NOTIFICACIONES',

  ESTABLECER_TAB_ACTIVO: 'ESTABLECER_TAB_ACTIVO',

  ESTABLECER_ID_DE_NOTIFICACION_SELECCIONADA:
    'ESTABLECER_ID_DE_NOTIFICACION_SELECCIONADA',

  LIMPIAR_ID_DE_NOTIFICACION_SELECCIONADA:
    'LIMPIAR_ID_DE_NOTIFICACION_SELECCIONADA',

  CONTAR_NOTIFICACIONES_NO_LEIDAS: 'CONTAR_NOTIFICACIONES_NO_LEIDAS',

  FILTRANDO_FECHAS: 'FILTRANDO_FECHAS',
};

export default actionsType;
