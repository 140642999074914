import React, { useEffect, useState, useRef } from "react";
import {
  Tabs,
  Badge,
  Space,
  Row,
  Col,
  Form,
  Button,
  DatePicker,
  notification,
} from "antd";
import {
  SnippetsOutlined,
  SolutionOutlined,
  BarChartOutlined,
  FundOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Actions from "../../../data/actions";
import Seccion from "../../Seccion";
import moment from "moment";
import { decrypt } from "react-crypt-gsm";
import jwtDecode from "jwt-decode";
import NotificacionesControlEnvases from "./NotificacionesControlEnvases/notificacionesControlEnvases";
import NotificacionesInventario from "./NotificacionesInventario/notificacionesInventario";
import NotificacionesConsumo from "./NotificacionesConsumo/notificacionesConsumo";
import NotificacionesFacturacion from "./NotificacionesFacturacion/notificacionesFacturacion";
// import NotificacionesEstadoDeCuenta from './NotificacionesEstadoDeCuenta/notificacionesEstadoDeCuenta';
import "./moduloNotificaciones.css";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const ModuloNotificaciones = (props) => {
  //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
  // pantalla
  var Token = sessionStorage.getItem("TokenUsuario");
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  //Aquí se guarda el código del cliente
  let Cliente = usuario.usuario.Compania;

  let {
    Tab_Activo,
    notificaciones,
    notificacionesNuevas,
    notificacionesNuevasConsumo,
    onGetNotificaciones,
    onGetNotificacionesConFecha,
    contarNotificacionesNoLeidas,
    ContarNoLeidas,
    filtrandoFechas,
    onGetNotificacionesNuevas,
    onGetNotificacionesNuevasConsumo,
  } = props;
  let dispatch = useDispatch();
  let FormDatePicker = useRef();

  const [
    NotificacionesControlDeEnvases,
    setNotificacionesControlDeEnvases,
  ] = useState([]);
  const [
    NotificacionesNuevasControlDeEnvases,
    setNotificacionesNuevasControlDeEnvases,
  ] = useState([]);

  const [NotificacionesDeFacturacion, setNotificacionesFacturacion] = useState(
    []
  );
  const [
    NotificacionesNuevasDeFacturacion,
    setNotificacionesNuevasFacturacion,
  ] = useState([]);

  const [NotificacionesDeInventario, setNotificacionesInventario] = useState(
    []
  );
  const [
    NotificacionesNuevasDeInventario,
    setNotificacionesNuevasInventario,
  ] = useState([]);

  const [NotificacionesDeConsumo, setNotificacionesConsumo] = useState([]);
  const [
    NotificacionesNuevasDeConsumo,
    setNotificacionesNuevasConsumo,
  ] = useState([]);


  //Este hook se activa si no hay que hacer un conteo de notificaciones nuevas, porque el filtro de fechas no
  //se está utilizando
  useEffect(() => {
    let todasNotificaciones = notificacionesNuevas.concat(
      notificacionesNuevasConsumo
    );
    let unicas = Array.from(new Set(todasNotificaciones));
    if (!ContarNoLeidas) {
      let notificacionesActualesCE = [];
      let notificacionesActualesFACT = [];
      let notificacionesActualesINVENT = [];
      let notificacionesActualesCONSU = [];
      for (let index in todasNotificaciones) {
        let NotificacionActual = todasNotificaciones[index];
        if (
          NotificacionActual.ID_Proveedor === 1 &&
          NotificacionActual.Notificacion_Leida === false
        ) {
          notificacionesActualesCE.push(NotificacionActual);
        } else if (
          NotificacionActual.ID_Proveedor === 3 &&
          NotificacionActual.Notificacion_Leida === false
        ) {
          notificacionesActualesFACT.push(NotificacionActual);
        } else if (
          NotificacionActual.ID_Proveedor === 2 &&
          NotificacionActual.Notificacion_Leida === false
        ) {
          notificacionesActualesINVENT.push(NotificacionActual);
        } else if (
          NotificacionActual.ID_Proveedor === 4 &&
          NotificacionActual.Notificacion_Leida === false
        ) {
          notificacionesActualesCONSU.push(NotificacionActual);
        }
      }
      return (
        setNotificacionesNuevasControlDeEnvases(notificacionesActualesCE),
        setNotificacionesNuevasFacturacion(notificacionesActualesFACT),
        setNotificacionesNuevasInventario(notificacionesActualesINVENT),
        setNotificacionesNuevasConsumo(notificacionesActualesCONSU)
      );
    }
  }, [notificacionesNuevas, notificacionesNuevasConsumo, ContarNoLeidas]);

  //Este hook se activa si hay que hacer un conteo de notificaciones nuevas, porque el filtro de fechas si
  //se está utilizando
  useEffect(() => {
    if (ContarNoLeidas) {
      let notificacionesActualesCE = [];
      let notificacionesActualesFACT = [];
      let notificacionesActualesINVENT = [];
      let notificacionesActualesCONSU = [];
      for (let index in notificaciones) {
        let NotificacionActual = notificaciones[index];
        if (
          NotificacionActual.ID_Proveedor === 1 &&
          NotificacionActual.Notificacion_Leida === false
        ) {
          notificacionesActualesCE.push(NotificacionActual);
        } else if (
          NotificacionActual.ID_Proveedor === 3 &&
          NotificacionActual.Notificacion_Leida === false
        ) {
          notificacionesActualesFACT.push(NotificacionActual);
        } else if (
          NotificacionActual.ID_Proveedor === 2 &&
          NotificacionActual.Notificacion_Leida === false
        ) {
          notificacionesActualesINVENT.push(NotificacionActual);
        } else if (
          NotificacionActual.ID_Proveedor === 4 &&
          NotificacionActual.Notificacion_Leida === false
        ) {
          notificacionesActualesCONSU.push(NotificacionActual);
        }
      }
      return (
        setNotificacionesNuevasControlDeEnvases(notificacionesActualesCE),
        setNotificacionesNuevasFacturacion(notificacionesActualesFACT),
        setNotificacionesNuevasInventario(notificacionesActualesINVENT),
        setNotificacionesNuevasConsumo(notificacionesActualesCONSU)
      );
    }
  }, [notificaciones, ContarNoLeidas]);

  useEffect(() => {
    let notificacionesActualesCE = [];
    let notificacionesActualesFACT = [];
    let notificacionesActualesINVENT = [];
    let notificacionesActualesCONSU = [];
    for (let index in notificaciones) {
      let NotificacionActual = notificaciones[index];
      if (NotificacionActual.ID_Proveedor === 1) {
        let Fecha = NotificacionActual.Fecha_Notificacion.split("T");
        Fecha = Fecha[0].split("-");
        Fecha = `${Fecha[2]}/${Fecha[1]}/${Fecha[0]}`;
        NotificacionActual = {
          key: NotificacionActual.ID_Notificacion,
          Fecha: Fecha,
          Estado: NotificacionActual.Notificacion_Leida === true ? "Leída" : "",
          Categoría:
            NotificacionActual.TipoNotificacion.Tipo_Notificacion ===
            "Aviso" ? (
              <Button type="link" className="BotonVerMas">
              {NotificacionActual.TipoNotificacion.Tipo_Notificacion}
              </Button>
            ) : (
              <Button type="link" className="BotonVerMasAlert">
              {NotificacionActual.TipoNotificacion.Tipo_Notificacion}
              </Button>
            ),
          Mensaje: NotificacionActual.Mensaje_Notificacion,
        };
        notificacionesActualesCE.push(NotificacionActual);
      } else if (NotificacionActual.ID_Proveedor === 3) {
        let Fecha = NotificacionActual.Fecha_Notificacion.split("T");
        Fecha = Fecha[0].split("-");
        Fecha = `${Fecha[2]}/${Fecha[1]}/${Fecha[0]}`;
        NotificacionActual = {
          key: NotificacionActual.ID_Notificacion,
          Fecha: Fecha,
          Estado: NotificacionActual.Notificacion_Leida === true ? "Leída" : "",
          Categoría:
            NotificacionActual.TipoNotificacion.Tipo_Notificacion ===
            "Aviso" ? (
              <Button type="link" className="BotonVerMas">
              {NotificacionActual.TipoNotificacion.Tipo_Notificacion}
              </Button>
            ) : (
              <Button type="link" className="BotonVerMasAlert">
              {NotificacionActual.TipoNotificacion.Tipo_Notificacion}
              </Button>
            ),
          Mensaje: NotificacionActual.Mensaje_Notificacion,
        };
        notificacionesActualesFACT.push(NotificacionActual);
      } else if (NotificacionActual.ID_Proveedor === 2) {
        let Fecha = NotificacionActual.Fecha_Notificacion.split("T");
        Fecha = Fecha[0].split("-");
        Fecha = `${Fecha[2]}/${Fecha[1]}/${Fecha[0]}`;
        NotificacionActual = {
          key: NotificacionActual.ID_Notificacion,
          Fecha: Fecha,
          Estado: NotificacionActual.Notificacion_Leida === true ? "Leída" : "",
          Categoría:
            NotificacionActual.TipoNotificacion.Tipo_Notificacion ===
            "Aviso" ? (
              <Button type="link" className="BotonVerMas">
              {NotificacionActual.TipoNotificacion.Tipo_Notificacion}
              </Button>
            ) : (
              <Button type="link" className="BotonVerMasAlert">
              {NotificacionActual.TipoNotificacion.Tipo_Notificacion}
              </Button>
            ),
          Mensaje: NotificacionActual.Mensaje_Notificacion,
        };
        notificacionesActualesINVENT.push(NotificacionActual);
      } else if (NotificacionActual.ID_Proveedor === 4) {
        let Fecha = NotificacionActual.Fecha_Notificacion.split("T");
        Fecha = Fecha[0].split("-");
        Fecha = `${Fecha[2]}/${Fecha[1]}/${Fecha[0]}`;
        NotificacionActual = {
          key: NotificacionActual.ID_Notificacion,
          Fecha: Fecha,
          Estado: NotificacionActual.Notificacion_Leida === true ? "Leída" : "",
          Categoría:
            NotificacionActual.TipoNotificacion.Tipo_Notificacion ===
            "Aviso" ? (
              <Button type="link" className="BotonVerMas">
              {NotificacionActual.TipoNotificacion.Tipo_Notificacion}
              </Button>
            ) : (
              <Button type="link" className="BotonVerMasAlert">
              {NotificacionActual.TipoNotificacion.Tipo_Notificacion}
              </Button>
            ),
          Mensaje: NotificacionActual.Mensaje_Notificacion,
        };
        notificacionesActualesCONSU.push(NotificacionActual);
      }
    }
    return (
      setNotificacionesControlDeEnvases(notificacionesActualesCE),
      setNotificacionesFacturacion(notificacionesActualesFACT),
      setNotificacionesInventario(notificacionesActualesINVENT),
      setNotificacionesConsumo(notificacionesActualesCONSU)
    );
  }, [notificaciones]);

  function FiltrarNotificaciones(Fechas) { 
    if (Fechas !== null) {
      let Inicio = Fechas[0];
      let Fin = Fechas[1];
      let Maximo = Fin.diff(Inicio, "days");
      if (Maximo > 90) {
        notification.open({
          type: "info",
          message: "¡Máximo Excedido!",
          description:
            "El máximo para consultar es de 90 días, no se puede realizar la consulta si este es excedido.",
          duration: 5,
          // onClose: () => {
          //
          // },
        });
      } else {
        let parametros = {
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: Inicio.utcOffset(0, true).format(),
          FechaFin: Fin.utcOffset(0, true).format(),
        };
        filtrandoFechas({
          FechaInicio: Inicio.format("YYYY-MM-DD"),
          FechaFin: Fin.format("YYYY-MM-DD"),
        });
        contarNotificacionesNoLeidas(true);
        onGetNotificacionesConFecha(parametros);
      }
    } else {
      contarNotificacionesNoLeidas(false);
      onGetNotificaciones(Cliente);
      onGetNotificacionesNuevas(Cliente);
      onGetNotificacionesNuevasConsumo(Cliente);
    }
  }

  function deshabilitarFechaFutura(dia) {
    const Fin = moment();
    return !Fin.isAfter(dia);
  }
  return (
    <>
      <Row
        style={{
          marginTop: "1.9%",
        }}
      >
        <Col
          lg={{ span: 16 }}
          md={{ span: 16 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Seccion titulo={"Notificaciones"} />
        </Col>
        <Col
          lg={{ span: 2 }}
          md={{ span: 2 }}
          sm={{ span: 3 }}
          xs={{ span: 6 }}
        >
          <Row
            style={{
              background: "#81973a",
              borderRadius: "5px 0px 0px 5px",
              marginLeft: "5%",
              height: "100%",
            }}
          >
            <Col style={{
                    color: "white",
                    marginLeft: "10%",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}>
              Fecha:
            </Col>
          </Row>
        </Col>
        <Col
          lg={{ span: 6 }}
          md={{ span: 6 }}
          sm={{ span: 12 }}
          xs={{ span: 24 }}
        >
          <Form
            style={{ marginTop: -5 }}
            ref={FormDatePicker}
            // initialValues={{ ItemDatePicker: FECHADATEPICKER }}
          >
            <Row style={{ height: "3vh" }}>
              <Col
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="ItemDatePicker">
                  <RangePicker
                    format={("DD/MM/YYYY", "DD/MM/YYYY")}
                    onChange={FiltrarNotificaciones}
                    disabledDate={deshabilitarFechaFutura}
                    style={{ border: "2px solid #81973a" }}
                    className="FechaFac"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Tabs
        style={{ marginTop: "2%" }}
        activeKey={`${Tab_Activo}`}
        type="card"
        onChange={(Tab) =>
          dispatch(Actions.notificaciones.calls.establecerTabActivo(`${Tab}`))
        }
      >
        <TabPane
          tab={
            <>
              <Space>
                <span>
                  <BarChartOutlined />
                  Control de Envases
                </span>
                <Badge
                  overflowCount={99}
                  count={NotificacionesNuevasControlDeEnvases.length}
                  style={{ marginTop: -5, backgroundColor: "gray" }}
                />
              </Space>
            </>
          }
          key="Control de Envases"
        >
          <NotificacionesControlEnvases
            Notificaciones={NotificacionesControlDeEnvases}
          />
        </TabPane>
        <TabPane
          tab={
            <>
              <Space>
                <span>
                  <SnippetsOutlined />
                  Inventario
                </span>
                <Badge
                  overflowCount={99}
                  count={NotificacionesNuevasDeInventario.length}
                  style={{ marginTop: -5, backgroundColor: "gray" }}
                />
              </Space>
            </>
          }
          key="Inventario"
        >
          <NotificacionesInventario
            Notificaciones={NotificacionesDeInventario}
          />
        </TabPane>
        <TabPane
          tab={
            <>
              <Space>
                <span>
                  <FundOutlined />
                  Consumo
                </span>
                <Badge
                  overflowCount={99}
                  count={NotificacionesNuevasDeConsumo.length}
                  style={{ marginTop: -5, backgroundColor: "gray" }}
                />
              </Space>
            </>
          }
          key="Consumo"
        >
          <NotificacionesConsumo Notificaciones={NotificacionesDeConsumo} />
        </TabPane>
        <TabPane
          tab={
            <>
              <Space>
                <span>
                  <SolutionOutlined />
                  Facturación
                </span>
                <Badge
                  overflowCount={99}
                  count={NotificacionesNuevasDeFacturacion.length}
                  style={{ marginTop: -5, backgroundColor: "gray" }}
                />
              </Space>
            </>
          }
          key="Facturación"
        >
          <NotificacionesFacturacion
            Notificaciones={NotificacionesDeFacturacion}
          />
        </TabPane>
        {/* <TabPane
          tab={
            <>
              <Space>
                <span>
                  <SolutionOutlined />
                  Estado de Cuenta
                </span>
                <Badge
                  overflowCount={99}
                  count={0}
                  style={{ marginTop: -5, backgroundColor: 'orange' }}
                />
              </Space>
            </>
          }
          key='Estado de Cuenta'
        >
          <NotificacionesEstadoDeCuenta />
        </TabPane> */}
      </Tabs>
    </>
  );
};
export default ModuloNotificaciones;
