import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { path } from '../../../routes';
import Swal from 'sweetalert2';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import {
  Row,
  Col,
  Input,
  Tooltip,
  Form,
  Button,
  Select,
  notification,
  Divider,
} from 'antd';
import {
  CaretRightOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
  BankOutlined,
  KeyOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import './agregarEmpresas.css';
const { TextArea } = Input;
const { Option } = Select;

const AgregarUsuarios = (props) => {
  //Usuario logueado en el sistema
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  const history = useHistory();
  const [prefijoPais, setPrefijoPais] = useState(null);
  const [deshabilitarGuardar, setDeshabilitarGuardar] = useState(false);
  let formAgregarEmpresa = useRef();
  let inputContactoEmergencia = useRef();
  let PrefijosArray = [];

  props.paises.map((elemento) =>
    PrefijosArray.push({
      id: elemento.ID_Pais,
      prefijo: elemento.Prefijo_Telefonico,
    }),
  );

  const buscarPrefijo = async (id) => {
    const elemento = await PrefijosArray.find((pais) => pais.id === id);

    return setPrefijoPais(elemento.prefijo);
  };

  useEffect(() => {
    if (inputContactoEmergencia.current !== undefined) {
      formAgregarEmpresa.current.setFieldsValue({
        telefono: prefijoPais,
        contactoEmergencia: prefijoPais,
      });
    }
  }, [prefijoPais]);

  const menuPaises = (
    <Select
      suffixIcon={<CaretRightOutlined rotate={90} />}
      placeholder='País'
      onChange={buscarPrefijo}
    >
      {props.paises.map((elemento) => (
        <Option key={elemento.ID_Pais} value={elemento.ID_Pais}>
          {elemento.Nombre}
        </Option>
      ))}
    </Select>
  );

  //-------------USEEFFECTS PARA AGREGAR EMPRESA--------------------------------
  useEffect(() => {
    setDeshabilitarGuardar(false);
  }, [props.Agregar_Empresa_Status]);

  useEffect(() => {
    if (props.Agregar_Empresa_Status === 200) {
      setDeshabilitarGuardar(true);
      history.push({ pathname: path.consultarEmpresas });
    } else if (
      props.Agregar_Empresa_Status === 400 ||
      props.Agregar_Empresa_Status === 404 ||
      props.Agregar_Empresa_Status === 500
    ) {
      setDeshabilitarGuardar(true);
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    }
  }, [props.Agregar_Empresa_Status]);

  //--------------------Funciones de agregar empresa-----------------
  //No se permite crear una empresa si no tiene al menos un módulo asociado a ella
  //Por cuestiones de tiempo y seguir con los sprints, se soluciona la validación de los
  //campos de los módulos que no permitan vacíos ni espacios en blanco para crear la empresa, pero
  //esta validación puede mejorar sustancialmente
  const handleSubmitAgregarEmpresa = (values) => {
    if (
      values.codigoUnicoControlEnvases === undefined &&
      values.codigoUnicoInventario === undefined &&
      values.codigoUnicoConsumo === undefined &&
      values.codigoUnicoFacturacion === undefined
    ) {
      Swal.fire(
        'Atención',
        '¡La empresa debe tener al menos un módulo asociado!',
        'info',
      );
    } else {
      if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoControlEnvases.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoInventario.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoConsumo.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (values.codigoUnicoFacturacion.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoControlEnvases.trim() === '' &&
          values.codigoUnicoInventario.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoControlEnvases.trim() === '' &&
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (
          values.codigoUnicoConsumo.trim() === '' &&
          values.codigoUnicoFacturacion.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === '' &&
          values.codigoUnicoFacturacion.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases.trim() === '' &&
        values.codigoUnicoInventario.trim() === '' &&
        values.codigoUnicoConsumo.trim() === '' &&
        values.codigoUnicoFacturacion.trim() === ''
      ) {
        Swal.fire(
          'Atención',
          '¡La empresa debe tener al menos un módulo asociado!',
          'info',
        );
      } else {
        const Empresa = {
          Nombre: values.nombre,
          Descripcion: values.descripcion,
          Activo: true,
          Pais: values.pais,
          Direccion: values.direccion,
          Telefono: values.telefono,
          Web_site: values.website,
          Contacto_Emergencia: values.contactoEmergencia,
          providers: [
            {
              providerId: 1,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoControlEnvases,
            },
            {
              providerId: 2,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoInventario,
            },
            {
              providerId: 3,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoFacturacion,
            },
            {
              providerId: 4,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoConsumo,
            },
          ],
        };
        props.onGetEmpresasLoading();
        props.onPostEmpresas(usuario.usuario.unique_name, Empresa);
      }
    }
  };

  //------------------------Col Form Item Responsive----------------------------
  const FormItemResponsive = (props) => {
    return (
      <Col
        lg={{ offset: 0, span: 21 }}
        md={{ offset: 0, span: 21 }}
        sm={{ offset: 0, span: 21 }}
        xs={{ offset: 0, span: 21 }}
      >
        {props.FormItem}
      </Col>
    );
  };

  return (
    <>
      {/* Pantalla de agregar empresa */}
      <Form
        name='Agregar_Empresa_Form'
        onFinish={handleSubmitAgregarEmpresa}
        ref={formAgregarEmpresa}
      >
        <Row justify='end' style={{ marginTop: '2%' }}>
          <Col
            lg={{ offset: 0, span: 4 }}
            md={{ offset: 0, span: 6 }}
            sm={{ offset: 0, span: 10 }}
            xs={{ offset: 0, span: 13 }}
          >
            <Button
              disabled={deshabilitarGuardar}
              htmlType='submit'
              className='botonGuardarUsuario'
            >
              Guardar
            </Button>
          </Col>

          <Col
            style={{ marginLeft: 20 }}
            lg={{ offset: 0, span: 4 }}
            md={{ offset: 0, span: 6 }}
            sm={{ offset: 0, span: 10 }}
            xs={{ offset: 0, span: 13 }}
          >
            <Button
              className='botonCancelarEmpresas'
              onClick={() => history.push({ pathname: path.consultarEmpresas })}
            >
              Cancelar
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            background: '#81973a',
            borderRadius: 5,
            marginBottom: '2%',
            marginTop: '2%',
          }}
        >
          <Col style={{ color: 'white', marginLeft: '1%' }}>
            Información de la Empresa
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='nombre'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 100,
                      message: 'Mínimo 1 carácter, máximo 100 carácteres',
                    },
                  ]}
                >
                  <Input
                    placeholder='Nombre'
                    prefix={<BankOutlined />}
                    suffix={
                      <Tooltip
                        title='Nombre de la Empresa'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='descripcion'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 200,
                      message: 'Mínimo 1 carácter, máximo 200 carácteres',
                    },
                  ]}
                >
                  <TextArea rows={3} placeholder='Descripción' />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='direccion'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 200,
                      message: 'Mínimo 1 carácter, máximo 200 carácteres',
                    },
                  ]}
                >
                  <TextArea rows={3} placeholder='Dirección de la empresa' />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  validateTrigger='onSubmit'
                  name='telefono'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 20,
                      message: 'Mínimo 1 carácter, máximo 20 carácteres',
                    },
                    {
                      pattern: /^([0-9])*$/,
                      message: 'Solo se admiten números',
                    },
                  ]}
                >
                  <Input
                    placeholder='Teléfono'
                    prefix={<PhoneOutlined />}
                    suffix={
                      <Tooltip
                        title='Teléfono de la empresa'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='website'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 100,
                      message: 'Mínimo 1 carácter, máximo 100 carácteres',
                    },
                  ]}
                >
                  <Input
                    placeholder='Sitio Web'
                    prefix={<LinkOutlined />}
                    suffix={
                      <Tooltip
                        title='Sitio Web de la Empresa'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  validateTrigger='onSubmit'
                  name='contactoEmergencia'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    // {
                    //   min: 1,
                    //   max: 20,
                    //   message: 'Mínimo 1 carácter, máximo 20 carácteres',
                    // },
                    // {
                    //   pattern: /^([0-9])*$/,
                    //   message: 'Solo se admiten números',
                    // },
                  ]}
                >
                  <Input
                    ref={inputContactoEmergencia}
                    placeholder='Contacto Emergencia'
                    prefix={<PhoneOutlined />}
                    suffix={
                      <Tooltip
                        title='Contacto en caso de emergencia'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='pais'
                  rules={[
                    { required: true, message: '¡Este campo es requerido!' },
                  ]}
                >
                  {menuPaises}
                </Form.Item>
              }
            />

            <FormItemResponsive
              FormItem={
                <Divider
                  orientation='left'
                  style={{
                    marginTop: '-1%',
                    backgroundColor: '#81973a',
                    color: 'white',
                    borderRadius: 5,
                  }}
                >
                  Control de Envases
                </Divider>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item name='codigoUnicoControlEnvases'>
                  <Input
                    type='text'
                    placeholder='Código único'
                    prefix={<KeyOutlined />}
                    suffix={
                      <Tooltip
                        title='Código de la empresa'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />

            <FormItemResponsive
              FormItem={
                <Divider
                  orientation='left'
                  style={{
                    marginTop: '-1%',
                    backgroundColor: '#81973a',
                    color: 'white',
                    borderRadius: 5,
                  }}
                >
                  Inventario
                </Divider>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item name='codigoUnicoInventario'>
                  <Input
                    type='text'
                    placeholder='Código único'
                    prefix={<KeyOutlined />}
                    suffix={
                      <Tooltip
                        title='Código de la empresa'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />

            <FormItemResponsive
              FormItem={
                <Divider
                  orientation='left'
                  style={{
                    marginTop: '-1%',
                    backgroundColor: '#81973a',
                    color: 'white',
                    borderRadius: 5,
                  }}
                >
                  Consumo
                </Divider>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item name='codigoUnicoConsumo'>
                  <Input
                    type='text'
                    placeholder='Código único'
                    prefix={<KeyOutlined />}
                    suffix={
                      <Tooltip
                        title='Código de la empresa'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />

            <FormItemResponsive
              FormItem={
                <Divider
                  orientation='left'
                  style={{
                    marginTop: '-1%',
                    backgroundColor: '#81973a',
                    color: 'white',
                    borderRadius: 5,
                  }}
                >
                  Facturación / Estado de Cuenta
                </Divider>
              }
            />

            <FormItemResponsive
              FormItem={
                <Form.Item name='codigoUnicoFacturacion'>
                  <Input
                    type='text'
                    placeholder='Código único'
                    prefix={<KeyOutlined />}
                    suffix={
                      <Tooltip
                        title='Código de la empresa'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default AgregarUsuarios;
