import actions from '../actions';

const roles = actions.roles;

const initialState = {
  persona: [],
  roles: [],
  rolesError: '',
};

function rolesApp(state = initialState, action) {
  switch (action.type) {
    case roles.actionsType.GET_ROLES_SUCCESS:
      return roles.action.getRolesAction(state, action.data);
    case roles.actionsType.GET_ROLES_FAILURE:
      return {
        ...state,
        persona: [],
        roles: [],
        rolesError: 'No se pudo recuperar la información',
      };
    case roles.actionsType.GETALL_ROLES_SUCCESS:
      return roles.action.getAllRolesAction(state, action.data);
    case roles.actionsType.GETALL_ROLES_FAILURE:
      return {
        ...state,
        persona: [],
        roles: [],
        rolesError: 'No se pudo recuperar la información',
      };
    case roles.actionsType.POST_ROLES_SUCCESS:
      return roles.action.postRolesAction(state, action.data);
    case roles.actionsType.POST_ROLES_FAILURE:
      return {
        ...state,
        persona: [],
        roles: [],
        rolesError: 'No se pudo recuperar la información',
      };
    case roles.actionsType.PUT_ROLES_SUCCESS:
      return roles.action.putRolesAction(state, action.data);
    case roles.actionsType.PUT_ROLES_FAILURE:
      return {
        ...state,
        persona: [],
        roles: [],
        rolesError: 'No se pudo recuperar la información',
      };
    case roles.actionsType.DELETE_ROLES_SUCCESS:
      return roles.action.deleteRolesAction(state, action.data);
    case roles.actionsType.DELETE_ROLES_FAILURE:
      return {
        ...state,
        persona: [],
        roles: [],
        rolesError: 'No se pudo recuperar la información',
      };
    default:
      return { ...state };
  }
}

export default rolesApp;
