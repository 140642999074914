import React from 'react';
import Instagram from '../../assets/icono-insta.png';
import Facebook from '../../assets/icono-fb.png';
import LinkedIn from '../../assets/icono-lkin.png';
import YouTube from '../../assets/icono-yt.png';
import Gaspro from '../../assets/icono_web.png';
import { Col, Row, Divider, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './socialMedia.css';

const SocialMedia = () => {
  const menu = (
    <Menu>
      <Menu.Item key='0'>
        <a
          className='LinkPais'
          href='https://gaspro.com/contacto-panama/'
          target='blank'
        >
          Panamá
        </a>
      </Menu.Item>
      <Menu.Item key='1'>
        <a
          className='LinkPais'
          href='https://gaspro.com/contacto-el-salvador/'
          target='blank'
        >
          El Salvador
        </a>
      </Menu.Item>
      <Menu.Item key='3'>
        <a
          className='LinkPais'
          href='https://gaspro.com/contacto-guatemala/'
          target='blank'
        >
          Guatemala
        </a>
      </Menu.Item>
      <Menu.Item key='4'>
        <a
          className='LinkPais'
          href='https://gaspro.com/contacto-nicaragua/'
          target='blank'
        >
          Nicaragua
        </a>
      </Menu.Item>
      <Menu.Item key='5'>
        <a
          className='LinkPais'
          href='https://gaspro.com/contacto-honduras/'
          target='blank'
        >
          Honduras
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Row justify='end' style={{ marginTop: '2%' }}>
        <Col>
          <a href='https://gaspro.com/' target='blank' title='Gaspro'>
            <img src={Gaspro} alt='Gaspro' width={23} height={23} />
          </a>
        </Col>
        <Divider type='vertical' style={{ visibility: 'hidden' }} />
        <Col>
          <a
            href='https://www.instagram.com/gasprolatinoamerica/?hl=es-la'
            target='blank'
            title='Instagram'
          >
            <img src={Instagram} alt='IG' width={22} height={22} />
          </a>
        </Col>
        <Divider type='vertical' style={{ visibility: 'hidden' }} />
        <Col>
          <a
            href='https://www.facebook.com/gasprolatinoamerica/?ref=bookmarks'
            target='blank'
            title='Facebook'
          >
            <img src={Facebook} alt='Fb' width={20} height={20} />
          </a>
        </Col>
        <Divider type='vertical' style={{ visibility: 'hidden' }} />
        <Col>
          <a
            href='https://www.linkedin.com/company/gasproca/'
            target='blank'
            title='LinkedIn'
          >
            <img src={LinkedIn} alt='Fb' width={20} height={20} />
          </a>
        </Col>
        <Divider type='vertical' style={{ visibility: 'hidden' }} />
        <Col>
          <a
            href='https://www.youtube.com/channel/UCVFXoIp0tmEEQLB-9Wrg8xw'
            target='blank'
            title='YouTube'
          >
            <img src={YouTube} alt='Fb' width={25} height={25} />
          </a>
        </Col>
        <Col>
          <Divider
            type='vertical'
            style={{ borderColor: 'white', height: '100%' }}
          />
        </Col>
        <Col style={{ color: 'white', fontSize: 17 }}>
          <Dropdown overlay={menu} trigger={['click']}>
            <a
              className='ant-dropdown-link'
              onClick={(e) => e.preventDefault()}
            >
              Contáctenos <DownOutlined />
            </a>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};
export default SocialMedia;
