import actionsType from './actionsTypes';

function getProveedoresSuccess(data) {
  return { type: actionsType.GET_PROVEEDORES_SUCCESS, data: data };
}
function getProveedoresFailure(error) {
  return { type: actionsType.GET_PROVEEDORES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getAllProveedoresSuccess(data) {
  return { type: actionsType.GETALL_PROVEEDORES_SUCCESS, data: data };
}
function getAllProveedoresFailure(error) {
  return { type: actionsType.GETALL_PROVEEDORES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getProveedorSuccess(data) {
  return { type: actionsType.GET_PROVEEDOR_SUCCESS, data: data };
}
function getProveedorFailure(error) {
  return { type: actionsType.getProveedorFailure, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function postProveedoresSuccess(data) {
  return { type: actionsType.POST_PROVEEDORES_SUCCESS, data: data };
}
function postProveedoresFailure(error) {
  return { type: actionsType.POST_PROVEEDORES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function putProveedoresSuccess(data) {
  return { type: actionsType.PUT_PROVEEDORES_SUCCESS, data: data };
}
function putProveedoresFailure(error) {
  return { type: actionsType.PUT_PROVEEDORES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function deleteProveedoresSuccess(data) {
  return { type: actionsType.DELETE_PROVEEDORES_SUCCESS, data: data };
}
function deleteProveedoresFailure(error) {
  return { type: actionsType.DELETE_PROVEEDORES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaActualizarProveedor() {
  return { type: actionsType.CERRAR_ALERTA_ACTUALIZAR };
}

function getProveedoresLoading() {
  return { type: actionsType.GET_PROVEEDORES_LOADING };
}

export default {
  getProveedoresSuccess: getProveedoresSuccess,
  getProveedoresFailure: getProveedoresFailure,
  getAllProveedoresSuccess: getAllProveedoresSuccess,
  getAllProveedoresFailure: getAllProveedoresFailure,
  getProveedorSuccess: getProveedorSuccess,
  getProveedorFailure: getProveedorFailure,
  postProveedoresSuccess: postProveedoresSuccess,
  postProveedoresFailure: postProveedoresFailure,
  putProveedoresSuccess: putProveedoresSuccess,
  putProveedoresFailure: putProveedoresFailure,
  deleteProveedoresSuccess: deleteProveedoresSuccess,
  deleteProveedoresFailure: deleteProveedoresFailure,
  cerrarAlertaActualizarProveedor: cerrarAlertaActualizarProveedor,
  getProveedoresLoading: getProveedoresLoading,
};
