import actions from '../actions';

const usuarios = actions.usuarios;

const initialState = {
  persona: [],
  usuarios: [],
  usuariosError: '',
  Agregar_Usuario_Status: null,
  Actualizar_Usuario_Status: null,
  ActivarDesactivar_Usuario_Status: null,
  loading: false,
};

function usuariosApp(state = initialState, action) {
  switch (action.type) {
    case usuarios.actionsType.GET_USUARIOS_SUCCESS:
      return usuarios.action.getUsuariosAction(state, action.data);
    case usuarios.actionsType.GET_USUARIOS_FAILURE:
      return {
        ...state,
        loading: false,
        persona: [],
        usuarios: [],
        usuariosError: 'No se pudo recuperar la información',
      };
    case usuarios.actionsType.GETALL_USUARIOS_SUCCESS:
      return usuarios.action.getAllUsuariosAction(state, action.data);
    case usuarios.actionsType.GETALL_USUARIOS_FAILURE:
      return {
        ...state,
        loading: false,
        persona: [],
        usuarios: [],
        usuariosError: 'No se pudo recuperar la información',
      };
    case usuarios.actionsType.POST_USUARIOS_SUCCESS:
      return usuarios.action.postUsuariosAction(state, action);
    case usuarios.actionsType.POST_USUARIOS_FAILURE:
      return {
        ...state,
        loading: false,
        Agregar_Usuario_Status: action.errorMessage.response.status,
      };
    case usuarios.actionsType.PUT_USUARIOS_SUCCESS:
      return usuarios.action.putUsuariosAction(state, action);
    case usuarios.actionsType.PUT_USUARIOS_FAILURE:
      return {
        ...state,
        loading: false,
        Actualizar_Usuario_Status: action.errorMessage.response.status,
      };
    case usuarios.actionsType.DELETE_USUARIOS_SUCCESS:
      return usuarios.action.deleteUsuariosAction(state, action);
    case usuarios.actionsType.DELETE_USUARIOS_FAILURE:
      return {
        ...state,
        loading: false,
        ActivarDesactivar_Usuario_Status: action.errorMessage.response.status,
      };
    case usuarios.actionsType.CERRAR_ALERTA_AGREGAR:
      return usuarios.action.cerrarAlertaAgregar(state);
    case usuarios.actionsType.CERRAR_ALERTA_ACTIVARINACTIVAR:
      return usuarios.action.cerrarAlertaActivarInactivar(state);
    case usuarios.actionsType.CERRAR_ALERTA_ACTUALIZAR:
      return usuarios.action.cerrarAlertaActualizar(state);
    case usuarios.actionsType.GET_USUARIOS_LOADING:
      return usuarios.action.getUsuariosLoading(state);
    case usuarios.actionsType.UNGET_USUARIOS_LOADING:
      return usuarios.action.ungetUsuariosLoading(state);
    default:
      return { ...state };
  }
}

export default usuariosApp;
