import React, { useEffect } from 'react';
import { Card, Tag, Row, Col, notification, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import Actions from '../../data/actions';
import { path } from '../../routes';

const CardsNotificaciones = (props) => {
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let { key, Notificacion, setOpenDrawer } = props;
  let dispatch = useDispatch();
  let history = useHistory();

  const Marcar_Notificacion_Leida_Status = useSelector(
    (state) => state.notificaciones.Marcar_Notificacion_Leida_Status,
  );
  const ContarNoLeidas = useSelector(
    (state) => state.notificaciones.ContarNoLeidas,
  );
  const FechaInicio = useSelector((state) => state.notificaciones.FechaInicio);
  const FechaFin = useSelector((state) => state.notificaciones.FechaFin);

  useEffect(() => {
    if (Marcar_Notificacion_Leida_Status === 200 && !ContarNoLeidas) {
      dispatch(
        Actions.notificaciones.calls.getAllNotificacionesNuevas(
          usuario.usuario.Compania,
        ),
      );
      dispatch(
        Actions.notificaciones.calls.getAllNotificacionesNuevasConsumo(
          usuario.usuario.Compania,
        ),
      );
      dispatch(
        Actions.notificaciones.calls.getAllNotificaciones(
          usuario.usuario.Compania,
        ),
      );
      dispatch(
        Actions.sideBar.calls.postOpcionActiva(path.moduloNotificaciones),
      );
      history.push({ pathname: path.moduloNotificaciones });
    } else if (Marcar_Notificacion_Leida_Status === 200 && ContarNoLeidas) {
      dispatch(
        Actions.notificaciones.calls.getAllNotificacionesConFecha({
          IdEmpresa: usuario.usuario.Compania,
          FechaInicio: FechaInicio,
          FechaFin: FechaFin,
        }),
      );
      dispatch(
        Actions.sideBar.calls.postOpcionActiva(path.moduloNotificaciones),
      );
      history.push({ pathname: path.moduloNotificaciones });
    } else if (
      Marcar_Notificacion_Leida_Status === 400 ||
      Marcar_Notificacion_Leida_Status === 404
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes en la petición.',
        duration: 3,
        onClose: () => {
          dispatch(
            Actions.notificaciones.calls.cerrarAlertaObtenerNotificaciones(),
          );
        },
      });
    } else if (
      Marcar_Notificacion_Leida_Status === 500 ||
      Marcar_Notificacion_Leida_Status === 503
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que el servidor está fuera de servicio.',
        duration: 3,
        onClose: () => {
          dispatch(
            Actions.notificaciones.calls.cerrarAlertaObtenerNotificaciones(),
          );
        },
      });
    }
  }, [Marcar_Notificacion_Leida_Status]);
  return (
    <>
      <Card
        key={key}
        onClick={() => {
          setOpenDrawer(false);
          dispatch(
            Actions.notificaciones.calls.establecerIdDeNotificacionSeleccionada(
              Notificacion.key,
            ),
          );
          dispatch(
            Actions.notificaciones.calls.marcarNotificacionLeida({
              IdNotificacion: Notificacion.key,
            }),
          );
          dispatch(
            Actions.notificaciones.calls.establecerTabActivo(
              `${Notificacion.Modulo}`,
            ),
          );
        }}
        style={{ marginBottom: '5%', borderColor: 'darkgreen' }}
        title={
          <>
            <Row>
              <Col
                lg={{ span: 16 }}
                md={{ span: 16 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <span>{Notificacion.Modulo}</span>
              </Col>
              <Col
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Typography.Text style={{ marginRight: '2%', fontSize: 13 }}>
                  {Notificacion.Fecha}
                </Typography.Text>
                <Tag
                  color={
                    Notificacion.Categoría === 'Urgente' ? 'error' : 'warning'
                  }
                  style={{ float: 'right' }}
                >
                  {Notificacion.Categoría}
                </Tag>
              </Col>
            </Row>
          </>
        }
      >
        {Notificacion.Mensaje}
      </Card>
    </>
  );
};
export default CardsNotificaciones;
