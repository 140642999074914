import actions from '../actions';

const facturacion = actions.facturacion;

const initialState = {
  facturas: [],
  facturasError: '',
  loading: false,
  Obtener_Facturas_Status: null,
};

function facturacionApp(state = initialState, action) {
  switch (action.type) {
    case facturacion.actionsType.GET_FACTURAS_SUCCESS:
      return facturacion.action.getFacturasAction(state, action.data);
    case facturacion.actionsType.GET_FACTURAS_FAILURE:
      return {
        ...state,
        loading: false,
        Obtener_Facturas_Status: action.errorMessage.response.status,
      };
    case facturacion.actionsType.GET_FACTURAS_LOADING:
      return facturacion.action.getFacturasLoading(state);
    case facturacion.actionsType.CERRAR_ALERTA_OBTENER_FACTURAS:
      return facturacion.action.cerrarAlertaObtenerFacturas(state);

    default:
      return { ...state };
  }
}

export default facturacionApp;
