import actionsType from './actionsTypes';

function getConsumoSuccess(data) {
  return { type: actionsType.GET_CONSUMO_SUCCESS, data: data };
}
function getConsumoFailure(error) {
  return { type: actionsType.GET_CONSUMO_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getConsumoLoading() {
  return { type: actionsType.GET_CONSUMO_LOADING };
}

export default {
  getConsumoSuccess: getConsumoSuccess,
  getConsumoFailure: getConsumoFailure,
  getConsumoLoading: getConsumoLoading,
};
