import sing from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

function postOpcionActiva(state, Opcion) {
  return {
    ...state,
    Opcion_Activa: Opcion.data,
  };
}

export default {
  actionsType: actionsType,
  sign: sing,
  action: {
    postOpcionActiva: postOpcionActiva,
  },
  calls: calls,
};
