import axios from 'axios';
import { decrypt } from "react-crypt-gsm";

const apiUrl = 'https://gasproconnect.com/';
//const apiUrl = 'https://web-gpc-dev.azurewebsites.net/';
// const apiUrl = 'https://web-gpc-qa.azurewebsites.net/';
//const apiUrl = 'https://localhost:44340/';

const api = axios.create({
  baseURL: apiUrl,
});

var Token = sessionStorage.getItem("TokenUsuario");
  Token = JSON.parse(Token);
  let TokenDecrypted = ''
  if(Token !== null){
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
   TokenDecrypted = decrypt(encrypted);
  }

api.defaults.headers.common['Content-Type'] = 'application/json';
api.defaults.headers.common['Authorization'] = `Bearer ${TokenDecrypted}`;


export default api;
