const actionsType = {
  GET_ESTADODECUENTA_SUCCESS: 'GET_ESTADODECUENTA_SUCCESS',
  GET_ESTADODECUENTA_FAILURE: 'GET_ESTADODECUENTA_FAILURE',

  CERRAR_ALERTA_ESTADODECUENTA: 'CERRAR_ALERTA_ESTADODECUENTA',

  ABRIR_MODAL_ESTADODECUENTA: 'ABRIR_MODAL_ESTADODECUENTA',
  CERRAR_MODAL_ESTADODECUENTA: 'CERRAR_MODAL_ESTADODECUENTA',

  GET_ESTADODECUENTA_LOADING: 'GET_ESTADODECUENTA_LOADING',
};

export default actionsType;
