import signs from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

function getComprobanteAction(state, response) {
  return {
    ...state,
    datos: response.data.data,
    verComprobanteStatus: response.data.status,
    loading: false,
  };
}

function setCodigoClienteAction(state, response) {
  return {
    ...state,
    codigoCliente: response,
  };
}

function getComprobanteLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

export default {
  actionsType: actionsType,
  sign: signs,
  action: {
    getComprobanteAction: getComprobanteAction,
    setCodigoClienteAction: setCodigoClienteAction,
    getComprobanteLoading: getComprobanteLoading,
  },
  calls: calls,
};
