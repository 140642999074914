import actionsType from './actionsTypes';

function getNotificacionesSuccess(data) {
  return { type: actionsType.GET_NOTIFICACIONES_SUCCESS, data: data };
}
function getNotificacionesFailure(error) {
  return { type: actionsType.GET_NOTIFICACIONES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getNotificacionesConFechaSuccess(data) {
  return { type: actionsType.GET_NOTIFICACIONES_CON_FECHA_SUCCESS, data: data };
}
function getNotificacionesConFechaFailure(error) {
  return {
    type: actionsType.GET_NOTIFICACIONES_CON_FECHA_FAILURE,
    errorMessage: error,
  };
}
//--------------------------------------------------------------------------------------
function getNotificacionesNuevasSuccess(data) {
  return { type: actionsType.GET_NOTIFICACIONES_NUEVAS_SUCCESS, data: data };
}
function getNotificacionesNuevasFailure(error) {
  return {
    type: actionsType.GET_NOTIFICACIONES_NUEVAS_FAILURE,
    errorMessage: error,
  };
}
//--------------------------------------------------------------------------------------
function getNotificacionesNuevasConsumoSuccess(data) {
  return { type: actionsType.GET_NOTIFICACIONES_NUEVAS_CONSUMO_SUCCESS, data: data };
}
function getNotificacionesNuevasConsumoFailure(error) {
  return {
    type: actionsType.GET_NOTIFICACIONES_NUEVAS_CONSUMO_FAILURE,
    errorMessage: error,
  };
}
//--------------------------------------------------------------------------------------
function marcarNotificacionLeidaSuccess(data) {
  return { type: actionsType.MARCAR_NOTIFICACION_LEIDA_SUCCESS, data: data };
}
function marcarNotificacionLeidaFailure(error) {
  return {
    type: actionsType.MARCAR_NOTIFICACION_LEIDA_FAILURE,
    errorMessage: error,
  };
}
//--------------------------------------------------------------------------------------
function marcarNotificacionLeidaPanelSuccess(data) {
  return {
    type: actionsType.MARCAR_NOTIFICACION_LEIDA_PANEL_SUCCESS,
    data: data,
  };
}
function marcarNotificacionLeidaPanelFailure(error) {
  return {
    type: actionsType.MARCAR_NOTIFICACION_LEIDA_PANEL_FAILURE,
    errorMessage: error,
  };
}
//--------------------------------------------------------------------------------------
function getNotificacionesLoading() {
  return { type: actionsType.GET_NOTIFICACIONES_LOADING };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaObtenerNotificaciones() {
  return { type: actionsType.CERRAR_ALERTA_OBTENER_NOTIFICACIONES };
}
//--------------------------------------------------------------------------------------
function establecerTabActivo(data) {
  return { type: actionsType.ESTABLECER_TAB_ACTIVO, data: data };
}
//--------------------------------------------------------------------------------------
function establecerIdDeNotificacionSeleccionada(data) {
  return {
    type: actionsType.ESTABLECER_ID_DE_NOTIFICACION_SELECCIONADA,
    data: data,
  };
}
//--------------------------------------------------------------------------------------
function limpiarIdDeNotificacionSeleccionada() {
  return { type: actionsType.LIMPIAR_ID_DE_NOTIFICACION_SELECCIONADA };
}
//--------------------------------------------------------------------------------------
function contarNotificacionesNoLeidas(data) {
  return { type: actionsType.CONTAR_NOTIFICACIONES_NO_LEIDAS, data: data };
}
//--------------------------------------------------------------------------------------
function filtrandoFechas(data) {
  return { type: actionsType.FILTRANDO_FECHAS, data: data };
}

export default {
  getNotificacionesSuccess: getNotificacionesSuccess,
  getNotificacionesFailure: getNotificacionesFailure,
  getNotificacionesConFechaSuccess: getNotificacionesConFechaSuccess,
  getNotificacionesConFechaFailure: getNotificacionesConFechaFailure,
  getNotificacionesNuevasSuccess: getNotificacionesNuevasSuccess,
  getNotificacionesNuevasFailure: getNotificacionesNuevasFailure,
  getNotificacionesNuevasConsumoSuccess: getNotificacionesNuevasConsumoSuccess,
  getNotificacionesNuevasConsumoFailure: getNotificacionesNuevasConsumoFailure,
  marcarNotificacionLeidaSuccess: marcarNotificacionLeidaSuccess,
  marcarNotificacionLeidaFailure: marcarNotificacionLeidaFailure,
  marcarNotificacionLeidaPanelSuccess: marcarNotificacionLeidaPanelSuccess,
  marcarNotificacionLeidaPanelFailure: marcarNotificacionLeidaPanelFailure,
  getNotificacionesLoading: getNotificacionesLoading,
  cerrarAlertaObtenerNotificaciones: cerrarAlertaObtenerNotificaciones,
  establecerTabActivo: establecerTabActivo,
  establecerIdDeNotificacionSeleccionada: establecerIdDeNotificacionSeleccionada,
  limpiarIdDeNotificacionSeleccionada: limpiarIdDeNotificacionSeleccionada,
  contarNotificacionesNoLeidas: contarNotificacionesNoLeidas,
  filtrandoFechas: filtrandoFechas,
};
