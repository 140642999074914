import sing from './sings';
import calls from './calls';
import actionsType from './actionsTypes';

function getRolesAction(state, response) {
  return {
    ...state,
    rol: response,
    rolesError: '',
  };
}
function getAllRolesAction(state, response) {
  return {
    ...state,
    roles: response,
    rolesError: '',
  };
}

function postRolesAction(state, response) {
  // let newUsuario = [
  //   ...state.usuario,
  //   { id: state.usuario.length, text: response },
  // ];
  return {
    ...state,
  };
}

function putRolesAction(state, response) {
  return {
    ...state,
  };
}

function deleteRolesAction(state, response) {
  return {
    ...state,
    rol: response,
  };
}

export default {
  actionsType: actionsType,
  sign: sing,
  action: {
    getRolesAction: getRolesAction,
    getAllRolesAction: getAllRolesAction,
    postRolesAction: postRolesAction,
    putRolesAction: putRolesAction,
    deleteRolesAction: deleteRolesAction,
  },
  calls: calls,
};
