import React, { Component } from 'react';
import Home from '../../components/home/home';
import { connect } from 'react-redux';
import actions from '../../data/actions';
import Loading from '../../components/loading/loading';

class home extends Component {
  render() {
    return <>{this.props.loading ? <Loading /> : <Home {...this.props} />}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login.login,
    loginError: state.login.loginError,
    loading: state.login.loading,
    Login_Status: state.login.Login_Status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (data) => dispatch(actions.login.calls.login(data)),
    startLoading: () => dispatch(actions.login.calls.startLoading()),
    onLimpiarStateLogin: () => dispatch(actions.login.calls.limpiarState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(home);
