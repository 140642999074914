const actionsType = {
  GET_EMPRESAS_SUCCESS: 'GET_EMPRESAS_SUCCESS',
  GET_EMPRESAS_FAILURE: 'GET_EMPRESAS_FAILURE',

  GETALL_EMPRESAS_SUCCESS: 'GETALL_EMPRESAS_SUCCESS',
  GETALL_EMPRESAS_FAILURE: 'GETALL_EMPRESAS_FAILURE',

  POST_EMPRESAS_SUCCESS: 'POST_EMPRESAS_SUCCESS',
  POST_EMPRESAS_FAILURE: 'POST_EMPRESAS_FAILURE',

  PUT_EMPRESAS_SUCCESS: 'PUT_EMPRESAS_SUCCESS',
  PUT_EMPRESAS_FAILURE: 'PUT_EMPRESAS_FAILURE',

  DELETE_EMPRESAS_SUCCESS: 'DELETE_EMPRESAS_SUCCESS',
  DELETE_EMPRESAS_FAILURE: 'DELETE_EMPRESAS_FAILURE',

  //Cerrar alerta de eliminar empresa
  CERRAR_ALERTA_ELIMINAR: 'CERRAR_ALERTA_ELIMINAR',
  //Cerrar alerta de agregar empresa
  CERRAR_ALERTA_AGREGAR: 'CERRAR_ALERTA_AGREGAR',
  //Cerrar alerta de actualizar empresa
  CERRAR_ALERTA_ACTUALIZAR: 'CERRAR_ALERTA_ACTUALIZAR',

  GET_EMPRESAS_LOADING: 'GET_EMPRESAS_LOADING',

  GET_PAISES_SUCCESS: 'GET_PAISES_SUCCESS',
  GET_PAISES_FAILURE: 'GET_PAISES_FAILURE',
};

export default actionsType;
