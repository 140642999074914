import React, { Component } from 'react';
import ConsultarEmpresas from '../../../components/gestionDeEmpresas/consultarEmpresas/consultarEmpresas';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import Loading from '../../../components/loading/loading';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';

class consultarEmpresas extends Component {
  componentDidMount() {
    //Usuario logueado en el sistema
    var Token = sessionStorage.getItem('TokenUsuario');
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };

    this.props.onGetEmpresasLoading();
    this.props.onGetAllEmpresas(usuario.usuario.authmethod);
    this.props.onGetAllPaises();
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <ConsultarEmpresas {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    empresa: state.empresas.empresa,
    empresas: state.empresas.empresas,
    paises: state.empresas.paises,
    empresasError: state.empresas.empresasError,
    Agregar_Empresa_Status: state.empresas.Agregar_Empresa_Status,
    Actualizar_Empresa_Status: state.empresas.Actualizar_Empresa_Status,
    Eliminar_Empresa_Status: state.empresas.Eliminar_Empresa_Status,
    loading: state.empresas.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmpresa: (id) => dispatch(actions.empresas.calls.getEmpresasById(id)),
    onGetAllEmpresas: (id) =>
      dispatch(actions.empresas.calls.getAllEmpresas(id)),
    onGetAllPaises: () => dispatch(actions.empresas.calls.getAllPaises()),
    onPostEmpresas: (nombre, data) =>
      dispatch(actions.empresas.calls.postEmpresas(nombre, data)),
    onPutEmpresas: (empresa) =>
      dispatch(actions.empresas.calls.putEmpresas(empresa)),
    onDeleteEmpresas: (id, nombre) =>
      dispatch(actions.empresas.calls.deleteEmpresas(id, nombre)),
    cerrarAlertaEliminar: () =>
      dispatch(actions.empresas.calls.cerrarAlertaEliminar()),
    cerrarAlertaAgregar: () =>
      dispatch(actions.empresas.calls.cerrarAlertaAgregar()),
    cerrarAlertaActualizar: () =>
      dispatch(actions.empresas.calls.cerrarAlertaActualizar()),
    onGetEmpresasLoading: () =>
      dispatch(actions.empresas.calls.getEmpresasLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(consultarEmpresas);
