const actionsType = {
  GET_USUARIOS_SUCCESS: 'GET_USUARIOS_SUCCESS',
  GET_USUARIOS_FAILURE: 'GET_USUARIOS_FAILURE',

  GETALL_USUARIOS_SUCCESS: 'GETALL_USUARIOS_SUCCESS',
  GETALL_USUARIOS_FAILURE: 'GETALL_USUARIOS_FAILURE',

  POST_USUARIOS_SUCCESS: 'POST_USUARIOS_SUCCESS',
  POST_USUARIOS_FAILURE: 'POST_USUARIOS_FAILURE',

  PUT_USUARIOS_SUCCESS: 'PUT_USUARIOS_SUCCESS',
  PUT_USUARIOS_FAILURE: 'PUT_USUARIOS_FAILURE',

  DELETE_USUARIOS_SUCCESS: 'DELETE_USUARIOS_SUCCESS',
  DELETE_USUARIOS_FAILURE: 'DELETE_USUARIOS_FAILURE',

  //Cerrar alerta de activar / inactivar usuario
  CERRAR_ALERTA_ACTIVARINACTIVAR: 'CERRAR_ALERTA_ACTIVARINACTIVAR',
  //Cerrar alerta de agregar usuario
  CERRAR_ALERTA_AGREGAR: 'CERRAR_ALERTA_AGREGAR',
  //Cerrar alerta de actualizar usuario
  CERRAR_ALERTA_ACTUALIZAR: 'CERRAR_ALERTA_ACTUALIZAR',

  GET_USUARIOS_LOADING: 'GET_USUARIOS_LOADING',
  UNGET_USUARIOS_LOADING: 'UNGET_USUARIOS_LOADING',
};

export default actionsType;
