import actionsType from './actionsTypes';

function getRolesSuccess(data) {
  return { type: actionsType.GET_ROLES_SUCCESS, data: data };
}
function getRolesFailure(error) {
  return { type: actionsType.GET_ROLES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getAllRolesSuccess(data) {
  return { type: actionsType.GETALL_ROLES_SUCCESS, data: data };
}
function getAllRolesFailure(error) {
  return { type: actionsType.GETALL_ROLES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function postRolesSuccess(data) {
  return { type: actionsType.POST_ROLES_SUCCESS, data: data };
}
function postRolesFailure(error) {
  return { type: actionsType.POST_ROLES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function putRolesSuccess(data) {
  return { type: actionsType.PUT_ROLES_SUCCESS, data: data };
}
function putRolesFailure(error) {
  return { type: actionsType.PUT_ROLES_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function deleteRolesSuccess(data) {
  return { type: actionsType.DELETE_ROLES_SUCCESS, data: data };
}
function deleteRolesFailure(error) {
  return { type: actionsType.DELETE_ROLES_FAILURE, errorMessage: error };
}

export default {
  getRolesSuccess: getRolesSuccess,
  getRolesFailure: getRolesFailure,
  getAllRolesSuccess: getAllRolesSuccess,
  getAllRolesFailure: getAllRolesFailure,
  postRolesSuccess: postRolesSuccess,
  postRolesFailure: postRolesFailure,
  putRolesSuccess: putRolesSuccess,
  putRolesFailure: putRolesFailure,
  deleteRolesSuccess: deleteRolesSuccess,
  deleteRolesFailure: deleteRolesFailure,
};
