import signs from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

function getFacturasAction(state, response) {
  return {
    ...state,
    facturas: response.data,
    loading: false,
    Obtener_Facturas_Status: response.status,
  };
}

function getFacturasLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function cerrarAlertaObtenerFacturas(state) {
  return {
    ...state,
    Obtener_Facturas_Status: null,
  };
}

export default {
  actionsType: actionsType,
  sign: signs,
  action: {
    getFacturasAction: getFacturasAction,
    getFacturasLoading: getFacturasLoading,
    cerrarAlertaObtenerFacturas: cerrarAlertaObtenerFacturas,
  },
  calls: calls,
};
