import React from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import Logo from '../../../assets/logo-gaspro.png';

// Componente para crear el PDF de Recibo de Entrega
const Comprobante = (props) => {
  let ProductosEntregados = [];
  let ProductosRecibidos = [];
  props.datos.ProductosEntregados.forEach((element) => {
    ProductosEntregados.push(element);
  });
  props.datos.ProductosRecibidos.forEach((element) => {
    ProductosRecibidos.push(element);
  });

  return (
    <>
      <Document title={'Recibo de entrega'}>
        <Page style={styles.page}>
          <Text style={styles.textoFijado} fixed>
            {' '}
          </Text>
          <Image src={Logo} style={styles.logo} />
          <Text style={styles.textCompañia}>{props.datos.Compañia}</Text>
          <Text style={styles.textCompañia}>{props.datos.Dirección}</Text>
          <Text style={styles.textCompañia}>{props.datos.Telefono}</Text>
          <View style={styles.viewRow}>
            <View style={styles.viewIzquierda}>
              <Text>Fecha: {props.datos.Fecha}</Text>
            </View>
            <View style={styles.viewDerecha}>
              <Text> Nº Entrega: {props.datos.NúmeroOrden}</Text>
            </View>
          </View>
          <View style={styles.viewRow}>
            <View style={styles.viewIzquierda}>
              <Text>Nº Viaje: {props.datos.NúmeroOrden}</Text>
            </View>
            <View style={styles.viewDerecha}>
              <Text>Planta de Entrega: {props.datos.Planta}</Text>
            </View>
          </View>
          <View style={styles.divider} />
          <View style={styles.dividerCorto} />
          {/* AGREGAR EL CODIGO DE CLIENTE Y EL NOMBRE DEL CLIENTE */}
          <Text style={styles.textDatos}>
            Cliente Nº: {props.datosCliente.CodigoCliente}{' '}
            {props.datosCliente.NombreCompania}
          </Text>
          <Text style={styles.textDatos}>Entregado a: </Text>
          <Text style={styles.textDatos}>Instrucciones al despachador: </Text>
          <View style={styles.divider} />
          <View style={styles.dividerCorto} />

          {/* SECCIÓN DE PRODUCTOS ENTREGADOS */}
          <View style={styles.viewRow}>
            <View style={styles.viewRowProducto}>
              <Text>Productos Entregados</Text>
            </View>
            <View style={styles.viewRowProductoCantUn}>
              <Text>Cant.</Text>
            </View>
            <View style={styles.viewRowProductoCantUn}>
              <Text>Un.</Text>
            </View>
          </View>

          {ProductosEntregados.map((element) => {
            return (
              <>
                {/* SECCIÓN A GENERAR DE ACUERDO AL ARRAY DE LOS DATOS DESCRIPCIÓN, CANTIDAD Y UNIDAD*/}
                <View style={styles.viewRow}>
                  <View style={styles.viewRowProducto}>
                    <Text>{element.Descripcion}</Text>
                  </View>
                  <View style={styles.viewRowProductoCantUn}>
                    <Text>{element.Cantidad}</Text>
                  </View>
                  <View style={styles.viewRowProductoCantUn}>
                    <Text>{element.Unidad}</Text>
                  </View>
                </View>

                {/* SECCIÓN DE CÓDIGOS DE BARRA */}
                <View style={styles.viewRow}>
                  <Text>Código de barra:</Text>
                </View>
                <View style={styles.viewRow}>
                  {element.CodigosBarras.map((codigoBarra) => {
                    return (
                      <>
                        <Text>{codigoBarra} </Text>
                      </>
                    );
                  })}
                </View>
              </>
            );
          })}

          <View style={styles.divider} />
          <View style={styles.dividerCorto} />

          {/* SECCIÓN DE PRODUCTOS RECIBIDOS */}
          <View style={styles.viewRow}>
            <View style={styles.viewRowProducto}>
              <Text>Productos Recibidos</Text>
            </View>
            <View style={styles.viewRowProductoCantUn}>
              <Text>Cant.</Text>
            </View>
            <View style={styles.viewRowProductoCantUn}>
              <Text>Un.</Text>
            </View>
          </View>

          {ProductosRecibidos.map((element) => {
            return (
              <>
                {/* SECCIÓN A GENERAR DE ACUERDO AL ARRAY DE LOS DATOS DESCRIPCIÓN, CANTIDAD Y UNIDAD*/}
                <View style={styles.viewRow}>
                  <View style={styles.viewRowProducto}>
                    <Text>{element.Descripcion}</Text>
                  </View>
                  <View style={styles.viewRowProductoCantUn}>
                    <Text>{element.Cantidad}</Text>
                  </View>
                  <View style={styles.viewRowProductoCantUn}>
                    <Text>{element.Unidad}</Text>
                  </View>
                </View>

                {/* SECCIÓN DE CÓDIGOS DE BARRA */}
                <View style={styles.viewRow}>
                  <Text>Código de barra:</Text>
                </View>
                <View style={styles.viewRow}>
                  {element.CodigosBarras.map((codigoBarra) => {
                    return (
                      <>
                        <Text>{codigoBarra} </Text>
                      </>
                    );
                  })}
                </View>
              </>
            );
          })}

          <View style={styles.divider} />
          <View style={styles.dividerCorto} />

          {/* SECCIÓN DE TOTAL DE PRODUCTOS ENTREGADOS */}
          <View style={styles.viewRow}>
            <Text style={styles.viewRowProducto}>
              Productos con códigos de barra entregados:
            </Text>
            <Text style={styles.viewRowProductoCantUn}>
              {props.datos.ProductosEntregadosConCodigoBarras}
            </Text>
          </View>
          <View style={styles.viewRow}>
            <Text style={styles.viewRowProducto}>
              Productos sin códigos de barra entregados:
            </Text>
            <Text style={styles.viewRowProductoCantUn}>
              {props.datos.ProductosEntregadosSinCodigoBarras}
            </Text>
          </View>
          <View style={styles.dividerSuma} />
          <View style={styles.viewRow}>
            <Text style={styles.viewRowProducto}>
              TOTAL DE PRODUCTOS ENTREGADOS:
            </Text>
            <Text style={styles.viewRowProductoCantUn}>
              {props.datos.TotalProductosEntregados}
            </Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.dividerCorto} />

          {/* SECCIÓN DE TOTAL DE PRODUCTOS RECIBIDOS */}
          <View style={styles.viewRow}>
            <Text style={styles.viewRowProducto}>
              Productos con códigos de barra recibidos:
            </Text>
            <Text style={styles.viewRowProductoCantUn}>
              {props.datos.ProductosRecibidosConCodigoBarras}
            </Text>
          </View>
          <View style={styles.viewRow}>
            <Text style={styles.viewRowProducto}>
              Productos sin códigos de barra recibidos:
            </Text>
            <Text style={styles.viewRowProductoCantUn}>
              {props.datos.ProductosRecibidosSinCodigoBarras}
            </Text>
          </View>
          <View style={styles.dividerSuma} />
          <View style={styles.viewRow}>
            <Text style={styles.viewRowProducto}>
              TOTAL DE PRODUCTOS RECIBIDOS:
            </Text>
            <Text style={styles.viewRowProductoCantUn}>
              {props.datos.TotalProductosRecibidos}
            </Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.dividerCorto} />

          <View style={styles.viewRow}>
            <Text>Comentarios del conductor:</Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.dividerCorto} />

          <View style={styles.viewRow}>
            <Text>
              Los bienes recibidos cumplen con lo ordenado y fueron verificados
              por:
            </Text>
          </View>

          {/* IMAGEN DE FIRMA AQUÍ */}
          <Image
            src={`data:image/png;base64,${props.datos.Firma}`}
            alt='Firma'
            style={styles.firma}
          />

          <View style={styles.viewRow}>
            <Text>Nombre:</Text>
            <Text style={styles.textNombre}>{props.datos.NombreFirma}</Text>
          </View>
          <View style={styles.viewRow}>
            <Text>Conductor:</Text>
            <Text style={styles.textConductor}>{props.datos.Conductor}</Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.dividerCorto} />
        </Page>
      </Document>
    </>
  );
};

export default Comprobante;

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Courier',
    fontSize: 11,
  },
  textoFijado: {
    marginBottom: '4%',
  },
  logo: {
    display: 'block',
    marginLeft: '35%',
    marginRight: 'auto',
    marginBottom: '4%',
    height: '7%',
    width: 'auto',
  },
  textCompañia: {
    textAlign: 'center',
    marginBottom: '4%',
  },
  viewRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3%',
  },
  viewIzquierda: {
    width: '50%',
  },
  viewDerecha: {
    width: '50%',
    textAlign: 'right',
  },
  divider: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3%',
    borderTop: '1 solid #000',
    borderStyle: 'dashed',
  },
  dividerCorto: {
    width: '10%',
    marginLeft: '10%',
    marginTop: '3%',
    borderTop: '1 solid #000',
    borderStyle: 'dashed',
  },
  textDatos: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3%',
  },
  viewRowProducto: {
    width: '70%',
    textAlign: 'left',
  },
  viewRowProductoCantUn: {
    width: '15%',
  },
  textNombre: {
    marginLeft: '20%',
  },
  textConductor: {
    marginLeft: '16%',
  },
  dividerSuma: {
    width: '5%',
    marginRight: '31%',
    marginLeft: 'auto',
    marginTop: '1%',
    borderTop: '1 solid #000',
    borderStyle: 'dashed',
  },
  firma: {
    width: '25%',
    height: 'auto',
    marginLeft: '15%',
    marginTop: '2%',
  },
});
