import React, { Component } from 'react';
import AgregarConsumo from '../../../components/gestionDeConsumo/agregarConsumo/agregarConsumo';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import Loading from '../../../components/loading/loading';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';

class agregarConsumo extends Component {
  componentDidMount() {
    var Token = sessionStorage.getItem('TokenUsuario');
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };

    this.props.onGetAllEmpresas(usuario.usuario.authmethod);
    this.props.onGetAllTiposConsumo();
  }
  render() {
    return (
      <>
        {this.props.loading ? <Loading /> : <AgregarConsumo {...this.props} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tiposConsumo: state.gestionConsumo.tiposConsumo,
    empresas: state.empresas.empresas,
    consumosError: state.gestionConsumo.consumosError,
    Agregar_Consumo_Status: state.gestionConsumo.Agregar_Consumo_Status,
    loading: state.gestionConsumo.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllTiposConsumo: () => dispatch(actions.gestionConsumo.calls.getAllTiposConsumo()),
    onGetAllEmpresas: (id) =>
      dispatch(actions.empresas.calls.getAllEmpresas(id)),
    onPostEmpresas: (data) =>
      dispatch(actions.gestionConsumo.calls.postConsumo(data)),
    cerrarAlertaAgregar: () =>
      dispatch(actions.gestionConsumo.calls.cerrarAlertaAgregar()),
    onGetConsumoLoading: () =>
      dispatch(actions.gestionConsumo.calls.getConsumoLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(agregarConsumo);
