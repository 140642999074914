const FloatFormatter = (value) => {
  var format;
  var sep = ",";
  if (value != null && value != "" && value != "undefined") {
    // split decimals
    var parts = value.toString().split(".");
    // format whole numbers
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    // put them back together
    format = parts[1] ? parts.join(".") : parts[0];
  }
  return format;
};

export default FloatFormatter;
