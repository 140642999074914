const actionsType = {
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

  GETALL_ROLES_SUCCESS: 'GETALL_ROLES_SUCCESS',
  GETALL_ROLES_FAILURE: 'GETALL_ROLES_FAILURE',

  POST_ROLES_SUCCESS: 'POST_ROLES_SUCCESS',
  POST_ROLES_FAILURE: 'POST_ROLES_FAILURE',

  PUT_ROLES_SUCCESS: 'PUT_ROLES_SUCCESS',
  PUT_ROLES_FAILURE: 'PUT_ROLES_FAILURE',

  DELETE_ROLES_SUCCESS: 'DELETE_ROLES_SUCCESS',
  DELETE_ROLES_FAILURE: 'DELETE_ROLES_FAILURE',
};

export default actionsType;
