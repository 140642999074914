import actions from '../actions';

const sideBar = actions.sideBar;

const initialState = {
  Opcion_Activa: document.location.pathname,
};

function sideBarApp(state = initialState, action) {
  switch (action.type) {
    case sideBar.actionsType.POST_OPCION_ACTIVA:
      return sideBar.action.postOpcionActiva(state, action);
    default:
      return { ...state };
  }
}

export default sideBarApp;
