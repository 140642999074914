import React from 'react';
import { Spin } from 'antd';
import iconloading from '../../assets/icono_cargando2.gif';

const Loading = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(255,255,255,0.60)',
        color: '#ace96f',
        display: 'block',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 100000,
        textAlign: 'center',
        padding: '50vh 0',
      }}
    >
      <Spin
        size='large'
        indicator={<img src={iconloading} alt='Icono Cargando' />}
        style={{ color: '#81973a' }}
      />
      <p style={{ color: '#81973a' }}>Cargando...</p>
    </div>
  );
};

export default Loading;
