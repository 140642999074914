import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Typography,
  Button,
  Table,
  DatePicker,
  Card,
  Tag,
  notification,
  Form,
  Modal,
  Spin,
  Skeleton,
} from "antd";
import Column from "antd/lib/table/Column";
import { BlobProvider } from "@react-pdf/renderer";
import { decrypt } from "react-crypt-gsm";
import jwtDecode from "jwt-decode";
import Factura from "./moduloFacturacionVerFactura";
import Facturacion from "../../../assets/Imagen5-Carousel.png";
import PantallaDeNoAcceso from "../../pantallaDeNoAcceso";
import Seccion from "../../Seccion";
import iconloading from "../../../assets/icono_cargando2.gif";
import "./moduloFacturacion.css";
import FloatFormatter from "../../../hoc/helpers";
const { RangePicker } = DatePicker;

const ModuloFacturacion = (props) => {
  //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
  // pantalla
  var Token = sessionStorage.getItem("TokenUsuario");
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let ItemDatePicker = useRef();
  let FormDatePicker = useRef();
  const [Facturas, setFacturas] = useState([]);
  const [PaginaActual, setPaginaActual] = useState(1);
  const [FilaSeleccionada, setFilaSeleccionada] = useState(null);
  const [CargandoFactura, setCargandoFactura] = useState(false);
  const [CargandoFacturasPaginadas, setCargandoFacturasPaginadas] = useState(
    false
  );
  const [FechaInicio, setFechaInicio] = useState(null);
  const [FechaFinal, setFechaFinal] = useState(null);
  const [Moneda, setMoneda] = useState(null);

  let FECHADATEPICKER = null;

  //Hook para recibir las facturas como un array de json y se debe recorrer elemento por elemento
  //para poder obtener la data de cada json como registro y luego cargar ese arreglo en el array de
  //Facturas que se pasa como parametro de datos a la tabla de facturas
  useEffect(() => {
    setCargandoFacturasPaginadas(false);
    props.cerrarAlertaObtenerFacturas();
    let Registros = [];
    console.log(props)
    for (let elemento in props.facturas.facturas) {
      let registro = props.facturas.facturas[elemento];
      let FechaFactura = registro.FechaFactura;
      FechaFactura = FechaFactura.split("T");
      FechaFactura = FechaFactura[0].split("-");
      FechaFactura = `${FechaFactura[2]}/${FechaFactura[1]}/${FechaFactura[0]}`;
      registro = {
        key: elemento,
        fecha: FechaFactura,
        número: registro.NumeroFactura,
        orden: registro.OrdenCompra ? (
          registro.OrdenCompra
        ) : (
          <Tag color="success">Sin Orden</Tag>
        ),
        condición: registro.Condiciones,
        estado: registro.Estado,
        importe:
          registro.Moneda === "EUR"
            ? `€ ${FloatFormatter(
                (Math.round(registro.Importe * 100) / 100).toFixed(2)
              )}`
            : registro.Moneda === "USD"
            ? `$ ${FloatFormatter(
                (Math.round(registro.Importe * 100) / 100).toFixed(2)
              )}`
            : registro.Moneda === "QTZ"
            ? `Q ${FloatFormatter(
                (Math.round(registro.Importe * 100) / 100).toFixed(2)
              )}`
            : registro.Moneda === "LPS"
            ? `L ${FloatFormatter(
                (Math.round(registro.Importe * 100) / 100).toFixed(2)
              )}`
            : registro.Moneda === "NIO"
            ? `C$ ${FloatFormatter(
                (Math.round(registro.Importe * 100) / 100).toFixed(2)
              )}`
            : registro.Moneda === "CRC"
            ? `₡ ${FloatFormatter(
                (Math.round(registro.Importe * 100) / 100).toFixed(2)
              )}`
            : FloatFormatter(
                (Math.round(registro.Importe * 100) / 100).toFixed(2)
              ),
      };
      Registros.push(registro);
    }
    return setFacturas(Registros);
  }, [props.facturas.facturas]);

  //setear la moneda de país
  useEffect(() => {
    let monedas =
      props.facturas.Moneda === "EUR"
        ? "€"
        : props.facturas.Moneda === "USD"
        ? "$"
        : props.facturas.Moneda === "QTZ"
        ? "Q"
        : props.facturas.Moneda === "LPS"
        ? "L"
        : props.facturas.Moneda === "NIO"
        ? "C$"
        : props.facturas.Moneda === "CRC"
        ? "₡"
        : "";
    return setMoneda(monedas);
  }, [props.facturas.Moneda]);

  // Hook para saber si el consumo del api para obtener los datos de una factura especifica
  //es OK entonces abrir la factura en una pestaña aparte con los datos correspondientes, sino entonces
  //mostrar la notificación correspondiente para el usario
  useEffect(() => {
    if (props.Ver_Factura_Status === 200) {
      setCargandoFactura(!CargandoFactura);
    } else if (
      props.Ver_Factura_Status === 400 ||
      props.Ver_Factura_Status === 404 ||
      props.Ver_Factura_Status === 500
    ) {
      setCargandoFactura(!CargandoFactura);
      notification.open({
        type: "error",
        message: "¡Ha ocurrido un error!",
        description:
          "La acción no se realizó debido a que hay problemas presentes.",
        duration: 3,
        onClose: () => {
          props.cerrarAlertaObtenerDatosDeFactura();
        },
      });
    } else if (props.Ver_Factura_Status === 409) {
      setCargandoFactura(!CargandoFactura);
      notification.open({
        type: "warning",
        message: "¡NO HAY FACTURA PARA MOSTRAR!",
        description:
          "Lo sentimos, no hemos encontrado su factura. Póngase en contacto con el Administrador del sitio.",
        duration: 4,
        onClose: () => {
          props.cerrarAlertaObtenerDatosDeFactura();
        },
      });
    }
  }, [props.Ver_Factura_Status]);

  // Hook para saber si hay o no facturas para la fecha establecida que se puedan mostrar.
  //Si el status del response es 409 significa que no hay facturas para mostrar entonces se notifica.
  useEffect(() => {
    if (props.Obtener_Facturas_Status === 200) {
      setCargandoFacturasPaginadas(false);
      if (props.facturas.facturas.length === 0) {
        setCargandoFacturasPaginadas(false);
        notification.open({
          type: "info",
          message: "¡FECHA SIN FACTURAS!",
          description:
            "Para la fecha establecida no hay facturas que mostrar, seleccione un rango de fechas para consultar.",
          duration: 4,
          onClose: () => {
            props.cerrarAlertaObtenerFacturas();
          },
        });
      }
    } else if (
      props.Obtener_Facturas_Status === 400 ||
      props.Obtener_Facturas_Status === 404
    ) {
      setCargandoFacturasPaginadas(false);
      notification.open({
        type: "error",
        message: "¡Ha ocurrido un error!",
        description:
          "La acción no se realizó debido a que hay problemas presentes en la petición.",
        duration: 3,
        onClose: () => {
          props.cerrarAlertaObtenerFacturas();
        },
      });
    } else if (
      props.Obtener_Facturas_Status === 500 ||
      props.Obtener_Facturas_Status === 503
    ) {
      setCargandoFacturasPaginadas(false);
      notification.open({
        type: "error",
        message: "¡Ha ocurrido un error!",
        description:
          "La acción no se realizó debido a que el servidor está fuera de servicio.",
        duration: 3,
        onClose: () => {
          props.cerrarAlertaObtenerFacturas();
        },
      });
    } else if (props.Obtener_Facturas_Status === 409) {
      setCargandoFacturasPaginadas(false);
      notification.open({
        type: "info",
        message: "¡NO HAY FACTURAS PARA MOSTRAR!",
        description: "No hay facturas para mostrar para la fecha establecida.",
        duration: 4,
        onClose: () => {
          props.cerrarAlertaObtenerFacturas();
        },
      });
    }
  }, [props.Obtener_Facturas_Status]);

  //Función para consumir el endpoint para mostrar las facturas correspondiendes a la página que
  //se visita, el endpoint indica el total de facturas que existen y se reciben 20 registros máximo por
  //página
  const PaginarFacturas = (pagina) => {
    setCargandoFacturasPaginadas(true);
    setPaginaActual(pagina);
    if (ItemDatePicker.current.props.value === null) {
      let parametros = {
        CodigoEmpresa: usuario.usuario.FacturacionCodigo,
        Pagina: pagina,
      };
      props.onGetFacturas(parametros);
    } else {
      let parametros = {
        CodigoEmpresa: usuario.usuario.FacturacionCodigo,
        Pagina: pagina,
        FechaInicio: FechaInicio,
        FechaFin: FechaFinal,
      };
      props.onGetFacturas(parametros);
    }
  };

  //Función para filtrar las facturas por un rango de fechas, se recibe un array con la fecha inicial
  //y la fecha final para el filtrado, entonces se les da el formato correcto que debe ser enviado como
  //parámetro para consumir el endpoint
  const FiltrarFacturas = (rangoFechas) => {
    setCargandoFacturasPaginadas(true);
    setPaginaActual(1);
    if (rangoFechas === null) {
      setFacturas([]);
      setCargandoFacturasPaginadas(false);
      notification.open({
        type: "info",
        message: "¡SELECCIONE LAS FECHAS!",
        description:
          "Para consultar las facturas seleccione un rango de fechas a consultar.",
        duration: 4,
      });
    } else {
      //Aqui se le asigna la fecha al datepicker para que la mantenga y no quede en null al cambiar las fechas
      FormDatePicker.current.setFieldsValue({
        ItemDatePicker: [rangoFechas[0], rangoFechas[1]],
      });
      let FechaInicio = rangoFechas[0]._d + "";
      let FechaFinal = rangoFechas[1]._d + "";
      let diaInicio, mesInicio, annoInicio, diaFinal, mesFinal, annoFinal;

      FechaInicio = FechaInicio.split(" ");
      FechaFinal = FechaFinal.split(" ");

      diaInicio = FechaInicio[2];
      mesInicio = FechaInicio[1];
      annoInicio = FechaInicio[3];
      diaFinal = FechaFinal[2];
      mesFinal = FechaFinal[1];
      annoFinal = FechaFinal[3];

      //Condicional para saber el numero del mes de inicio seleccionado para enviar el formato correcto en el request
      if (mesInicio === "Jan") {
        mesInicio = "01";
      } else if (mesInicio === "Feb") {
        mesInicio = "02";
      } else if (mesInicio === "Mar") {
        mesInicio = "03";
      } else if (mesInicio === "Apr") {
        mesInicio = "04";
      } else if (mesInicio === "May") {
        mesInicio = "05";
      } else if (mesInicio === "Jun") {
        mesInicio = "06";
      } else if (mesInicio === "Jul") {
        mesInicio = "07";
      } else if (mesInicio === "Aug") {
        mesInicio = "08";
      } else if (mesInicio === "Sep") {
        mesInicio = "09";
      } else if (mesInicio === "Oct") {
        mesInicio = "10";
      } else if (mesInicio === "Nov") {
        mesInicio = "11";
      } else {
        mesInicio = "12";
      }

      //Condicional para saber el numero del mes final seleccionado para enviar el formato correcto en el request
      if (mesFinal === "Jan") {
        mesFinal = "01";
      } else if (mesFinal === "Feb") {
        mesFinal = "02";
      } else if (mesFinal === "Mar") {
        mesFinal = "03";
      } else if (mesFinal === "Apr") {
        mesFinal = "04";
      } else if (mesFinal === "May") {
        mesFinal = "05";
      } else if (mesFinal === "Jun") {
        mesFinal = "06";
      } else if (mesFinal === "Jul") {
        mesFinal = "07";
      } else if (mesFinal === "Aug") {
        mesFinal = "08";
      } else if (mesFinal === "Sep") {
        mesFinal = "09";
      } else if (mesFinal === "Oct") {
        mesFinal = "10";
      } else if (mesFinal === "Nov") {
        mesFinal = "11";
      } else {
        mesFinal = "12";
      }

      FechaInicio = `${annoInicio}-${mesInicio}-${diaInicio}`;
      FechaFinal = `${annoFinal}-${mesFinal}-${diaFinal}`;

      // Se guarda la fecha que se consulta para cuando se consulta por paginacion
      // entonces poder enviar la fecha que se tiene seleccionada
      setFechaInicio(FechaInicio);
      setFechaFinal(FechaFinal);

      let parametros = {
        CodigoEmpresa: usuario.usuario.FacturacionCodigo,
        Pagina: 1,
        FechaInicio: FechaInicio,
        FechaFin: FechaFinal,
      };
      props.onGetFacturas(parametros);
    }
  };

  //Función para hacer el request al api de obtener datos de una factura especifica
  const verFactura = (registro) => {
    let parametros = {
      CodigoEmpresa: usuario.usuario.FacturacionCodigo,
      NumeroFactura: registro.número,
    };
    setCargandoFactura(!CargandoFactura);
    props.onGetDatosDeFactura(parametros);
  };
  return (
    <>
      <Modal
        visible={CargandoFactura}
        footer={null}
        centered
        closable={false}
        bodyStyle={{ backgroundColor: "rgba(255,255,255,0.60)" }}
      >
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Spin
            size="large"
            indicator={<img src={iconloading} alt='iconloading'/>}
            style={{ color: "#81973a" }}
          />
          <p style={{ color: "#81973a" }}>Cargando...</p>
        </div>
      </Modal>
      {usuario.usuario.Facturacion === "true" ? (
        <>
          <Seccion titulo={"Información del Cliente"} marginTop={"2%"} marginLeft={"0.5%"} />

          {props.facturas.length !== 0 ? (
            <>
              <Card className="CardFacturacion">
                <Row>
                  <Col
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text style={{marginRight: 75}} strong>
                          Cliente:
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.NombreCliente}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text style={{marginRight: 5}} strong>
                          Código de
                          Cliente:
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.CodigoCliente}
                        </Typography.Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text style={{marginRight: 95}} strong>
                          País:
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.PaisCliente}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text style={{marginRight: 59}} strong>
                          Vendedor:
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.NombreVendedor}
                        </Typography.Text>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text strong>
                          Datos Contacto Vendedor:&nbsp;
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.ContactoVendedor}
                        </Typography.Text>
                      </Col>
                    </Row> */}
                  </Col>
                  <Col
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text strong>
                          Dirección:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.DireccionCliente}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text strong>
                          Moneda:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography.Text>
                        <Typography.Text>
                          {props.facturas.Moneda === "EUR"
                            ? "Euros"
                            : props.facturas.Moneda === "USD"
                            ? "Dólares"
                            : props.facturas.Moneda === "QTZ"
                            ? "Quetzales"
                            : props.facturas.Moneda === "LPS"
                            ? "Lempira"
                            : props.facturas.Moneda === "NIO"
                            ? "Córdoba"
                            : props.facturas.Moneda === "CRC"
                            ? "Colón"
                            : props.facturas.Moneda === "##"
                            ? "Todas las Monedas"
                            : "Na"}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Text strong>
                          Saldo Adeudado:&nbsp;&nbsp;
                        </Typography.Text>
                        <Typography.Text>
                          {Moneda}&nbsp;
                          {FloatFormatter(
                            (
                              Math.round(props.facturas.SaldoAdeudado * 100) /
                              100
                            ).toFixed(2)
                          )}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </>
          ) : (
            <Card className="CardFacturacion">
              <Skeleton />
            </Card>
          )}

          {/* <Seccion
            titulo={'Detalle de Movimientos Contables'}
            marginTop={'1%'}
          /> */}

          <Row
            style={{
              marginTop: "1%",
            }}
          >
            <Col
              lg={{ span: 16 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Seccion titulo={"Detalle de Movimientos Contables"} />
            </Col>
            <Col
              lg={{ span: 2 }}
              md={{ span: 5 }}
              sm={{ span: 3 }}
              xs={{ span: 6 }}
            >
              <Row
                style={{
                  background: "#81973a",
                  borderRadius: "5px 0px 0px 5px",
                  marginLeft: "5%",
                  height: "100%",
                }}
              >
                <Col
                  style={{
                    color: "white",
                    marginLeft: "10%",
                    fontSize: "12px",
                    marginTop: "1%",
                    fontWeight: "bold",
                  }}
                >
                  Fecha:
                </Col>
              </Row>
            </Col>
            <Col
              lg={{ span: 6 }}
              md={{ span: 9 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <Form
                style={{ marginTop: -5 }}
                ref={FormDatePicker}
                initialValues={{ ItemDatePicker: FECHADATEPICKER }}
              >
                <Row style={{ height: "3vh" }}>
                  <Col
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Form.Item name="ItemDatePicker">
                      <RangePicker
                        size="small"
                        format={("DD/MM/YYYY", "DD/MM/YYYY")}
                        value={FECHADATEPICKER}
                        ref={ItemDatePicker}
                        onChange={FiltrarFacturas}
                        className="FechaFac"
                        style={{ border: "2px solid #81973a" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row style={{ marginTop: "1%" }}>
            <Col
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Table
                // title={() => (
                //   <>
                //     <Row>
                //       <Col
                //         lg={{ span: 24 }}
                //         md={{ span: 24 }}
                //         sm={{ span: 24 }}
                //         xs={{ span: 24 }}
                //       >
                //         <Typography.Text
                //           style={{
                //             color: 'rgba(0, 0, 0, 0.85)',
                //             fontWeight: 500,
                //           }}
                //         >
                //           Rango de Fechas
                //         </Typography.Text>
                //       </Col>
                //     </Row>

                //     <Form
                //       ref={FormDatePicker}
                //       initialValues={{ ItemDatePicker: FECHADATEPICKER }}
                //     >
                //       <Row style={{ height: '4vh' }}>
                //         <Col
                //           lg={{ span: 24 }}
                //           md={{ span: 24 }}
                //           sm={{ span: 24 }}
                //           xs={{ span: 24 }}
                //         >
                //           <Form.Item name='ItemDatePicker'>
                //             <RangePicker
                //               size='small'
                //               format={('DD/MM/YYYY', 'DD/MM/YYYY')}
                //               value={FECHADATEPICKER}
                //               ref={ItemDatePicker}
                //               onChange={FiltrarFacturas}
                //             />
                //           </Form.Item>
                //         </Col>
                //       </Row>
                //     </Form>
                //   </>
                // )}
                bordered
                size="small"
                dataSource={Facturas}
                pagination={{
                  size: "small",
                  current: PaginaActual,
                  total: props.facturas.TotalRegistros,
                  pageSize: 20,
                  onChange: (pagina) => PaginarFacturas(pagina),
                }}
                scroll={{ x: 800, y: 200 }}
                loading={CargandoFacturasPaginadas}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      props.cerrarAlertaObtenerDatosDeFactura();
                      setFilaSeleccionada(record.número);
                    },
                  };
                }}
              >
                <Column
                  title="Fecha"
                  dataIndex="fecha"
                  key="fecha"
                  width="5%"
                  align="left"
                />
                <Column
                  title="Número"
                  dataIndex="número"
                  key="número"
                  width="5%"
                  align="left"
                />
                <Column
                  title="Orden de Compra"
                  dataIndex="orden"
                  key="orden"
                  width="6%"
                  align="left"
                />
                <Column
                  title="Condiciones"
                  dataIndex="condición"
                  key="condición"
                  width="6%"
                  align="left"
                />
                <Column
                  title="Estado"
                  dataIndex="estado"
                  key="estado"
                  width="7%"
                  align="left"
                />
                <Column
                  title="Importe"
                  dataIndex="importe"
                  key="importe"
                  width="6%"
                  align="right"
                />
                <Column
                  width="6%"
                  fixed="right"
                  title="Factura"
                  dataIndex="ver"
                  key="ver"
                  align="center"
                  render={(text, row) => {
                    if (props.Ver_Factura_Status === 200) {
                      if (FilaSeleccionada !== null) {
                        if (
                          parseInt(FilaSeleccionada) === parseInt(row.número)
                        ) {
                          return (
                            <BlobProvider
                              document={
                                <Factura
                                  datos={props.DatosFactura}
                                  moneda={Moneda}
                                  pais={props.facturas.PaisCliente}
                                />
                              }
                            >
                              {({ url }) =>
                                url !== null ? (
                                  (window.open(url, "_blank"),
                                  (
                                    <Button
                                      onClick={props.cerrarAlertaObtenerDatosDeFactura()}
                                      className="BotonVerMas"
                                      type="link"
                                    >
                                      Ver más...
                                    </Button>
                                  ))
                                ) : (
                                  <Button
                                    onClick={() => verFactura(row)}
                                    className="BotonVerMas"
                                    type="link"
                                  >
                                    Ver más...
                                  </Button>
                                )
                              }
                            </BlobProvider>
                          );
                        } else {
                          return (
                            <Button
                              onClick={() => verFactura(row)}
                              className="BotonVerMas"
                              type="link"
                            >
                              Ver más...
                            </Button>
                          );
                        }
                      }
                    } else {
                      return (
                        <Button
                          onClick={() => verFactura(row)}
                          className="BotonVerMas"
                          type="link"
                        >
                          Ver más...
                        </Button>
                      );
                    }
                  }}
                />
              </Table>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <PantallaDeNoAcceso src={Facturacion} alt={"Facturación"} />
        </>
      )}
    </>
  );
};
export default ModuloFacturacion;
