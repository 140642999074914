//-----------------------------------Ruta DEFAULT CAROUSEL-----------------------------------
import HomeCarousel from './components/HomeCarousel';

import GoDaddy from './components/viewCertificate/godaddy';

//-----------------------------------Rutas de Gestión de Usuarios-----------------------------------
import ConsultarUsuarios from './container/gestionDeUsuarios/consultarUsuarios/consultarUsuarios';
import AgregarUsuarios from './container/gestionDeUsuarios/agregarUsuarios/agregarUsuarios';
//-----------------------------------Rutas de Gestión de Proveedor-----------------------------------
import ConsultarProveedores from './container/gestionDeProveedor/consultarProveedores/consultarProveedor';
//-----------------------------------Rutas de Gestión de Empresas-----------------------------------
import AgregarEmpresas from './container/gestionDeEmpresas/agregarEmpresas/agregarEmpresas';
import ConsultarEmpresas from './container/gestionDeEmpresas/consultarEmpresas/consultarEmpresas';
//-----------------------------------Rutas de Gestión de Consumo-----------------------------------
import AgregarConsumo from './container/gestionDeConsumo/agregarConsumo/agregarConsumo';
import ConsultarConsumo from './container/gestionDeConsumo/consultarConsumo/consultarConsumo';

//-----------------------------------Rutas de Módulos de Cliente-----------------------------------
import ModuloControlDeEnvases from './container/modulosCliente/moduloControlDeEnvases/moduloControlDeEnvases';
import ModuloInventario from './container/modulosCliente/moduloInventario/moduloInventario';
import ModuloConsumo from './container/modulosCliente/moduloConsumo/moduloConsumo';
import ModuloFacturacion from './container/modulosCliente/moduloFacturacion/moduloFacturacion';
import ModuloEstadoDeCuentaNoAcceso from './components/modulosCliente/moduloEstadoDeCuenta/moduloEstadoDeCuentaNoAcceso';
import ModuloNotificaciones from './container/modulosCliente/moduloNotificaciones/moduloNotificaciones';

import React from 'react';

import { isAdmin, isClient, validationNotNeeded, isAuthenticated } from './security';

export const path = {
  default: '/',
  consultarUsuarios: '/consultarUsuarios',
  agregarUsuarios: '/agregarUsuarios',
  consultarProveedores: '/consultarProveedores',
  consultarEmpresas: '/consultarEmpresas',
  agregarEmpresas: '/agregarEmpresas',
  consultarConsumo: '/consultarConsumo',
  agregarConsumo: '/agregarConsumo',
  //Modulos de cliente
  moduloControlDeEnvases: '/ControlDeEnvases',
  moduloInventario: '/Inventario',
  moduloConsumo: '/Consumo',
  moduloFacturacion: '/Facturacion',
  moduloEstadoDeCuentaNoAcceso: '/EstadoDeCuenta',
  moduloNotificaciones: '/Notificaciones',
  //Visor certificado
  goDaddy: '/.well-known/pki-validation/godaddy.html',
};

const newRoute = (path, defaultUrl, view, validator) => {
  return {
    path: path,
    defaultUrl: defaultUrl,
    view: view,
    validator: validator,
  };
};

export const generateRoutes = () => {
  let homeCarousel = <HomeCarousel />;
  let consultarUsuarios = <ConsultarUsuarios />;
  let agregarUsuarios = <AgregarUsuarios />;
  let consultarProveedores = <ConsultarProveedores />;
  let consultarEmpresas = <ConsultarEmpresas />;
  let agregarEmpresas = <AgregarEmpresas />;
  let consultarConsumo = <ConsultarConsumo />;
  let agregarConsumo = <AgregarConsumo />;
  //Módulos de cliente
  let moduloControlDeEnvases = <ModuloControlDeEnvases />;
  let moduloInventario = <ModuloInventario />;
  let moduloConsumo = <ModuloConsumo />;
  let moduloFacturacion = <ModuloFacturacion />;
  let moduloEstadoDeCuentaNoAcceso = <ModuloEstadoDeCuentaNoAcceso />;
  let moduloNotificaciones = <ModuloNotificaciones />;
  let goDaddy = <GoDaddy/>
  let routes = [];

  routes.push(
    newRoute(path.goDaddy, path.goDaddy, goDaddy, () =>
    true,
    ),
  );
  routes.push(
    newRoute(path.consultarUsuarios, path.default, consultarUsuarios, () =>
      isAdmin(),
    ),
  );
  routes.push(
    newRoute(path.agregarUsuarios, path.default, agregarUsuarios, () =>
      isAdmin(),
    ),
  );
  routes.push(
    newRoute(
      path.consultarProveedores,
      path.default,
      consultarProveedores,
      () => isAdmin(),
    ),
  );
  routes.push(
    newRoute(path.consultarEmpresas, path.default, consultarEmpresas, () =>
      isAdmin(),
    ),
  );
  routes.push(
    newRoute(path.agregarEmpresas, path.default, agregarEmpresas, () =>
      isAdmin(),
    ),
  );
  routes.push(
    newRoute(path.consultarConsumo, path.default, consultarConsumo, () =>
      isAdmin(),
    ),
  );
  routes.push(
    newRoute(path.agregarConsumo, path.default, agregarConsumo, () =>
      isAdmin(),
    ),
  );

  //Modulos de cliente
  routes.push(
    newRoute(
      path.moduloControlDeEnvases,
      path.default,
      moduloControlDeEnvases,
      () => isClient(),
    ),
  );
  routes.push(
    newRoute(path.moduloInventario, path.default, moduloInventario, () =>
      isClient(),
    ),
  );
  routes.push(
    newRoute(path.moduloConsumo, path.default, moduloConsumo, () => isClient()),
  );
  routes.push(
    newRoute(path.moduloFacturacion, path.default, moduloFacturacion, () =>
      isClient(),
    ),
  );
  routes.push(
    newRoute(
      path.moduloEstadoDeCuentaNoAcceso,
      path.default,
      moduloEstadoDeCuentaNoAcceso,
      () => isClient(),
    ),
  );
  routes.push(
    newRoute(
      path.moduloNotificaciones,
      path.default,
      moduloNotificaciones,
      () => isClient(),
    ),
  );

  //Ruta para el caroussel
  routes.push(
    newRoute(path.default, path.default, homeCarousel, () =>
      validationNotNeeded(),
    ),
  );
  return routes;
};
