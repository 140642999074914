import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { path } from '../../../routes';
import Swal from 'sweetalert2';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import {
  Row,
  Col,
  Input,
  Tooltip,
  Form,
  Button,
  Select,
  Modal,
  notification,
  Divider,
} from 'antd';
import {
  CaretRightOutlined,
  UserOutlined,
  InfoCircleOutlined,
  LockOutlined,
  PhoneOutlined,
  MobileOutlined,
  PlusOutlined,
  BankOutlined,
  KeyOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import './agregarUsuarios.css';
const { TextArea } = Input;
const { Option } = Select;

const AgregarUsuarios = (props) => {
  //Usuario logueado en el sistema
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [prefijoPais, setPrefijoPais] = useState(null);
  const [deshabilitarGuardar, setDeshabilitarGuardar] = useState(false);
  let formAgregarEmpresa = useRef();
  let inputContactoEmergencia = useRef();
  let PrefijosArray = [];

  props.paises.map((elemento) =>
    PrefijosArray.push({
      id: elemento.ID_Pais,
      prefijo: elemento.Prefijo_Telefonico,
    }),
  );

  const buscarPrefijo = async (id) => {
    const elemento = await PrefijosArray.find((pais) => pais.id === id);

    return setPrefijoPais(elemento.prefijo);
  };

  useEffect(() => {
    if (inputContactoEmergencia.current !== undefined) {
      formAgregarEmpresa.current.setFieldsValue({
        telefono: prefijoPais,
        contactoEmergencia: prefijoPais,
      });
    }
  }, [prefijoPais]);

  const menuPaises = (
    <Select
      suffixIcon={<CaretRightOutlined rotate={90} />}
      placeholder='País'
      onChange={buscarPrefijo}
    >
      {props.paises.map((elemento) => (
        <Option key={elemento.ID_Pais} value={elemento.ID_Pais}>
          {elemento.Nombre}
        </Option>
      ))}
    </Select>
  );

  const menuEmpresa = (
    <Select
      suffixIcon={<CaretRightOutlined rotate={90} />}
      placeholder='Empresa'
      showSearch
      optionFilterProp='label'
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {props.empresas.map((elemento) => (
        <Option
          key={elemento.ID_CompaniaCliente}
          value={elemento.ID_CompaniaCliente}
          label={elemento.Nombre}
        >
          {elemento.Nombre}
        </Option>
      ))}
    </Select>
  );

  //-------------USEEFFECTS PARA AGREGAR USUARIO--------------------------------
  useEffect(() => {
    setDeshabilitarGuardar(false);
  }, [props.Agregar_Usuario_Status]);

  useEffect(() => {
    if (props.Agregar_Usuario_Status === 200) {
      setDeshabilitarGuardar(true);
      history.push({ pathname: path.consultarUsuarios });
    } else if (
      props.Agregar_Usuario_Status === 400 ||
      props.Agregar_Usuario_Status === 404 ||
      props.Agregar_Usuario_Status === 500
    ) {
      setDeshabilitarGuardar(true);
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    }
  }, [props.Agregar_Usuario_Status]);

  //-------------USEEFFECTS PARA AGREGAR EMPRESA--------------------------------
  useEffect(() => {
    if (props.Agregar_Empresa_Status === 200) {
      props.onGetAllEmpresas(usuario.usuario.authmethod);
      setVisible(false);
      notification.open({
        type: 'success',
        message: 'Empresa agregada correctamente!',
        description: 'La empresa se agregó correctamente.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    } else if (
      props.Agregar_Empresa_Status === 400 ||
      props.Agregar_Empresa_Status === 404 ||
      props.Agregar_Empresa_Status === 500
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaAgregar();
        },
      });
    }
  }, [props.Agregar_Empresa_Status]);

  const handleSubmit = (values) => {
    const Usuario = {
      ID_Usuario: 0,
      Nombre: values.nombre,
      Apellido: values.apellido,
      Email: values.email,
      RoleID: values.rol,
      CompaniaClienteID: values.empresa,
      Contrasenna: values.password,
      Created_At: '2020-09-17T03:37:24.898Z',
      Update_At: '2020-09-17T03:37:24.898Z',
      Activo: usuario.usuario.role === '4' ? false : true,
      Cargo: values.cargo,
      Departamento: values.departamento,
      Telefono_Oficina: values.teloficina,
      Telefono_Movil: values.telmovil,
    };
    props.onGetLoading();
    props.onPostUsuarios(usuario.usuario.unique_name, Usuario);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  //------------------------Col Form Item Responsive----------------------------
  const FormItemResponsive = (props) => {
    return (
      <Col
        lg={{ offset: 0, span: 21 }}
        md={{ offset: 0, span: 21 }}
        sm={{ offset: 0, span: 21 }}
        xs={{ offset: 0, span: 21 }}
      >
        {props.FormItem}
      </Col>
    );
  };

  //---------------------------------Función agregar empresa----------------------------
  //No se permite crear una empresa si no tiene al menos un módulo asociado a ella
  //Por cuestiones de tiempo y seguir con los sprints, se soluciona la validación de los
  //campos de los módulos que no permitan vacíos ni espacios en blanco para crear la empresa, pero
  //esta validación puede mejorar sustancialmente
  const handleSubmitAgregarEmpresa = (values) => {
    if (
      values.codigoUnicoControlEnvases === undefined &&
      values.codigoUnicoInventario === undefined &&
      values.codigoUnicoConsumo === undefined &&
      values.codigoUnicoFacturacion === undefined
    ) {
      Swal.fire(
        'Atención',
        '¡La empresa debe tener al menos un módulo asociado!',
        'info',
      );
    } else {
      if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoControlEnvases.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoInventario.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (values.codigoUnicoConsumo.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (values.codigoUnicoFacturacion.trim() === '') {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo === undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoControlEnvases.trim() === '' &&
          values.codigoUnicoInventario.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases !== undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoControlEnvases.trim() === '' &&
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario === undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (
          values.codigoUnicoConsumo.trim() === '' &&
          values.codigoUnicoFacturacion.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion !== undefined
      ) {
        if (
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === '' &&
          values.codigoUnicoFacturacion.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases === undefined &&
        values.codigoUnicoInventario !== undefined &&
        values.codigoUnicoConsumo !== undefined &&
        values.codigoUnicoFacturacion === undefined
      ) {
        if (
          values.codigoUnicoInventario.trim() === '' &&
          values.codigoUnicoConsumo.trim() === ''
        ) {
          Swal.fire(
            'Atención',
            '¡La empresa debe tener al menos un módulo asociado!',
            'info',
          );
        }
      } else if (
        values.codigoUnicoControlEnvases.trim() === '' &&
        values.codigoUnicoInventario.trim() === '' &&
        values.codigoUnicoConsumo.trim() === '' &&
        values.codigoUnicoFacturacion.trim() === ''
      ) {
        Swal.fire(
          'Atención',
          '¡La empresa debe tener al menos un módulo asociado!',
          'info',
        );
      } else {
        const Empresa = {
          Nombre: values.nombre,
          Descripcion: values.descripcion,
          Activo: true,
          Pais: values.pais,
          Direccion: values.direccion,
          Telefono: values.telefono,
          Web_site: values.website,
          Contacto_Emergencia: values.contactoEmergencia,
          providers: [
            {
              providerId: 1,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoControlEnvases,
            },
            {
              providerId: 2,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoInventario,
            },
            {
              providerId: 3,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoFacturacion,
            },
            {
              providerId: 4,
              email: '',
              password: '',
              uniqueCode: values.codigoUnicoConsumo,
            },
          ],
        };
        props.onPostEmpresa(usuario.usuario.unique_name, Empresa);
      }
    }
  };
  return (
    <>
      {/* Modal para agregar empresa dentro de agregar usuario */}
      <Modal
        title='Agregar Empresa'
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        centered
      >
        <Form
          name='Agregar_Empresa_Form'
          onFinish={handleSubmitAgregarEmpresa}
          ref={formAgregarEmpresa}
          preserve={false}
        >
          <Row>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='nombre'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 100,
                        message: 'Mínimo 1 carácter, máximo 100 carácteres',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Nombre'
                      prefix={<BankOutlined />}
                      suffix={
                        <Tooltip
                          title='Nombre de la Empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='descripcion'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 200,
                        message: 'Mínimo 1 carácter, máximo 200 carácteres',
                      },
                    ]}
                  >
                    <TextArea rows={3} placeholder='Descripción' />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='direccion'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 200,
                        message: 'Mínimo 1 carácter, máximo 200 carácteres',
                      },
                    ]}
                  >
                    <TextArea rows={3} placeholder='Dirección de la empresa' />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    validateTrigger='onSubmit'
                    name='telefono'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 20,
                        message: 'Mínimo 1 carácter, máximo 20 carácteres',
                      },
                      // {
                      //   pattern: /^([0-9])*$/,
                      //   message: 'Solo se admiten números',
                      // },
                    ]}
                  >
                    <Input
                      placeholder='Teléfono'
                      prefix={<PhoneOutlined />}
                      suffix={
                        <Tooltip
                          title='Teléfono de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    name='website'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      {
                        min: 1,
                        max: 100,
                        message: 'Mínimo 1 carácter, máximo 100 carácteres',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Sitio Web'
                      prefix={<LinkOutlined />}
                      suffix={
                        <Tooltip
                          title='Sitio Web de la Empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <FormItemResponsive
                FormItem={
                  <Form.Item
                    validateTrigger='onSubmit'
                    name='contactoEmergencia'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: '¡Este campo es requerido!',
                      },
                      // {
                      //   min: 1,
                      //   max: 20,
                      //   message: 'Mínimo 1 carácter, máximo 20 carácteres',
                      // },
                      // {
                      //   pattern: /^([0-9])*$/,
                      //   message: 'Solo se admiten números',
                      // },
                    ]}
                  >
                    <Input
                      ref={inputContactoEmergencia}
                      placeholder='Contacto Emergencia'
                      prefix={<PhoneOutlined />}
                      suffix={
                        <Tooltip
                          title='Contacto en caso de emergencia'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
            </Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <Col
                lg={{ span: 21 }}
                md={{ span: 21 }}
                sm={{ span: 21 }}
                xs={{ span: 21 }}
              >
                <Form.Item
                  name='pais'
                  rules={[
                    { required: true, message: '¡Este campo es requerido!' },
                  ]}
                >
                  {menuPaises}
                </Form.Item>
              </Col>

              <Divider orientation='left' style={{ marginTop: '-2%' }}>
                Control de Envases
              </Divider>
              <FormItemResponsive
                FormItem={
                  <Form.Item name='codigoUnicoControlEnvases'>
                    <Input
                      type='text'
                      placeholder='Código único'
                      prefix={<KeyOutlined />}
                      suffix={
                        <Tooltip
                          title='Código de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />

              <Divider orientation='left' style={{ marginTop: '-2%' }}>
                Inventario
              </Divider>
              <FormItemResponsive
                FormItem={
                  <Form.Item name='codigoUnicoInventario'>
                    <Input
                      type='text'
                      placeholder='Código único'
                      prefix={<KeyOutlined />}
                      suffix={
                        <Tooltip
                          title='Código de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
              <Divider orientation='left' style={{ marginTop: '-2%' }}>
                Consumo
              </Divider>
              <FormItemResponsive
                FormItem={
                  <Form.Item name='codigoUnicoConsumo'>
                    <Input
                      type='text'
                      placeholder='Código único'
                      prefix={<KeyOutlined />}
                      suffix={
                        <Tooltip
                          title='Código de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />

              <Divider orientation='left' style={{ marginTop: '-2%' }}>
                Facturación / Estado de Cuenta
              </Divider>
              <FormItemResponsive
                FormItem={
                  <Form.Item name='codigoUnicoFacturacion'>
                    <Input
                      type='text'
                      placeholder='Código único'
                      prefix={<KeyOutlined />}
                      suffix={
                        <Tooltip
                          title='Código de la empresa'
                          placement='bottomLeft'
                        >
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                }
              />
            </Col>
          </Row>

          <Row justify='center'>
            <Col
              lg={{ offset: 0, span: 8 }}
              md={{ offset: 0, span: 8 }}
              sm={{ offset: 0, span: 8 }}
              xs={{ offset: 0, span: 8 }}
            >
              <Button htmlType='submit' className='botonAgregarEmpresa'>
                Agregar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Pantalla de agregar usuario */}
      <Form name='Agregar_Usuario_Form' onFinish={handleSubmit}>
        <Row justify='end' style={{ marginTop: '2%' }}>
          <Col
            lg={{ offset: 0, span: 4 }}
            md={{ offset: 0, span: 6 }}
            sm={{ offset: 0, span: 10 }}
            xs={{ offset: 0, span: 13 }}
          >
            <Button
              disabled={deshabilitarGuardar}
              htmlType='submit'
              className='botonGuardarUsuario'
            >
              Guardar
            </Button>
          </Col>

          <Col
            style={{ marginLeft: 20 }}
            lg={{ offset: 0, span: 4 }}
            md={{ offset: 0, span: 6 }}
            sm={{ offset: 0, span: 10 }}
            xs={{ offset: 0, span: 13 }}
          >
            <Button
              className='botonCancelarUsuarios'
              onClick={() => history.push({ pathname: path.consultarUsuarios })}
            >
              Cancelar
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            background: '#81973a',
            borderRadius: 5,
            marginBottom: '2%',
            marginTop: '2%',
          }}
        >
          <Col style={{ color: 'white', marginLeft: '1%' }}>
            Información de Usuario
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='nombre'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 100,
                      message: 'Mínimo 1 caracter, máximo 100 caracteres',
                    },
                  ]}
                >
                  <Input
                    placeholder='Nombre'
                    prefix={<UserOutlined />}
                    suffix={
                      <Tooltip
                        title='Nombre del usuario'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='apellido'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 100,
                      message: 'Mínimo 1 carácter, máximo 100 carácteres',
                    },
                  ]}
                >
                  <Input
                    placeholder='Apellido'
                    style={{ marginTop: '-2%' }}
                    prefix={<UserOutlined />}
                    suffix={
                      <Tooltip
                        title='Apellido del usuario'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  validateTrigger='onSubmit'
                  name='email'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 150,
                      message: 'Mínimo 1 carácter, máximo 150 carácteres',
                    },
                    {
                      pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                      message: 'Formato de correo inválido',
                    },
                  ]}
                >
                  <Input
                    type='email'
                    placeholder='Usuario (correo)'
                    style={{ marginTop: '-2%' }}
                    prefix={<UserOutlined />}
                    suffix={
                      <Tooltip
                        title='Correo del usuario'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='password'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 8,
                      max: 150,
                      message: 'Mínimo 8 carácteres, máximo 150 carácteres',
                    },
                    {
                      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,150}$/,
                      message:
                        'La contraseña debe contener (Mayúsculas, minúsculas y números)',
                    },
                  ]}
                >
                  <Input.Password
                    type='password'
                    placeholder='Contraseña'
                    style={{ marginTop: '-2%' }}
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='rol'
                  rules={[
                    { required: true, message: '¡Este campo es requerido!' },
                  ]}
                >
                  <Select
                    placeholder='Rol'
                    suffixIcon={<CaretRightOutlined rotate={90} />}
                  >
                    {usuario.usuario.role === '1' ? (
                      <>
                        <Option value={1}>Administrador</Option>
                        <Option value={2}>Cliente</Option>
                        <Option value={3}>Consult Manager</Option>
                        <Option value={4}>Cliente Administrador</Option>
                      </>
                    ) : (
                      <>
                        <Option value={2}>Cliente</Option>
                      </>
                    )}
                  </Select>
                </Form.Item>
              }
            />
          </Col>
          <Col
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Row>
              <Col
                lg={{ offset: 0, span: 21 }}
                md={{ offset: 0, span: 21 }}
                sm={{ offset: 0, span: 21 }}
                xs={{ offset: 0, span: 21 }}
                style={{ marginRight: '2%' }}
              >
                <Form.Item
                  name='empresa'
                  rules={[
                    { required: true, message: '¡Este campo es requerido!' },
                  ]}
                >
                  {menuEmpresa}
                </Form.Item>
              </Col>
              <Col>
                {usuario.usuario.role === '4' ? (
                  <></>
                ) : (
                  <Tooltip title='Agregar Empresa'>
                    <Button
                      type='primary'
                      shape='circle'
                      style={{
                        backgroundColor: '#81973a',
                        borderColor: '#81973a',
                      }}
                      icon={<PlusOutlined />}
                      onClick={() => setVisible(!visible)}
                    />
                  </Tooltip>
                )}
              </Col>
            </Row>
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='cargo'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 100,
                      message: 'Mínimo 1 carácter, máximo 100 carácteres',
                    },
                  ]}
                >
                  <Input
                    placeholder='Cargo'
                    prefix={<UserOutlined />}
                    suffix={
                      <Tooltip title='Cargo del usuario' placement='bottomLeft'>
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  name='departamento'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 100,
                      message: 'Mínimo 1 carácter, máximo 100 carácteres',
                    },
                  ]}
                >
                  <Input
                    placeholder='Departamento'
                    prefix={<UserOutlined />}
                    suffix={
                      <Tooltip
                        title='Departamento del usuario'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  validateTrigger='onSubmit'
                  name='teloficina'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 20,
                      message: 'Mínimo 1 carácter, máximo 20 carácteres',
                    },
                    {
                      pattern: /^([0-9])*$/,
                      message: 'Solo se admiten números',
                    },
                  ]}
                >
                  <Input
                    placeholder='Teléfono oficina'
                    prefix={<PhoneOutlined />}
                    suffix={
                      <Tooltip
                        title='Teléfono de oficina del usuario'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
            <FormItemResponsive
              FormItem={
                <Form.Item
                  validateTrigger='onSubmit'
                  name='telmovil'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '¡Este campo es requerido!',
                    },
                    {
                      min: 1,
                      max: 20,
                      message: 'Mínimo 1 carácter, máximo 20 carácteres',
                    },
                    {
                      pattern: /^([0-9])*$/,
                      message: 'Solo se admiten números',
                    },
                  ]}
                >
                  <Input
                    placeholder='Teléfono móvil'
                    prefix={<MobileOutlined />}
                    suffix={
                      <Tooltip
                        title='Teléfono móvil del usuario'
                        placement='bottomLeft'
                      >
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              }
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default AgregarUsuarios;
