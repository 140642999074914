import actionsType from './actionsTypes';

function getFacturasSuccess(data) {
  return { type: actionsType.GET_FACTURAS_SUCCESS, data: data };
}
function getFacturasFailure(error) {
  return { type: actionsType.GET_FACTURAS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getFacturasLoading() {
  return { type: actionsType.GET_FACTURAS_LOADING };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaObtenerFacturas() {
  return { type: actionsType.CERRAR_ALERTA_OBTENER_FACTURAS };
}

export default {
  getFacturasSuccess: getFacturasSuccess,
  getFacturasFailure: getFacturasFailure,
  getFacturasLoading: getFacturasLoading,
  cerrarAlertaObtenerFacturas: cerrarAlertaObtenerFacturas,
};
