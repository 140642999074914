import signs from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

function getEstadoDeCuentaAction(state, response) {
  return {
    ...state,
    estadoDeCuenta: response.data,
    loading: false,
    EstadoDeCuenta_Status: response.status,
  };
}

function getEstadoDeCuentaLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function cerrarAlertaEstadoDeCuenta(state) {
  return {
    ...state,
    EstadoDeCuenta_Status: null,
  };
}

function abrirModalEstadoDeCuenta(state) {
  return {
    ...state,
    abrirModalEstadoDeCuenta: true,
  };
}

function cerrarModalEstadoDeCuenta(state) {
  return {
    ...state,
    abrirModalEstadoDeCuenta: false,
  };
}
export default {
  actionsType: actionsType,
  sign: signs,
  action: {
    getEstadoDeCuentaAction: getEstadoDeCuentaAction,
    getEstadoDeCuentaLoading: getEstadoDeCuentaLoading,
    abrirModalEstadoDeCuenta: abrirModalEstadoDeCuenta,
    cerrarModalEstadoDeCuenta: cerrarModalEstadoDeCuenta,
    cerrarAlertaEstadoDeCuenta: cerrarAlertaEstadoDeCuenta,
  },
  calls: calls,
};
