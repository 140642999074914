import React, { useState, useEffect } from 'react';
import {
  EditOutlined,
  UserOutlined,
  InfoCircleOutlined,
  LockOutlined,
  LinkOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import {
  Table,
  Input,
  Button,
  Tooltip,
  Row,
  Col,
  Modal,
  Form,
  notification,
} from 'antd';
import './consultarProveedores.css';
const { TextArea } = Input;

const ConsultarProveedores = (props) => {
  const [visible, setVisible] = useState(false);

  //-------------------------Values del modal actualizar-----------------------------
  const [descripcion, setNombreDescripcion] = useState('');
  const [webSite, setWebSite] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [correo, setCorreo] = useState(null);
  const [contrasena, setContrasena] = useState('');

  useEffect(() => {
    setNombreDescripcion(props.proveedor.Descripcion);
    setWebSite(props.proveedor.Website);
    setAccessToken(props.proveedor.AccessToken);
    setCorreo(props.proveedor.Email);
    setContrasena(props.proveedor.Contrasena);
  }, [props.proveedor]);

  //-------------USEEFFECTS PARA ACTUALIZAR PROVEEDOR--------------------------------
  useEffect(() => {
    if (props.Actualizar_Proveedor_Status === 200) {
      props.onGetAllProveedores();
      notification.open({
        type: 'success',
        message: '¡Proveedor actualizado correctamente!',
        description: 'El proveedor se actualizó correctamente.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActualizar();
        },
      });
    } else if (
      props.Actualizar_Proveedor_Status === 400 ||
      props.Actualizar_Proveedor_Status === 404 ||
      props.Actualizar_Proveedor_Status === 500
    ) {
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes.',
        duration: 3,
        onClose: () => {
          props.cerrarAlertaActualizar();
        },
      });
    }
  }, [props.Actualizar_Proveedor_Status]);

  //-------------------------Datos de los proveedores------------------------
  const datos = props.proveedores.map(function (elemento) {
    return {
      key: elemento.ID_Proveedor,
      nombre: elemento.Nombre,
      correo: elemento.Email,
      sitioweb: elemento.Website,
      token: elemento.AccessToken,
    };
  });

  //--------------------Función de actualizar proveedor-----------------
  const ActualizarProveedor = (id) => {
    props.onGetProveedor(id);
    setTimeout(() => {
      setVisible(!visible);
    }, 1000);
  };

  //--------------------Funciones de Modal actualizar proveedor-----------------
  const handleSubmitActualizar = (values) => {
    const Proveedor = {
      ID_Proveedor: props.proveedor.ID_Proveedor,
      Nombre: props.proveedor.Nombre,
      Descripcion: values.descripcion,
      Website: values.website,
      AccessToken: values.access_token,
      Email: values.email,
      Contrasena: values.password,
      Activo: props.proveedor.Activo,
    };
    props.onGetProveedoresLoading();
    props.onPutProveedor(Proveedor);
  };

  const handleCancel = () => {
    setVisible(!visible);
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: '10%',
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
      width: '12%',
    },
    {
      title: 'Sitio web',
      align: 'center',
      dataIndex: 'sitioweb',
      key: 'sitioweb',
      width: '10%',
    },
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
      width: '12%',
    },
    {
      title: 'Acciones',
      align: 'center',
      fixed: 'right',
      width: '5%',
      render: (record) => (
        <>
          <Tooltip title='Editar'>
            <EditOutlined onClick={() => ActualizarProveedor(record.key)} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      {/* Modal para actualizar un proveedor*/}
      <Modal
        title='Actualizar Proveedor'
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        centered
      >
        <Form
          name='Actualizar_Proveedor_Form'
          initialValues={{
            descripcion: descripcion,
            website: webSite,
            access_token: accessToken,
            email: correo,
            password: contrasena,
          }}
          onFinish={handleSubmitActualizar}
        >
          <Form.Item
            name='descripcion'
            rules={[
              {
                required: true,
                whitespace: true,
                message: '¡Este campo es requerido!',
              },
              {
                min: 1,
                max: 350,
                message: 'Mínimo 1 carácter, máximo 350 carácteres',
              },
            ]}
          >
            <TextArea rows={3} placeholder='Descripción' />
          </Form.Item>

          <Form.Item
            name='website'
            rules={[
              {
                required: true,
                whitespace: true,
                message: '¡Este campo es requerido!',
              },
              {
                min: 1,
                max: 200,
                message: 'Mínimo 1 carácter, máximo 200 carácteres',
              },
            ]}
          >
            <Input
              placeholder='Sitio Web'
              style={{ marginTop: '-2%' }}
              prefix={<LinkOutlined />}
              suffix={
                <Tooltip title='Sitio Web del proveedor' placement='bottomLeft'>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
          </Form.Item>

          <Form.Item
            name='access_token'
            rules={[
              {
                required: true,
                whitespace: true,
                message: '¡Este campo es requerido!',
              },
              {
                min: 1,
                max: 500,
                message: 'Mínimo 1 carácter, máximo 500 carácteres',
              },
            ]}
          >
            <Input
              placeholder='Access Token'
              style={{ marginTop: '-2%' }}
              prefix={<IdcardOutlined />}
              suffix={
                <Tooltip
                  title='Access Token del proveedor'
                  placement='bottomLeft'
                >
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
          </Form.Item>

          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                whitespace: true,
                message: '¡Este campo es requerido!',
              },
              {
                min: 1,
                max: 150,
                message: 'Mínimo 1 carácter, máximo 150 carácteres',
              },
              {
                pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                message: 'Formato de correo inválido',
              },
            ]}
          >
            <Input
              type='email'
              placeholder='Correo'
              style={{ marginTop: '-2%' }}
              prefix={<UserOutlined />}
              suffix={
                <Tooltip title='Correo del proveedor' placement='bottomLeft'>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
          </Form.Item>

          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                whitespace: true,
                message: '¡Este campo es requerido!',
              },
            ]}
          >
            <Input.Password
              type='password'
              placeholder='Contraseña'
              style={{ marginTop: '-2%' }}
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Row justify='center'>
            <Col
              lg={{ offset: 0, span: 8 }}
              md={{ offset: 0, span: 8 }}
              sm={{ offset: 0, span: 8 }}
              xs={{ offset: 0, span: 8 }}
            >
              <Button htmlType='submit' className='botonActualizarProveedor'>
                Actualizar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Pantalla de consulta de usuarios */}
      <Row style={{ marginTop: '2%' }}>
        <Col
          lg={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          xs={{ offset: 0, span: 24 }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={datos}
            scroll={{ x: 1000, y: 420 }}
            pagination={{
              hideOnSinglePage: true,
              defaultCurrent: 1,
              total: datos.length,
              responsive: true,
              showSizeChanger: true,
              size: 'small',
              defaultPageSize: 20,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ConsultarProveedores;
