import signs from './signs';
import api from '../../../api/api';

function getAllFacturas(parametros) {
  return (dispatch) => {
    api
      .post(`api/Invoice`, { ...parametros })
      .then(function (response) {
        dispatch(signs.getFacturasSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getFacturasFailure(err));
      });
  };
}

function getFacturasLoading() {
  return (dispatch) => {
    dispatch(signs.getFacturasLoading());
  };
}

function cerrarAlertaObtenerFacturas() {
  return (dispatch) => {
    dispatch(signs.cerrarAlertaObtenerFacturas());
  };
}

export default {
  getAllFacturas: getAllFacturas,
  getFacturasLoading: getFacturasLoading,
  cerrarAlertaObtenerFacturas: cerrarAlertaObtenerFacturas,
};
