import signs from './signs';
import calls from './calls';
import actionsType from './actionsTypes';

function getInsumosAction(state, response) {
  return {
    ...state,
    insumos: response,
    controlDeEnvasesError: '',
    loading: false,
  };
}

function getInsumosLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

export default {
  actionsType: actionsType,
  sign: signs,
  action: {
    getInsumosAction: getInsumosAction,
    getInsumosLoading: getInsumosLoading,
  },
  calls: calls,
};
