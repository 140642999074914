import signs from './signs';
import api from '../../../api/api';
/**
 * Obtener todas las notificaciones existentes para el cliente específico.
 * @param  {string} cliente
 */
function getAllNotificaciones(cliente) {
  return (dispatch) => {
    api
      .get(`api/Notificacion/${cliente}`)
      .then(function (response) {
        dispatch(signs.getNotificacionesSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getNotificacionesFailure(err));
      });
  };
}

function getAllNotificacionesConFecha(parametros) {
  return (dispatch) => {
    api
      .post(`api/Notificacion`, { ...parametros })
      .then(function (response) {
        dispatch(signs.getNotificacionesConFechaSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getNotificacionesConFechaFailure(err));
      });
  };
}

function getAllNotificacionesNuevas(cliente) {
  return (dispatch) => {
    api
      .get(`nuevas/${cliente}`)
      .then(function (response) {
        dispatch(signs.getNotificacionesNuevasSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getNotificacionesNuevasFailure(err));
      });
  };
}

function getAllNotificacionesNuevasConsumo(cliente) {
  return (dispatch) => {
    api
      .get(`nuevasConsumo/${cliente}`)
      .then(function (response) {
        dispatch(signs.getNotificacionesNuevasConsumoSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.getNotificacionesNuevasConsumoFailure(err));
      });
  };
}

function marcarNotificacionLeida(notificacion) {
  return (dispatch) => {
    api
      .post(`api/Notificacion/Update`, { ...notificacion })
      .then(function (response) {
        dispatch(signs.marcarNotificacionLeidaSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.marcarNotificacionLeidaFailure(err));
      });
  };
}

function marcarNotificacionLeidaPanel(notificacion) {
  return (dispatch) => {
    api
      .post(`api/Notificacion/Update`, { ...notificacion })
      .then(function (response) {
        dispatch(signs.marcarNotificacionLeidaPanelSuccess(response));
      })
      .catch((err) => {
        dispatch(signs.marcarNotificacionLeidaPanelFailure(err));
      });
  };
}

function getNotificacionesLoading() {
  return (dispatch) => {
    dispatch(signs.getNotificacionesLoading());
  };
}

function cerrarAlertaObtenerNotificaciones() {
  return (dispatch) => {
    dispatch(signs.cerrarAlertaObtenerNotificaciones());
  };
}

function establecerTabActivo(Tab) {
  return (dispatch) => {
    dispatch(signs.establecerTabActivo(Tab));
  };
}

function establecerIdDeNotificacionSeleccionada(id) {
  return (dispatch) => {
    dispatch(signs.establecerIdDeNotificacionSeleccionada(id));
  };
}

function limpiarIdDeNotificacionSeleccionada() {
  return (dispatch) => {
    dispatch(signs.limpiarIdDeNotificacionSeleccionada());
  };
}

function contarNotificacionesNoLeidas(valor) {
  return (dispatch) => {
    dispatch(signs.contarNotificacionesNoLeidas(valor));
  };
}

function filtrandoFechas(Fechas) {
  return (dispatch) => {
    dispatch(signs.filtrandoFechas(Fechas));
  };
}

export default {
  getAllNotificaciones: getAllNotificaciones,
  getAllNotificacionesConFecha: getAllNotificacionesConFecha,
  getAllNotificacionesNuevas: getAllNotificacionesNuevas,
  getAllNotificacionesNuevasConsumo: getAllNotificacionesNuevasConsumo,
  marcarNotificacionLeida: marcarNotificacionLeida,
  marcarNotificacionLeidaPanel: marcarNotificacionLeidaPanel,
  getNotificacionesLoading: getNotificacionesLoading,
  cerrarAlertaObtenerNotificaciones: cerrarAlertaObtenerNotificaciones,
  establecerTabActivo: establecerTabActivo,
  establecerIdDeNotificacionSeleccionada: establecerIdDeNotificacionSeleccionada,
  limpiarIdDeNotificacionSeleccionada: limpiarIdDeNotificacionSeleccionada,
  contarNotificacionesNoLeidas: contarNotificacionesNoLeidas,
  filtrandoFechas: filtrandoFechas,
};
