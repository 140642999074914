import { combineReducers } from 'redux';
import sideBarReducer from './sideBarReducer';
import loginReducer from './loginReducer';
import usuariosReducer from './usuariosReducer';
import proveedoresReducer from './proveedoresReducer';
import empresasReducer from './empresasReducer';
import gestionConsumoReducer from './gestionConsumoReducer';
import rolesReducer from './rolesReducer';
import controlDeEnvasesReducer from './controlDeEnvasesReducer';
import controlDeEnvasesVerComprobanteReducer from './controlDeEnvasesVerComprobanteReducer';
import inventarioReducer from './inventarioReducer';
import consumoReducer from './consumoReducer';
import facturacionReducer from './facturacionReducer';
import facturacionVerFacturaReducer from './facturacionVerFacturaReducer';
import estadoDeCuentaReducer from './estadoDeCuentaReducer';
import notificacionesReducer from './notificacionesReducer';

const todoApp = combineReducers({
  sideBar: sideBarReducer,
  login: loginReducer,
  usuarios: usuariosReducer,
  proveedores: proveedoresReducer,
  empresas: empresasReducer,
  gestionConsumo: gestionConsumoReducer,
  roles: rolesReducer,
  controlDeEnvases: controlDeEnvasesReducer,
  controlDeEnvasesVerComprobante: controlDeEnvasesVerComprobanteReducer,
  inventario: inventarioReducer,
  consumo: consumoReducer,
  facturacion: facturacionReducer,
  facturacionVerFactura: facturacionVerFacturaReducer,
  estadoDeCuenta: estadoDeCuentaReducer,
  notificaciones: notificacionesReducer,
});

export default todoApp;
