import sing from './sings';
import calls from './calls';
import actionsType from './actionsTypes';

function getUsuariosAction(state, response) {
  return {
    ...state,
    persona: response,
    usuariosError: '',
  };
}
function getAllUsuariosAction(state, response) {
  return {
    ...state,
    usuarios: response,
    usuariosError: '',
    loading: false,
    Agregar_Usuario_Status: null,
  };
}

function postUsuariosAction(state, response) {
  // let newUsuario = [
  //   ...state.usuario,
  //   { id: state.usuario.length, text: response },
  // ];
  return {
    ...state,
    Agregar_Usuario_Status: response.data.status,
    loading: false,
  };
}

function putUsuariosAction(state, response) {
  return {
    ...state,
    Actualizar_Usuario_Status: response.data.status,
    loading: false,
  };
}

function deleteUsuariosAction(state, response) {
  return {
    ...state,
    ActivarDesactivar_Usuario_Status: response.data.status,
    loading: false,
  };
}

function cerrarAlertaAgregar(state) {
  return {
    ...state,
    Agregar_Usuario_Status: null,
  };
}

function cerrarAlertaActivarInactivar(state) {
  return {
    ...state,
    ActivarDesactivar_Usuario_Status: null,
  };
}

function cerrarAlertaActualizar(state) {
  return {
    ...state,
    Actualizar_Usuario_Status: null,
  };
}

function getUsuariosLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function ungetUsuariosLoading(state) {
  return {
    ...state,
    loading: false,
  };
}

export default {
  actionsType: actionsType,
  sign: sing,
  action: {
    getUsuariosAction: getUsuariosAction,
    getAllUsuariosAction: getAllUsuariosAction,
    postUsuariosAction: postUsuariosAction,
    putUsuariosAction: putUsuariosAction,
    deleteUsuariosAction: deleteUsuariosAction,
    cerrarAlertaActivarInactivar: cerrarAlertaActivarInactivar,
    cerrarAlertaActualizar: cerrarAlertaActualizar,
    cerrarAlertaAgregar: cerrarAlertaAgregar,
    getUsuariosLoading: getUsuariosLoading,
    ungetUsuariosLoading: ungetUsuariosLoading,
  },
  calls: calls,
};
