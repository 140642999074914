import React, { Component } from 'react';
import ModuloFacturacion from '../../../components/modulosCliente/moduloFacturacion/moduloFacturacion';
import Loading from '../../../components/loading/loading';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';

class moduloFacturacion extends Component {
  componentDidMount() {
    //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
    // pantalla
    var Token = sessionStorage.getItem('TokenUsuario');
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };

    if (usuario.usuario.Facturacion === 'true') {
      let parametros = {
        CodigoEmpresa: usuario.usuario.FacturacionCodigo,
        Pagina: 1,
      };
      this.props.onGetLoading();
      this.props.onGetFacturas(parametros);
    }
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <ModuloFacturacion {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    facturas: state.facturacion.facturas,
    facturasError: state.facturacion.facturasError,
    loading: state.facturacion.loading,
    Obtener_Facturas_Status: state.facturacion.Obtener_Facturas_Status,
    DatosFactura: state.facturacionVerFactura.DatosFactura,
    Ver_Factura_Status: state.facturacionVerFactura.Ver_Factura_Status,
    Ver_Factura_Loading: state.facturacionVerFactura.Ver_Factura_Loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFacturas: (parametros) =>
      dispatch(actions.facturacion.calls.getAllFacturas(parametros)),
    onGetLoading: () =>
      dispatch(actions.facturacion.calls.getFacturasLoading()),
    cerrarAlertaObtenerFacturas: () =>
      dispatch(actions.facturacion.calls.cerrarAlertaObtenerFacturas()),
    onGetDatosDeFactura: (parametros) =>
      dispatch(
        actions.facturacionVerFactura.calls.getDatosDeFactura(parametros),
      ),
    onGetDatosDeFacturaLoaging: () =>
      dispatch(actions.facturacionVerFactura.calls.getDatosDeFacturaLoading()),
    cerrarAlertaObtenerDatosDeFactura: () =>
      dispatch(
        actions.facturacionVerFactura.calls.cerrarAlertaObtenerDatosDeFactura(),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(moduloFacturacion);
