const actionsType = {
  GET_FACTURAS_SUCCESS: 'GET_FACTURAS_SUCCESS',
  GET_FACTURAS_FAILURE: 'GET_FACTURAS_FAILURE',

  GET_FACTURAS_LOADING: 'GET_FACTURAS_LOADING',

  CERRAR_ALERTA_OBTENER_FACTURAS: 'CERRAR_ALERTA_OBTENER_FACTURAS',
};

export default actionsType;
