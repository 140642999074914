import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Modal,
  DatePicker,
  Typography,
  Button,
  Form,
  notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { BlobProvider } from '@react-pdf/renderer';
import Actions from '../../../data/actions';
import VerEstadoDeCuenta from './VerEstadoDeCuenta';
import actions from '../../../data/actions';
// const { RangePicker } = DatePicker;

const ModuloEstadoDeCuenta = () => {
  //Obtener los datos de usuario logueado para saber si tiene o no permiso al mmódulo y mostrar la respectiva
  // pantalla
  var Token = sessionStorage.getItem('TokenUsuario');
  Token = JSON.parse(Token);
  let encrypted = {
    content: Token.content,
    tag: Uint8Array.from(Token.tag.data),
  };
  let TokenDecrypted = decrypt(encrypted);
  let usuario = {
    usuario: jwtDecode(TokenDecrypted),
  };

  let dispatch = useDispatch();
  let RangoDeFecha = useRef();
  const abrirModuloEstadoCuenta = useSelector(
    (state) => state.estadoDeCuenta.abrirModalEstadoDeCuenta,
  );
  const TipoMoneda = useSelector(
    (state) => state.facturacion.facturas.Moneda,
  );
  const estadoDeCuenta = useSelector(
    (state) => state.estadoDeCuenta.estadoDeCuenta,
  );
  const EstadoDeCuenta_Status = useSelector(
    (state) => state.estadoDeCuenta.EstadoDeCuenta_Status,
  );
  const [CargandoEstadoDeCuenta, setCargandoEstadoDeCuenta] = useState(false);
  const [MostarEstadoDeCuenta, setMostarEstadoDeCuenta] = useState(false);

  const [FechaFinalProp, setFechaFinalProp] = useState('');
  let FechaActual = [moment().subtract(6, 'months'), moment()];

  const [Moneda, setMoneda] = useState(null);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('month');
  }

  //Hook para Mostrar el Estado de cuenta si el status del request es OK entonces se abre el PDF, sino entonces
  //se muestra la notificación correspondiente
  useEffect(() => {
    if (EstadoDeCuenta_Status === 200) {
      setMostarEstadoDeCuenta(!MostarEstadoDeCuenta);
      setCargandoEstadoDeCuenta(!CargandoEstadoDeCuenta);
      dispatch(actions.estadoDeCuenta.calls.cerrarAlertaEstadoDeCuenta());
    } else if (EstadoDeCuenta_Status === 409) {
      setCargandoEstadoDeCuenta(!CargandoEstadoDeCuenta);
      notification.open({
        type: 'warning',
        message: '¡NO HAY FACTURAS PARA MOSTRAR!',
        description:
          'No se encontraron facturas pendientes para mostrar en su estado de cuenta.',
        duration: 4,
        onClose: () => {
          dispatch(actions.estadoDeCuenta.calls.cerrarAlertaEstadoDeCuenta());
        },
      });
    } else if (EstadoDeCuenta_Status === 400 || EstadoDeCuenta_Status === 404) {
      setCargandoEstadoDeCuenta(!CargandoEstadoDeCuenta);
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que hay problemas presentes en la petición.',
        duration: 3,
        onClose: () => {
          dispatch(actions.estadoDeCuenta.calls.cerrarAlertaEstadoDeCuenta());
        },
      });
    } else if (EstadoDeCuenta_Status === 500 || EstadoDeCuenta_Status === 503) {
      setCargandoEstadoDeCuenta(!CargandoEstadoDeCuenta);
      notification.open({
        type: 'error',
        message: '¡Ha ocurrido un error!',
        description:
          'La acción no se realizó debido a que el servidor está fuera de servicio.',
        duration: 3,
        onClose: () => {
          dispatch(actions.estadoDeCuenta.calls.cerrarAlertaEstadoDeCuenta());
        },
      });
    }
  }, [EstadoDeCuenta_Status]);

  useEffect(() => {
    let monedas =
    TipoMoneda === "EUR"
        ? "€"
        : TipoMoneda === "USD"
        ? "$"
        : TipoMoneda === "QTZ"
        ? "Q"
        : TipoMoneda === "LPS"
        ? "L"
        : TipoMoneda === "NIO"
        ? "C$"
        : TipoMoneda === "CRC"
        ? "₡"
        : "";
    return setMoneda(monedas);
  }, [TipoMoneda]);
  //Función para generar el estado de cuenta
  const GenerarEstadoDeCuenta = () => {
    if (RangoDeFecha.current.getFieldValue('RangoDeFecha') !== null) {
      // let Fechas = RangoDeFecha.current.getFieldValue('RangoDeFecha');
      // let FechaInicio = Fechas[0].format('YYYY-MM-DD');
      // let FechaFinal = Fechas[1].format('YYYY-MM-DD');
      // setFechaFinalProp(Fechas[1].endOf('month').format('DD/MM/YYYY'));
      // setCargandoEstadoDeCuenta(!CargandoEstadoDeCuenta);
      // let parametros = {
      //   CodigoEmpresa: usuario.usuario.FacturacionCodigo,
      //   FechaInicio: FechaInicio,
      //   FechaFin: FechaFinal,
      // };
      let Fecha = RangoDeFecha.current.getFieldValue('RangoDeFecha');
      let FechaFinal = Fecha.endOf('month').format('YYYY-MM-DD');
      setFechaFinalProp(Fecha.endOf('month').format('DD/MM/YYYY'));
      setCargandoEstadoDeCuenta(!CargandoEstadoDeCuenta);
      let parametros = {
        CodigoEmpresa: usuario.usuario.FacturacionCodigo,
        FechaFin: FechaFinal,
      };
      dispatch(actions.estadoDeCuenta.calls.getEstadoDeCuenta(parametros));
    } else {
      notification.open({
        type: 'info',
        message: '¡SELECCIONE LAS FECHAS!',
        description:
          'Para consultar las facturas seleccione un rango de fechas a consultar.',
        duration: 4,
      });
    }
  };
  return (
    <>
      <Modal
        title='Estado de Cuenta'
        centered
        footer={null}
        visible={abrirModuloEstadoCuenta}
        onCancel={() =>
          dispatch(Actions.estadoDeCuenta.calls.cerrarModalEstadoDeCuenta())
        }
        destroyOnClose
        width='30%'
      >
        <Row>
          <Col
            style={{
              textAlign: 'center',
              backgroundColor: '#81973a',
              borderRadius: 5,
            }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Typography.Text style={{ color: '#FFF' }}>
              Fecha de Corte:
            </Typography.Text>
          </Col>
        </Row>

        <Row style={{ marginTop: '2%' }}>
          <Col
            style={{ textAlign: 'center' }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form
              style={{ marginTop: '3%' }}
              ref={RangoDeFecha}
              // initialValues={{ RangoDeFecha: FechaActual }}
              initialValues={{ RangoDeFecha: moment() }}
            >
              <Form.Item name='RangoDeFecha'>
                {/* <RangePicker
                  size='middle'
                  format={('DD/MM/YYYY', 'DD/MM/YYYY')}
                /> */}
                <DatePicker
                  placeholder='Fecha'
                  size='middle'
                  picker='month'
                  format="MMMM YYYY"
                  style={{width: "60%"}}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Row style={{ marginTop: '-2%' }}>
          <Col
            style={{ textAlign: 'center' }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            {MostarEstadoDeCuenta ? (
              <BlobProvider
                document={
                  <VerEstadoDeCuenta
                    datos={estadoDeCuenta}
                    Usuario={usuario.usuario.FacturacionCodigo}
                    FechaVencimiento={FechaFinalProp}
                    moneda={Moneda}
                  />
                }
              >
                {({ url }) =>
                  url !== null ? (
                    (window.open(url, '_blank'),
                    (
                      <Button
                        onClick={setMostarEstadoDeCuenta(!MostarEstadoDeCuenta)}
                        className='BotonGenerarPDF'
                      >
                        Generar
                      </Button>
                    ))
                  ) : (
                    <Button
                      loading={CargandoEstadoDeCuenta}
                      onClick={() => GenerarEstadoDeCuenta()}
                      className='BotonGenerarPDF'
                    >
                      Generar
                    </Button>
                  )
                }
              </BlobProvider>
            ) : (
              <Button
                loading={CargandoEstadoDeCuenta}
                onClick={() => GenerarEstadoDeCuenta()}
                className='BotonGenerarPDF'
              >
                Generar
              </Button>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ModuloEstadoDeCuenta;
