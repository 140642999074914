import actionsType from './actionsTypes';

function getComprobanteSuccess(data) {
  return { type: actionsType.GET_COMPROBANTE_SUCCESS, data: data };
}
function getComprobanteFailure(error) {
  return { type: actionsType.GET_COMPROBANTE_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function setCodigoCliente(data) {
  return { type: actionsType.SET_CODIGO_CLIENTE, data: data };
}
function clearCodigoCliente() {
  return { type: actionsType.CLEAR_CODIGO_CLIENTE };
}
//--------------------------------------------------------------------------------------
function getComprobanteLoading() {
  return { type: actionsType.GET_COMPROBANTE_LOADING };
}
//--------------------------------------------------------------------------------------
function limpiarStatusVerComprobane() {
  return { type: actionsType.LIMPIAR_STATUS };
}

export default {
  getComprobanteSuccess: getComprobanteSuccess,
  getComprobanteFailure: getComprobanteFailure,
  getComprobanteLoading: getComprobanteLoading,
  setCodigoCliente: setCodigoCliente,
  clearCodigoCliente: clearCodigoCliente,
  limpiarStatusVerComprobane: limpiarStatusVerComprobane,
};
