import React, { useEffect, useState } from "react";
import Home from "./container/home/home";
import Layout from "./container/layout/layout";
import GuardedRoute from "./hoc/GuardedRoute";
import { ConfigProvider } from "antd";
import esES from "antd/es/locale/es_ES";
import store from "./data/store";
import { generateRoutes } from "./routes";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { path } from "./routes";
import EstadoDeCuenta from "./components/modulosCliente/moduloEstadoDeCuenta/moduloEstadoDeCuenta";

function App() {
  const directory = generateRoutes();

  const [userLogged, setUserLogged] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const routes = directory.map((route) => (
    <GuardedRoute
      key={route.path}
      validator={route.validator}
      path={route.path}
      notAuthorizedRedirection={route.defaultUrl}
      component={route.view}
    ></GuardedRoute>
  ));

  useEffect(() => {
    let timer1 = setTimeout(() => {
      let ruta = window.location.pathname;
      let userLogged = sessionStorage.getItem("TokenUsuario");
      if ((userLogged && ruta === "/") || (userLogged && ruta !== "/")) {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          focusConfirm: false,
          timer: 15000,
          timerProgressBar: true,
          title: `Cierre de sesión`,
          text: `La sesión se cerrará debido a inactividad del usuario`,

          confirmButtonText: `Continuar con la sesión`,
          confirmButtonColor: "#81973a",
          icon: "info",
        }).then(function (result) {
          if (result.value) {
            setRefresh(!refresh);
            Swal.fire(
              "Sesión activa",
              "¡La sesión se mantiene activa!",
              "info"
            );
          } else if (result.dismiss) {
            setRefresh(!refresh);
            sessionStorage.removeItem("TokenUsuario");
            window.location.href = path.default;
          }
        });
      }
    }, 21600000);
    return () => clearTimeout(timer1);
  }, [refresh]);

  useEffect(() => {
    setUserLogged(sessionStorage.getItem("TokenUsuario"));
  }, [userLogged]);

  let redirectToUrl;
  if (sessionStorage.getItem("TokenUsuario")) {
    redirectToUrl = (
      <Layout>
        <Switch>{routes}</Switch>
        <EstadoDeCuenta />
      </Layout>
    );
  }if (!sessionStorage.getItem("TokenUsuario")) {
    redirectToUrl = <Home />;
  }

  return (
    <ConfigProvider locale={esES}>
      <Provider store={store}>
        <Router>{redirectToUrl}</Router>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
