import actions from '../actions';

const proveedores = actions.proveedores;

const initialState = {
  proveedor: [],
  proveedores: [],
  proveedoresError: '',
  Actualizar_Proveedor_Status: null,
  loading: false,
};

function proveedoresApp(state = initialState, action) {
  switch (action.type) {
    case proveedores.actionsType.GET_PROVEEDORES_SUCCESS:
      return proveedores.action.getProveedoresAction(state, action.data);
    case proveedores.actionsType.GET_PROVEEDORES_FAILURE:
      return {
        ...state,
        proveedor: [],
        proveedores: [],
        proveedoresError: 'No se pudo recuperar la información',
      };
    case proveedores.actionsType.GETALL_PROVEEDORES_SUCCESS:
      return proveedores.action.getAllProveedoresAction(state, action.data);
    case proveedores.actionsType.GETALL_PROVEEDORES_FAILURE:
      return {
        ...state,
        proveedor: [],
        proveedores: [],
        proveedoresError: 'No se pudo recuperar la información',
        loading: false,
      };
    case proveedores.actionsType.POST_PROVEEDORES_SUCCESS:
      return proveedores.action.postProveedoresAction(state, action.data);
    case proveedores.actionsType.POST_PROVEEDORES_FAILURE:
      return {
        ...state,
        proveedor: [],
        proveedores: [],
        proveedoresError: 'No se pudo recuperar la información',
        loading: false,
      };
    case proveedores.actionsType.PUT_PROVEEDORES_SUCCESS:
      return proveedores.action.putProveedoresAction(state, action);
    case proveedores.actionsType.PUT_PROVEEDORES_FAILURE:
      return {
        ...state,
        Actualizar_Proveedor_Status: action.errorMessage.response.status,
        loading: false,
      };
    case proveedores.actionsType.DELETE_PROVEEDORES_SUCCESS:
      return proveedores.action.deleteProveedoresAction(state, action.data);
    case proveedores.actionsType.DELETE_PROVEEDORES_FAILURE:
      return {
        ...state,
        proveedor: [],
        proveedores: [],
        proveedoresError: 'No se pudo recuperar la información',
        loading: false,
      };
    case proveedores.actionsType.CERRAR_ALERTA_ACTUALIZAR:
      return proveedores.action.cerrarAlertaActualizar(state);
    case proveedores.actionsType.GET_PROVEEDORES_LOADING:
      return proveedores.action.getProveedoresLoading(state);
    default:
      return { ...state };
  }
}

export default proveedoresApp;
