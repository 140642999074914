import actionsType from './actionsTypes';

//-----------------------------------SIGNS PARA OBTENER LA FATURA DEL CLIENTE-----------------------------
function getDatosDeFacturaSuccess(data) {
  return { type: actionsType.OBTENER_DATOS_FACTURA_SUCCESS, data: data };
}
function getDatosDeFacturaFailure(error) {
  return {
    type: actionsType.OBTENER_DATOS_FACTURA_FAILURE,
    errorMessage: error,
  };
}
//--------------------------------------------------------------------------------------
function getDatosDeFacturaLoading() {
  return { type: actionsType.OBTENER_DATOS_FACTURA_LOADING };
}
//--------------------------------------------------------------------------------------
function cerrarAlertaObtenerDatosFactura() {
  return { type: actionsType.CERRAR_ALERTA_OBTENER_DATOS_FACTURA };
}

export default {
  getDatosDeFacturaSuccess: getDatosDeFacturaSuccess,
  getDatosDeFacturaFailure: getDatosDeFacturaFailure,
  getDatosDeFacturaLoading: getDatosDeFacturaLoading,
  cerrarAlertaObtenerDatosFactura: cerrarAlertaObtenerDatosFactura,
};
