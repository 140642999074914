import sing from './sings';
import calls from './calls';
import actionsType from './actionsTypes';
import { encrypt } from 'react-crypt-gsm';

/**
 * Esta función recibe el estado y la respuesta del endpoint de inicio de sesión,
 * se verifica el sessionStorage para saber si existe un token previo o no,
 * si no existe ningún token entonces se procede a encriptar el token de la respuesta
 * del endpoint y colocarlo en el sessionStorage para poder acceder a él desde cualquier parte
 * del sitio, pero este está encriptado.
 * @param  {object} state El estado actual
 * @param  {object} response La respuesta del endpoint de inicio de sesión
 *
 */
function loginAction(state, response) {
  let userFromStorage = sessionStorage.getItem('TokenUsuario');
  if (userFromStorage === null || userFromStorage === undefined) {
    if (response.data.data !== 0) {
      let tokenENCRYPTED = encrypt(response.data.data.toString());
      sessionStorage.setItem('TokenUsuario', JSON.stringify(tokenENCRYPTED));
      return {
        ...state,
        login: true,
        loading: false,
        Login_Status: response.data.status,
      };
    } else {
      sessionStorage.removeItem('TokenUsuario');
      return {
        ...state,
        login: false,
        loading: false,
        Login_Status: response.data.status,
      };
    }
  } else {
    sessionStorage.removeItem('TokenUsuario');
    if (response.data.data !== 0) {
      let tokenENCRYPTED = encrypt(response.data.data);
      sessionStorage.setItem('TokenUsuario', JSON.stringify(tokenENCRYPTED));
      return {
        ...state,
        login: true,
        loading: false,
        Login_Status: response.data.status,
      };
    } else {
      sessionStorage.removeItem('TokenUsuario');
      return {
        ...state,
        login: false,
        loading: false,
        Login_Status: response.data.status,
      };
    }
  }
}

// function loginAction(state, response) {
//   let userFromStorage = sessionStorage.getItem('TokenUsuario');
//   if (userFromStorage === null || userFromStorage === undefined) {
//     if (response.data.data !== 0) {
//       let jwtDecode = require('jwt-decode');
//       let decoded = jwtDecode(response.data.data);
//       let usuario = {
//         usuario: decoded,
//         token: response.data.data,
//       };
//       sessionStorage.setItem('TokenUsuario', JSON.stringify(usuario));
//       return {
//         ...state,
//         login: response.data.data,
//         loading: false,
//         Login_Status: response.data.status,
//       };
//     } else {
//       sessionStorage.removeItem('TokenUsuario');
//       return {
//         ...state,
//         loginError: '0',
//         loading: false,
//         Login_Status: response.data.status,
//       };
//     }
//   } else {
//     sessionStorage.removeItem('TokenUsuario');
//     return {
//       ...state,
//       loginError: '0',
//       loading: false,
//       Login_Status: response.data.status,
//     };
//   }
// }

function logoutAction(state) {
  sessionStorage.removeItem('TokenUsuario');
  return {
    ...state,
  };
}

function startLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function limpiarStateLogin() {
  return {
    login: false,
    loginError: '',
    loading: false,
    Login_Status: null,
  };
}

export default {
  actionsType: actionsType,
  sign: sing,
  action: {
    loginAction: loginAction,
    logoutAction: logoutAction,
    startLoading: startLoading,
    limpiarStateLogin: limpiarStateLogin,
  },
  calls: calls,
};
