import React, { Component } from 'react';
import ModuloControlDeEnvases from '../../../components/modulosCliente/moduloControlDeEnvases/moduloControlDeEnvases';
import Loading from '../../../components/loading/loading';
import { connect } from 'react-redux';
import actions from '../../../data/actions';
import moment from 'moment';
import { decrypt } from 'react-crypt-gsm';
import jwtDecode from 'jwt-decode';

class moduloControlDeEnvases extends Component {
  componentDidMount() {
    this.props.onLimpiarStatusVerComprobante();
    var Token = sessionStorage.getItem('TokenUsuario');
    Token = JSON.parse(Token);
    let encrypted = {
      content: Token.content,
      tag: Uint8Array.from(Token.tag.data),
    };
    let TokenDecrypted = decrypt(encrypted);
    let usuario = {
      usuario: jwtDecode(TokenDecrypted),
    };

    if (
      usuario.usuario.ControlEnvase === 'true' &&
      usuario.usuario.ControlEnvaseCodigo !== ''
    ) {
      let parametros = {
        Empresa: usuario.usuario.ControlEnvaseCodigo,
        Fecha: moment().format('MM/YYYY'),
      };
      this.props.onGetLoading();
      this.props.onGetInsumos(parametros);
    }
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <Loading />
        ) : (
          <ModuloControlDeEnvases {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insumos: state.controlDeEnvases.insumos,
    controlDeEnvasesError: state.controlDeEnvases.controlDeEnvasesError,
    loading: state.controlDeEnvases.loading,
    verComprobanteStatus:
      state.controlDeEnvasesVerComprobante.verComprobanteStatus,
    datos: state.controlDeEnvasesVerComprobante.datos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetInsumos: (parametros) =>
      dispatch(actions.controlDeEnvases.calls.getAllInsumos(parametros)),
    onSetCodigoCliente: (codigo) =>
      dispatch(
        actions.controlDeEnvasesVerComprobante.calls.setCodigoCliente(codigo),
      ),
    onGetLoading: () =>
      dispatch(actions.controlDeEnvases.calls.getInsumosLoading()),
    onGetComprobante: (parametros) =>
      dispatch(
        actions.controlDeEnvasesVerComprobante.calls.getComprobante(parametros),
      ),
    onClearCodigoCliente: () =>
      dispatch(
        actions.controlDeEnvasesVerComprobante.calls.clearCodigoCliente(),
      ),
    onLimpiarStatusVerComprobante: () =>
      dispatch(
        actions.controlDeEnvasesVerComprobante.calls.limpiarStatusVerComprobante(),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(moduloControlDeEnvases);
