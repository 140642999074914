import React from "react";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import Logo from "../../../assets/logo-gaspro.png";
import FloatFormatter from "../../../hoc/helpers";

const VerEstadoDeCuenta = (props) => {
  let Facturas = [];
  let Montos = [];

 let moneda =
    props.datos.Moneda === "EUR"
      ? "€"
      : props.datos.Moneda === "USD"
      ? "$"
      : props.datos.Moneda === "QTZ"
      ? "Q"
      : props.datos.Moneda === "LPS"
      ? "L"
      : props.datos.Moneda === "NIO"
      ? "C$"
      : props.datos.Moneda === "CRC"
      ? "₡"
      : "";
  //Se extraen los registros de facturas que vienen del api y se guardan en un arreglo que se recorre luego en el
  //render del documento para mostrar los registros
  props.datos.Facturas.forEach((element) => {
    Facturas.push(element);
  });
  //Se extraen los montos de la sección de saldo, para luego renderizarlos por orden en el documento
  props.datos.Montos.forEach((elemento) => {
    Montos.push(elemento.Monto);
  });
  return (
    <>
      <Document title="Estado De Cuenta">
        <Page style={styles.pagina}>
          <Text style={styles.EspacioFijado} fixed>
            {" "}
          </Text>
          {/* ENCABEZADO DEL DOCUMENTO FIJADO PARA TODAS LAS PÁGINAS */}
          <View style={styles.HeaderContainer}>
            <View style={styles.seccionIzquierda}>
              <Image src={Logo} style={styles.Logo} />
              <Text>{props.datos.Compania}</Text>
              <Text style={styles.SubTituloEmpresa}>
                VENTAS AL POR MAYOR DE OTROS PRODUCTOS
              </Text>
            </View>

            <View style={styles.seccionCentral}>
              <Text style={styles.TituloCentral}>
                ESTADO DE CUENTA DE CLIENTES
              </Text>
              <Text style={styles.TituloCentral2}>
                VENCIMIENTO AL: {props.FechaVencimiento}
              </Text>
            </View>

            <View style={styles.seccionDerecha}>
              <Text style={styles.TextoEncabezadoDerechaDireccion}>
                {props.datos.DireccionCompania}
              </Text>
              <Text style={styles.TextoEncabezadoDerechaTelefono}>
                Tel: {props.datos.TelefonoCompania}
              </Text>
              <Text style={styles.TextoEncabezadoDerechaEmail}>
                Email: {props.datos.CorreoCompania}
              </Text>
            </View>
          </View>

          <View style={styles.divider} />

          {/* SECCION PARA LA INFORMACION DEL CLIENTE */}
          <View style={styles.informacionClienteContenedor}>
            <View style={styles.informacionClienteRow}>
              <Text style={styles.tituloInformacionCliente}>Cliente:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <Text style={styles.tituloInformacionCliente}>
                {props.Usuario} - {props.datos.NombreCliente}</Text>
            </View>
            <View style={styles.informacionClienteRow}>
              <Text style={styles.tituloInformacionCliente}>
                Persona de Contacto:&nbsp;&nbsp;
                {props.datos.PersonaContacto === null
                  ? ""
                  : props.datos.PersonaContacto}
              </Text>
            </View>
            <View style={styles.informacionClienteRow}>
              <Text style={styles.tituloInformacionCliente}>Telefóno:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <Text>{props.datos.TelefonoCliente}</Text>
            </View>
            <View style={styles.informacionClienteRow}>
              <Text style={styles.tituloInformacionCliente}>Dirección:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <Text>{props.datos.DireccionCliente}</Text>
            </View>
            <View style={styles.informacionClienteRow}>
              <View style={styles.informacionClienteRowCredito}>
                <Text style={styles.tituloInformacionCliente}>
                  Límite de Crédito:&nbsp;
                </Text>
                <Text>
                  {moneda}&nbsp;{(Math.round(props.datos.LimiteCredito * 100) / 100).toFixed(
                    2
                  )}
                </Text>
              </View>
              <View style={styles.informacionClienteRowVendedor}>
                <Text style={styles.tituloInformacionCliente}>
                  Vendedor Asignado:
                </Text>
                <Text>{props.datos.Vendedor}</Text>
              </View>
            </View>
          </View>

          <View style={styles.divider} />

          {/* SECCION PARA LOS TITULOS DE LA TABLA */}
          <View style={styles.titulosTabla}>
            <View style={styles.titulosTablaViews}>
              <Text>Documento Físico</Text>
            </View>
            <View style={styles.titulosTablaViews}>
              <Text>Fecha Factura</Text>
            </View>
            <View style={styles.titulosTablaViews}>
              <Text>Fecha Vencimiento</Text>
            </View>
            <View style={styles.titulosTablaViewDescripcion}>
              <Text>Descripción</Text>
            </View>
            <View style={styles.titulosTablaViews}>
              <Text>Importe</Text>
            </View>
            <View style={styles.titulosTablaViews}>
              <Text>Saldo Actual</Text>
            </View>
          </View>

          <View style={styles.divider} />

          {Facturas.map((element) => {
            let FechaFactura = element.FechaFactura.split("T");
            FechaFactura = FechaFactura[0].split("-");
            FechaFactura = `${FechaFactura[2]}/${FechaFactura[1]}/${FechaFactura[0]}`;

            let FechaVencimiento = element.FechaVencimientoFactura.split("T");
            FechaVencimiento = FechaVencimiento[0].split("-");
            FechaVencimiento = `${FechaVencimiento[2]}/${FechaVencimiento[1]}/${FechaVencimiento[0]}`;
            return (
              <View style={styles.registrosRow}>
                <View style={styles.registrosCeldas}>
                  <Text>{element.DocumentoFisico}</Text>
                </View>
                <View style={styles.registrosCeldas}>
                  <Text>{FechaFactura}</Text>
                </View>
                <View style={styles.registrosCeldas}>
                  <Text>{FechaVencimiento}</Text>
                </View>
                <View style={styles.registrosCeldasDescripcion}>
                  <Text>{element.Descripcion}</Text>
                </View>
                <View style={styles.registrosCeldasMontos}>
                  <Text>
                  {moneda}&nbsp;{FloatFormatter((Math.round(element.Importe * 100) / 100).toFixed(2))}
                  </Text>
                </View>
                <View style={styles.registrosCeldasMontos}>
                  <Text>
                  {moneda}&nbsp;{FloatFormatter((Math.round(element.SaldoActual * 100) / 100).toFixed(2))}
                  </Text>
                </View>
              </View>
            );
          })}

          <View style={styles.divider} />

          {/* SECCION PARA LOS DETALLES DEL SALDO */}
          <View style={styles.detallesDeSaldo}>
            <View style={styles.detallesDeSaldoTop}>
              <Text style={styles.titulosSaldoTop}>Saldo</Text>
              <Text style={styles.titulosSaldoTop}>Corriente</Text>
              <Text style={styles.titulosSaldoTop}>0 - 30</Text>
              <Text style={styles.titulosSaldoTop}>31 - 60</Text>
              <Text style={styles.titulosSaldoTop}>61 - 90</Text>
              <Text style={styles.titulosSaldoTop}>91 - 120</Text>
              <Text style={styles.titulosSaldoTop}>+120</Text>
            </View>
            <View style={styles.dividerSaldo} />
            <View style={styles.detallesDeSaldoBottom}>
              {Montos.map((monto) => {
                return (
                  <Text style={styles.columnasSaldoBottom}>
                    {moneda}&nbsp;{FloatFormatter((Math.round(monto * 100) / 100).toFixed(2))}
                  </Text>
                );
              })}
            </View>
          </View>

          {/* SECCION DEL PIE DE PAGINA FIJADO PARA TODAS LAS PAGINAS */}
          <View style={styles.pieDePagina}>
            <View style={styles.pieDePaginaSeccionIzquierda}>
              <Text>{moment().format("DD/MM/YYYY")}</Text>
            </View>
            <View style={styles.pieDePaginaSeccionCentro}>
              <Text>{props.datos.Compania} - Depto. de Cobros</Text>
            </View>
            <View style={styles.pieDePaginaSeccionDerecha}>
              <Text>{moment().format("h:mm:ss A")}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};
export default VerEstadoDeCuenta;

const styles = StyleSheet.create({
  EspacioFijado: {
    height: 20,
  },
  pagina: {
    paddingBottom: 20,
  },
  HeaderContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    height: 120,
    width: "94%",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: 11,
  },
  seccionIzquierda: {
    flex: 1 / 3,
    textAlign: "center",
  },
  Logo: {
    width: "60%",
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  SubTituloEmpresa: {
    fontSize: 7,
    marginTop: 8,
  },
  seccionCentral: {
    flex: 1 / 3,
    justifyContent: "center",
    textAlign: "center",
    fontSize: 10,
  },
  TituloCentral: {
    textDecoration: "underline",
    marginBottom: 10,
  },
  seccionDerecha: {
    flex: 1 / 3,
    justifyContent: "center",
    textAlign: "right",
    paddingLeft: 1,
  },
  TextoEncabezadoDerecha: {
    marginTop: 5,
  },
  divider: {
    borderTop: "4 solid #000",
    marginTop: 0,
    marginBottom: 0,
    width: "94%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  informacionClienteContenedor: {
    height: 80,
    width: "94%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  informacionClienteRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 2,
    marginBottom: 2,
    fontSize: 10,
  },
  informacionClienteRowCredito: {
    flexDirection: "row",
    marginTop: "auto",
    marginBottom: "auto",
    fontSize: 10,
    flex: 0.8 / 2,
  },
  informacionClienteRowVendedor: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "auto",
    marginBottom: "auto",
    fontSize: 10,
    flex: 1 / 2,
  },
  tituloInformacionCliente: {
    marginRight: 20,
  },
  titulosTabla: {
    height: 30,
    width: "94%",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 9,
  },
  titulosTablaViews: {
    flex: 1 / 6,
    textAlign: "center",
    justifyContent: "center",
  },
  titulosTablaViewDescripcion: {
    flex: 2 / 6,
    textAlign: "center",
    justifyContent: "center",
  },
  registrosRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "94%",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: 9,
    marginTop: 6,
    marginBottom: 6,
  },
  registrosCeldas: {
    flex: 1 / 6,
    textAlign: "center",
  },
  registrosCeldasMontos: {
    flex: 1 / 6,
    textAlign: "right",
    marginRight: "1%"
  },
  registrosCeldasDescripcion: {
    flex: 2 / 6,
    textAlign: "center",
  },
  detallesDeSaldo: {
    height: 80,
    width: "80%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  detallesDeSaldoTop: {
    marginTop: "auto",
    marginBottom: "auto",
    height: "auto",
    textAlign: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 10,
  },
  titulosSaldoTop: {
    flex: 1 / 7,
  },
  dividerSaldo: {
    borderTop: "2 solid #000",
  },
  detallesDeSaldoBottom: {
    marginTop: "auto",
    marginBottom: "auto",
    height: "auto",
    flexDirection: "row",
    textAlign: "center",
  },
  columnasSaldoBottom: {
    flex: 1 / 7,
    fontSize: 10,
  },
  pieDePagina: {
    height: 50,
    width: "94%",
    marginLeft: "3%",
    marginRight: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 10,
    position: "absolute",
    bottom: 0,
  },
  pieDePaginaSeccionIzquierda: {
    flex: 1 / 3,
    textAlign: "left",
    justifyContent: "center",
  },
  pieDePaginaSeccionCentro: {
    flex: 2 / 3,
    textAlign: "center",
    justifyContent: "center",
  },
  pieDePaginaSeccionDerecha: {
    flex: 1 / 3,
    textAlign: "right",
    justifyContent: "center",
  },
});
