import actionsType from './actionsTypes';

function getInsumosSuccess(data) {
  return { type: actionsType.GET_INSUMOS_SUCCESS, data: data };
}
function getInsumosFailure(error) {
  return { type: actionsType.GET_INSUMOS_FAILURE, errorMessage: error };
}
//--------------------------------------------------------------------------------------
function getInsumosLoading() {
  return { type: actionsType.GET_INSUMOS_LOADING };
}

export default {
  getInsumosSuccess: getInsumosSuccess,
  getInsumosFailure: getInsumosFailure,
  getInsumosLoading: getInsumosLoading,
};
