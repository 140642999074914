import sideBar from './sideBar/sideBarActions';
import login from './login/loginActions';
import usuarios from './usuarios/usuariosActions';
import empresas from './empresas/empresasActions';
import gestionConsumo from './gestionConsumo/consumoActions';
import proveedores from './proveedores/proveedoresActions';
import roles from './roles/rolesActions';
import controlDeEnvases from './controlDeEnvases/controlDeEnvasesActions';
import controlDeEnvasesVerComprobante from './controlDeEnvasesVerComprobante/controlDeEnvasesVerComprobanteActions';
import inventario from './inventario/inventarioActions';
import consumo from './consumo/consumoActions';
import facturacion from './facturacion/facturacionActions';
import facturacionVerFactura from './facturacionVerFactura/facturacionVerFacturaActions';
import estadoDeCuenta from './estadoDeCuenta/estadoDeCuentaActions';
import notificaciones from './notificaciones/notificacionesActions';

const actions = {
  sideBar: sideBar,
  login: login,
  usuarios: usuarios,
  empresas: empresas,
  gestionConsumo: gestionConsumo,
  proveedores: proveedores,
  roles: roles,
  controlDeEnvases: controlDeEnvases,
  controlDeEnvasesVerComprobante: controlDeEnvasesVerComprobante,
  inventario: inventario,
  consumo: consumo,
  facturacion: facturacion,
  facturacionVerFactura: facturacionVerFactura,
  estadoDeCuenta: estadoDeCuenta,
  notificaciones: notificaciones,
};

export default actions;
